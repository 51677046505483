import Vue from 'vue';
export default {
	/**
   * 判断是否为空
   */
	isEmpty: function (value) {
		if (value === null || value === undefined || value === '') {
			return true;
		} else if ((value instanceof Number || typeof value === 'number') && isNaN(value)) {
			return true;
		} else {
			return false;
		}
	},
	dateFormat: function (date, sSplit) {
		let sDate, sY, sM, sD;
		if (date instanceof Date) {
			sY = date.getFullYear();
			sM = date.getMonth() + 1 + '';
			if (sM.length === 1) {
				sM = '0' + sM;
			}
			sD = date.getDate() + '';
			if (sD.length === 1) sD = '0' + sD;
		} else {
			sDate = date.toString();
			if (/^\d{8}$/.test(sDate)) {
				sY = sDate.substring(0, 4);
				sM = sDate.substring(4, 6);
				sD = sDate.substring(6, 8);
			} else return '-';
		}

		if (sSplit === 'CN') {
			sDate = sY + '年' + sM + '月' + sD + '日';
		} else {
			if (sSplit === '' || sSplit == null) {
				sSplit = '-';
			}
			sDate = sY + sSplit + sM + sSplit + sD;
		}
		return sDate;
	},
	getToken: function () {
		return localStorage.getItem('tokenSBD');
	},
	getClientHeight: function () {
		let clientHeight = 0;
		if (document.body.clientHeight && document.documentElement.clientHeight) {
			clientHeight =
				document.body.clientHeight < document.documentElement.clientHeight
					? document.body.clientHeight
					: document.documentElement.clientHeight;
		} else {
			clientHeight =
				document.body.clientHeight > document.documentElement.clientHeight
					? document.body.clientHeight
					: document.documentElement.clientHeight;
		}
		return clientHeight;
	},
	//获取验证规则，验证错误提示
	validate(type) {
		switch (type) {
			//手机号
			case 'phone':
				return [/^1\d{10}$/, '请输入11位手机号！'];
				break;
			//密码
			case 'password':
				//6-16位字母数字特殊字符的组合
				return [/^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{6,16}$/, '请输入6-16位字母数字特殊字符的组合'];
				//6-16位字母数字的组合
				// return [/^((?=.*[0-9].*)(?=.*[A-Za-z].*))[_0-9A-Za-z]{6,16}$/,'请输入6-16位字母数字的组合'];
				break;
			//身份证号
			case 'idCard':
				//复杂
				return [
					/^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/,
					'请输入正确身份证号！'
				];
				//简单
				// return [/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,'请输入正确身份证号！']
				break;
			//邮箱
			case 'email':
				return [/^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/, '请输入包含@和.的正确邮箱！'];
				break;
			//浮点数，小数点后两位数
			case 'float':
				return [/^[0-9]+([.][0-9]{1,2})?$/, '请输入整数或保留两位小数的浮点数！'];
				break;
			default:
				return '';
		}
	},
	// 验证大于或等于零的整数
	validatePositiveInteger: (rule, value, callback) => {
		// let patrn = /^(0|([1-9]\d*))$/;
		let patrn = /^\d{1,8}?$/;
		if (value != null && value != '') {
			if (patrn.test(value) == false) {
				callback(new Error('请输入大于或等于零的8位以内的整数！'));
			} else {
				callback();
			}
		} else {
			callback();
		}
	},
	// 验证大于等于0的所有数，小数点后保留两位小数
	validateThanZero: (rule, value, callback) => {
		// let patrn = /^(0|[1-9]\d*)(\s|$|\.\d{1,2}\b)/;
		let patrn = /^\d{1,10}(\.\d{1,2})?$/;
		if (value != null && value != '') {
			if (patrn.test(value) == false) {
				callback(new Error('请输入整数位10位，小数位2位，不可为负值'));
			} else {
				callback();
			}
		} else {
			callback();
		}
	},
	// 验证大于等于0的所有数，小数点后保留两位小数
	validateThanZeroFifteen: (rule, value, callback) => {
		let patrn = /^\d{1,12}(\.\d{1,2})?$/;
		if (value != null && value != '') {
			if (patrn.test(value) == false) {
				callback(new Error('请输入整数位12位，小数位2位，不可为负值'));
			} else {
				callback();
			}
		} else {
			callback();
		}
	},
	// 验证可以为负数的浮点数
	validateFloatingNumber: (rule, value, callback) => {
		// let patrn = /^([\+ \-]?(([1-9]\d*)|(0)))([.]\d{0,2})?$/;

		let patrn = /^-?\d{1,10}([.]\d{1,2})?$/;

		if (value != null && value != '') {
			console.log(value)
			if (patrn.test(value) == false) {
				callback(new Error('请输入整数位10位，小数位2位，可为负值'));
			} else {
				callback();
			}
		} else {
			callback();
		}
	},
	// 验证固话和手机号
	validatePhone: (rule, value, callback) => {
		// let patrn = /^((13[0-9])|(14[5|7])|(15([0-3]|[5-9]))|(18[0,5-9]))\d{8}$|^((13[0-9])|(14[5|7])|(15([0-3]|[5-9]))|(18[0,5-9]))\d{8}$|^0\d{2,3}-?\d{7,8}$/;
		let patrn = /^[\d\+\-\(\)]*$/;
		if (value != null && value != '') {
			if (patrn.test(value) == false) {
				callback(new Error('请输入正确的号码！'));
			} else if (value.length < 5) {
				callback(new Error('号码长度不得小于5位！'));
			} else {
				callback();
			}
		} else {
			callback();
		}
	},
	//验证%不可以为负6位以内
	validatePercentageThanZero: (rule, value, callback) => {
		// let patrn = /^(0|[1-9]\d*)(\s|$|\.\d{1,2}\b)/;
		let patrn = /^\d{1,3}(\.\d{1,2})?$/;
		if (value != null && value != '') {
			if (patrn.test(value) == false) {
				// callback(new Error("请输入大于等于零的整数或者保留两位的浮点数！"));
				callback(new Error('请输入整数位3位，小数位2位，不可为负值'));
			} else {
				callback();
			}
		} else {
			callback();
		}
	},

	//验证%可以为负7位以内
	validatePercentage: (rule, value, callback) => {
		// let patrn = /^([\+ \-]?(([1-9]\d*)|(0)))([.]\d{0,2})?$/;
		let patrn = /^-?\d{1,3}([.]\d{1,2})?$/;
		if (value != null && value != '') {
			if (patrn.test(value) == false) {
				callback(new Error('请输入整数位3位，小数位2位，可为负值'));
			} else {
				callback();
			}
		} else {
			callback();
		}
	},
	// 统一社会信用代码
	validateUniformSocialCreditCode: (rule, value, callback) => {
		var patrn = /^[0-9A-Z]+$/;
		// if (value != null && value != '') {
		// var patrn = /^[0-9A-Z]+$/;
		//18位校验及大写校验
		if (value.length != 18 || patrn.test(value) == false) {
			/*console.info("不是有效的统一社会信用编码！");*/
			callback(new Error('不是有效的统一社会信用编码！'));
		} else {
			var Ancode; //统一社会信用代码的每一个值
			var Ancodevalue; //统一社会信用代码每一个值的权重
			var total = 0;
			var weightedfactors = [1, 3, 9, 27, 19, 26, 16, 17, 20, 29, 25, 13, 8, 24, 10, 30, 28]; //加权因子
			var str = '0123456789ABCDEFGHJKLMNPQRTUWXY';
			//不用I、O、S、V、Z
			for (var i = 0; i < value.length - 1; i++) {
				Ancode = value.substring(i, i + 1);
				Ancodevalue = str.indexOf(Ancode);
				total = total + Ancodevalue * weightedfactors[i];
				//权重与加权因子相乘之和
			}
			var logiccheckcode = 31 - total % 31;
			if (logiccheckcode == 31) {
				logiccheckcode = 0;
			}
			var Str = '0,1,2,3,4,5,6,7,8,9,A,B,C,D,E,F,G,H,J,K,L,M,N,P,Q,R,T,U,W,X,Y';
			var Array_Str = Str.split(',');
			logiccheckcode = Array_Str[logiccheckcode];

			var checkcode = value.substring(17, 18);
			if (logiccheckcode != checkcode) {
				/*console.info("不是有效的统一社会信用编码！");*/
				callback(new Error('不是有效的统一社会信用编码！'));
			} else {
				callback();
			}
			callback();
		}
		// if (patrn.test(value) == false) {
		//     callback(new Error("请输入正确的统一社会信用代码！"));
		// } else {
		//     callback();
		// }
		// } else {
		// 	callback();
		// }
	},
	// 密码验证
	validatePass: (rule, value, callback) => {
		if (value === '') {
			callback(new Error('请输入密码'));
		} else {
			var reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/;
			if (!reg.test(value)) {
				callback(new Error('请输入6-16位字母、数字组合'));
				return;
			}
			callback();
		}
	},
	// 验证邮编
	validateZipCode: (rule, value, callback) => {
		let patrn = /^\d{5,6}$/;
		if (value != null && value != '') {
			if (patrn.test(value) == false) {
				callback(new Error('请输入正确的邮政编码(5-6位数字)！'));
			} else {
				callback();
			}
		} else {
			callback();
		}
	},
	// 验证邮箱
	validateMailbox: (rule, value, callback) => {
		let patrn = /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/;
		if (value != null && value != '') {
			if (patrn.test(value) == false) {
				callback(new Error('请输入包含@和.的正确邮箱！'));
			} else {
				callback();
			}
		} else {
			callback();
		}
	},
	//手机号码验证
	validateCellPhoneNumber: (rule, value, callback) => {
		let patrn = /^1\d{10}$/;
		if (value != null && value != '') {
			if (patrn.test(value) == false) {
				callback(new Error('请输入11位手机号！'));
			} else {
				callback();
			}
		} else {
			callback();
		}
	},
	/**
   * 说明：统一信用代码验证
   */

	CheckSocialCreditCode(Code) {
		var patrn = /^[0-9A-Z]+$/;
		//18位校验及大写校验
		if (Code.length != 18 || patrn.test(Code) == false) {
			/*console.info("不是有效的统一社会信用编码！");*/
			return false;
		} else {
			var Ancode; //统一社会信用代码的每一个值
			var Ancodevalue; //统一社会信用代码每一个值的权重
			var total = 0;
			var weightedfactors = [1, 3, 9, 27, 19, 26, 16, 17, 20, 29, 25, 13, 8, 24, 10, 30, 28]; //加权因子
			var str = '0123456789ABCDEFGHJKLMNPQRTUWXY';
			//不用I、O、S、V、Z
			for (var i = 0; i < Code.length - 1; i++) {
				Ancode = Code.substring(i, i + 1);
				Ancodevalue = str.indexOf(Ancode);
				total = total + Ancodevalue * weightedfactors[i];
				//权重与加权因子相乘之和
			}
			var logiccheckcode = 31 - total % 31;
			if (logiccheckcode == 31) {
				logiccheckcode = 0;
			}
			var Str = '0,1,2,3,4,5,6,7,8,9,A,B,C,D,E,F,G,H,J,K,L,M,N,P,Q,R,T,U,W,X,Y';
			var Array_Str = Str.split(',');
			logiccheckcode = Array_Str[logiccheckcode];

			var checkcode = Code.substring(17, 18);
			if (logiccheckcode != checkcode) {
				/*console.info("不是有效的统一社会信用编码！");*/
				return false;
			} else {
				/*console.info("yes");*/
			}
			return true;
		}
	},
	// 半角转全角
	ToDBC(str, e) {
		let indexFocus = e.target.selectionEnd;
		let arr = [];
		for (let i = 0; i < str.length; i++) {
			arr.push(str.charCodeAt(i));
		}
		for (var k in arr) {
			if (arr[k] == 32) {
				arr[k] = 12288;
			}
		}
		let aa = String.fromCharCode(...arr);
		Vue.nextTick(() => {
			e.target.selectionEnd = indexFocus;
		});
		return aa;
	},
	 //数字转大写
	 numberToChinese: num => {
		const chineseNums = [
		  "零",
		  "一",
		  "二",
		  "三",
		  "四",
		  "五",
		  "六",
		  "七",
		  "八",
		  "九"
		];
		const chineseUnits = ["", "十", "百", "千"];
  
		if (num === 0) {
		  return chineseNums[0];
		}
  
		let chineseStr = "";
		let unitIndex = 0;
  
		while (num > 0) {
		  const digit = num % 10;
		  if (digit !== 0) {
			// 处理非零数字
			chineseStr =
			  chineseNums[digit] + chineseUnits[unitIndex] + chineseStr;
		  } else {
			// 处理连续的零，只在个位数为零时添加零
			if (
			  chineseStr.length > 0 &&
			  chineseStr.charAt(0) !== chineseNums[0]
			) {
			  chineseStr = chineseNums[0] + chineseStr;
			}
		  }
  
		  num = Math.floor(num / 10);
		  unitIndex++;
		}
  
		return chineseStr;
	  },
};
