<template>
  <div class="attachment  GL_declareForm">
    <div class="attention">
      <span>提醒：</span>
      相关扫描件请使用原件扫描或复印件加盖公章后扫描，格式为*.pdf,大小不超过20M(若超过请拆分上传)，标注
      <span>*</span> 的附件为必须上传。
    </div>
    <Form
      class="form"
      ref="formValidate"
      :model="formValidate"
      :label-width="0"
    >
      <div class="m_item">
        <Row
          :key="item.sfId"
          v-for="(item, index) in formValidate.programFilesDtoList"
          type="flex"
          class="m_row"
        >
          <Col style="padding:8px" class="m_col m_title" span="8">
            <span class="spantext">
              <span
                :style="item.wasNeed == 1 ? '' : 'visibility: hidden'"
                class="redStar"
                >*</span
              >{{ item.declareMaterialName }}</span
            >
          </Col>
          <Col class="s_col" span="16">
            <my-upload
              multiple
              type="drag"
              :isShowUploadlist="true"
              :size="size"
              :format="format"
              :formatMassage="formatMassage"
              :defaultList="item.programFilesList"
              :sfId="item.sfId"
              action="/shsj_declare/file/fileUpload"
              @uploadSuccess="uploadSuccess(index, arguments)"
              @uploadRemove="uploadRemove(index, arguments)"
            >
              <Button icon="ios-cloud-upload-outline">点击上传</Button>
            </my-upload>
            <!-- <template v-for="(item2, index) in item.programFilesList">
              <div class="picText" :key="index">
                <p>{{ item2.fileName }}</p>
                <div class="demo-upload-list-cover">
                  <Icon
                    type="ios-trash-outline"
                    size="20"
                    @click.native="handleRemove(item.sfId, index)"
                  ></Icon>
                </div>
              </div>
            </template> -->
          </Col>
        </Row>
      </div>
      <p>
        附件下载：
        <a title="点击下载" :href="link" :download="linkName"
          >企业设计引领发展情况报告编写提纲</a
        >
      </p>
      <!-- <a :href="item.filePath" :download="item.filePath"> -->
    </Form>
    <div class="foot">
      <button v-waves @click="cancel" class="GL_cancel">取消</button>
      <button v-waves @click="save" class="GL_save">保存</button>
      <Button type="primary" :loading="loading" @click="submit" class="GL_next"
        >提交</Button
      >
    </div>
  </div>
</template>

<script>
import waves from "../../directive/waves";
import myUpload from "../../components/upload";
export default {
  components: {
    myUpload
  },
  directives: { waves },
  name: "",
  props: {},
  data() {
    return {
      linkName: null,
      link: null,
      loading: false,
      programId: null,
      size: 20,
      format: ["pdf", "doc", "docx"],
      formatMassage: "*.pdf、*.doc、*.docx",
      forEachFiles: {},
      specialFiles: [],
      formValidate: {
        programFilesDtoList: null
      }
    };
  },
  created() {
    this.queryAttachment();
    this.queryBook();
  },

  computed: {},
  mounted() {},
  methods: {
    /*字典查询
     **/

    async queryBook() {
      let { messageCode, result } = await this.$http.get(
        "/shsj_declare/sysDict/queryDictDataList?dictId=10400"
      );
      if (messageCode == 10000) {
        this.link = result.dictDataList[0].dataValue;
        this.linkName = this.link.split("/")[this.link.split("/").length - 1];
      }
    },
    /*查询附件
     *method queryAttachment
     */
    async queryAttachment() {
      let { messageCode, result } = await this.$http.post(
        "/shsj_declare/ldeDeclare/queryLdeDeclareInfo",
        {
          programId: this.$route.query.programId,
          type: "6",
          specialId: this.$route.query.specialId
        }
      );
      if (messageCode === 10000) {
        this.programId = result.declareInfo.programId;
        this.formValidate.programFilesDtoList =
          result.declareInfo.programFilesDtoList;
      }
    },
    /**上传成功
     * method uploadSuccess
     * */
    uploadSuccess(index, arg) {
      this.formValidate.programFilesDtoList[index].programFilesList.push(
        arg[0]
      );
    },
    /**删除
     * method handleRemove
     * */
    uploadRemove(index, arg) {
      if (arg[0].fileId) {
        let removeId = this.formValidate.programFilesDtoList[
          index
        ].programFilesList.findIndex(item => item.fileId == arg[0].fileId);
        if (removeId != -1) {
          this.formValidate.programFilesDtoList[index].programFilesList.splice(
            removeId,
            1
          );
        }
      } else {
        if (arg[0].response.messageCode == 10000) {
          let delindex = this.formValidate.programFilesDtoList[
            index
          ].programFilesList.findIndex(
            item => item.filePath == arg[0].response.result.returnFile.filePath
          );
          this.formValidate.programFilesDtoList[index].programFilesList.splice(
            delindex,
            1
          );
        } else {
          this.$Message.error(arg[0].response.messageContent);
        }
      }
    },
    /*保存表单
     *method saveProgram
     **/
    async saveProgram(type) {
      try {
        let { messageCode } = await this.$http.post(
          "/shsj_declare/ldeDeclare/saveLdeDeclareInfo",
          {
            programId: this.programId,
            specialId: this.$route.query.specialId,
            type: "6",
            programFilesDtoList: this.formValidate.programFilesDtoList
          }
        );
        if (messageCode === 10000) {
          try {
            if (type == "submit") {
              var res = await this.$http.get(
                `/shsj_declare/ldeDeclare/subProgram?programId=${this.programId}`
              );
              if (res.messageCode == 10000) {
                this.$Message.success("提交成功");
                this.$router.push("/manage");
              }
            } else {
              this.$Message.success("保存页面成功!");
            }
            this.loading = false;
          } catch (error) {
            this.loading = false;
          }
        }
      } catch (error) {
        this.loading = false;
      }
    },
    /**取消保存
     * method cancel
     * */
    cancel() {
      this.$router.go(-1);
    },
    /**保存
     * method save
     * */
    save() {
      this.saveProgram();
    },
    /**提交
     * method submit
     * */
    submit() {
      this.loading = true;
      this.saveProgram("submit");
    }
  }
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.attachment {
  .m_item {
    .s_col {
      border-right: 1px solid #e8ecf5;
      border-bottom: 1px solid #e8ecf5;
      .ivu-btn {
        margin: 5px;
        border-radius: 20px;
        padding: 10px 50px;
      }
      .picText {
        padding: 10px;
        display: inline-block;
      }
    }
  }
}
.spantext {
  max-width: 100%;
  display: inline-block;
  overflow-wrap: break-word; /*  css3属性，当内容超过指定容器的边界时是否断行  */
}
.attention {
  margin-bottom: 15px;
  span {
    color: red;
  }
}
</style>
