<template>
  <div class="register GL_login_register">
    <div class="loginBox">
      <div class="from">
        <div class="title">上海设计申报项目管理平台</div>
        <Form
          ref="formValidate"
          :model="formValidate"
          :rules="ruleValidate"
          :label-width="0"
        >
          <FormItem label prop="companyName">
            <Input
              :maxlength="50"
              v-model.trim="formValidate.companyName"
              placeholder="请输入公司名称"
            ></Input>
          </FormItem>
          <FormItem label prop="accountCode">
            <Input
              v-model.trim="formValidate.accountCode"
              placeholder="请输入统一社会信用代码"
            ></Input>
          </FormItem>
          <FormItem label prop="email">
            <Input
              :maxlength="50"
              v-model.trim="formValidate.email"
              placeholder="请输入邮箱"
            ></Input>
          </FormItem>
          <FormItem label prop="contentName">
            <Input
              :maxlength="20"
              v-model.trim="formValidate.contentName"
              placeholder="请输入联系人姓名"
            ></Input>
          </FormItem>
          <FormItem label prop="contentPhone">
            <Input
              v-model.trim="formValidate.contentPhone"
              placeholder="请输入联系人电话"
            ></Input>
          </FormItem>
          <FormItem label prop="accountPassword">
            <Input
              :type="isShowPwdOne"
              @keydown.enter.native="searchEnterFun"
              v-model.trim="formValidate.accountPassword"
              placeholder="请输入密码"
            >
              <Icon
                style="font-size:18px"
                @click="isShowPwd('one')"
                :type="
                  isShowPwdOne == 'password'
                    ? 'ios-eye-off-outline'
                    : 'ios-eye-outline'
                "
                slot="suffix"
              />
            </Input>
          </FormItem>
          <FormItem label prop="confirmPassword">
            <Input
              :type="isShowPwdTwo"
              v-model.trim="formValidate.confirmPassword"
              placeholder="请再次输入密码"
            >
              <Icon
                style="font-size:18px"
                @click="isShowPwd('two')"
                :type="
                  isShowPwdTwo == 'password'
                    ? 'ios-eye-off-outline'
                    : 'ios-eye-outline'
                "
                slot="suffix"
              />
            </Input>
          </FormItem>
          <FormItem class="formItem" label prop="pictureCode">
            <Input
              v-model.trim="formValidate.pictureCode"
              placeholder="请输入验证码"
            ></Input>
            <img
              @click="getCodeImg"
              alt="点击更换"
              title="点击更换"
              :src="this.codeUrl"
            />
          </FormItem>
          <FormItem>
            <Button type="primary" @click="handleSubmit('formValidate')"
              >注册</Button
            >
          </FormItem>
        </Form>
        <div class="foot">
          已有账号,
          <router-link to="/login">去登录</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  props: {},
  data() {
    const validateEmail = (rule, value, callback) => {
      let patrn = /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/;
      if (value === "") {
        callback(new Error("请输入邮箱！"));
      } else if (patrn.test(value) == false) {
        callback(new Error("请输入正确的邮箱地址！"));
      } else {
        callback();
      }
    };
    const validatePhone = (rule, value, callback) => {
      let patrn = /^1\d{10}$/;
      if (value === "") {
        callback(new Error("请输入手机号！"));
      } else if (patrn.test(value) == false) {
        callback(new Error("请输入11位的手机号！"));
      } else {
        callback();
      }
    };
    const validatePwd = (rule, value, callback) => {
      var reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/;
      if (value === "") {
        callback(new Error("请输入密码"));
      } else if (!reg.test(value)) {
        callback(new Error("请输入6-16位字母、数字的组合"));
        return;
      } else {
        if (this.formValidate.confirmPassword !== "") {
          // 对第二个密码框单独验证
          this.$refs.formValidate.validateField("confirmPassword");
        }
        callback();
      }
    };
    const validatePassCheck = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.formValidate.accountPassword) {
        callback(new Error("两次输入的密码不一致"));
      } else {
        callback();
      }
    };
    return {
      isShowPwdOne: "password",
      isShowPwdTwo: "password",
      codeUrl: "/shsj_declare/account/getCaptcha",
      formValidate: {
        companyName: "",
        accountCode: "",
        email: "",
        contentName: "",
        contentPhone: "",
        accountPassword: "",
        confirmPassword: "",
        pictureCode: ""
      },
      ruleValidate: {
        companyName: [
          { required: true, message: "请输入公司名称", trigger: "blur" }
        ],
        accountCode: [
          {
            validator: this.$util.validateUniformSocialCreditCode,
            trigger: "blur"
          }
        ],
        email: [{ validator: validateEmail, trigger: "blur" }],
        contentName: [
          {
            required: true,
            message: "联系人姓名不能为空",
            trigger: "blur"
          }
        ],
        contentPhone: [{ validator: validatePhone, trigger: "blur" }],
        accountPassword: [{ validator: validatePwd, trigger: "blur" }],
        confirmPassword: [{ validator: validatePassCheck, trigger: "blur" }],
        pictureCode: [
          { required: true, message: "验证码不能为空", trigger: "blur" }
        ]
      }
    };
  },
  created() {
    this.getCodeImg();
  },
  mounted() {},
  methods: {
    /**
     * methods
     * 点击注册
     */
    handleSubmit(name) {
      this.$refs[name].validate(async valid => {
        if (valid) {
          // this.

          var postData = JSON.parse(JSON.stringify(this.formValidate));
          postData.accountPassword = this.formValidate.accountPassword;
          postData.confirmPassword = this.formValidate.confirmPassword;
          let res = await this.$http.post(
            "shsj_declare/account/saveAccount",
            postData
          );
          if (res.messageCode === 10000) {
            this.$Message.success("注册成功!");
          }
          setTimeout(() => {
            this.$router.push("/login");
          }, 2000);
        }
      });
    },
    searchEnterFun() {
      this.handleSubmit("formValidate");
    },
    /**
     * 窗口代码
     * @param {Boolean} bRefresh 是否刷新
     * 刷新验证码功能
     */
    async getCodeImg() {
      let num = new Date().getTime();
      // let num = Math.ceil(Math.random() * 10); //生成一个随机数（防止缓存）
      this.codeUrl = "/shsj_declare/account/getCaptcha?" + num;
    },
    // showPwd
    isShowPwd(type) {
      if (type == "one") {
        if (this.isShowPwdOne == "password") {
          this.isShowPwdOne = "text";
        } else {
          this.isShowPwdOne = "password";
        }
      } else {
        if (this.isShowPwdTwo == "password") {
          this.isShowPwdTwo = "text";
        } else {
          this.isShowPwdTwo = "password";
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.register {
  overflow: hidden;
  min-height: 100vh;
  .loginBox {
    margin: 70px auto;
    .formItem {
      width: 80%;
      position: relative;
      img {
        width: 80px;
        position: absolute;
        top: -2px;
        right: -90px;
      }
    }
  }
}
</style>
