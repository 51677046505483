<style lang="scss" rel="stylesheet/scss" scoped>
.u-upload {
  ::v-deep {
    .ivu-upload-drag {
      /*overflow: inherit;*/
      border: none;
    }
  }
}
</style>

<template>
  <Upload
    multiple
    type="drag"
    :default-file-list="listObj"
    :show-upload-list="isShowUploadlist"
    :format="format"
    :disabled="disabled"
    :max-size="size * 1024"
    :on-format-error="handleFormatError"
    :on-exceeded-size="handleMaxSize"
    :before-upload="handleBefore"
    :on-success="handleSuccess"
    :on-progress="handleProgress"
    :action="action"
    :headers="getHeaders"
    :data="fileInfo"
    class="u-upload"
    :on-remove="remove"
  >
    <div>
      <slot></slot>
    </div>
  </Upload>
</template>

<script>
export default {
  name: "",
  model: {
    prop: "wjData"
  },
  props: {
    wjData: {
      type: Array,
      default() {
        return [];
      }
    },
    action: {
      type: String,
      default() {
        return "";
      }
    },
    isShowUploadlist: {
      type: Boolean,
      default() {
        return false;
      }
    },
    uploadType: {
      type: String,
      default() {
        return "";
      }
    },
    sfId: {
      type: Number
    },
    format: {
      type: Array,
      default() {
        return [];
      }
    },
    defaultList: {
      type: Array,
      default() {
        return [];
      }
    },
    size: {
      type: Number,
      default() {
        return null;
      }
    },
    formatMassage: {
      type: String,
      default() {
        return "";
      }
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      }
    },
    //文件夹名称
    folderName: {
      type: null,
      default: ""
    }
  },
  data() {
    return {
      sizeErrorMessageZh: "文件大小不能超过2M！",
      fileInfo: {
        files: "",
        folderName: ""
      },
      listObj: []
    };
  },
  watch: {},
  computed: {
    getHeaders() {
      return {
        authorization: localStorage.tokenSBD
      };
    }
  },
  components: {},
  // created() {
  //   console.log(this.defaultList);
  //   this.$nextTick(() => {
  //     this.defaultList.forEach(item => {
  //       this.listObj.push({
  //         name: item.fileName,
  //         url: item.filePath,
  //         fileId: item.fileId
  //       });
  //     });
  //   });
  // },
  mounted() {
    this.defaultList.forEach(item => {
      this.listObj.push({
        name: item.fileName,
        url: item.filePath,
        fileId: item.fileId
      });
    });
  },
  methods: {
    /**上传文件之前的钩子
     * method handleBefore
     * */
    handleBefore(file) {
      this.fileInfo.folderName = this.folderName;
      this.fileInfo.files = file;
      this.$emit("processShow", true);
      // if(file.name.length>200){
      //   this.$Message.error('文件名长度超过200')
      //   return false
      // }
    },
    /**上传格式错误
     * method handleFormatError
     * */
    handleFormatError(type, file) {
      console.log(this.type);
      this.$Message.error("正确格式" + this.formatMassage + "！");
    },
    /**上传大小错误
     * method handleMaxSize
     * */
    handleMaxSize(file) {
      if (this.size >= 1) {
        this.$Message.error("文件大小不能超过" + this.size + "M！");
      } else {
        this.$Message.error("文件大小不能超过" + this.size * 1000 + "K！");
      }
    },
    handleProgress(event){
        // event.loaded		已经上传了文件大小
        // event.total		上传文件总大小
        let uploadPercent = ((parseInt(event.loaded) / parseInt(event.total)) * 100).toFixed(1)
        this.$emit("uploadProgress", uploadPercent);

    },
    /**上传成功
     * method handleSuccess
     * */
    handleSuccess(res) {
      // this.$Message.success("上传成功!");
      // this.$emit("uploadSuccess", res.result, this.uploadType);
      if (res.messageCode == 10000) {
        if(res.result.returnFile.heigh){
          this.$emit("uploadSuccess", res.result.returnFile, this.sfId);
        }else{
          this.$Message.success("上传成功!");
          // res.message = JSON.parse(res.message);
          this.$emit("uploadSuccess", res.result.returnFile, this.sfId);
        }

        // console.log(res.message);
        // if (res.message) {
        //   this.wjData.push({
        //     fileName: res.message.fileName,
        //     filePath: res.message.filePath
        // fileSize: res.result.fileSize,
        //   });
        // }
        this.$emit("update", res.result, this.uploadType);
      } else {
        this.$Message.error(res.messageContent);
      }
    },
    // remove
    remove(e) {
      this.$emit("uploadRemove", e, this.sfId);
    }
  }
};
</script>
