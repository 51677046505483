<style scoped lang="scss">

</style>

<template>
  <Page :total="page.total" :page-size="page.pageSize"  show-elevator show-total @on-change="onChange"/>
</template>

<script>
	export default {
		name: "",
        props:{
          page:{
              type:Object,
              default:{}
          } 
        },
		data(){
			return {}
		},
        watch:{

        },
        computed:{

        },
        components:{

        },
        mounted(){

        },
        methods:{
            /**页码改变
             * method onChange
             * */
            onChange(page){
              this.$emit('onChange',page)
            }
        }
	}
</script>


