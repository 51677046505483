var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"OtherSituation GL_declareForm Gl_input"},[_c('Form',{staticClass:"form",attrs:{"model":_vm.formValidate,"label-width":0}},[_c('div',{staticClass:"m_item"},[_c('Row',{staticClass:"m_row",attrs:{"type":"flex"}},[_c('Col',{staticClass:"m_col m_title",attrs:{"span":"8"}},[_vm._v(" 企业参与制定国际、国家或行业标准情况 ")]),_c('Col',{staticClass:"m_col",attrs:{"span":"16"}},[_c('Input',{attrs:{"maxlength":1000,"type":"textarea","autosize":{ minRows: 3, maxRows: 8 }},on:{"on-change":function($event){_vm.formValidate.standardSituation = _vm.$util.ToDBC(
                _vm.formValidate.standardSituation,
                $event
              )}},model:{value:(_vm.formValidate.standardSituation),callback:function ($$v) {_vm.$set(_vm.formValidate, "standardSituation", $$v)},expression:"formValidate.standardSituation"}})],1)],1),_c('Row',{staticClass:"m_row",attrs:{"type":"flex"}},[_c('Col',{staticClass:"m_col m_title",attrs:{"span":"8"}},[_vm._v(" 企业获国际、国内设计奖项情况 ")]),_c('Col',{staticClass:"m_col",attrs:{"span":"16"}},[_c('Input',{attrs:{"maxlength":1000,"type":"textarea","autosize":{ minRows: 3, maxRows: 8 }},on:{"on-change":function($event){_vm.formValidate.awardsSituation = _vm.$util.ToDBC(
                _vm.formValidate.awardsSituation,
                $event
              )}},model:{value:(_vm.formValidate.awardsSituation),callback:function ($$v) {_vm.$set(_vm.formValidate, "awardsSituation", $$v)},expression:"formValidate.awardsSituation"}})],1)],1),_c('Row',{staticClass:"m_row",attrs:{"type":"flex"}},[_c('Col',{staticClass:"m_col m_title",attrs:{"span":"8"}},[_vm._v(" 企业获国家级、本市科技奖情况 ")]),_c('Col',{staticClass:"m_col",attrs:{"span":"16"}},[_c('Input',{attrs:{"maxlength":1000,"type":"textarea","autosize":{ minRows: 3, maxRows: 8 }},on:{"on-change":function($event){_vm.formValidate.technologySituation = _vm.$util.ToDBC(
                _vm.formValidate.technologySituation,
                $event
              )}},model:{value:(_vm.formValidate.technologySituation),callback:function ($$v) {_vm.$set(_vm.formValidate, "technologySituation", $$v)},expression:"formValidate.technologySituation"}})],1)],1),_c('Row',{staticClass:"m_row",attrs:{"type":"flex"}},[_c('Col',{staticClass:"m_col m_title",attrs:{"span":"8"}},[_vm._v(" 企业被认定国家级、市级、区级工业设计中心情况 ")]),_c('Col',{staticClass:"m_col",attrs:{"span":"16"}},[_c('Input',{attrs:{"maxlength":1000,"type":"textarea","autosize":{ minRows: 3, maxRows: 8 }},on:{"on-change":function($event){_vm.formValidate.designCenterSituation = _vm.$util.ToDBC(
                _vm.formValidate.designCenterSituation,
                $event
              )}},model:{value:(_vm.formValidate.designCenterSituation),callback:function ($$v) {_vm.$set(_vm.formValidate, "designCenterSituation", $$v)},expression:"formValidate.designCenterSituation"}})],1)],1),_c('Row',{staticClass:"m_row",attrs:{"type":"flex"}},[_c('Col',{staticClass:"m_col m_title",attrs:{"span":"8"}},[_vm._v(" 企业参与国家、本市重要设计类活动情况 ")]),_c('Col',{staticClass:"m_col",attrs:{"span":"16"}},[_c('Input',{attrs:{"maxlength":1000,"type":"textarea","autosize":{ minRows: 3, maxRows: 8 }},on:{"on-change":function($event){_vm.formValidate.activitySituation = _vm.$util.ToDBC(
                _vm.formValidate.activitySituation,
                $event
              )}},model:{value:(_vm.formValidate.activitySituation),callback:function ($$v) {_vm.$set(_vm.formValidate, "activitySituation", $$v)},expression:"formValidate.activitySituation"}})],1)],1),_c('Row',{staticClass:"m_row",attrs:{"type":"flex"}},[_c('Col',{staticClass:"m_col m_title",attrs:{"span":"8"}},[_vm._v(" 其他有关情况 ")]),_c('Col',{staticClass:"m_col",attrs:{"span":"16"}},[_c('Input',{attrs:{"maxlength":1000,"type":"textarea","autosize":{ minRows: 3, maxRows: 8 }},on:{"on-change":function($event){_vm.formValidate.otherSituation = _vm.$util.ToDBC(
                _vm.formValidate.otherSituation,
                $event
              )}},model:{value:(_vm.formValidate.otherSituation),callback:function ($$v) {_vm.$set(_vm.formValidate, "otherSituation", $$v)},expression:"formValidate.otherSituation"}})],1)],1)],1)]),_c('div',{staticClass:"foot"},[_c('button',{directives:[{name:"waves",rawName:"v-waves"}],staticClass:"GL_cancel",on:{"click":_vm.cancel}},[_vm._v("取消")]),_c('button',{directives:[{name:"waves",rawName:"v-waves"}],staticClass:"GL_save",on:{"click":_vm.save}},[_vm._v(" 保存 ")]),_c('button',{directives:[{name:"waves",rawName:"v-waves"}],staticClass:"GL_next",on:{"click":_vm.next}},[_vm._v(" 保存并跳转下一步 ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }