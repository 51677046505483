<template>
  <div class="forgotPwd">
    <div class="form forgotPwd_box">
      <div class="formBox">
        <div class="title"><Icon type="md-unlock" />修改密码</div>
        <Form
          class="GL_form"
          ref="formValidate"
          :model="formValidate"
          :rules="ruleValidate"
          :label-width="150"
        >
          <FormItem label="新密码" prop="newPassword">
            <Input type="password" v-model="formValidate.newPassword"></Input>
          </FormItem>
          <FormItem label="确认密码" prop="checkPassword">
            <Input type="password" v-model="formValidate.checkPassword"></Input>
          </FormItem>
          <!-- <FormItem> -->
          <Button
            class="btnNext"
            type="primary"
            @click="handleSubmit('formValidate')"
            >确定</Button
          >
          <!-- </FormItem> -->
        </Form>
        <div class="foot">
          已有账号
          <router-link to="/login">去登录</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import md5 from 'js-md5';
export default {
  name: "FindPwd",
  props: {},
  data() {
    const validatePass = (rule, value, callback) => {
      var reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/;
      if (value === "") {
        callback(new Error("请输入密码"));
      } else if (!reg.test(value)) {
        callback(new Error("请输入6-16位字母、数字组合"));
        return;
      } else {
        if (this.formValidate.checkPassword !== "") {
          // 对第二个密码框单独验证
          this.$refs.formValidate.validateField("checkPassword");
        }
        callback();
      }
    };
    const validatePassCheck = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.formValidate.newPassword) {
        callback(new Error("两次输入的密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      formValidate: {
        newPassword: "",
        checkPassword: ""
      },
      ruleValidate: {
        newPassword: [
          { required: true, validator: validatePass, trigger: "blur" }
        ],
        checkPassword: [
          { required: true, validator: validatePassCheck, trigger: "blur" }
        ]
      }
    };
  },
  created() {},
  mounted() {},
  methods: {
    handleSubmit(name) {
      this.$refs[name].validate(async valid => {
        if (valid) {
          var { messageCode } = await this.$http.post(
            "shsj_declare/account/findPassword",
            {
              newPassword: this.formValidate.newPassword
            }
          );
          if (messageCode === 10000) {
            this.$Message.success("修改密码成功!");
            setTimeout(() => {
              this.$router.push("/login");
            }, 1000);
          }
        } else {
          this.$Message.error("修改密码失败!");
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.forgotPwd {
  margin-top: 30px;
  padding: 20px;
  .forgotPwd_box {
    overflow: hidden;
    border-top: 5px solid #3385ff;
    border-radius: 10px;
    box-shadow: 0px 2px 10px -2px rgba(51, 133, 255, 0.7);
    .formBox {
      letter-spacing: 1px;
      text-align: center;
      margin: 0 auto;
      width: 50%;
      padding: 70px 10px;
      .title {
        font-size: 20px;
        margin-bottom: 30px;
        color: #666;
        .ivu-icon {
          vertical-align: baseline;
          color: #999;
          padding-right: 10px;
        }
      }
      .foot {
        margin: 40px auto;
      }
      .btnNext {
        margin-top: 50px;
      }
    }
  }
}
</style>
