<template>
  <div class="DesignTeam GL_declareForm Gl_input">
    <Form
      :rules="ruleValidate"
      class="form"
      ref="formValidate"
      :model="formValidate"
      :label-width="0"
    >
      <!-- 经济指标 -->
      <Row>
        <Col style="font-size:16px ;margin-bottom:10px" span="24">
          企业知识产权情况
          <span style="font-size:12px;color:#99A5C2"
            >（近两年累计，自申报之日起倒推）</span
          >
        </Col>
      </Row>
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="6">
            分类
          </Col>
          <Col class="m_col m_title" span="6">
            企业申请数
          </Col>
          <Col class="m_col m_title" span="6">
            获得批准数
          </Col>
          <Col class="m_col m_title" span="6">
            实现产业化数量
          </Col>
        </Row>
        <!-- TODO: -->
        <Row type="flex" class="m_row">
          <Col class="t_col m_title" span="6">
            知识产权总数（个）
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="ldeFesignInnovationAbilityList[0].totalIntellectualProperty"
            >
              <Input
                @on-focus="
                  formValidate.ldeFesignInnovationAbilityList[0]
                    .totalIntellectualProperty == 0
                    ? (formValidate.ldeFesignInnovationAbilityList[0].totalIntellectualProperty = null)
                    : null
                "
                @on-blur="
                  formValidate.ldeFesignInnovationAbilityList[0]
                    .totalIntellectualProperty == null ||
                  formValidate.ldeFesignInnovationAbilityList[0]
                    .totalIntellectualProperty == ''
                    ? (formValidate.ldeFesignInnovationAbilityList[0].totalIntellectualProperty = 0)
                    : null
                "
                :maxlength="8"
                v-model="
                  formValidate.ldeFesignInnovationAbilityList[0]
                    .totalIntellectualProperty
                "
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="ldeFesignInnovationAbilityList[1].totalIntellectualProperty"
            >
              <!-- @on-focus="
                  focusEvent(
                    'ldeFesignInnovationAbilityList[1].totalIntellectualProperty'
                  )
                "
                @on-blur="
                  blurEvent(
                    'ldeFesignInnovationAbilityList[1].totalIntellectualProperty'
                  )
                " -->
              <Input
                @on-focus="
                  formValidate.ldeFesignInnovationAbilityList[1]
                    .totalIntellectualProperty == 0
                    ? (formValidate.ldeFesignInnovationAbilityList[1].totalIntellectualProperty = null)
                    : null
                "
                @on-blur="
                  formValidate.ldeFesignInnovationAbilityList[1]
                    .totalIntellectualProperty == null ||
                  formValidate.ldeFesignInnovationAbilityList[1]
                    .totalIntellectualProperty == ''
                    ? (formValidate.ldeFesignInnovationAbilityList[1].totalIntellectualProperty = 0)
                    : null
                "
                :maxlength="8"
                v-model="
                  formValidate.ldeFesignInnovationAbilityList[1]
                    .totalIntellectualProperty
                "
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="ldeFesignInnovationAbilityList[2].totalIntellectualProperty"
            >
              <Input
                @on-focus="
                  formValidate.ldeFesignInnovationAbilityList[2]
                    .totalIntellectualProperty == 0
                    ? (formValidate.ldeFesignInnovationAbilityList[2].totalIntellectualProperty = null)
                    : null
                "
                @on-blur="
                  formValidate.ldeFesignInnovationAbilityList[2]
                    .totalIntellectualProperty == null ||
                  formValidate.ldeFesignInnovationAbilityList[2]
                    .totalIntellectualProperty == ''
                    ? (formValidate.ldeFesignInnovationAbilityList[2].totalIntellectualProperty = 0)
                    : null
                "
                :maxlength="8"
                v-model="
                  formValidate.ldeFesignInnovationAbilityList[2]
                    .totalIntellectualProperty
                "
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="6">
            其中：发明专利（个）
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="ldeFesignInnovationAbilityList[0].inventionPatent"
            >
              <Input
                @on-focus="
                  formValidate.ldeFesignInnovationAbilityList[0]
                    .inventionPatent == 0
                    ? (formValidate.ldeFesignInnovationAbilityList[0].inventionPatent = null)
                    : null
                "
                @on-blur="
                  formValidate.ldeFesignInnovationAbilityList[0]
                    .inventionPatent == null ||
                  formValidate.ldeFesignInnovationAbilityList[0]
                    .inventionPatent == ''
                    ? (formValidate.ldeFesignInnovationAbilityList[0].inventionPatent = 0)
                    : null
                "
                :maxlength="8"
                v-model="
                  formValidate.ldeFesignInnovationAbilityList[0].inventionPatent
                "
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="ldeFesignInnovationAbilityList[1].inventionPatent"
            >
              <Input
                @on-focus="
                  formValidate.ldeFesignInnovationAbilityList[1]
                    .inventionPatent == 0
                    ? (formValidate.ldeFesignInnovationAbilityList[1].inventionPatent = null)
                    : null
                "
                @on-blur="
                  formValidate.ldeFesignInnovationAbilityList[1]
                    .inventionPatent == null ||
                  formValidate.ldeFesignInnovationAbilityList[1]
                    .inventionPatent == ''
                    ? (formValidate.ldeFesignInnovationAbilityList[1].inventionPatent = 0)
                    : null
                "
                :maxlength="8"
                v-model="
                  formValidate.ldeFesignInnovationAbilityList[1].inventionPatent
                "
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="ldeFesignInnovationAbilityList[2].inventionPatent"
            >
              <Input
                @on-focus="
                  formValidate.ldeFesignInnovationAbilityList[2]
                    .inventionPatent == 0
                    ? (formValidate.ldeFesignInnovationAbilityList[2].inventionPatent = null)
                    : null
                "
                @on-blur="
                  formValidate.ldeFesignInnovationAbilityList[2]
                    .inventionPatent == null ||
                  formValidate.ldeFesignInnovationAbilityList[2]
                    .inventionPatent == ''
                    ? (formValidate.ldeFesignInnovationAbilityList[2].inventionPatent = 0)
                    : null
                "
                :maxlength="8"
                v-model="
                  formValidate.ldeFesignInnovationAbilityList[2].inventionPatent
                "
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="6">
            实用新型专利（个）
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="ldeFesignInnovationAbilityList[0].utilityModelPatent"
            >
              <Input
                @on-focus="
                  formValidate.ldeFesignInnovationAbilityList[0]
                    .utilityModelPatent == 0
                    ? (formValidate.ldeFesignInnovationAbilityList[0].utilityModelPatent = null)
                    : null
                "
                @on-blur="
                  formValidate.ldeFesignInnovationAbilityList[0]
                    .utilityModelPatent == null ||
                  formValidate.ldeFesignInnovationAbilityList[0]
                    .utilityModelPatent == ''
                    ? (formValidate.ldeFesignInnovationAbilityList[0].utilityModelPatent = 0)
                    : null
                "
                :maxlength="8"
                v-model="
                  formValidate.ldeFesignInnovationAbilityList[0]
                    .utilityModelPatent
                "
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="ldeFesignInnovationAbilityList[1].utilityModelPatent"
            >
              <Input
                @on-focus="
                  formValidate.ldeFesignInnovationAbilityList[1]
                    .utilityModelPatent == 0
                    ? (formValidate.ldeFesignInnovationAbilityList[1].utilityModelPatent = null)
                    : null
                "
                @on-blur="
                  formValidate.ldeFesignInnovationAbilityList[1]
                    .utilityModelPatent == null ||
                  formValidate.ldeFesignInnovationAbilityList[1]
                    .utilityModelPatent == ''
                    ? (formValidate.ldeFesignInnovationAbilityList[1].utilityModelPatent = 0)
                    : null
                "
                :maxlength="8"
                v-model="
                  formValidate.ldeFesignInnovationAbilityList[1]
                    .utilityModelPatent
                "
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="ldeFesignInnovationAbilityList[2].utilityModelPatent"
            >
              <Input
                @on-focus="
                  formValidate.ldeFesignInnovationAbilityList[2]
                    .utilityModelPatent == 0
                    ? (formValidate.ldeFesignInnovationAbilityList[2].utilityModelPatent = null)
                    : null
                "
                @on-blur="
                  formValidate.ldeFesignInnovationAbilityList[2]
                    .utilityModelPatent == null ||
                  formValidate.ldeFesignInnovationAbilityList[2]
                    .utilityModelPatent == ''
                    ? (formValidate.ldeFesignInnovationAbilityList[2].utilityModelPatent = 0)
                    : null
                "
                :maxlength="8"
                v-model="
                  formValidate.ldeFesignInnovationAbilityList[2]
                    .utilityModelPatent
                "
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="6">
            外观设计专利（个）
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="ldeFesignInnovationAbilityList[0].industrialDesignPatent"
            >
              <Input
                @on-focus="
                  formValidate.ldeFesignInnovationAbilityList[0]
                    .industrialDesignPatent == 0
                    ? (formValidate.ldeFesignInnovationAbilityList[0].industrialDesignPatent = null)
                    : null
                "
                @on-blur="
                  formValidate.ldeFesignInnovationAbilityList[0]
                    .industrialDesignPatent == null ||
                  formValidate.ldeFesignInnovationAbilityList[0]
                    .industrialDesignPatent == ''
                    ? (formValidate.ldeFesignInnovationAbilityList[0].industrialDesignPatent = 0)
                    : null
                "
                :maxlength="8"
                v-model="
                  formValidate.ldeFesignInnovationAbilityList[0]
                    .industrialDesignPatent
                "
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="ldeFesignInnovationAbilityList[1].industrialDesignPatent"
            >
              <Input
                @on-focus="
                  formValidate.ldeFesignInnovationAbilityList[1]
                    .industrialDesignPatent == 0
                    ? (formValidate.ldeFesignInnovationAbilityList[1].industrialDesignPatent = null)
                    : null
                "
                @on-blur="
                  formValidate.ldeFesignInnovationAbilityList[1]
                    .industrialDesignPatent == null ||
                  formValidate.ldeFesignInnovationAbilityList[1]
                    .industrialDesignPatent == ''
                    ? (formValidate.ldeFesignInnovationAbilityList[1].industrialDesignPatent = 0)
                    : null
                "
                :maxlength="8"
                v-model="
                  formValidate.ldeFesignInnovationAbilityList[1]
                    .industrialDesignPatent
                "
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="ldeFesignInnovationAbilityList[2].industrialDesignPatent"
            >
              <Input
                @on-focus="
                  formValidate.ldeFesignInnovationAbilityList[2]
                    .industrialDesignPatent == 0
                    ? (formValidate.ldeFesignInnovationAbilityList[2].industrialDesignPatent = null)
                    : null
                "
                @on-blur="
                  formValidate.ldeFesignInnovationAbilityList[2]
                    .industrialDesignPatent == null ||
                  formValidate.ldeFesignInnovationAbilityList[2]
                    .industrialDesignPatent == ''
                    ? (formValidate.ldeFesignInnovationAbilityList[2].industrialDesignPatent = 0)
                    : null
                "
                :maxlength="8"
                v-model="
                  formValidate.ldeFesignInnovationAbilityList[2]
                    .industrialDesignPatent
                "
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col
            style="justify-content: flex-start;"
            class="m_col m_title"
            span="6"
          >
            版权数（个）
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="ldeFesignInnovationAbilityList[0].softwareCopyright"
            >
              <Input
                @on-focus="
                  formValidate.ldeFesignInnovationAbilityList[0]
                    .softwareCopyright == 0
                    ? (formValidate.ldeFesignInnovationAbilityList[0].softwareCopyright = null)
                    : null
                "
                @on-blur="
                  formValidate.ldeFesignInnovationAbilityList[0]
                    .softwareCopyright == null ||
                  formValidate.ldeFesignInnovationAbilityList[0]
                    .softwareCopyright == ''
                    ? (formValidate.ldeFesignInnovationAbilityList[0].softwareCopyright = 0)
                    : null
                "
                :maxlength="8"
                v-model="
                  formValidate.ldeFesignInnovationAbilityList[0]
                    .softwareCopyright
                "
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="ldeFesignInnovationAbilityList[1].softwareCopyright"
            >
              <Input
                @on-focus="
                  formValidate.ldeFesignInnovationAbilityList[1]
                    .softwareCopyright == 0
                    ? (formValidate.ldeFesignInnovationAbilityList[1].softwareCopyright = null)
                    : null
                "
                @on-blur="
                  formValidate.ldeFesignInnovationAbilityList[1]
                    .softwareCopyright == null ||
                  formValidate.ldeFesignInnovationAbilityList[1]
                    .softwareCopyright == ''
                    ? (formValidate.ldeFesignInnovationAbilityList[1].softwareCopyright = 0)
                    : null
                "
                :maxlength="8"
                v-model="
                  formValidate.ldeFesignInnovationAbilityList[1]
                    .softwareCopyright
                "
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="ldeFesignInnovationAbilityList[2].softwareCopyright"
            >
              <Input
                @on-focus="
                  formValidate.ldeFesignInnovationAbilityList[2]
                    .softwareCopyright == 0
                    ? (formValidate.ldeFesignInnovationAbilityList[2].softwareCopyright = null)
                    : null
                "
                @on-blur="
                  formValidate.ldeFesignInnovationAbilityList[2]
                    .softwareCopyright == null ||
                  formValidate.ldeFesignInnovationAbilityList[2]
                    .softwareCopyright == ''
                    ? (formValidate.ldeFesignInnovationAbilityList[2].softwareCopyright = 0)
                    : null
                "
                :maxlength="8"
                v-model="
                  formValidate.ldeFesignInnovationAbilityList[2]
                    .softwareCopyright
                "
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <!-- TODO: -->
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="6">
            其中：软件著作权（个）
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="ldeFesignInnovationAbilityList[0].numberOfCopyrights"
            >
              <Input
                @on-focus="
                  formValidate.ldeFesignInnovationAbilityList[0]
                    .numberOfCopyrights == 0
                    ? (formValidate.ldeFesignInnovationAbilityList[0].numberOfCopyrights = null)
                    : null
                "
                @on-blur="
                  formValidate.ldeFesignInnovationAbilityList[0]
                    .numberOfCopyrights == null ||
                  formValidate.ldeFesignInnovationAbilityList[0]
                    .numberOfCopyrights == ''
                    ? (formValidate.ldeFesignInnovationAbilityList[0].numberOfCopyrights = 0)
                    : null
                "
                :maxlength="8"
                v-model="
                  formValidate.ldeFesignInnovationAbilityList[0]
                    .numberOfCopyrights
                "
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="ldeFesignInnovationAbilityList[1].numberOfCopyrights"
            >
              <Input
                @on-focus="
                  formValidate.ldeFesignInnovationAbilityList[1]
                    .numberOfCopyrights == 0
                    ? (formValidate.ldeFesignInnovationAbilityList[1].numberOfCopyrights = null)
                    : null
                "
                @on-blur="
                  formValidate.ldeFesignInnovationAbilityList[1]
                    .numberOfCopyrights == null ||
                  formValidate.ldeFesignInnovationAbilityList[1]
                    .numberOfCopyrights == ''
                    ? (formValidate.ldeFesignInnovationAbilityList[1].numberOfCopyrights = 0)
                    : null
                "
                :maxlength="8"
                v-model="
                  formValidate.ldeFesignInnovationAbilityList[1]
                    .numberOfCopyrights
                "
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="ldeFesignInnovationAbilityList[2].numberOfCopyrights"
            >
              <Input
                @on-focus="
                  formValidate.ldeFesignInnovationAbilityList[2]
                    .numberOfCopyrights == 0
                    ? (formValidate.ldeFesignInnovationAbilityList[2].numberOfCopyrights = null)
                    : null
                "
                @on-blur="
                  formValidate.ldeFesignInnovationAbilityList[2]
                    .numberOfCopyrights == null ||
                  formValidate.ldeFesignInnovationAbilityList[2]
                    .numberOfCopyrights == ''
                    ? (formValidate.ldeFesignInnovationAbilityList[2].numberOfCopyrights = 0)
                    : null
                "
                :maxlength="8"
                v-model="
                  formValidate.ldeFesignInnovationAbilityList[2]
                    .numberOfCopyrights
                "
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
      </div>
      <!-- 近两年主要设计项目完成情况及成果 -->
      <Row>
        <Col style="font-size:16px ;margin-bottom:10px" span="24"
          >近两年主要设计项目完成情况及成果</Col
        >
      </Row>
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title icon_col" span="6">
            <Icon @click="add" class="icon" type="ios-add-circle-outline" />
            <span class="text_col">
              <span class="redStar">*</span>项目名称
            </span>
          </Col>
          <Col class="m_col m_title" span="6">
            <span class="redStar">*</span>投产时间
          </Col>
          <Col class="m_col m_title" span="12">
            <span class="redStar">*</span>设计成果产业化情况
          </Col>
        </Row>
        <Row
          type="flex"
          v-for="(item,
          index) in formValidate.ldeProjectCompletionAndResultsList"
          :key="index"
          class="m_row"
        >
          <Col class="m_col icon_col" span="6">
            <Icon
              @click="reduce(index)"
              class="icon"
              type="ios-remove-circle-outline"
            />
            <Input
              :maxlength="50"
              v-model.trim="item.programName"
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="6">
            <DatePicker
              type="date"
              :value="item.commissioningTime"
              @on-change="item.commissioningTime = $event"
              placeholder="选择时间"
            ></DatePicker>
          </Col>
          <Col class="m_col" span="12">
            <Input
              placeholder="产值规模，对企业创新发展模式、提升经济效益、品牌影响力等方面所起到的效果。"
              :maxlength="1000"
              v-model="item.designAchievements"
              @on-change="
                item.designAchievements = $util.ToDBC(
                  item.designAchievements,
                  $event
                )
              "
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 8 }"
            ></Input>
          </Col>
        </Row>
      </div>
    </Form>
    <div class="foot">
      <button v-waves @click="cancel" class="GL_cancel">取消</button>
      <button v-waves @click="save('formValidate')" class="GL_save">
        保存
      </button>
      <button v-waves @click="next('formValidate')" class="GL_next">
        保存并跳转下一步
      </button>
    </div>
  </div>
</template>

<script>
import waves from "../../directive/waves";
export default {
  name: "designTeam",
  directives: { waves },
  props: {},
  data() {
    return {
      programId: null,
      formValidate: {
        ldeFesignInnovationAbilityList: [
          {
            totalIntellectualProperty: 0,
            inventionPatent: 0,
            utilityModelPatent: 0,
            industrialDesignPatent: 0,
            softwareCopyright: 0,
            numberOfCopyrights: 0,
            type: "1"
          },
          {
            totalIntellectualProperty: 0,
            inventionPatent: 0,
            utilityModelPatent: 0,
            industrialDesignPatent: 0,
            softwareCopyright: 0,
            numberOfCopyrights: 0,
            type: "2"
          },
          {
            totalIntellectualProperty: 0,
            inventionPatent: 0,
            utilityModelPatent: 0,
            industrialDesignPatent: 0,
            softwareCopyright: 0,
            numberOfCopyrights: 0,
            type: "3"
          }
        ],
        ldeProjectCompletionAndResultsList: [
          {
            programName: null,
            commissioningTime: null,
            designAchievements: null
          }
        ]
      },
      ruleValidate: {
        validatePositiveInteger: [
          {
            validator: this.$util.validatePositiveInteger,
            trigger: "blur"
          }
        ]
      }
    };
  },
  created() {
    // 查询表
    this.queyData();
  },
  watch: {},
  computed: {},
  components: {},
  mounted() {},
  methods: {
    /*method querydata
     *查询表
     */
    async queyData() {
      let { messageCode, result } = await this.$http.post(
        "/shsj_declare/ldeDeclare/queryLdeDeclareInfo",
        {
          programId: this.$route.query.programId,
          specialId: this.$route.query.specialId,
          type: "3"
        }
      );
      if (messageCode == 10000) {
        this.programId = result.declareInfo.programId;
        if (
          result.declareInfo.ldeInnovationAbilityInfo
            .ldeFesignInnovationAbilityList.length == 3
        ) {
          this.formValidate.ldeFesignInnovationAbilityList =
            result.declareInfo.ldeInnovationAbilityInfo.ldeFesignInnovationAbilityList;
        }
        if (
          result.declareInfo.ldeInnovationAbilityInfo
            .ldeProjectCompletionAndResultsList.length > 0
        ) {
          this.formValidate.ldeProjectCompletionAndResultsList =
            result.declareInfo.ldeInnovationAbilityInfo.ldeProjectCompletionAndResultsList;
        }
      }
    },
    /*保存表单
     *method saveProgram
     **/
    saveProgram(name, type) {
      this.$refs[name].validate(async valid => {
        if (valid) {
          let { messageCode } = await this.$http.post(
            "/shsj_declare/ldeDeclare/saveLdeDeclareInfo",
            {
              programId: this.programId,
              type: "3",
              specialId: this.$route.query.specialId,
              ldeInnovationAbilityInfo: this.formValidate
            }
          );
          if (messageCode == 10000) {
            this.$Message.success("保存页面成功!");
            if (type == "next") {
              this.$emit("nextChange", 4);
            }
          }
        } else {
          this.$Message.error("内容格式不正确!");
        }
      });
    },
    /**减少人员
     * method reduce product
     * */
    reduce(index) {
      if (this.formValidate.ldeProjectCompletionAndResultsList.length > 1) {
        return this.formValidate.ldeProjectCompletionAndResultsList.splice(
          index,
          1
        );
      }
      this.$Message.error("删除失败，项目不得少于1");
    },
    /**增加人员
     * method add product
     * */
    add() {
      this.formValidate.ldeProjectCompletionAndResultsList.push({
        programName: null,
        commissioningTime: null,
        designAchievements: null
      });
    },
    /*method
     *点击取消
     */
    cancel() {
      this.$router.go(-1);
    },
    /*method
     *点击保存
     */
    save(name) {
      this.saveProgram(name);
    },
    /*method
     *点击下一步
     */
    next(name) {
      this.saveProgram(name, "next");
    }
  }
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.t_col {
  display: flex;
  justify-content: left;
  align-items: center;
  border-bottom: 1px solid #e8ecf5;
  border-right: 1px solid #e8ecf5;
  min-height: 45px;
  padding-left: 5px;
}
</style>
