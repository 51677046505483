<template>
  <div class="baseSituation GL_declareForm Gl_input">
    <Form
      :rules="ruleValidate"
      class="form"
      ref="formValidate"
      :model="formValidate"
      :label-width="0"
    >
      <!-- 企业名称 -->
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>企业名称
          </Col>
          <Col class="m_col" span="20">
            <Input
              :maxlength="50"
              v-model.trim="formValidate.ldeUnitBase.unitName"
              class="unit_input"
            />
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>统一社会信用代码
          </Col>
          <Col style="color:#ccc" class="m_col" span="8">
            <Input :placeholder="code" readonly class="unit_input" />
          </Col>

          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>税务登记区
          </Col>
          <Col class="m_col" span="8">
            <Select v-model="formValidate.ldeUnitBase.unitTaxArea">
              <Option
                v-for="item in areaList"
                :key="item.dataId"
                :value="item.dataId"
                >{{ item.dataValue }}</Option
              >
            </Select>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>企业注册地址
          </Col>
          <Col class="m_col" span="8">
            <Input
              :maxlength="50"
              v-model.trim="formValidate.ldeUnitBase.unitContactAddress"
              class="unit_input"
            />
          </Col>
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>注册时间
          </Col>
          <Col class="m_col" span="8">
            <DatePicker
              type="date"
              :value="formValidate.ldeUnitBase.unitRegisteredDate"
              @on-change="formValidate.ldeUnitBase.unitRegisteredDate = $event"
              placeholder="选择时间"
            ></DatePicker>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>企业经营地址
          </Col>
          <Col class="m_col" span="20">
            <Input
              :maxlength="50"
              v-model.trim="formValidate.ldeUnitBase.manageAddress"
              class="unit_input"
            />
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>企业注册类型
          </Col>
          <Col class="m_col" span="20">
            <div style="width:100%">
              <RadioGroup
                @on-change="radioChange"
                class="RadioGroup"
                size="large"
                v-model="formValidate.ldeUnitBase.unitRegistrationTypeParentId"
              >
                <Radio
                  :key="index"
                  v-for="(item, index) in formValidate.sysDicts"
                  :label="item.dictId"
                  >{{ item.dictName }}</Radio
                >
              </RadioGroup>
              <br />
              <div
                v-show="childrenList.length > 0"
                style="border-top:1px dashed #ccc;margin:5px 0"
              ></div>
              <RadioGroup
                v-show="childrenList.length > 0"
                class="RadioGroup"
                size="large"
                v-model="formValidate.ldeUnitBase.unitRegistrationType"
              >
                <Radio
                  :key="index2"
                  v-for="(item2, index2) in childrenList"
                  :label="item2.dataId"
                  >{{ item2.dataValue }}</Radio
                >
              </RadioGroup>
            </div>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">银行信用等级</Col>
          <Col class="m_col" span="8">
            <Input
              :maxlength="10"
              v-model.trim="formValidate.ldeUnitBase.unitCreditRating"
              class="unit_input"
            />
          </Col>
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>职工人数
          </Col>
          <Col class="m_col" span="8">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="ldeUnitBase.unitPersonNumber"
            >
              <Input
                :maxlength="8"
                v-model.trim="formValidate.ldeUnitBase.unitPersonNumber"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>资产总额（万元）
          </Col>
          <Col class="m_col" span="8">
            <FormItem
              :rules="ruleValidate.validateFloatingNumber"
              prop="ldeUnitBase.totalAssets"
            >
              <Input
                :maxlength="13"
                v-model.trim="formValidate.ldeUnitBase.totalAssets"
                class="unit_input"
              />
            </FormItem>
          </Col>

          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>所属行业
          </Col>
          <Col class="m_col" span="8">
            <!-- <RadioGroup
              class="RadioGroup"
              size="large"
              v-model="formValidate.ldeUnitBase.industry"
            >
              <Radio
                class="radiolist"
                :key="index"
                v-for="(item, index) in tradeList"
                :label="item.dataId"
                :title="item.dataValue.length > 4 ? item.dataValue : null"
                >{{ item.dataValue }}</Radio
              >
            </RadioGroup> -->
            <Select v-model="formValidate.ldeUnitBase.industry">
              <Option
                :key="index"
                v-for="(item, index) in tradeList"
                :value="item.dataId"
                >{{ item.dataValue }}</Option
              >
            </Select>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>资产负债率（%）
          </Col>
          <Col class="m_col" span="20">
            <FormItem
              :rules="ruleValidate.validatePercentage"
              prop="ldeUnitBase.assetLiabilityRatio"
            >
              <Input
                :maxlength="7"
                v-model.trim="formValidate.ldeUnitBase.assetLiabilityRatio"
                class="unit_input"
              />
            </FormItem>
          </Col>

        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>主营业务
          </Col>
          <Col class="m_col" span="20">
            <Input
              :maxlength="1000"
              v-model="formValidate.ldeUnitBase.mainBusiness"
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 8 }"
            ></Input>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>主导产品大类
          </Col>
          <Col class="m_col" span="20">
            <Input
              :maxlength="1000"
              v-model="formValidate.ldeUnitBase.leadingProductCategory"
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 8 }"
            ></Input>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>主要经营业绩和行业地位
          </Col>
          <Col class="m_col" span="20">
            <Input
              :maxlength="1000"
              v-model="formValidate.ldeUnitBase.performanceAndIndustryPosition"
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 8 }"
            ></Input>
          </Col>
        </Row>
      </div>
      <!-- 联系方式 -->
      <Row>
        <Col style="font-size:16px ;margin-bottom:10px" span="24">联系方式</Col>
      </Row>
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>人员
          </Col>
          <Col class="m_col m_title" span="5">
            <span class="redStar">*</span>姓名
          </Col>
          <Col class="m_col m_title" span="5">
            <span class="redStar">*</span>职务
          </Col>
          <Col class="m_col m_title" span="5">
            <span class="redStar">*</span>联系手机
          </Col>
          <Col class="m_col m_title" span="5">
            <span class="redStar">*</span>邮箱
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>企业负责人
          </Col>
          <Col class="m_col" span="5">
            <Input
              :maxlength="20"
              v-model.trim="formValidate.ldeUnitContactsList[0].name"
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="5">
            <Input
              :maxlength="20"
              v-model.trim="formValidate.ldeUnitContactsList[0].post"
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="5">
            <FormItem
              :rules="ruleValidate.validateCellPhoneNumber"
              prop="ldeUnitContactsList[0].contactWay"
            >
              <Input
                :maxlength="20"
                v-model="formValidate.ldeUnitContactsList[0].contactWay"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="5">
            <FormItem
              :rules="ruleValidate.validateMailbox"
              prop="ldeUnitContactsList[0].fax"
            >
              <Input
                :maxlength="50"
                v-model="formValidate.ldeUnitContactsList[0].fax"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>申请联系人
          </Col>
          <Col class="m_col" span="5">
            <Input
              :maxlength="20"
              v-model.trim="formValidate.ldeUnitContactsList[1].name"
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="5">
            <Input
              :maxlength="20"
              v-model.trim="formValidate.ldeUnitContactsList[1].post"
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="5">
            <FormItem
              :rules="ruleValidate.validateCellPhoneNumber"
              prop="ldeUnitContactsList[1].contactWay"
            >
              <Input
                :maxlength="20"
                v-model="formValidate.ldeUnitContactsList[1].contactWay"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="5">
            <FormItem
              :rules="ruleValidate.validateMailbox"
              prop="ldeUnitContactsList[1].fax"
            >
              <Input
                :maxlength="50"
                v-model="formValidate.ldeUnitContactsList[1].fax"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
      </div>
      <!-- 经济指标 -->
      <Row>
        <Col style="font-size:16px ;margin-bottom:10px" span="24"
          >企业近两年主要财务数据</Col
        >
      </Row>
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="8">
            <span class="redStar">*</span>指标项目
          </Col>
          <Col class="m_col m_title" span="8">
            <span class="redStar">*</span>2020年
          </Col>
          <Col class="m_col m_title" span="8">
            <span class="redStar">*</span>2021年
          </Col>
        </Row>

        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="8">
            <span class="redStar">*</span>主营业务收入（万元）
          </Col>
          <Col class="m_col" span="8">
            <FormItem
              :rules="ruleValidate.validateFloatingNumber"
              prop="ldeUnitFinancialDataList[0].mainBusinessIncome"
            >
              <Input
                :maxlength="13"
                v-model="
                  formValidate.ldeUnitFinancialDataList[0].mainBusinessIncome
                "
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="8">
            <FormItem
              :rules="ruleValidate.validateFloatingNumber"
              prop="ldeUnitFinancialDataList[1].mainBusinessIncome"
            >
              <Input
                :maxlength="13"
                v-model="
                  formValidate.ldeUnitFinancialDataList[1].mainBusinessIncome
                "
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="8">
            <span class="redStar">*</span>利润总额（万元）
          </Col>
          <Col class="m_col" span="8">
            <FormItem
              :rules="ruleValidate.validateFloatingNumber"
              prop="ldeUnitFinancialDataList[0].totalProfit"
            >
              <Input
                :maxlength="13"
                v-model="formValidate.ldeUnitFinancialDataList[0].totalProfit"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="8">
            <FormItem
              :rules="ruleValidate.validateFloatingNumber"
              prop="ldeUnitFinancialDataList[1].totalProfit"
            >
              <Input
                :maxlength="13"
                v-model="formValidate.ldeUnitFinancialDataList[1].totalProfit"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="8">
            <span class="redStar">*</span>纳税总额（万元）
          </Col>
          <Col class="m_col" span="8">
            <FormItem
              :rules="ruleValidate.validateFloatingNumber"
              prop="ldeUnitFinancialDataList[0].totalTax"
            >
              <Input
                :maxlength="13"
                v-model="formValidate.ldeUnitFinancialDataList[0].totalTax"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="8">
            <FormItem
              :rules="ruleValidate.validateFloatingNumber"
              prop="ldeUnitFinancialDataList[1].totalTax"
            >
              <Input
                :maxlength="13"
                v-model="formValidate.ldeUnitFinancialDataList[1].totalTax"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="8">
            <span class="redStar">*</span>出口交货值（万元）
          </Col>
          <Col class="m_col" span="8">
            <FormItem
              :rules="ruleValidate.validateFloatingNumber"
              prop="ldeUnitFinancialDataList[0].exportDeliveryValue"
            >
              <Input
                :maxlength="13"
                v-model="
                  formValidate.ldeUnitFinancialDataList[0].exportDeliveryValue
                "
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="8">
            <FormItem
              :rules="ruleValidate.validateFloatingNumber"
              prop="ldeUnitFinancialDataList[1].exportDeliveryValue"
            >
              <Input
                :maxlength="13"
                v-model="
                  formValidate.ldeUnitFinancialDataList[1].exportDeliveryValue
                "
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="8">
            <span class="redStar">*</span>新产品销售收入占总营收比重（%）
          </Col>
          <Col class="m_col" span="8">
            <FormItem
              :rules="ruleValidate.validatePercentage"
              prop="ldeUnitFinancialDataList[0].outputValueRateOfNewProducts"
            >
              <Input
                :maxlength="7"
                v-model="
                  formValidate.ldeUnitFinancialDataList[0]
                    .outputValueRateOfNewProducts
                "
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="8">
            <FormItem
              :rules="ruleValidate.validatePercentage"
              prop="ldeUnitFinancialDataList[1].outputValueRateOfNewProducts"
            >
              <Input
                :maxlength="7"
                v-model="
                  formValidate.ldeUnitFinancialDataList[1]
                    .outputValueRateOfNewProducts
                "
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
      </div>
    </Form>
    <div class="foot">
      <button @click="cancel" class="GL_cancel">取消</button>
      <button v-waves @click="save('formValidate')" class="GL_save">
        保存
      </button>
      <button v-waves @click="next('formValidate')" class="GL_next">
        保存并跳转下一步
      </button>
    </div>
  </div>
</template>

<script>
import waves from "../../directive/waves";
export default {
  name: "baseSituation",
  props: {},
  directives: { waves },
  data() {
    return {
      code: localStorage.getItem("accountCode"),
      childrenList: [],
      programId: null,
      // 区域列表
      areaList: [],
      // 所属行业列表
      tradeList: [],
      formValidate: {
        ldeUnitFinancialDataList: [
          {
            mainBusinessIncome: null,
            totalProfit: null,
            totalTax: null,
            exportDeliveryValue: null,
            outputValueRateOfNewProducts: null,
            year: "2020"
          },
          {
            mainBusinessIncome: null,
            totalProfit: null,
            totalTax: null,
            exportDeliveryValue: null,
            outputValueRateOfNewProducts: null,
            year: "2021"
          }
        ],
        ldeUnitBase: {
          // unitName: "单位名字",
          // unitCode:
          // unitRegisteredDate: "2020-01-01",
          // unitContactAddress: "企业注册地址",
          // unitPostalCode: "邮政编码",
          // unitRegistrationType: "10101",
          // unitTaxArea: 8002,
          // unitCreditRating: "信用等级",
          // unitPersonNumber: 30,
          // totalAssets: 500,
          // assetLiabilityRatio: 300,
          // industry: 4003,
          // mainBusiness: "主营业务",
          // leadingProductCategory: "主导产品大类",
          // performanceAndIndustryPosition: "主要经营业绩和行业地位"
        },
        ldeUnitContactsList: [
          {
            name: null,
            post: null,
            contactWay: null,
            fax: null,
            type: "1"
          },
          {
            name: null,
            post: null,
            contactWay: null,
            fax: null,
            type: "2"
          }
        ]
      },
      ruleValidate: {
        validatePositiveInteger: [
          {
            validator: this.$util.validatePositiveInteger,
            trigger: "blur"
          }
        ],
        validateFloatingNumber: [
          {
            validator: this.$util.validateFloatingNumber,
            trigger: "blur"
          }
        ],
        validatePercentage: [
          {
            validator: this.$util.validatePercentage,
            trigger: "blur"
          }
        ],
        validateCellPhoneNumber: [
          {
            validator: this.$util.validateCellPhoneNumber,
            trigger: "blur"
          }
        ],
        validateMailbox: [
          {
            validator: this.$util.validateMailbox,
            trigger: "blur"
          }
        ],
      }
    };
  },
  created() {
    this.getRradeList();
    // 获取区域列表
    this.getAreaList();
    // 查询表
    this.queyData();
  },
  watch: {},
  computed: {},
  components: {},
  mounted() {},
  methods: {
    /*
     *method getRradeList
     获取行业列表
     */
    async getRradeList() {
      let { result } = await this.$http.get(
        "shsj_declare/sysDict/queryDictDataList",
        {
          dictId: 4000
        }
      );
      this.tradeList = result.dictDataList;
    },
    /*获取区域列表
     *method getAreaList
     */
    async getAreaList() {
      let { result } = await this.$http.get(
        "shsj_declare/sysDict/queryDictDataList",
        {
          dictId: 8000
        }
      );
      this.areaList = result.dictDataList;
      let shanghaiIndex = this.areaList.findIndex(
        item => item.dataValue == "上海市"
      );
      this.areaList.splice(shanghaiIndex, 1);
    },
    /*method querydata
     *查询表
     */
    async queyData() {
      let { messageCode, result } = await this.$http.post(
        "/shsj_declare/ldeDeclare/queryLdeDeclareInfo",
        {
          programId: this.$route.query.programId,
          specialId: this.$route.query.specialId,

          type: "1"
        }
      );
      if (messageCode == 10000) {
        this.programId = result.declareInfo.programId;
        this.formValidate.ldeUnitBase =
          result.declareInfo.ldeUnitBaseInfo.ldeUnitBase;
        if (this.formValidate.ldeUnitBase.unitName == null) {
          this.formValidate.ldeUnitBase.unitName = localStorage.companyName;
        }
        this.formValidate.sysDicts =
          result.declareInfo.ldeUnitBaseInfo.sysDicts;
        let index = this.formValidate.sysDicts.findIndex(
          item =>
            item.dictId ==
            this.formValidate.ldeUnitBase.unitRegistrationTypeParentId
        );
        if (index == -1) {
          this.childrenList = [];
        } else {
          this.childrenList = this.formValidate.sysDicts[index].sysDictDataList;
        }
        if (
          result.declareInfo.ldeUnitBaseInfo.ldeUnitContactsList.length == 2
        ) {
          this.formValidate.ldeUnitContactsList =
            result.declareInfo.ldeUnitBaseInfo.ldeUnitContactsList;
        }
        if (
          result.declareInfo.ldeUnitBaseInfo.ldeUnitFinancialDataList.length ==
          2
        ) {
          this.formValidate.ldeUnitFinancialDataList =
            result.declareInfo.ldeUnitBaseInfo.ldeUnitFinancialDataList;
        }
      }
    },
    /*method
     *点击取消
     */
    cancel() {
      this.$router.go(-1);
    },
    /*保存表单
     *method saveProgram
     **/
    saveProgram(name, type) {
      this.$refs[name].validate(async valid => {
        if (valid) {
          this.formValidate.ldeUnitBase.unitCode = this.code;
          let { messageCode } = await this.$http.post(
            "/shsj_declare/ldeDeclare/saveLdeDeclareInfo",
            {
              programId: this.programId,
              type: "1",
              specialId: this.$route.query.specialId,
              ldeUnitBaseInfo: this.formValidate
            }
          );
          if (messageCode == 10000) {
            this.$Message.success("保存页面成功!");
            if (type == "next") {
              this.$emit("nextChange", 2);
            }
          }
        } else {
          this.$Message.error("内容格式不正确!");
        }
      });
    },
    /****/
    radioChange(e) {
      let list = this.formValidate.sysDicts.filter(item => item.dictId == e);
      if (list.length > 0) {
        this.childrenList = list[0].sysDictDataList;
        this.formValidate.ldeUnitBase.unitRegistrationType = null;
      }
    },
    /*method
     *点击保存
     */
    save(name) {
      this.saveProgram(name);
    },
    /*method
     *点击下一步
     */
    next(name) {
      this.saveProgram(name, "next");
    }
  }
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.radiolist {
  width: 100px;
  margin-right: 4px;
  margin-left: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
