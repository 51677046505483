<template>
  <div class="modeCompany">
    <crumbs title="市级设计引领示范企业"></crumbs>
    <Row>
      <Col span="5">
        <BroadsideIndex
          @tabChange="tabChange"
          :tabList="tabList"
          :index="currentIndex"
        ></BroadsideIndex>
      </Col>
      <Col
        span="19"
        class="componentHt"
        style="overflow-y:auto ;padding-right:10px"
      >
        <crumbs
          style="padding:52px 0"
          :title="tabList[currentIndex].title"
        ></crumbs>
        <component
          style="padding-bottom:50px"
          @nextChange="tabChange"
          :is="tabList[currentIndex].component"
        ></component>
      </Col>
    </Row>
  </div>
</template>

<script>
import NeedingAttention from "../modeCompanyCompon/NeedingAttention";
import BroadsideIndex from "../../components/broadsideIndex";
import Attachment from "../modeCompanyCompon/Attachment";
import BaseSituation from "../modeCompanyCompon/BaseSituation";
import DesignInnovation from "../modeCompanyCompon/DesignInnovation";
import DesignTeam from "../modeCompanyCompon/DesignTeam";
import SupportingFacilities from "../modeCompanyCompon/SupportingFacilities";
import OtherSituation from "../modeCompanyCompon/OtherSituation";
import crumbs from "../../components/crumbs";
export default {
  name: "",
  props: {},
  components: {
    NeedingAttention,
    BroadsideIndex,
    Attachment,
    BaseSituation,
    DesignInnovation,
    DesignTeam,
    SupportingFacilities,
    OtherSituation,
    crumbs
  },
  data() {
    return {
      currentView: "BaseSituation",
      currentIndex: 0,
      tabList: [
        {
          lable: "填表须知（申报前请仔细阅读）",
          component: NeedingAttention,
          title: "填表须知"
        },
        {
          lable: "企业基本情况",
          component: BaseSituation,
          title: "所在企业情况"
        },
        {
          lable: "设计团队基本情况",
          component: DesignTeam,
          title: "设计团队基本情况"
        },
        {
          lable: "设计创新能力情况",
          component: DesignInnovation,
          title: "设计创新能力情况"
        },
        {
          lable: "设计创新投入、软硬件配套设施情况",
          component: SupportingFacilities,
          title: "设计创新投入、软硬件配套设施情况"
        },
        {
          lable: "企业其他情况",
          component: OtherSituation,
          title: "企业其他情况"
        },
        { lable: "附件上传", component: Attachment, title: "附件上传" }
      ]
    };
  },
  watch: {},
  computed: {},

  mounted() {},
  methods: {
    tabChange(e) {
      this.currentIndex = e;
    }
  }
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.componentHt {
  height: calc(100vh - 220px);
}
</style>
