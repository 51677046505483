<template>
  <div class="Home">
    <layout></layout>
  </div>
</template>

<script>
import layout from "../components/layout.vue";
export default {
  name: "",
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  components: {
    layout
  },
  mounted() {},
  methods: {
    /***jo
     * method sdf
     */
  }
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped></style>
