import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		industryProgramId: null,
		industrySpecialId: null,
		// userInfo: null,
		industryFromPage: null
	},

	mutations: {
		/*重置数据
     ** mutations
     */
		resetState(state) {
			var deepClone = (obj) => {
				var newObj = obj instanceof Array ? [] : {};
				for (var i in obj) {
					newObj[i] = typeof obj[i] === 'object' ? deepClone(obj[i]) : obj[i];
					newObj[i] = null;
				}
				return newObj;
			};
			var copyState = deepClone(state); // 拷贝state对象
			for (var i in copyState) {
				state[i] = copyState[i]; // 递归赋值
			}
		},
		/* 从哪个页面进入工业申报
     ** mutations
     */
		getWhereFrom(state, val) {
			state.industryFromPage = val;
		},

		/*获取工业设计的项目id
     ** mutations
     */
		getIndustryProgramId(state, val) {
			state.industryProgramId = val;
		},
		/*获取工业设计的Special项目id
     ** mutations
     */
		getIndustrySpecialId(state, val) {
			state.industrySpecialId = val;
		}
		/*获取用户信息
     ** mutations
     */
		// getUserInfo(state, val) {
		// 	// state.userInfo = val
		// 	state.userInfo = {
		// 		accountCode: val.accountCode,
		// 		accountId: val.accountId,
		// 		companyName: val.companyName,
		// 		email: val.email
		// 	};
		// }
	},
	actions: {},
	modules: {}
});
