<template>
  <div class="broadsid">
    <ul class="broadsid_ul">
      <li
        :class="current == index ? 'active' : ''"
        @click="tabEvent(index)"
        v-for="(item, index) in tabList"
        :key="index"
      >
        {{ item.lable }}
      </li>
      ​
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    tabList: {
      type: Array,
      default: () => []
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      current: 0
    };
  },
  created() {},
  mounted() {},
  methods: {
    tabEvent(e) {
      this.current = e;
      this.$emit("tabChange", e);
    }
  },
  watch: {
    index: {
      immediate: true, // 这句重要
      handler(val) {
        this.current = val;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.broadsid {
  width: 200px;
  border: 2px solid #f6f9ff;
  padding: 20px 0;
  .broadsid_ul {
    li {
      // font-size: 16px;
      cursor: pointer;
      padding: 15px 12px;
      // background-color: pink;
      border-radius: 5px;
      a {
        color: #000;
      }
    }
    .active {
      background-color: #3385ff;
      color: #fff;
    }
  }
}
</style>
