<template>
    <div>
        <!--页面弹窗-->
        <div class="popup-cover" v-show="isShowPopup"></div>
        <!--视频库弹窗-->
        <div class="check-popup" v-show="isShowPopup">
            <div class="check-popup-title">
                <span>视频库</span>
            </div>
            <hr>
            <div class="check-popup-content popup-common-row">
                <div class="check-content-div popup-common-column" v-for="(item,index) in videosList">
                    <div class="check-content-video">
                        <video controls="controls" width="100%" height="100%"  :src="item.videoPath"></video>
                    </div>
                    <div style="margin-top: 5px;width: 100px;text-align: center;min-height: 16px;word-break: break-all;"><span>{{item.videoName}}</span></div>
                    <div @click="okPopup(index)" class="check-content-button" style="margin-top: 2px"><button>添加</button></div>
                </div>
            </div>
            <hr>
            <div class="check-popup-bottom">
                <button @click="escPopup">取消</button>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props:{
            "isShowPopup":{
                type:Boolean,
                default(){
                    return true
                }
            },
            "videosList":{
                type:Array,
                default(){
                    return []
                }
            },
        },
        data() {
            return {

            }
        },
        methods:{
            escPopup(){
                this.$emit("cancelVideos",false)
            },
            okPopup(index){
                this.$emit("okVideos",[false,index]);
            },
        }
    }
</script>
<style scoped>
    .popup-cover {
        position: absolute;
        z-index: 1000;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0.5;
        filter: alpha(opacity=50);
    }
    .check-popup{
        position: absolute;
        z-index: 10001;
        top: 15%;
        left: 35%;
        width: 660px;
        height: 440px;
        background-color: #fff;
        box-shadow: 2px 2px 10px #555;
        border-radius: 10px;
        /*overflow: hidden;*/
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .check-popup hr{
        width: 100%;
        border: #ececec 1px solid;
    }
    .check-popup-title{
        width: 100%;
        height: 70px;
        /*margin-top: 20px;*/
        padding-top: 20px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        background-color: rgba(255, 101, 1, 0.0196078431372549);
    }
    .check-popup-title span{
        font-size: 16px;
        font-weight: 600;
        color:#3385ff;
    }
    .check-popup-content{
        height: 450px;
        padding-top: 40px;
        flex-wrap: wrap;
        justify-content: flex-start;
        overflow-y: auto;
        padding-left: 30px;
        padding-right: 40px;
    }
    .check-content-div{
        width: 100px;
        min-height: 150px;
        margin-left: 40px;
        align-items: center;
        margin-bottom: 5px;
    }
    .check-content-video{
        width: 90px;
        height: 90px;
    }
    .check-content-button button{
        width: 50px;
        height: 25px;
        background-color: #3385ff;
        color: white;
        border: 0;
    }
    .popup-common-row{
        display: flex;
        flex-direction: row;
    }
    .popup-common-column{
        display: flex;
        flex-direction: column;
    }
    .check-popup-bottom{
        padding-left: 80%;
        padding-top: 10px;
        width: 100%;
        height: 70px;
        background-color: rgba(255, 101, 1, 0.0196078431372549);
    }
    .check-popup-bottom button{
        width: 80px;
        height: 34px;
        border: 0;
        background: white;
        border-radius: 5px;
        outline:none
    }
    .check-popup-bottom button:nth-child(1){
        margin-right: 20px;
        border: 1px solid #cccccc;
        color: #aaaaaa;
    }
</style>   