var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"declare"},[_c('Crumbs',{attrs:{"title":_vm.$route.meta.title}}),_c('div',{staticClass:"content"},[_c('ul',{staticClass:"content_ul"},_vm._l((_vm.list),function(item,index){return _c('li',{key:index},[_c('div',{staticClass:"li_box"},[_c('div',[_c('a',{staticStyle:{"cursor":"default"},attrs:{"href.prevent":""}},[_c('img',{attrs:{"src":item.src,"alt":""}})])]),_c('div',{staticClass:"content_ws"},[(item.specialType == 1002)?_c('p',{staticStyle:{"height":"21px"}},[_vm._v(" 市级设计创新中心 ")]):_c('p',{staticStyle:{"height":"21px"}},[_vm._v(_vm._s(item.specialTypeValue))]),_c('div',{staticClass:"downLink"},_vm._l((item.manageSpecialGuideFileList),function(item2,index2){return _c('a',{key:index2,staticClass:"aLink",style:(item2.guideFileName == '123' && item2.guideFilePath == '123'
                    ? 'visibility:hidden'
                    : ''),attrs:{"title":'点击下载：' + item2.guideFileName,"href":"#"},on:{"click":function($event){return _vm.downLoad(item2.guideFilePath)}}},[_vm._v(_vm._s(item2.guideFileName))])}),0),_c('div',[_vm._v(" "+_vm._s(item.specialStartTime.substring( 0, item.specialStartTime.length - 3 ))+" - "+_vm._s(item.specialEndTime.substring( 0, item.specialEndTime.length - 3 ))+" ")]),_c('div',{staticClass:"btnBox",staticStyle:{"text-align":"center"}},[_c('Button',{staticClass:"btnLink",attrs:{"disabled":item.wasShow == 0,"title":item.wasShow == 0 && item.comment == '去申报'
                    ? '已申报，一年内不得重复提交'
                    : ''},on:{"click":function($event){return _vm.goProgram(item)}}},[_vm._v(_vm._s(item.comment))]),(
                  item.specialType == 1002 &&
                    item.comment !== '该项目已截止申报,请期待明年'
                )?_c('span',{staticStyle:{"padding-left":"10px"}},[_c('Button',{staticClass:"btnLink",attrs:{"disabled":item.wasShow == 0},on:{"click":function($event){return _vm.goProgram(item)}}},[_vm._v("去复核")])],1):_vm._e()],1)])])])}),0)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }