"use strict";

import axios from "axios";
import router from "../router";
import iView from "iview";
import util from "./functions";
// const Timestamp = new Date().getTime();
axios.interceptors.request.use(
  config => {
    // loading show
    document.getElementById("ajaxLoader").style.display = "inline-block";
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => {
    // loading hidden
    document.getElementById("ajaxLoader").style.display = "none";
    // 如果http状态码正常，则直接返回数据
    if (response.headers["x-device-type"] === "Mobile") {
      alert("请至PC端申报填写");
    }
    if (
      response &&
      (response.status === 200 ||
        response.status === 304 ||
        response.status === 400)
    ) {
      const res = response.data;
      if (res.messageCode !== 10000) {
        iView.Spin.hide();
        // 判断登录状态
        if (res.messageCode === 500004) {
          iView.Modal.confirm({
            title: "消息提示",
            content: "登录超时，是否重新登录",
            cancelText: "取消",
            okText: "确定",
            onOk: function() {
              router.replace("/login");
            }
          });
        } else if (res.messageCode === 401002) {
          iView.Message.error({
            content: "请填写" + res.result.fieldName,
            duration: 3
          });
        } else if (res.messageCode === 40017) {
          router.replace("/login");
        }
        iView.Message.error(res.messageContent);
        return Promise.reject(res.messageContent || "error");
      } else {
        // console.log(response.headers.authorization);
        // let token = response.headers.authorization;
        // localStorage.setItem("tokenSBD", token);
        let token = response.headers.authorization;
        token ? localStorage.setItem("tokenSBD", token) : "";
        return res;
      }
    }
  },
  error => {
    // loading hidden
    document.getElementById("ajaxLoader").style.display = "none";
    iView.Message.error("接口报错啦");
    return Promise.reject(error);
  }
);

export default {
  post(url, data) {
    return axios({
      method: "post",
      baseURL: "/",
      url,
      data: JSON.stringify(data),
      // timeout: 20000,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        authorization: util.getToken()
      }
    });
  },
  get(url, params) {
    return axios({
      method: "get",
      baseURL: "/",
      url: url,
      params,
      // timeout: 20000,
      headers: {
        Pragma: "no-cache",
        "Cache-Control": "no-cache",
        "X-Requested-With": "XMLHttpRequest",
        authorization: util.getToken()
      }
    });
  }
};
