<template>
  <div class="shangHaiDesignAuit">
    <div class="designOne">
      <div class="design-title-one">一、单位基本情况</div>
      <Row class="content-row">
        <Col class="key" span="4">单位名称</Col>
        <Col class="value" span="8">{{ postList.hpUnitBase.unitName }}</Col>
        <Col class="key" span="4">通讯地址</Col>
        <Col class="value" span="8">
          {{ postList.hpUnitBase.mailingAddress }}
        </Col>
        <Col class="key" span="4">单位负责人</Col>
        <Col class="value" span="8">{{ postList.hpUnitBase.legalPerson }}</Col>
        <Col class="key" span="4">负责人移动电话</Col>
        <Col class="value" span="8">{{ postList.hpUnitBase.mobilePhone }}</Col>
        <Col class="key" span="4">邮编</Col>
        <Col class="value" span="8">{{ postList.hpUnitBase.postcode }}</Col>
        <Col class="key" span="4">申报联系人</Col>
        <Col class="value" span="8">{{ postList.hpUnitBase.contentName }}</Col>
        <Col class="key" span="4">联系人移动电话</Col>
        <Col class="value" span="8">{{ postList.hpUnitBase.mobile }}</Col>
        <Col class="key" span="4">电子邮箱</Col>
        <Col class="value" span="8">{{ postList.hpUnitBase.email }}</Col>
      </Row>
      <div class="design-title-two">申报单位简介（限500字内）</div>
      <div class="design-content">
        <textarea
          readonly="readonly"
          v-model="postList.hpUnitBase.unitIntroduce"
          style="border: 0;width: 100%"
          name
        ></textarea>
      </div>
      <div class="design-title-two">设计价值（限500字内）</div>
      <div class="design-content">
        <textarea
          readonly="readonly"
          v-model="postList.hpDeclareData.designExplain"
          style="border: 0;width: 100%"
          name
        ></textarea>
      </div>
      <div class="design-title-two">设计价值（限500字内）</div>
      <div class="design-title-two">(一) 市场价值</div>
      <div class="design-content">
        <textarea
          readonly="readonly"
          v-model="postList.hpDeclareData.marketValue"
          style="border: 0;width: 100%"
          name
        ></textarea>
      </div>
      <div class="design-title-two">(二) 社会价值</div>
      <div class="design-content">
        <textarea
          readonly="readonly"
          v-model="postList.hpDeclareData.socialValue"
          style="border: 0;width: 100%"
          name
        ></textarea>
      </div>
    </div>
    <div class="video-sky"></div>
    <div class="designTwo">
      <div class="design-title-one">
        二、申报作品代表性展示图
        <span>（3张以内）</span>
      </div>
      <div class="imgStyle">
        <div v-for="(item, index) in postList.hpResultsImagesList">
          <img
            style="width: 280px;height: 200px;object-fit: scale-down;"
            :src="item.imagePath"
            :alt="item.imageName"
          />
        </div>
      </div>
    </div>
    <div class="view-bottom">
      <button @click="buttonEsc">返回</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShanghaiDesignAuit",
  data() {
    return {
      postList: {
        hpUnitBase: {
          programId: null,
          unitName: "",
          unitCode: "",
          declareType: null,
          email: null,
          declareUnitType: null,
          declareUnitTypeOther: null,
          industryInvolved: null,
          mailingAddress: null,
          postcode: null,
          legalPerson: null, //法人代表
          mobilePhone: null, //移动电话
          contentName: null,
          department: null,
          position: null,
          telephone: null,
          mobile: null,
          fix: null,
          unitIntroduce: null
        },
        hpDeclareData: {
          programId: null,
          dataName: "",
          designFinishTime: null,
          hasPatent: null,
          eventsTime: null,
          hasRegisterCopyright: "",
          hasSales: null,
          wantSales: null,
          memberName: null,
          stayUnitName: null,
          position: null,
          contentPhone: null,
          mobile: null,
          fix: null,
          designExplain: null,
          designValueExplain: null,
          marketValue: null,
          socialValue: null
        },
        hpAddDeclare: {
          programId: null,
          declareType: null,
          unitOrPerson: "",
          personName: "",
          personPosition: null,
          deedsShow: null
        },
        hpResultsImagesList: [
          {
            irId: null,
            programId: null,
            imageName: "",
            imagePath: ""
          }
        ],
        hpOriginalImage: {
          programId: null,
          originalImageName: "",
          originalIamgePath: ""
        },
        hpVideosList: []
      },
      imageList: [
        // {imagePath:require("../../assets/special0.png"),imageName:""},
      ]
    };
  },
  computed: {
    getType() {
      return this.$route.query.type;
    },
    getProgramId() {
      return this.$route.query.programId;
    },
    getSpecialId() {
      return this.$route.query.specialId;
    }
  },
  created() {
    this.init();
  },
  methods: {
    /**
     * 查询初始化数据
     * */
    init() {
      this.$http
        .post("/shsj_declare/hpDeclare/queryHpDeclareByType", {
          programId: this.getProgramId,
          type: "0",
          specialId: this.getSpecialId
        })
        .then(res => {
          if (res.messageCode == 10000) {
            this.postList = res.result.newHpDelareInfoDto;
          }
        });
    },
    /**
     * 返回
     * */
    buttonEsc() {
      this.$router.push({
        path: "/shanghaiDesign",
        query: { programId: this.getProgramId, specialId: this.getSpecialId }
      });
    }
  }
};
</script>

<style scoped>
.shangHaiDesignAuit {
  padding-left: 190px;
  padding-top: 50px;
}
.design-title-one {
  font-size: 20px;
  font-weight: 500;
}
.design-title-one span {
  font-size: 14px;
  color: #999999;
  margin-left: 10px;
}
.content-row {
  border-top: 1px solid #ededed;
  border-left: 1px solid #ededed;
  margin-top: 20px;
}
.key {
  background-color: #f6f9ff;
  height: 50px;
  display: flex;
  flex-direction: row;
  /*align-items: center;*/
  justify-content: center;
  text-align: center;
  border-right: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  font-size: 14px;
  padding-top: 15px;
}
.value {
  height: 50px;
  display: flex;
  flex-direction: row;
  /*align-items: center;*/
  justify-content: center;
  border-right: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  overflow-y: auto;
  font-size: 14px;
  padding-top: 15px;
}
.design-title-two {
  background-color: #f6f9ff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid #ededed;
  border-top: 0;
  height: 50px;
}
.design-content {
  border: 1px solid #ededed;
  border-top: 0;
}
.design-content textarea {
  padding: 20px 30px;
  height: 350px;
}
.video-sky {
  height: 10px;
  background-color: rgba(248, 248, 248, 1);
  margin: 40px 0;
}
.imgStyle {
  width: 100%;
}
.imgStyle div {
  display: inline-block;
  width: 450px;
  height: 300px;
  margin-top: 30px;
}
.imgStyle div:nth-child(2) {
  margin-left: 40px;
}
.view-bottom {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.view-bottom button {
  width: 120px;
  height: 40px;
  border-radius: 5px;
  outline: none;
  font-size: 14px;
  border: 1px solid #d1d1d1;
  background-color: #ffffff;
  color: #333333;
}
/*.imgStyle div:nth-child(3){*/
/*margin-left: 30px;*/
/*}*/
</style>
