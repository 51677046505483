<style lang="scss" rel="stylesheet/scss" scoped>
#g-pc-layout {
  #header {
    background-color: rgba(62, 138, 255, 1);
    color: #fff;
    padding: 15px 0;
    .m-header-wrapper {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .m-header-title {
        font-size: 25px;
        font-weight: bold;
      }
      .m-nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .m-nav-item {
          border-radius: 20px;
          padding: 8px 40px;
          cursor: pointer;
          position: relative;
          .m-mav-child {
            position: absolute;
            width: 200px;
            top: 65px;
            right: 0;
            background: #fff;
            z-index: 1;
            color: #333;
            border-radius: 6px;
            box-shadow: 0 0 10px #bdbcbc;
            .m-child-item {
              padding: 15px 20px;
              cursor: pointer;
            }
          }
        }
        .m-nav_to:hover {
          background-color: #fff;
          color: #3385ff;
        }
        .active {
          background-color: #fff;
          color: #3385ff;
        }
      }
    }
  }
  #content {
    .m-content {
      width: 1200px;
      margin: 0 auto;
      padding: 20px 0;
      min-height: calc(100vh - 80px);
    }
  }
}

.active {
  background: #fff;
  border-radius: 30px;
  color: rgb(62, 138, 255);
}
</style>

<template>
  <div id="g-pc-layout">
    <div id="header">
      <div class="m-header-wrapper">
        <div class="m-header-title">上海设计申报项目管理平台</div>
        <div class="m-nav">
          <div
            v-show="token && token != 'undefined' && userInfo"
            @click="toNav(index, item.path)"
            v-for="(item, index) in nav"
            :key="index"
            :class="
              $route.meta.active === item.path
                ? 'm-nav-item m-nav_to active'
                : 'm-nav-item m-nav_to'
            "
          >
            <!-- :class="index==current? 'm-nav-item m-nav_to active':'m-nav-item m-nav_to' " -->
            <span>{{ item.title }}</span>
          </div>
          <!-- <div class="m-nav-item m-nav_to"><router-link  to="/declare">项目申报</router-link></div>
          <div class="m-nav-item m-nav_to"><router-link  to="manage">项目管理</router-link></div>-->
          <div class="m-nav-item" @click="show">
            <span>
              <img src="../assets/user.png" alt />
            </span>
            <!-- <div v-show="token && token!='undefined'" class="m-mav-child" v-if="isShow"> -->
            <div
              v-show="token && token != 'undefined' && userInfo"
              class="m-mav-child"
              v-if="isShow"
            >
              <div class="m-child-item">{{ userInfo }}</div>
              <div @click="openModal('修改密码')" class="m-child-item">
                修改密码
              </div>
              <div @click="openModal('修改邮箱')" class="m-child-item">
                修改邮箱
              </div>
              <div @click="openModal('退出确认')" class="m-child-item">
                退出
              </div>
            </div>
          </div>
          <!-- 对话框 -->
          <Modal
            :mask-closable="false"
            width="300"
            class="GL_dialog"
            v-model="dialogShow"
            :title="dialogTitle"
            @on-ok="ok('formValidate')"
            @on-cancel="cancel"
          >
            <div v-if="dialogTitle === '修改密码'">
              <Form
                ref="formValidate"
                :model="formValidate"
                :rules="ruleValidate"
                :label-width="0"
              >
                <FormItem label prop="oldPassword">
                  <Input
                    type="password"
                    v-model="formValidate.oldPassword"
                    placeholder="请输入原密码"
                  ></Input>
                </FormItem>
                <FormItem label prop="newPassword">
                  <Input
                    type="password"
                    v-model="formValidate.newPassword"
                    placeholder="请输入新密码"
                  ></Input>
                </FormItem>
                <FormItem label prop="checkPassword">
                  <Input
                    type="password"
                    v-model="formValidate.checkPassword"
                    placeholder="重复密码"
                  ></Input>
                </FormItem>
              </Form>
            </div>
            <div v-if="dialogTitle === '修改邮箱'">
              <Input
                type="text"
                :maxlength="50"
                :placeholder="email"
                v-model="newEmail"
              ></Input>
            </div>
            <div v-if="dialogTitle === '退出确认'">
              <p>确定要退出登录吗？</p>
            </div>
            <div slot="footer">
              <Button type="text" size="large" @click="cancel">取消</Button>
              <Button type="primary" size="large" @click="ok('formValidate')"
                >确定</Button
              >
            </div>
          </Modal>
        </div>
      </div>
    </div>
    <div id="content">
      <div class="m-content">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  data() {
    const validateOld = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        var reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/;
        if (!reg.test(value)) {
          callback(new Error("请输入6-16位字母数字特殊字符的组合"));
          return;
        }
        callback();
      }
    };
    const validatePass = (rule, value, callback) => {
      var reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/;
      if (value === "") {
        callback(new Error("请输入密码"));
      } else if (!reg.test(value)) {
        callback(new Error("请输入6-16位字母数字特殊字符的组合"));
        return;
      } else {
        if (this.formValidate.checkPassword !== "") {
          // 对第二个密码框单独验证
          this.$refs.formValidate.validateField("checkPassword");
        }
        callback();
      }
    };
    const validatePassCheck = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.formValidate.newPassword) {
        callback(new Error("两次输入的密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      newEmail: null,
      email: null,
      userInfo: null,
      token: null,
      dialogTitle: "",
      dialogShow: false,
      isShow: false,
      nav: [
        // { title: "首页", path: "/home" },
        { title: "项目申报", path: "/declare" },
        { title: "项目管理", path: "/manage" }
      ],
      formValidate: {
        oldPassword: "",
        newPassword: "",
        checkPassword: ""
      },
      ruleValidate: {
        oldPassword: [{ validator: validateOld, trigger: "blur" }],
        newPassword: [{ validator: validatePass, trigger: "blur" }],
        checkPassword: [{ validator: validatePassCheck, trigger: "blur" }]
      }
    };
  },
  created() {
    this.token = this.$util.getToken;
    this.userInfo = localStorage.getItem("companyName");
  },
  watch: {},
  computed: {},
  components: {},
  mounted() {},
  methods: {
    /**显示二级菜单
     * method show
     * */
    show() {
      this.isShow = !this.isShow;
    },
    /**点击跳转
     * method toNav
     * */
    toNav(index, path) {
      this.$router.push(path);
    },
    /**对话框确定
     * method ok
     * */

    async ok(name) {
      if (this.dialogTitle === "修改密码") {
        this.$refs[name].validate(async valid => {
          if (valid) {
            var { oldPassword, newPassword } = this.formValidate;
            var res = await this.$http.post(
              "/shsj_declare/account/updatePassword",
              {
                oldPassword,
                newPassword
              }
            );
            if (res.messageCode == 10000) {
              this.$Message.info("修改密码成功");
              this.dialogShow = false;
              setTimeout(() => {
                localStorage.removeItem("tokenSBD");
                localStorage.removeItem("accountId");
                localStorage.removeItem("companyName");
                localStorage.removeItem("loglevel:webpack-dev-server");
                window.sessionStorage.clear();
                this.$store.commit("resetState");
                this.$router.push("/login");
              }, 1000);
            }
          } else {
            this.$Message.error("请填写正确信息!");
          }
        });
      } else if (this.dialogTitle === "修改邮箱") {
        let patrn = this.$util.validate("email")[0];
        if (patrn.test(this.newEmail) == false) {
          this.$Message.error(this.$util.validate("email")[1]);
          return;
        }
        let { messageCode } = await this.$http.post(
          "/shsj_declare/user/updateEmail",
          {
            email: this.newEmail
          }
        );
        if (messageCode == 10000) {
          this.$Message.success("修改邮箱成功");
          // this.email = this.newEmail;
          this.getUserInfo();
          this.newEmail = null;
          this.dialogShow = false;
        }
      } else if (this.dialogTitle === "退出确认") {
        this.$Message.info("退出登录成功");
        localStorage.removeItem("tokenSBD");
        localStorage.removeItem("accountId");
        localStorage.removeItem("companyName");
        localStorage.removeItem("loglevel:webpack-dev-server");
        window.sessionStorage.clear();
        this.$store.commit("resetState");
        this.dialogShow = false;
        setTimeout(() => {
          this.$router.push("/login");
        }, 500);
      }
    },
    /**对话框取消
     * method cancel
     * */
    cancel() {
      switch (this.dialogTitle) {
        case "修改密码":
          this.$refs.formValidate.resetFields();
          this.$Message.info("取消修改");
          break;
        case "修改邮箱":
          this.newEmail = null;
          this.$Message.info("取消修改");
          break;
        case "退出确认":
          this.$Message.info("取消操作");
          break;
      }
      this.dialogShow = false;
    },
    /*获取用户信息
     *getUserInfo
     */

    async getUserInfo() {
      let { messageCode, result } = await this.$http.get(
        "/shsj_declare/account/getAccountInfo"
      );
      if (messageCode == 10000) {
        this.email = result.account.email;
      }
    },
    /*打开对话框
     **/

    openModal(type) {
      this.userInfo = localStorage.getItem("companyName");
      this.dialogShow = true;
      switch (type) {
        case "修改密码":
          this.dialogTitle = "修改密码";
          break;
        case "修改邮箱":
          this.dialogTitle = "修改邮箱";
          this.getUserInfo();
          break;
        case "退出确认":
          this.dialogTitle = "退出确认";
          break;
      }
    }
  }
};
</script>
