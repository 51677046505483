<template>
  <div class="attachmentUpload GL_declareForm">
    <Crumbs :title="'附件上传 '"></Crumbs>
    <div class="attention">
      <span>提醒：</span>
      附件允许格式为*.pdf、*.doc、*.docx、*.xlsx、*.xls，其中审计报告和获奖证书等请务必使用原件扫描或复印件加盖公章后扫描成pdf，大小不超过20M(若超过请拆分上传)，标注
      <span>*</span> 的附件为必须上传。
    </div>
    <Form
      class="form"
      ref="formValidate"
      :model="formValidate"
      :label-width="0"
    >
      <div class="m_item">
        <Row
          v-for="(item, index) in formValidate.programFilesDtoList"
          :key="item.sfId"
          type="flex"
          class="m_row"
        >
          <Col style="padding:8px" class="m_col m_title" span="8">
            <span class="spantext">
              <span
                :style="item.wasNeed == 1 ? '' : 'visibility: hidden'"
                class="redStar"
                >*</span
              >{{ item.declareMaterialName }}</span
            >
          </Col>
          <Col class="s_col" span="16">
            <my-upload
              multiple
              :isShowUploadlist="true"
              :size="size"
              :format="format"
              :formatMassage="formatMassage"
              :defaultList="item.programFilesList"
              :sfId="item.sfId"
              action="/shsj_declare/file/fileUpload"
              @uploadRemove="uploadRemove(index, arguments)"
              @uploadSuccess="uploadSuccess"
            >
              <Button icon="ios-cloud-upload-outline">点击上传</Button>
            </my-upload>
          </Col>
        </Row>
      </div>
    </Form>
    <div class="foot">
      <button v-waves @click="cancel" class="GL_cancel">取消</button>
      <button v-waves @click="save('save')" class="GL_save">保存</button>
      <Button type="primary" :loading="loading" @click="submit" class="GL_next"
        >提交</Button
      >
    </div>
  </div>
</template>

<script>
import myUpload from "../../components/upload";
import Crumbs from "../../components/crumbs";
import waves from "../../directive/waves";
export default {
  components: {
    Crumbs,
    myUpload
  },
  directives: { waves },
  name: "",
  props: {},
  data() {
    return {
      loading: false,
      size: 20,
      format: ["pdf", "doc", "docx", "xls", "xlsx"],
      formatMassage: "*.pdf、*.doc、*.docx、*.xlsx、*.xls ",
      forEachFiles: {},
      formValidate: {
        programId: null,
        pageStatus: 6,
        declareType: 3002,
        programFilesDtoList: []
      }
    };
  },
  created() {
    this.formValidate.programId = this.$store.state.industryProgramId;
    // 初始化专项附件
    // this.initAttachment();
    this.queryAttachment();
  },
  computed: {},
  mounted() {},
  methods: {
    /*查询附件
     *method queryAttachment
     */
    async queryAttachment() {
      let res = await this.$http.post(
        "/shsj_declare/industralDesign/getIndustralDesignInfo",
        {
          programId: this.formValidate.programId,
          pageStatus: 6,
          declareType: 3002,
          specialId: this.$store.state.industrySpecialId
        }
      );
      if (res.messageCode === 10000) {
        if (res.result.result != null) {
          this.formValidate.programFilesDtoList = res.result.result;
        }
      }
    },
    /**上传成功
     * method uploadSuccess
     * */
    uploadSuccess(file, id) {
      let listIndex = this.formValidate.programFilesDtoList.findIndex(
        item => item.sfId == id
      );
      this.formValidate.programFilesDtoList[listIndex].programFilesList.push({
        fileName: file.fileName,
        filePath: file.filePath
      });
    },

    /**删除
     * method handleRemove
     * */
    uploadRemove(index, arg) {
      if (arg[0].url) {
        let removeURL = this.formValidate.programFilesDtoList[
          index
        ].programFilesList.findIndex(item => item.filePath == arg[0].url);
        if (removeURL != -1) {
          this.formValidate.programFilesDtoList[index].programFilesList.splice(
            removeURL,
            1
          );
        }
      } else {
        if (arg[0].response.messageCode == 10000) {
          let delindex = this.formValidate.programFilesDtoList[
            index
          ].programFilesList.findIndex(
            item => item.filePath == arg[0].response.result.returnFile.filePath
          );
          this.formValidate.programFilesDtoList[index].programFilesList.splice(
            delindex,
            1
          );
        } else {
          this.$Message.error(arg[0].response.messageContent);
        }
      }
    },

    /*保存表单
     *method saveProgram
     **/
    async saveProgram(type) {
      try {
        let { messageCode } = await this.$http.post(
          "shsj_declare/industralDesign/saveIndustralDesignInfo",
          this.formValidate
        );
        if (messageCode === 10000) {
          try {
            if (type == "submit") {
              var res = await this.$http.post(
                "shsj_declare/industralDesign/submitProgram",
                {
                  programId: this.$store.state.industryProgramId,
                  declareType: 3002,
                  specialId: this.$store.state.industrySpecialId
                }
              );
              if (res.messageCode == 10000) {
                this.$Message.success("提交成功");
                this.$router.push("/manage");
              }
            } else {
              this.$Message.success("保存页面成功!");
            }
            this.loading = false;
          } catch (error) {
            this.loading = false;
          }
        }
      } catch (error) {
        this.loading = false;
      }
    },
    /**取消保存
     * method cancel
     * */
    cancel() {
      this.$Message.info("取消操作");
      setTimeout(() => {
        if (this.$store.state.industryFromPage == "manage") {
          return this.$router.push("/manage");
        }
        return this.$router.push("/declare");
      }, 1000);
    },
    /**保存
     * method save
     * */
    save(type) {
      this.saveProgram(type);
    },
    /**提交
     * method submit
     * */
    async submit() {
      this.loading = true;
      this.saveProgram("submit");
    }
  }
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.attachmentUpload {
  .spantext {
    max-width: 100%;
    display: inline-block;
    overflow-wrap: break-word; /*  css3属性，当内容超过指定容器的边界时是否断行  */
  }
  .m_item {
    .s_col {
      border-right: 1px solid #e8ecf5;
      border-bottom: 1px solid #e8ecf5;
      .ivu-btn {
        margin: 5px;
        border-radius: 20px;
        padding: 10px 50px;
      }
      .picText {
        padding: 10px;
        display: inline-block;
      }
    }
  }
}
.attention {
  margin-bottom: 15px;
  span {
    color: red;
  }
}
</style>
