<template>
  <div class="manage">
    <Crumbs :title="$route.meta.title"></Crumbs>
    <div class="input">
      <div>
        <label for>申报年度：</label>
        <DatePicker
          style="width:200px"
          size="large"
          type="year"
          @on-change="formData.specialYear = $event"
        ></DatePicker>
      </div>
      <div>
        <label for>专项类别：</label>
        <Select
          clearable
          size="large"
          v-model="formData.specialType"
          style="width:200px"
          @on-change="selectionSpecific"
        >
          <Option
            v-for="item in specialList"
            :value="item.dataId"
            :key="item.dataId"
            >{{ item.dataValue }}</Option
          >
        </Select>
      </div>
      <div>
        <label for>申报类别：</label>
        <Select
          clearable
          size="large"
          v-model="formData.declareType"
          style="width:200px"
        >
          <Option
            v-for="item in declareList"
            :value="item.dataId"
            :key="item.dataId"
            >{{ item.dataValue }}</Option
          >
          <!-- <Option value="3001">企业工业设计中心</Option>
          <Option value="3002">工业设计企业</Option>
          <Option value="6001">设计产品</Option>
          <Option value="6002">设计案例</Option>
          <Option value="6003">设计事件</Option>-->
        </Select>
      </div>
    </div>
    <div class="input">
      <div>
        <label for>状态：</label>
        <Select
          clearable
          size="large"
          v-model="formData.status"
          style="width:200px"
        >
          <Option value="0">待提交</Option>
          <Option value="1">初审</Option>
          <Option value="2">终审</Option>
          <!-- <Option value="3">初审不通过</Option>
          <Option value="4">终审不通过</Option>
          <Option value="5">终审通过</Option>-->
        </Select>
      </div>
      <div>
        <button
          v-waves
          @click="inquiryBtn"
          style="margin-left:10px"
          class="GL_Blue_Sbtn"
        >
          查询
        </button>
      </div>
      <div class="btnBox"></div>
    </div>
    <div class="table">
      <Table size="large" :columns="columns1" :data="manageData">
        <template slot-scope="{ row }" slot="specialType">
          {{ row.specialTypeName }}
        </template>
        <template slot-scope="{ row }" slot="declareType">
          {{ row.declareTypeName }}
        </template>
        <template slot-scope="{ row, index }" slot="handle">
          <a class="aLink" @click.stop="show(row)">查看</a>
          <a
            v-show="
              (row.status == '0' || row.status == '1') && row.isUpdate == 0
            "
            class="aLink"
            @click.stop="edit(row)"
            >修改</a
          >
          <a
            v-show="row.status > 0"
            @click.stop="download(row)"
            class="aLink"
            href.prevent
            >下载PDF</a
          >
          <!-- <a
            v-show=" row.implStatus == '3'"
            class="aLink"
            href.prevent
          >生成PDF失败</a>-->
          <a
            v-show="row.status == '0'"
            class="aLink"
            @click.stop="remove(index, row.programId)"
            >删除</a
          >
        </template>
      </Table>
    </div>
    <keep-alive>
      <my-page
        @onChange="pageChange($event)"
        :page="{ ...page, pageSize: formData.pageSize }"
        class="myPage"
      ></my-page>
    </keep-alive>
    <!-- 修改弹框 -->
    <Modal v-model="editModal" title="修改提示" @on-cancel="editModal = false">
      <p>修改信息保存后，需重新进行提交。</p>
      <div slot="footer">
        <Button type="text" size="large" @click="editModal = false"
          >取消</Button
        >
        <Button type="primary" size="large" @click="sure">确定</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import Crumbs from "../../components/crumbs";
import myPage from "../../components/page";
import waves from "../../directive/waves";
export default {
  name: "Manage",
  components: {
    Crumbs,
    myPage
  },
  directives: { waves },
  props: {},
  data() {
    return {
      currentRow: null,
      editModal: false,
      // 申报类别
      declareList: null,
      programId: null,
      // 专项类别
      specialList: null,
      page: {
        total: null
      },
      formData: {
        page: 1,
        pageSize: 10,
        specialYear: null, //专项年份
        specialName: null, //专项名称
        specialType: null, //专项类型
        declareType: null, //申报类型
        status: null //状态（0：未提交 1：待初审 2：待定）
      },
      value: "",
      columns1: [
        {
          width: 120,
          title: "项目编号",
          key: "programCode"
        },
        {
          title: "版本号",
          key: "programVersion"
        },
        {
          title: "申报年度",
          key: "specialYear",
          width: 100
        },
        {
          title: "专项类别",
          slot: "specialType",
          width: 150
        },
        {
          title: "申报类别",
          slot: "declareType",
          // key: "declareTypeName",
          width: 150
        },
        {
          title: "状态",
          key: "statusValue"
        },
        {
          width: 110,
          title: "申报日期",
          key: "declareTime"
        },
        {
          width: 280,
          title: "操作",
          slot: "handle"
        }
      ],
      manageData: []
    };
  },
  created() {
    this.$store.commit("getIndustryProgramId", null);
    this.$store.commit("getIndustrySpecialId", null);
  },
  mounted() {
    this.getList();
    this.wordbook(1000);
  },
  methods: {
    /*
     *method
     * 页码跳转
     * pageChange
     * **/
    pageChange(e) {
      this.formData.page = e;
      this.getList();
    },
    /*
     *method
     * 点击查询
     * inquiryBtn
     * **/
    inquiryBtn() {
      this.formData.page = 1;
      this.getList();
    },
    /*
     *method
     * 申报类别
     * **/
    async wordbook(id) {
      var res = await this.$http.get(
        `shsj_declare/sysDict/queryDictDataList?dictId=${id}`
      );
      if (res.messageCode == 10000) {
        switch (id) {
          case 1000:
            this.specialList = res.result.dictDataList;
            break;
          // case 3000:
          //   this.declareList = res.result.dictDataList;
          //   break;
        }
      }
    },

    /*
     *method
     * 删除
     * **/

    async remove(index, programId) {
      this.$Modal.confirm({
        title: "删除",
        content: "<p>是否确认删除此条申报？</p>",
        onOk: async () => {
          let { messageCode } = await this.$http.post(
            "/shsj_declare/program/updateProgramStatus",
            {
              status: 2,
              programId
            }
          );
          if (messageCode == 10000) {
            this.$Message.success("删除成功!");
            let totalPage = Math.ceil(
              (this.page.total - 1) / this.formData.pageSize
            ); // 总页数
            let page =
              this.formData.page > totalPage ? totalPage : this.formData.page;
            this.formData.page = this.formData.page < 1 ? 1 : page;
            this.page.total--;
            this.getList();
          }
        },
        onCancel: () => {
          this.$Message.info("取消删除");
        }
      });
    },
    /*
     *method
     * 获取列表信息
     * **/

    async getList() {
      var res = await this.$http.post("shsj_declare/program/getProgramManage", {
        ...this.formData,
        accountId: localStorage.getItem("accountId")
      });
      if (res.messageCode == 10000) {
        this.manageData = res.result.programManageList.list;
        this.page.total = res.result.programManageList.total;
        return res;
      }
    },
    /*
     *method
     * 收到建议
     * **/
    receiveAdvice(row) {
      this.$router.push({
        path: "/receiveAdvice",
        query: {
          type: "receiveAdvice",
          programId: row.programId,
          specialId: row.specialId
        }
      });
    },
    /*
     *method
     * 查看
     * **/
    show(row) {
      switch (row.specialType) {
        case 1001:
          if (row.specialYear == "2023") {
            this.$router.push({
              path: "/shanghaiDesignView2023",
              query: {
                type: "show",
                programId: row.programId,
                specialId: row.specialId
              }
            });
          } else if (row.specialYear == "2024") {
            this.$router.push({
              path: "/shanghaiDesignView2024",
              query: {
                type: "show",
                programId: row.programId,
                specialId: row.specialId
              }
            });
          } else {
            this.$router.push({
              path: "/shanghaiDesignView",
              query: {
                type: "show",
                programId: row.programId,
                specialId: row.specialId
              }
            });
          }
          break;
        case 1002:
          if (row.declareType == 3001) {
            this.$router.push({
              path: `/centerview${row.specialYear}`,
              query: {
                programId: row.programId,
                specialId: row.specialId
              }
            });
          } else {
            this.$router.push({
              path: `/industryview${row.specialYear}`,
              query: {
                programId: row.programId,
                specialId: row.specialId
              }
            });
          }
          break;
        case 1003:
          this.$router.push({
            path: `/modeCompanyView${row.specialYear}`,
            query: {
              programId: row.programId,
              specialId: row.specialId
            }
          });
          break;
      }
    },
    /*
     *method
     * 编辑
     * **/
    edit(row) {
      switch (row.specialType) {
        case 1001:
          if (row.status == 1) {
            this.currentRow = row;
            this.editModal = true;
          } else {
            if (row.specialYear == "2023") {
              this.$router.push({
                path: "/shanghaiDesign2023",
                query: {
                  type: "show",
                  programId: row.programId,
                  specialId: row.specialId
                }
              });
            } else if (row.specialYear == "2024") {
              this.$router.push({
                path: "/shanghaiDesign2024",
                query: {
                  type: "show",
                  programId: row.programId,
                  specialId: row.specialId
                }
              });
            } else {
              this.$router.push({
                path: "/shanghaiDesign",
                query: {
                  type: "show",
                  programId: row.programId,
                  specialId: row.specialId
                }
              });
            }
          }
          break;
        case 1002:
          if (row.declareType == 3001) {
            this.$router.push({
              path: "/declare/companysituation",
              query: { programId: row.programId, specialId: row.specialId }
            });
            this.$store.commit("getIndustryProgramId", row.programId);
          } else {
            this.$router.push({
              path: "/declare/industrialdesign",
              query: { programId: row.programId, specialId: row.specialId }
            });
          }
          break;
        case 1003:
          this.$router.push({
            path: "/modecompany",
            query: {
              programId: row.programId,
              specialId: row.specialId
            }
          });
          break;
      }
    },
    sure() {
      if (this.currentRow.specialYear == "2023") {
        this.$router.push({
          path: "/shanghaiDesign2023",
          query: {
            type: "show",
            programId: this.currentRow.programId,
            specialId: this.currentRow.specialId
          }
        });
      } else if (this.currentRow.specialYear == "2024") {
        this.$router.push({
          path: "/shanghaiDesign2024",
          query: {
            type: "show",
            programId: this.currentRow.programId,
            specialId: this.currentRow.specialId
          }
        });
      } else {
        this.$router.push({
          path: "/shanghaiDesign",
          query: {
            type: "show",
            programId: this.currentRow.programId,
            specialId: this.currentRow.specialId
          }
        });
      }
    },

    /*下载
     **/

    download(row) {
      const Timestamp = new Date().getTime();
      if (row.implStatus == "2") {
        window.open(row.pdfPath + "?Timestamp=" + Timestamp);
      } else {
        this.getList().then(res => {
          let downIndex = res.result.programManageList.list.findIndex(
            item => item.programId == row.programId
          );
          if (res.result.programManageList.list[downIndex].implStatus == 2) {
            window.open(
              res.result.programManageList.list[downIndex].pdfPath +
                "?Timestamp=" +
                Timestamp
            );
          } else {
            this.$Message.info("正在生成PDF,请稍后再试");
          }
        });
      }
    },
    selectionSpecific(value) {
      if (value == 1001) {
        this.declareList = [
          { dataId: 6001, dataValue: "设计产品" },
          { dataId: 6002, dataValue: "设计案例" },
          { dataId: 6003, dataValue: "设计事件" },
          { dataId: 6004, dataValue: "概念设计" }
        ];
      } else if (value == 1002) {
        this.declareList = [
          { dataId: 3001, dataValue: "企业设计创新中心/企业工业设计中心" },
          { dataId: 3002, dataValue: "设计创新企业/工业设计企业" }
        ];
      } else {
        // this.$set(this.formData, "specialType", null);
        this.declareList = [];
        // this.formData.specialType = null;
      }
    }
  }
};
</script>
<style lang="scss">
.manage {
  .ivu-table:before {
    background-color: #fff;
  }
  .ivu-table:after {
    background-color: #fff;
  }
}
</style>
<style lang="scss" scoped>
.manage {
  .input {
    padding: 10px;
    display: flex;
    div {
      flex: 1;
      label {
        display: inline-block;
        width: 80px;
        text-align: right;
      }
    }
  }
  .table {
    padding: 10px;
    margin-top: 50px;
    a {
      margin-right: 10px;
    }
  }
  .myPage {
    margin-top: 20px;
    text-align: right;
  }
}
.ivu-table-wrapper {
  border: none;
}
</style>
