<template>
  <div class="Status GL_declareForm Gl_input">
    <Crumbs :title="'现状及规划 '"></Crumbs>
    <Form
      class="form"
      ref="formValidate"
      :model="formValidate"
      :label-width="80"
    >
      <!-- 设计成果获奖情况 -->
      <Row>
        <Col span="24">设计成果获奖情况</Col>
      </Row>
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" style="text-align: center" span="24">
            <span class="redStar">*</span>企业运营等有关情况
          </Col>
        </Row>
        <Row class="m_row textarea_row">
          <Col span="24">
            <Input
              :maxlength="800"
              v-model="formValidate.statusQuoPlan.operationInfo"
              type="textarea"
              @on-change="
                formValidate.statusQuoPlan.operationInfo = $util.ToDBC(
                  formValidate.statusQuoPlan.operationInfo,
                  $event
                )
              "
              :autosize="{ minRows: 5, maxRows: 8 }"
              placeholder="重点是企业现在的组织体系、运营模式、产学研合作及专业人员培训及设计创新社会化服务等有关情况(800字以内)"
            ></Input>
          </Col>
        </Row>
      </div>
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" style="text-align: center" span="24">
            <span class="redStar">*</span>企业参与国家、本市重要设计类活动情况
          </Col>
        </Row>
        <Row class="m_row textarea_row">
          <Col span="24">
            <Input
              :maxlength="800"
              v-model="formValidate.statusQuoPlan.activitySituation"
              type="textarea"
              @on-change="
                formValidate.statusQuoPlan.activitySituation = $util.ToDBC(
                  formValidate.statusQuoPlan.activitySituation,
                  $event
                )
              "
              :autosize="{ minRows: 5, maxRows: 8 }"
              placeholder="(800字以内)"
            ></Input>
          </Col>
        </Row>
      </div>
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" style="text-align: center" span="24">
            <span class="redStar">*</span>今后两年目标与规划情况
          </Col>
        </Row>
        <Row class="m_row textarea_row">
          <Col span="24">
            <Input
              :maxlength="800"
              v-model="formValidate.statusQuoPlan.targetAndPlan"
              @on-change="
                formValidate.statusQuoPlan.targetAndPlan = $util.ToDBC(
                  formValidate.statusQuoPlan.targetAndPlan,
                  $event
                )
              "
              type="textarea"
              :autosize="{ minRows: 5, maxRows: 8 }"
              placeholder="重点是企业今后两年创新建设、有效投入、设计成果等主要指标，以及组织体系建设、运营模式创新、人才队伍建设、履行设计创新公共服务平台职责等规划和措施等情况(800字以内)"
            ></Input>
          </Col>
        </Row>
      </div>
    </Form>
    <div class="foot">
      <button v-waves @click="cancel" class="GL_cancel">取消</button>
      <button v-waves @click="save('save')" class="GL_save">保存</button>
      <button v-waves @click="next" class="GL_next">保存并跳转下一步</button>
    </div>
  </div>
</template>

<script>
import Crumbs from "../../components/crumbs";
import waves from "../../directive/waves";
export default {
  components: {
    Crumbs
  },
  directives: { waves },
  name: "Status",
  props: {},
  data() {
    return {
      formValidate: {
        programId: null,
        pageStatus: 4,
        declareType: 3002,
        statusQuoPlan: {
          programId: null,
          operationInfo: null,
          targetAndPlan: null,
          programType: 2
        }
      }
    };
  },
  created() {
    this.programId = this.$store.state.industryProgramId;
    this.formValidate.programId = this.$store.state.industryProgramId;
    this.formValidate.statusQuoPlan.programId = this.$store.state.industryProgramId;
    this.queryPage();
  },

  computed: {},
  mounted() {},
  methods: {
    /*初始化数据
     *method queryPage
     */
    async queryPage() {
      let res = await this.$http.post(
        "shsj_declare/industralDesign/getIndustralDesignInfo",
        {
          programId: this.programId,
          pageStatus: 4,
          declareType: 3002
        }
      );
      if (res.messageCode === 10000 && res.result.result != null) {
        this.formValidate.statusQuoPlan = res.result.result;
      }
    },
    /*保存表单
     *method saveProgram
     **/
    async saveProgram(type, next) {
      let { messageCode } = await this.$http.post(
        "shsj_declare/industralDesign/saveIndustralDesignInfo",
        this.formValidate
      );
      if (messageCode === 10000) {
        this.$Message.success("保存页面成功!");
        if (type == "next") {
          this.$router.push("/declare/member");
        }
      }
    },
    /**取消保存
     * method cancel
     * */
    cancel() {
      this.$Message.info("取消操作");
      setTimeout(() => {
        if (this.$store.state.industryFromPage == "manage") {
          return this.$router.push("/manage");
        }
        return this.$router.push("/declare");
      }, 1000);
    },
    /**保存
     * method save
     * */
    save(type) {
      this.saveProgram(type);
    },
    /**保存并下一步
     * method save and next
     * */
    next() {
      this.saveProgram("next");
    }
  }
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.Status {
}
</style>
