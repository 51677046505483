<template>
  <div class="OtherSituation GL_declareForm Gl_input">
    <Form class="form" :model="formValidate" :label-width="0">
      <!-- 情况 -->
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="8">
            企业参与制定国际、国家或行业标准情况
          </Col>
          <Col class="m_col" span="16">
            <Input
              :maxlength="1000"
              v-model="formValidate.standardSituation"
              @on-change="
                formValidate.standardSituation = $util.ToDBC(
                  formValidate.standardSituation,
                  $event
                )
              "
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 8 }"
            ></Input>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="8">
            企业获国际、国内设计奖项情况
          </Col>
          <Col class="m_col" span="16">
            <Input
              :maxlength="1000"
              v-model="formValidate.awardsSituation"
              @on-change="
                formValidate.awardsSituation = $util.ToDBC(
                  formValidate.awardsSituation,
                  $event
                )
              "
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 8 }"
            ></Input>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="8">
            企业获国家级、本市科技奖情况
          </Col>
          <Col class="m_col" span="16">
            <Input
              :maxlength="1000"
              v-model="formValidate.technologySituation"
              @on-change="
                formValidate.technologySituation = $util.ToDBC(
                  formValidate.technologySituation,
                  $event
                )
              "
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 8 }"
            ></Input>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="8">
            企业被认定国家级、市级、区级工业设计中心情况
          </Col>
          <Col class="m_col" span="16">
            <Input
              :maxlength="1000"
              v-model="formValidate.designCenterSituation"
              @on-change="
                formValidate.designCenterSituation = $util.ToDBC(
                  formValidate.designCenterSituation,
                  $event
                )
              "
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 8 }"
            ></Input>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="8">
            企业参与国家、本市重要设计类活动情况
          </Col>
          <Col class="m_col" span="16">
            <Input
              :maxlength="1000"
              v-model="formValidate.activitySituation"
              @on-change="
                formValidate.activitySituation = $util.ToDBC(
                  formValidate.activitySituation,
                  $event
                )
              "
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 8 }"
            ></Input>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="8">
            其他有关情况
          </Col>
          <Col class="m_col" span="16">
            <Input
              :maxlength="1000"
              v-model="formValidate.otherSituation"
              @on-change="
                formValidate.otherSituation = $util.ToDBC(
                  formValidate.otherSituation,
                  $event
                )
              "
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 8 }"
            ></Input>
          </Col>
        </Row>
      </div>
    </Form>
    <div class="foot">
      <button v-waves @click="cancel" class="GL_cancel">取消</button>
      <button v-waves @click="save" class="GL_save">
        保存
      </button>
      <button v-waves @click="next" class="GL_next">
        保存并跳转下一步
      </button>
    </div>
  </div>
</template>

<script>
import waves from "../../directive/waves";
// import myUpload from "../../components/upload";
export default {
  name: "OtherSituation",
  props: {},
  directives: { waves },
  data() {
    return {
      programId: null,
      // 所属行业列表
      formValidate: {}
    };
  },
  created() {
    // 查询表
    this.queyData();
  },
  watch: {},
  computed: {},
  components: {},
  mounted() {},
  methods: {
    /*method
     *点击取消
     */
    cancel() {
      this.$router.go(-1);
    },
    /*method
     *点击保存
     */
    save() {
      this.saveProgram(name);
    },
    /*method
     *点击下一步
     */
    next() {
      this.saveProgram("next");
    },
    /*保存表单
     *method saveProgram
     **/
    async saveProgram(type) {
      let { messageCode } = await this.$http.post(
        "/shsj_declare/ldeDeclare/saveLdeDeclareInfo",
        {
          programId: this.programId,
          type: "5",
          specialId: this.$route.query.specialId,
          ldeUnitOthersSituation: this.formValidate
        }
      );
      if (messageCode == 10000) {
        this.$Message.success("保存页面成功!");
        if (type == "next") {
          this.$emit("nextChange", 6);
        }
      }
    },
    /*method querydata
     *查询表
     */
    async queyData() {
      let { messageCode, result } = await this.$http.post(
        "/shsj_declare/ldeDeclare/queryLdeDeclareInfo",
        {
          programId: this.$route.query.programId,
          specialId: this.$route.query.specialId,
          type: "5"
        }
      );
      if (messageCode == 10000) {
        this.programId = result.declareInfo.programId;
        if (result.declareInfo.ldeUnitOthersSituation != null) {
          this.formValidate = result.declareInfo.ldeUnitOthersSituation;
        }
      }
    }
  }
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.m_item {
  .s_col {
    border-right: 1px solid #e8ecf5;
    border-bottom: 1px solid #e8ecf5;
    .ivu-btn {
      margin: 5px;
      border-radius: 20px;
      padding: 10px 50px;
    }
    .picText {
      padding: 10px;
      display: inline-block;
    }
  }
}
</style>
