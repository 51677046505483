<template>
  <div class="Resources GL_declareForm Gl_input">
    <Crumbs :title="'资源及绩效 '"></Crumbs>
    <Form
      class="form"
      ref="formValidate"
      :model="formValidate"
      :rules="ruleValidate"
      :label-width="0"
    >
      <Row style="padding:20px 0">
        <Col span="12">备注（没有可填无，金额可填0）</Col>
        <Col class="col_unit" span="12">单位：万元、%</Col>
      </Row>
      <!-- 设计成果获奖情况 -->
      <Row>
        <Col span="24">设计成果获奖情况</Col>
      </Row>
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title icon_col" span="6">
            <Icon
              @click="add('awardsInfoList')"
              class="icon"
              type="ios-add-circle-outline"
            />
            <span class="text_col">
              <span class="redStar">*</span>获奖作品
            </span>
          </Col>
          <Col class="m_col m_title" span="6">
            <span class="redStar">*</span>奖项名称
          </Col>
          <Col class="m_col m_title" span="6">
            <span class="redStar">*</span>获得时间
          </Col>
          <Col class="m_col m_title" span="6">
            <span class="redStar">*</span>授奖部门(或机构)
          </Col>
        </Row>
        <Row
          v-for="(item, index) in formValidate.resourceAndPerfInfo
            .awardsInfoList"
          :key="index"
          class="m_row"
        >
          <Col class="m_col icon_col" span="6">
            <Icon
              @click="reduce('awardsInfoList', index)"
              class="icon"
              type="ios-remove-circle-outline"
            />
            <Input
              :maxlength="50"
              v-model.trim="item.awardsWorks"
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="6">
            <Input
              :maxlength="50"
              v-model.trim="item.awardsName"
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="6">
            <DatePicker
              :options="pickerOptions"
              :value="item.obtainTime"
              @on-change="item.obtainTime = $event"
              type="date"
              placeholder="选择时间"
              style="width: 200px"
            ></DatePicker>
          </Col>
          <Col class="m_col" span="6">
            <Input
              :maxlength="50"
              v-model.trim="item.authorizationDepartment"
              class="unit_input"
            />
          </Col>
        </Row>
      </div>
      <!-- 主要设计成果 -->
      <Row>
        <Col span="24">主要设计成果</Col>
      </Row>
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title icon_col" span="6">
            <Icon
              @click="add('designResultList')"
              class="icon"
              type="ios-add-circle-outline"
            />
            <span class="text_col">
              <span class="redStar">*</span>项目名称
            </span>
          </Col>
          <Col class="m_col m_title" span="6">
            <span class="redStar">*</span>客户企业
          </Col>
          <Col class="m_col m_title" span="6">
            <span class="redStar">*</span>完成交付时间
          </Col>
          <Col class="m_col m_title" span="6">
            <span class="redStar">*</span>设计成果产业化及效果
          </Col>
        </Row>
        <Row
          type="flex"
          v-for="(item, index) in formValidate.resourceAndPerfInfo
            .designResultList"
          :key="index"
          class="m_row"
        >
          <Col class="m_col icon_col" span="6">
            <Icon
              @click="reduce('designResultList', index)"
              class="icon"
              type="ios-remove-circle-outline"
            />
            <Input
              :maxlength="50"
              v-model.trim="item.projectName"
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="6">
            <Input
              :maxlength="30"
              v-model.trim="item.clientCompany"
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="6">
            <DatePicker
              :options="pickerOptions"
              :value="item.finishDeliveryTime"
              @on-change="item.finishDeliveryTime = $event"
              type="date"
              placeholder="选择时间"
              style="width: 200px"
            ></DatePicker>
          </Col>
          <Col class="m_col" span="6">
            <Input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4 }"
              :maxlength="200"
              v-model="item.industrializationResult"
              @on-change="
                item.industrializationResult = $util.ToDBC(
                  item.industrializationResult,
                  $event
                )
              "
              class="unit_input"
            />
          </Col>
        </Row>
      </div>
      <!-- 主要硬件设施 -->
      <Row>
        <Col span="24">主要硬件设施</Col>
      </Row>
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title icon_col" span="5">
            <Icon
              @click="add('hardwareFacilityList')"
              class="icon"
              type="ios-add-circle-outline"
            />
            <span class="text_col">
              <span class="redStar">*</span>仪器设备名称
            </span>
          </Col>
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>台（套）数
          </Col>
          <Col class="m_col m_title" span="5">
            <span class="redStar">*</span>价值（万元）
          </Col>
          <Col class="m_col m_title" span="5">
            <span class="redStar">*</span>设备完好率（%）
          </Col>
          <Col class="m_col m_title" span="5">
            <span class="redStar">*</span>使用情况
          </Col>
        </Row>
        <Row
          type="flex"
          v-for="(item, index) in formValidate.resourceAndPerfInfo
            .hardwareFacilityList"
          :key="index"
          class="m_row"
        >
          <Col class="m_col icon_col" span="5">
            <Icon
              @click="reduce('hardwareFacilityList', index)"
              class="icon"
              type="ios-remove-circle-outline"
            />
            <Input
              :maxlength="50"
              v-model.trim="item.apparatusName"
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="4">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              :prop="
                `resourceAndPerfInfo.hardwareFacilityList[${index}].numberOfUnits`
              "
            >
              <Input
                :maxlength="8"
                v-model="item.numberOfUnits"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="5">
            <FormItem
              :rules="ruleValidate.validateThanZero"
              :prop="`resourceAndPerfInfo.hardwareFacilityList[${index}].cost`"
            >
              <Input :maxlength="13" v-model="item.cost" class="unit_input" />
            </FormItem>
          </Col>
          <Col class="m_col" span="5">
            <FormItem
              :rules="ruleValidate.validatePercentageThanZero"
              :prop="
                `resourceAndPerfInfo.hardwareFacilityList[${index}].facilityIntactRate`
              "
            >
              <Input
                :maxlength="6"
                v-model="item.facilityIntactRate"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="5">
            <Input
              :maxlength="100"
              v-model="item.useInfo"
              @on-change="item.useInfo = $util.ToDBC(item.useInfo, $event)"
              class="unit_input"
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4 }"
            />
          </Col>
        </Row>
      </div>
      <!-- 主要软件 -->
      <Row>
        <Col span="24">主要软件</Col>
      </Row>
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title icon_col" span="5">
            <Icon
              @click="add('softwareList')"
              class="icon"
              type="ios-add-circle-outline"
            />
            <span class="text_col">
              <span class="redStar">*</span>软件名称
            </span>
          </Col>
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>数量（套）
          </Col>
          <Col class="m_col m_title" span="5">
            <span class="redStar">*</span>价值（万元）
          </Col>
          <Col class="m_col m_title" span="5">
            <span class="redStar">*</span>使用情况
          </Col>
          <Col class="m_col m_title" span="5">备注</Col>
        </Row>
        <Row
          type="flex"
          v-for="(item, index) in formValidate.resourceAndPerfInfo.softwareList"
          :key="index"
          class="m_row"
        >
          <Col class="m_col icon_col" span="5">
            <Icon
              @click="reduce('softwareList', index)"
              class="icon"
              type="ios-remove-circle-outline"
            />
            <Input
              :maxlength="50"
              v-model.trim="item.softwareName"
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="4">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              :prop="`resourceAndPerfInfo.softwareList[${index}].quantity`"
            >
              <Input
                :maxlength="8"
                v-model="item.quantity"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="5">
            <FormItem
              :rules="ruleValidate.validateThanZero"
              :prop="`resourceAndPerfInfo.softwareList[${index}].cost`"
            >
              <Input :maxlength="13" v-model="item.cost" class="unit_input" />
            </FormItem>
          </Col>
          <Col class="m_col" span="5">
            <Input
              :maxlength="100"
              v-model="item.useInfo"
              class="unit_input"
              type="textarea"
              @on-change="item.useInfo = $util.ToDBC(item.useInfo, $event)"
              :autosize="{ minRows: 2, maxRows: 4 }"
            />
          </Col>
          <Col class="m_col" span="5">
            <Input
              :maxlength="50"
              v-model.trim="item.remark"
              class="unit_input"
            />
          </Col>
        </Row>
      </div>
    </Form>
    <div class="foot">
      <button v-waves @click="cancel" class="GL_cancel">取消</button>
      <button v-waves @click="save('formValidate')" class="GL_save">
        保存
      </button>
      <button v-waves @click="next('formValidate')" class="GL_next">
        保存并跳转下一步
      </button>
    </div>
  </div>
</template>

<script>
import Crumbs from "../../components/crumbs";
import waves from "../../directive/waves";
export default {
  components: {
    Crumbs
  },
  directives: { waves },
  name: "Resources",
  props: {},
  data() {
    const disabledDate = v => {
      return (
        v.getTime() < new Date("1970-1-1").getTime() ||
        v.getTime() > new Date("2038-1-11").getTime()
      );
    };
    return {
      pickerOptions: {
        disabledDate
      },
      programId: null,
      // countNum: 0,
      formValidate: {
        programId: null,
        pageStatus: 3,
        declareType: "3002",
        resourceAndPerfInfo: {
          awardsInfoList: [
            {
              awardsWorks: null,
              awardsName: null,
              obtainTime: null,
              authorizationDepartment: null,
              programType: 2
            }
          ],
          designResultList: [
            {
              projectName: null,
              clientCompany: null,
              finishDeliveryTime: null,
              industrializationResult: null,
              programType: 2
            }
          ],
          hardwareFacilityList: [
            {
              apparatusName: null,
              numberOfUnits: null,
              cost: null,
              facilityIntactRate: null,
              useInfo: null,
              programType: 2
            }
          ],
          softwareList: [
            {
              softwareName: null,
              quantity: null,
              cost: null,
              useInfo: null,
              remark: null,
              programType: 2
            }
          ]
        }
      },
      ruleValidate: {
        validatePositiveInteger: [
          { validator: this.$util.validatePositiveInteger, trigger: "blur" }
        ],
        validateThanZero: [
          { validator: this.$util.validateThanZero, trigger: "blur" }
        ],
        validatePercentageThanZero: [
          { validator: this.$util.validatePercentageThanZero, trigger: "blur" }
        ]
      }
    };
  },
  created() {
    this.programId = this.$store.state.industryProgramId;
    this.formValidate.programId = this.$store.state.industryProgramId;
    this.queryPage();
  },

  computed: {},
  mounted() {},
  methods: {
    /*初始化数据
     *method queryPage
     */
    async queryPage() {
      let res = await this.$http.post(
        "shsj_declare/industralDesign/getIndustralDesignInfo",
        {
          programId: this.programId,
          pageStatus: 3,
          declareType: 3002
        }
      );
      if (res.messageCode === 10000 && res.result.result != null) {
        for (let k in res.result.result) {
          if (
            Array.isArray(res.result.result[k]) &&
            !res.result.result[k].length
          ) {
            res.result.result[k] = [{}];
          }
        }
        this.formValidate.resourceAndPerfInfo = res.result.result;
      }
    },
    /**减少产品
     * method reduce product
     * */
    reduce(type, index) {
      switch (type) {
        case "awardsInfoList":
          if (this.formValidate.resourceAndPerfInfo.awardsInfoList.length > 1) {
            this.formValidate.resourceAndPerfInfo.awardsInfoList.splice(
              index,
              1
            );
          } else {
            this.$Message.error("最少一条");
          }

          break;
        case "designResultList":
          if (
            this.formValidate.resourceAndPerfInfo.designResultList.length > 1
          ) {
            this.formValidate.resourceAndPerfInfo.designResultList.splice(
              index,
              1
            );
          } else {
            this.$Message.error("最少一条");
          }

          break;
        case "hardwareFacilityList":
          if (
            this.formValidate.resourceAndPerfInfo.hardwareFacilityList.length >
            1
          ) {
            this.formValidate.resourceAndPerfInfo.hardwareFacilityList.splice(
              index,
              1
            );
          } else {
            this.$Message.error("最少一条");
          }

          break;
        case "softwareList":
          if (this.formValidate.resourceAndPerfInfo.softwareList.length > 1) {
            this.formValidate.resourceAndPerfInfo.softwareList.splice(index, 1);
          } else {
            this.$Message.error("最少一条");
          }

          break;
      }
    },
    /**增加产品
     * method add product
     * */
    add(e) {
      switch (e) {
        case "awardsInfoList":
          this.formValidate.resourceAndPerfInfo.awardsInfoList.push({
            awardsWorks: null,
            awardsName: null,
            obtainTime: null,
            authorizationDepartment: null,
            programType: 2
          });
          break;
        case "designResultList":
          this.formValidate.resourceAndPerfInfo.designResultList.push({
            projectName: null,
            clientCompany: null,
            finishDeliveryTime: null,
            industrializationResult: null,
            programType: 2
          });
          break;
        case "hardwareFacilityList":
          this.formValidate.resourceAndPerfInfo.hardwareFacilityList.push({
            apparatusName: null,
            numberOfUnits: null,
            cost: null,
            facilityIntactRate: null,
            useInfo: null,
            programType: 2
          });
          break;
        case "softwareList":
          this.formValidate.resourceAndPerfInfo.softwareList.push({
            softwareName: null,
            quantity: null,
            cost: null,
            useInfo: null,
            remark: null,
            programType: 2
          });
          break;
      }
    },
    /*保存表单
     *method saveProgram
     **/
    saveProgram(name, type, next) {
      this.$refs[name].validate(async valid => {
        if (valid) {
          this.formValidate.resourceAndPerfInfo.awardsInfoList = this.formValidate.resourceAndPerfInfo.awardsInfoList.map(
            item => {
              if (item.obtainTime == "") {
                item.obtainTime = null;
                return item;
              } else {
                item.obtainTime = item.obtainTime;
                return item;
              }
            }
          );
          this.formValidate.resourceAndPerfInfo.designResultList = this.formValidate.resourceAndPerfInfo.designResultList.map(
            item => {
              if (item.finishDeliveryTime == "") {
                item.finishDeliveryTime = null;
                return item;
              } else {
                item.finishDeliveryTime = item.finishDeliveryTime;
                return item;
              }
            }
          );
          let { messageCode } = await this.$http.post(
            "shsj_declare/industralDesign/saveIndustralDesignInfo",
            this.formValidate
          );
          if (messageCode === 10000) {
            // this.countNum = 0;
            this.$Message.success("保存页面成功!");
            if (next) {
              next();
            }
            if (type == "next") {
              this.$router.push("/declare/status");
            }
          }
        } else {
          this.$Message.error("内容格式不正确!");
        }
      });
    },
    /**取消保存
     * method cancel
     * */
    cancel() {
      this.$Message.info("取消操作");
      setTimeout(() => {
        if (this.$store.state.industryFromPage == "manage") {
          return this.$router.push("/manage");
        }
        return this.$router.push("/declare");
      }, 1000);
    },
    /**保存
     * method save
     * */
    save(name) {
      this.saveProgram(name, "save");
    },
    /**保存并下一步
     * method save and next
     * */
    next(name) {
      this.saveProgram(name, "next");
    }
  }
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped></style>
