<template>
  <div class="baseSituation GL_declareForm Gl_input">
    <Form class="form">
      <Crumbs :title="titleList[0]"></Crumbs>
      <!-- 企业名称 -->
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            企业名称
          </Col>
          <Col class="m_col" span="20">
            <Input
              readonly
              v-model="ldeUnitBaseInfo.ldeUnitBase.unitName"
              class="unit_input"
            />
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            统一社会信用代码
          </Col>
          <!-- v-model="ldeUnitBaseInfo.ldeUnitBase.unitCode" -->
          <Col class="m_col" span="8">
            <Input readonly v-model="code" class="unit_input" />
          </Col>
          <Col class="m_col m_title" span="4">
            注册时间
          </Col>
          <Col class="m_col" span="8">
            <DatePicker
              readonly
              type="date"
              :value="ldeUnitBaseInfo.ldeUnitBase.unitRegisteredDate"
              @on-change="
                ldeUnitBaseInfo.ldeUnitBase.unitRegisteredDate = $event
              "
              placeholder="选择时间"
            ></DatePicker>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            企业注册地址
          </Col>
          <Col class="m_col" span="8">
            <Input
              readonly
              v-model="ldeUnitBaseInfo.ldeUnitBase.unitContactAddress"
              class="unit_input"
            />
          </Col>
          <Col class="m_col m_title" span="4">
            税务登记区
          </Col>
          <Col class="m_col" span="8">
            <Select disabled v-model="ldeUnitBaseInfo.ldeUnitBase.unitTaxArea">
              <Option
                v-for="item in areaList"
                :key="item.dataId"
                :value="item.dataId"
                >{{ item.dataValue }}</Option
              >
            </Select>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            企业经营地址
          </Col>
          <Col class="m_col" span="20">
            <Input
              readonly
              v-model="ldeUnitBaseInfo.ldeUnitBase.manageAddress"
              class="unit_input"
            />
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            企业注册类型
          </Col>
          <Col class="m_col" span="20">
            <div style="width:100%">
              <RadioGroup
                class="RadioGroup"
                size="large"
                v-model="
                  ldeUnitBaseInfo.ldeUnitBase.unitRegistrationTypeParentId
                "
              >
                <Radio
                  disabled
                  :key="index"
                  v-for="(item, index) in ldeUnitBaseInfo.sysDicts"
                  :label="item.dictId"
                  >{{ item.dictName }}</Radio
                >
              </RadioGroup>
              <br />
              <div
                v-show="childrenList.length > 0"
                style="border-top:1px dashed #ccc;margin:5px 0"
              ></div>
              <RadioGroup
                v-show="childrenList.length > 0"
                class="RadioGroup"
                size="large"
                v-model="ldeUnitBaseInfo.ldeUnitBase.unitRegistrationType"
              >
                <Radio
                  disabled
                  :key="index2"
                  v-for="(item2, index2) in childrenList"
                  :label="item2.dataId"
                  >{{ item2.dataValue }}</Radio
                >
              </RadioGroup>
            </div>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">银行信用等级</Col>
          <Col class="m_col" span="8">
            <Input
              readonly
              v-model="ldeUnitBaseInfo.ldeUnitBase.unitCreditRating"
              class="unit_input"
            />
          </Col>
          <Col class="m_col m_title" span="4">
            职工人数
          </Col>
          <Col class="m_col" span="8">
            <Input
              readonly
              v-model="ldeUnitBaseInfo.ldeUnitBase.unitPersonNumber"
              class="unit_input"
            />
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            资产总额（万元）
          </Col>
          <Col class="m_col" span="8">
            <Input
              readonly
              v-model="ldeUnitBaseInfo.ldeUnitBase.totalAssets"
              class="unit_input"
            />
          </Col>

          <Col class="m_col m_title" span="4">
            所属行业
          </Col>
          <Col class="m_col" span="8">
            <!-- <RadioGroup
              class="RadioGroup"
              size="large"
              v-model="ldeUnitBaseInfo.ldeUnitBase.industry"
            >
              <Radio
                class="radiolist"
                disabled
                :key="index"
                v-for="(item, index) in tradeList"
                :label="item.dataId"
                >{{ item.dataValue }}</Radio
              >
            </RadioGroup> -->
            <Select disabled v-model="ldeUnitBaseInfo.ldeUnitBase.industry">
              <Option
                :key="index"
                v-for="(item, index) in tradeList"
                :value="item.dataId"
                >{{ item.dataValue }}</Option
              >
            </Select>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            资产负债率（%）
          </Col>
          <Col class="m_col" span="20">
            <Input
              readonly
              v-model="ldeUnitBaseInfo.ldeUnitBase.assetLiabilityRatio"
              class="unit_input"
            />
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            主营业务
          </Col>
          <Col class="m_col" span="20">
            <Input
              readonly
              v-model="ldeUnitBaseInfo.ldeUnitBase.mainBusiness"
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 8 }"
            ></Input>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            主导产品大类
          </Col>
          <Col class="m_col" span="20">
            <Input
              readonly
              v-model="ldeUnitBaseInfo.ldeUnitBase.leadingProductCategory"
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 8 }"
            ></Input>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            主要经营业绩和行业地位
          </Col>
          <Col class="m_col" span="20">
            <Input
              readonly
              v-model="
                ldeUnitBaseInfo.ldeUnitBase.performanceAndIndustryPosition
              "
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 8 }"
            ></Input>
          </Col>
        </Row>
      </div>
      <!-- 联系方式 -->
      <Row>
        <Col style="font-size:16px ;margin-bottom:10px" span="24">联系方式</Col>
      </Row>
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            人员
          </Col>
          <Col class="m_col m_title" span="5">
            姓名
          </Col>
          <Col class="m_col m_title" span="5">
            职务
          </Col>
          <Col class="m_col m_title" span="5">
            联系手机
          </Col>
          <Col class="m_col m_title" span="5">
            邮箱
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            企业负责人
          </Col>
          <Col class="m_col" span="5">
            <Input
              readonly
              v-model="ldeUnitBaseInfo.ldeUnitContactsList[0].name"
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="5">
            <Input
              readonly
              v-model="ldeUnitBaseInfo.ldeUnitContactsList[0].post"
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="5">
            <Input
              readonly
              v-model="ldeUnitBaseInfo.ldeUnitContactsList[0].contactWay"
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="5">
            <Input
              readonly
              v-model="ldeUnitBaseInfo.ldeUnitContactsList[0].fax"
              class="unit_input"
            />
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            申请联系人
          </Col>
          <Col class="m_col" span="5">
            <Input
              readonly
              v-model="ldeUnitBaseInfo.ldeUnitContactsList[1].name"
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="5">
            <Input
              readonly
              v-model="ldeUnitBaseInfo.ldeUnitContactsList[1].post"
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="5">
            <Input
              readonly
              v-model="ldeUnitBaseInfo.ldeUnitContactsList[1].contactWay"
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="5">
            <Input
              readonly
              v-model="ldeUnitBaseInfo.ldeUnitContactsList[1].fax"
              class="unit_input"
            />
          </Col>
        </Row>
      </div>
      <!-- 经济指标 -->
      <Row>
        <Col style="font-size:16px ;margin-bottom:10px" span="24"
          >工业设计成果</Col
        >
      </Row>
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="8">
            指标项目
          </Col>
          <Col class="m_col m_title" span="8">
            2018年
          </Col>
          <Col class="m_col m_title" span="8">
            2019年
          </Col>
        </Row>

        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="8">
            主营业务收入（万元）
          </Col>
          <Col class="m_col" span="8">
            <Input
              readonly
              v-model="
                ldeUnitBaseInfo.ldeUnitFinancialDataList[0].mainBusinessIncome
              "
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="8">
            <Input
              readonly
              v-model="
                ldeUnitBaseInfo.ldeUnitFinancialDataList[1].mainBusinessIncome
              "
              class="unit_input"
            />
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="8">
            利润总额（万元）
          </Col>
          <Col class="m_col" span="8">
            <Input
              readonly
              v-model="ldeUnitBaseInfo.ldeUnitFinancialDataList[0].totalProfit"
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="8">
            <Input
              readonly
              v-model="ldeUnitBaseInfo.ldeUnitFinancialDataList[1].totalProfit"
              class="unit_input"
            />
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="8">
            纳税总额（万元）
          </Col>
          <Col class="m_col" span="8">
            <Input
              readonly
              v-model="ldeUnitBaseInfo.ldeUnitFinancialDataList[0].totalTax"
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="8">
            <Input
              readonly
              v-model="ldeUnitBaseInfo.ldeUnitFinancialDataList[1].totalTax"
              class="unit_input"
            />
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="8">
            出口交货值（万元）
          </Col>
          <Col class="m_col" span="8">
            <Input
              readonly
              v-model="
                ldeUnitBaseInfo.ldeUnitFinancialDataList[0].exportDeliveryValue
              "
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="8">
            <Input
              readonly
              v-model="
                ldeUnitBaseInfo.ldeUnitFinancialDataList[1].exportDeliveryValue
              "
              class="unit_input"
            />
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="8">
            新产品销售收入占总营收比重（%）
          </Col>
          <Col class="m_col" span="8">
            <Input
              readonly
              v-model="
                ldeUnitBaseInfo.ldeUnitFinancialDataList[0]
                  .outputValueRateOfNewProducts
              "
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="8">
            <Input
              readonly
              v-model="
                ldeUnitBaseInfo.ldeUnitFinancialDataList[1]
                  .outputValueRateOfNewProducts
              "
              class="unit_input"
            />
          </Col>
        </Row>
      </div>
    </Form>
    <Form class="form">
      <Crumbs :title="titleList[1]"></Crumbs>
      <!-- 企业名称 -->
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="6">
            设计团队人员数
          </Col>
          <Col class="m_col" span="18">
            <Input
              readonly
              v-model="ldeDesignTeamBase.peopleNumber"
              class="unit_input"
            />
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="6">
            其中：从事3年以上人数
          </Col>
          <Col class="m_col" span="18">
            <Input
              readonly
              v-model="ldeDesignTeamBase.peopleNumberThree"
              class="unit_input"
            />
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="6">
            大学本科以上学历人员或高级职称人员数
          </Col>
          <Col class="m_col" span="18">
            <Input
              readonly
              v-model="ldeDesignTeamBase.undergraduateAboveAndSeniorTitle"
              class="unit_input"
            />
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="6">
            首席设计师（设计总监）岗位
          </Col>
          <Col class="m_col" span="18">
            <RadioGroup
              size="large"
              v-model="ldeDesignTeamBase.postOfChiefDesigner"
            >
              <Radio disabled label="1">有</Radio>
              <Radio disabled label="0">无</Radio>
            </RadioGroup>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="6">
            首席设计师（设计总监）简介
          </Col>
          <Col class="m_col" span="18">
            <Input
              readonly
              v-model="ldeDesignTeamBase.chiefDesignerSynopsis"
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 8 }"
            ></Input>
          </Col>
        </Row>
      </div>
    </Form>
    <Form class="form">
      <Crumbs :title="titleList[2]"></Crumbs>
      <!-- 经济指标 -->
      <Row>
        <Col style="font-size:16px ;margin-bottom:10px" span="24">
          企业知识产权情况
          <span style="font-size:12px;color:#99A5C2"
            >（近两年累计，自申报之日起倒推）</span
          >
        </Col>
      </Row>
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="6">分类</Col>
          <Col class="m_col m_title" span="6">企业申请数</Col>
          <Col class="m_col m_title" span="6">获得批准数</Col>
          <Col class="m_col m_title" span="6">实现产业化数量</Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="t_col m_title" span="6">知识产权总数（个）</Col>
          <Col class="m_col" span="6">
            <Input
              readonly
              v-model="
                ldeInnovationAbilityInfo.ldeFesignInnovationAbilityList[0]
                  .totalIntellectualProperty
              "
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="6">
            <Input
              readonly
              v-model="
                ldeInnovationAbilityInfo.ldeFesignInnovationAbilityList[1]
                  .totalIntellectualProperty
              "
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="6">
            <Input
              readonly
              v-model="
                ldeInnovationAbilityInfo.ldeFesignInnovationAbilityList[2]
                  .totalIntellectualProperty
              "
              class="unit_input"
            />
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="6">其中：发明专利（个）</Col>
          <Col class="m_col" span="6">
            <Input
              readonly
              v-model="
                ldeInnovationAbilityInfo.ldeFesignInnovationAbilityList[0]
                  .inventionPatent
              "
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="6">
            <Input
              readonly
              v-model="
                ldeInnovationAbilityInfo.ldeFesignInnovationAbilityList[1]
                  .inventionPatent
              "
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="6">
            <Input
              readonly
              v-model="
                ldeInnovationAbilityInfo.ldeFesignInnovationAbilityList[2]
                  .inventionPatent
              "
              class="unit_input"
            />
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="6">实用新型专利（个）</Col>
          <Col class="m_col" span="6">
            <Input
              readonly
              v-model="
                ldeInnovationAbilityInfo.ldeFesignInnovationAbilityList[0]
                  .utilityModelPatent
              "
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="6">
            <Input
              readonly
              v-model="
                ldeInnovationAbilityInfo.ldeFesignInnovationAbilityList[1]
                  .utilityModelPatent
              "
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="6">
            <Input
              readonly
              v-model="
                ldeInnovationAbilityInfo.ldeFesignInnovationAbilityList[2]
                  .utilityModelPatent
              "
              class="unit_input"
            />
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="6">外观设计专利（个）</Col>
          <Col class="m_col" span="6">
            <Input
              readonly
              v-model="
                ldeInnovationAbilityInfo.ldeFesignInnovationAbilityList[0]
                  .industrialDesignPatent
              "
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="6">
            <Input
              readonly
              v-model="
                ldeInnovationAbilityInfo.ldeFesignInnovationAbilityList[1]
                  .industrialDesignPatent
              "
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="6">
            <Input
              readonly
              v-model="
                ldeInnovationAbilityInfo.ldeFesignInnovationAbilityList[2]
                  .industrialDesignPatent
              "
              class="unit_input"
            />
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="6">软件著作权（个）</Col>
          <Col class="m_col" span="6">
            <Input
              readonly
              v-model="
                ldeInnovationAbilityInfo.ldeFesignInnovationAbilityList[0]
                  .softwareCopyright
              "
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="6">
            <Input
              readonly
              v-model="
                ldeInnovationAbilityInfo.ldeFesignInnovationAbilityList[1]
                  .softwareCopyright
              "
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="6">
            <Input
              readonly
              v-model="
                ldeInnovationAbilityInfo.ldeFesignInnovationAbilityList[2]
                  .softwareCopyright
              "
              class="unit_input"
            />
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="6">版权数（个）</Col>
          <Col class="m_col" span="6">
            <Input
              readonly
              v-model="
                ldeInnovationAbilityInfo.ldeFesignInnovationAbilityList[0]
                  .numberOfCopyrights
              "
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="6">
            <Input
              readonly
              v-model="
                ldeInnovationAbilityInfo.ldeFesignInnovationAbilityList[1]
                  .numberOfCopyrights
              "
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="6">
            <Input
              readonly
              v-model="
                ldeInnovationAbilityInfo.ldeFesignInnovationAbilityList[2]
                  .numberOfCopyrights
              "
              class="unit_input"
            />
          </Col>
        </Row>
      </div>
      <!-- 近两年主要设计项目完成情况及成果 -->
      <Row>
        <Col style="font-size:16px ;margin-bottom:10px" span="24"
          >近两年主要设计项目完成情况及成果</Col
        >
      </Row>
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title icon_col" span="6">
            <Icon class="icon" type="ios-add-circle-outline" />
            <span class="text_col">
              项目名称
            </span>
          </Col>
          <Col class="m_col m_title" span="6">
            投产时间
          </Col>
          <Col class="m_col m_title" span="12">
            设计成果产业化情况
          </Col>
        </Row>
        <Row
          type="flex"
          v-for="(item,
          index) in ldeInnovationAbilityInfo.ldeProjectCompletionAndResultsList"
          :key="index"
          class="m_row"
        >
          <Col class="m_col icon_col" span="6">
            <Icon class="icon" type="ios-remove-circle-outline" />
            <Input readonly v-model="item.programName" class="unit_input" />
          </Col>
          <Col class="m_col" span="6">
            <DatePicker
              readonly
              type="date"
              :value="item.commissioningTime"
              @on-change="item.commissioningTime = $event"
              placeholder="选择时间"
            ></DatePicker>
          </Col>
          <Col class="m_col" span="12">
            <Input
              readonly
              placeholder="产值规模，对企业创新发展模式、提升经济效益、品牌影响力等方面所起到的效果。"
              v-model="item.designAchievements"
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 8 }"
            ></Input>
          </Col>
        </Row>
      </div>
    </Form>
    <Form class="form">
      <Crumbs :title="titleList[3]"></Crumbs>
      <!-- （一）设计创新投入情况（单位：万元） -->
      <Row>
        <Col style="font-size:16px ; margin-bottom:10px" span="24"
          >（一）设计创新投入情况（单位：万元）</Col
        >
      </Row>
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col
            span="6"
            class="m_title"
            style="background:linear-gradient(17deg,#F6F9FF 49.5%, #E8ECF5 49.5%, #E8ECF5 50.5%, #F6F9FF 50.5%)"
          >
            <Row class="col_up">
              <Col span="24">年度</Col>
            </Row>
            <Row class="col_down">
              <Col span="24">投入分析</Col>
            </Row>
          </Col>
          <Col span="18">
            <Row type="flex">
              <Col class="m_col m_title" span="12">
                申报前的两年
              </Col>
              <Col class="m_col m_title" span="12">
                申报当年和后一年计划
              </Col>
            </Row>
            <Row type="flex">
              <Col class="m_col m_title" span="6">
                2018年
              </Col>
              <Col class="m_col m_title" span="6">
                2019年
              </Col>
              <Col class="m_col m_title" span="6">
                2020年
              </Col>
              <Col class="m_col m_title" span="6">
                2021年
              </Col>
            </Row>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="6">
            投入总额
          </Col>
          <Col span="18">
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[0]
                    .totalInvestment
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[1]
                    .totalInvestment
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[2]
                    .totalInvestment
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[3]
                    .totalInvestment
                "
                class="unit_input"
              />
            </Col>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="6">
            其中：人工费
          </Col>
          <Col span="18">
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[0]
                    .laborFee
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[1]
                    .laborFee
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[2]
                    .laborFee
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[3]
                    .laborFee
                "
                class="unit_input"
              />
            </Col>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="6">
            仪器设备购置和安装费
          </Col>
          <Col span="18">
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[0]
                    .equipmentPurchaseAndInstallationFee
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[1]
                    .equipmentPurchaseAndInstallationFee
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[2]
                    .equipmentPurchaseAndInstallationFee
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[3]
                    .equipmentPurchaseAndInstallationFee
                "
                class="unit_input"
              />
            </Col>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="6">
            商业软件购置费
          </Col>
          <Col span="18">
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[0]
                    .softwarePurchaseFee
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[1]
                    .softwarePurchaseFee
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[2]
                    .softwarePurchaseFee
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[3]
                    .softwarePurchaseFee
                "
                class="unit_input"
              />
            </Col>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="6">
            租赁费
          </Col>
          <Col span="18">
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[0]
                    .rentalFee
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[1]
                    .rentalFee
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[2]
                    .rentalFee
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[3]
                    .rentalFee
                "
                class="unit_input"
              />
            </Col>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="6">
            试制费
          </Col>
          <Col span="18">
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[0]
                    .trialProductionFee
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[1]
                    .trialProductionFee
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[2]
                    .trialProductionFee
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[3]
                    .trialProductionFee
                "
                class="unit_input"
              />
            </Col>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="6">
            材料费
          </Col>
          <Col span="18">
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[0]
                    .materialFee
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[1]
                    .materialFee
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[2]
                    .materialFee
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[3]
                    .materialFee
                "
                class="unit_input"
              />
            </Col>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="6">
            鉴定验收费
          </Col>
          <Col span="18">
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[0]
                    .appraisalFee
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[1]
                    .appraisalFee
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[2]
                    .appraisalFee
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[3]
                    .appraisalFee
                "
                class="unit_input"
              />
            </Col>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="6">
            培训费
          </Col>
          <Col span="18">
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[0]
                    .trainingFee
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[1]
                    .trainingFee
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[2]
                    .trainingFee
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[3]
                    .trainingFee
                "
                class="unit_input"
              />
            </Col>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="6">
            图书资料费
          </Col>
          <Col span="18">
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[0]
                    .booksAndReferenceFee
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[1]
                    .booksAndReferenceFee
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[2]
                    .booksAndReferenceFee
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[3]
                    .booksAndReferenceFee
                "
                class="unit_input"
              />
            </Col>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="6">
            知识产权申请费
          </Col>
          <Col span="18">
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[0]
                    .intellectualPropertyApplicationFee
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[1]
                    .intellectualPropertyApplicationFee
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[2]
                    .intellectualPropertyApplicationFee
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[3]
                    .intellectualPropertyApplicationFee
                "
                class="unit_input"
              />
            </Col>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="6">
            差旅费
          </Col>
          <Col span="18">
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[0]
                    .travelExpensesFee
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[1]
                    .travelExpensesFee
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[2]
                    .travelExpensesFee
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[3]
                    .travelExpensesFee
                "
                class="unit_input"
              />
            </Col>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="6">
            会议费
          </Col>
          <Col span="18">
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[0]
                    .conferenceExpensesFee
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[1]
                    .conferenceExpensesFee
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[2]
                    .conferenceExpensesFee
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[3]
                    .conferenceExpensesFee
                "
                class="unit_input"
              />
            </Col>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="6">
            设计外包采购费
          </Col>
          <Col span="18">
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[0]
                    .outsourcingProcurementFee
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[1]
                    .outsourcingProcurementFee
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[2]
                    .outsourcingProcurementFee
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[3]
                    .outsourcingProcurementFee
                "
                class="unit_input"
              />
            </Col>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="3">
            其他
          </Col>
          <Col class="m_col m_title" span="3">
            <Input
              readonly
              placeholder="(描述说明)"
              v-model="
                ldeSupportingSituationInfo.ldeInvestmentSituationList[0]
                  .otherExplain
              "
              class="unit_input"
            />
          </Col>
          <Col span="18">
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[0]
                    .otherFee
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[1]
                    .otherFee
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[2]
                    .otherFee
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  ldeSupportingSituationInfo.ldeInvestmentSituationList[3]
                    .otherFee
                "
                class="unit_input"
              />
            </Col>
          </Col>
        </Row>
      </div>
      <!-- 主要用于设计的硬件情况 -->
      <Row>
        <Col style="font-size:16px ; margin-bottom:10px" span="24"
          >（二）软硬件配套设施情况</Col
        >
      </Row>
      <Row>
        <Col span="24">主要用于设计的硬件情况</Col>
      </Row>
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title icon_col" span="6">
            <Icon class="icon" type="ios-add-circle-outline" />
            <span class="text_col">
              仪器（设备）名称
            </span>
          </Col>
          <Col class="m_col m_title" span="6">
            台（套）数
          </Col>
          <Col class="m_col m_title" span="6">
            原值(总价)(万元)
          </Col>
          <Col class="m_col m_title" span="6">
            购置时间
          </Col>
        </Row>
        <Row
          v-for="(item, index) in ldeSupportingSituationInfo.HardwareList"
          :key="index"
          class="m_row"
        >
          <Col class="m_col icon_col" span="6">
            <Icon class="icon" type="ios-remove-circle-outline" />
            <Input readonly v-model="item.instrumentName" class="unit_input" />
          </Col>
          <Col class="m_col" span="6">
            <Input readonly v-model="item.number" class="unit_input" />
          </Col>
          <Col class="m_col" span="6">
            <Input readonly v-model="item.originalValue" class="unit_input" />
          </Col>
          <Col class="m_col" span="6">
            <DatePicker
              readonly
              :value="item.purchaseTime"
              @on-change="item.purchaseTime = $event"
              type="date"
              placeholder="选择时间"
              style="width: 200px"
            ></DatePicker>
          </Col>
        </Row>
      </div>
      <!-- 主要用于设计的软件情况 -->
      <Row>
        <Col span="24">主要用于设计的软件情况</Col>
      </Row>
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title icon_col" span="6">
            <Icon class="icon" type="ios-add-circle-outline" />
            <span class="text_col"> 名称 </span>
          </Col>
          <Col class="m_col m_title" span="6">
            数量（套）
          </Col>
          <Col class="m_col m_title" span="6">
            价值（万元）
          </Col>
          <Col class="m_col m_title" span="6">
            购置时间
          </Col>
        </Row>
        <Row
          v-for="(item, index) in ldeSupportingSituationInfo.SoftwareList"
          :key="index"
          class="m_row"
        >
          <Col class="m_col icon_col" span="6">
            <Icon class="icon" type="ios-remove-circle-outline" />
            <Input readonly v-model="item.instrumentName" class="unit_input" />
          </Col>
          <Col class="m_col" span="6">
            <Input readonly v-model="item.number" class="unit_input" />
          </Col>
          <Col class="m_col" span="6">
            <Input readonly v-model="item.originalValue" class="unit_input" />
          </Col>
          <Col class="m_col" span="6">
            <DatePicker
              readonly
              :value="item.purchaseTime"
              @on-change="item.purchaseTime = $event"
              type="date"
              placeholder="选择时间"
              style="width: 200px"
            ></DatePicker>
          </Col>
        </Row>
      </div>
    </Form>
    <Form class="form">
      <Crumbs :title="titleList[4]"></Crumbs>
      <!-- 情况 -->
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="8"
            >企业参与制定国际、国家或行业标准情况</Col
          >
          <Col class="m_col" span="16">
            <Input
              readonly
              v-model="ldeUnitOthersSituation.standardSituation"
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 8 }"
            ></Input>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="8">企业获国际、国内设计奖项情况</Col>
          <Col class="m_col" span="16">
            <Input
              readonly
              v-model="ldeUnitOthersSituation.awardsSituation"
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 8 }"
            ></Input>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="8">企业获国家级、本市科技奖情况</Col>
          <Col class="m_col" span="16">
            <Input
              readonly
              v-model="ldeUnitOthersSituation.technologySituation"
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 8 }"
            ></Input>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="8"
            >企业被认定国家级、市级、区级工业设计中心情况</Col
          >
          <Col class="m_col" span="16">
            <Input
              readonly
              v-model="ldeUnitOthersSituation.designCenterSituation"
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 8 }"
            ></Input>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="8">其他有关情况</Col>
          <Col class="m_col" span="16">
            <Input
              readonly
              v-model="ldeUnitOthersSituation.otherSituation"
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 8 }"
            ></Input>
          </Col>
        </Row>
        <!-- <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="8">
            附件
          </Col>
          <Col class="s_col" span="16">
            <template
              v-for="(item, index) in ldeUnitOthersSituation.ldeFilesList"
            >
              <div class="picText" :key="index">
                <p>{{ item.fileName }}</p>
                <div class="openView" @click="preview(item.filePath)">
                  <Icon type="ios-eye" style="color:#3385FF" size="30"></Icon>
                </div>
              </div>
            </template>
          </Col>
        </Row>-->
      </div>
    </Form>
    <Form class="attachment form">
      <Crumbs :title="titleList[5]"></Crumbs>
      <div class="m_item">
        <Row
          :key="item.sfId"
          v-for="item in programFilesDtoList"
          type="flex"
          class="m_row"
        >
          <Col class="m_col m_title" span="8">
            <!-- <div class="text_box"> -->

            <span class="spantext">
              <span
                :style="item.wasNeed == 1 ? '' : 'visibility: hidden'"
                class="redStar"
                >*</span
              >
              {{ item.declareMaterialName }}
            </span>
            <!-- </div> -->
          </Col>
          <Col class="s_col" span="16">
            <template v-for="(item2, index) in item.programFilesList">
              <div class="picText" :key="index">
                <p>{{ item2.fileName }}</p>
                <div class="openView" @click="preview(item2.filePath)">
                  <Icon type="ios-eye" style="color:#3385FF" size="30"></Icon>
                </div>
              </div>
            </template>
          </Col>
        </Row>
      </div>
    </Form>
    <div style="text-align: center; ">
      <button @click="goBack" class="GL_cancel">返回</button>
    </div>
  </div>
</template>

<script>
import Crumbs from "../../components/crumbs";
export default {
  name: "",
  props: {},
  data() {
    return {
      code: localStorage.getItem("accountCode"),
      titleList: [
        "所在企业情况",
        "设计团队基本情况",
        "设计创新能力情况",
        "设计创新投入、软硬件配套设施情况",
        "企业其他情况",
        "附件上传"
      ],
      childrenList: [],
      // 区域列表
      areaList: [],
      // 所属行业列表
      tradeList: [],
      programFilesDtoList: [],
      ldeUnitBaseInfo: {
        ldeUnitBase: {
          // programId: 509,
          // unitName: "单位名字",
          // unitCode: "统一信用代码",
          // unitRegisteredDate: "2020-01-01",
          // unitContactAddress: "联系地址",
          // unitPostalCode: "邮政编码",
          // unitRegistrationType: 10101,
          // unitRegistrationTypeParentId: 10100,
          // unitRegistrationTypeValue: "国有企业",
          // unitTaxArea: 8002,
          // unitTaxAreaValue: "黄浦区",
          // unitCreditRating: "信用等级",
          // unitPersonNumber: 30,
          // totalAssets: 500,
          // assetLiabilityRatio: 300,
          // industry: 4003,
          // industryValue: "城建",
          // mainBusiness: "主营业务",
          // leadingProductCategory: "主导产品大类",
          // performanceAndIndustryPosition: "主要经营业绩和行业地位",
          // createTime: "2020-09-04 15:59:42",
          // updateTime: null
        },
        ldeUnitContactsList: [
          {
            name: null,
            post: null,
            contactWay: null,
            fax: null,
            type: "1"
          },
          {
            name: null,
            post: null,
            contactWay: null,
            fax: null,
            type: "2"
          }
        ],
        ldeUnitFinancialDataList: [
          {
            mainBusinessIncome: null,
            totalProfit: null,
            totalTax: null,
            exportDeliveryValue: null,
            outputValueRateOfNewProducts: null,
            year: "2018"
          },
          {
            mainBusinessIncome: null,
            totalProfit: null,
            totalTax: null,
            exportDeliveryValue: null,
            outputValueRateOfNewProducts: null,
            year: "2019"
          }
        ]
      },
      ldeDesignTeamBase: {
        peopleNumber: null,
        peopleNumberThree: null,
        undergraduateAboveAndSeniorTitle: null,
        postOfChiefDesigner: null,
        chiefDesignerSynopsis: null
      },
      ldeInnovationAbilityInfo: {
        ldeFesignInnovationAbilityList: [
          {
            totalIntellectualProperty: null,
            inventionPatent: null,
            utilityModelPatent: null,
            industrialDesignPatent: null,
            softwareCopyright: null,
            numberOfCopyrights: null,
            type: "1"
          },
          {
            totalIntellectualProperty: null,
            inventionPatent: null,
            utilityModelPatent: null,
            industrialDesignPatent: null,
            softwareCopyright: null,
            numberOfCopyrights: null,
            type: "2"
          },
          {
            totalIntellectualProperty: null,
            inventionPatent: null,
            utilityModelPatent: null,
            industrialDesignPatent: null,
            softwareCopyright: null,
            numberOfCopyrights: null,
            type: "3"
          }
        ],
        ldeProjectCompletionAndResultsList: [
          {
            programName: null,
            commissioningTime: null,
            designAchievements: null
          }
        ]
      },
      ldeSupportingSituationInfo: {
        ldeInvestmentSituationList: [
          {
            totalInvestment: null,
            laborFee: null,
            equipmentPurchaseAndInstallationFee: null,
            softwarePurchaseFee: null,
            rentalFee: null,
            trialProductionFee: null,
            materialFee: null,
            appraisalFee: null,
            trainingFee: null,
            booksAndReferenceFee: null,
            intellectualPropertyApplicationFee: null,
            travelExpensesFee: null,
            conferenceExpensesFee: null,
            outsourcingProcurementFee: null,
            otherExplain: null,
            otherFee: null,
            year: "2018"
          },
          {
            totalInvestment: null,
            laborFee: null,
            equipmentPurchaseAndInstallationFee: null,
            softwarePurchaseFee: null,
            rentalFee: null,
            trialProductionFee: null,
            materialFee: null,
            appraisalFee: null,
            trainingFee: null,
            booksAndReferenceFee: null,
            intellectualPropertyApplicationFee: null,
            travelExpensesFee: null,
            conferenceExpensesFee: null,
            outsourcingProcurementFee: null,
            otherExplain: null,
            otherFee: null,
            year: "2019"
          },
          {
            totalInvestment: null,
            laborFee: null,
            equipmentPurchaseAndInstallationFee: null,
            softwarePurchaseFee: null,
            rentalFee: null,
            trialProductionFee: null,
            materialFee: null,
            appraisalFee: null,
            trainingFee: null,
            booksAndReferenceFee: null,
            intellectualPropertyApplicationFee: null,
            travelExpensesFee: null,
            conferenceExpensesFee: null,
            outsourcingProcurementFee: null,
            otherExplain: null,
            otherFee: null,
            year: "2020"
          },
          {
            totalInvestment: null,
            laborFee: null,
            equipmentPurchaseAndInstallationFee: null,
            softwarePurchaseFee: null,
            rentalFee: null,
            trialProductionFee: null,
            materialFee: null,
            appraisalFee: null,
            trainingFee: null,
            booksAndReferenceFee: null,
            intellectualPropertyApplicationFee: null,
            travelExpensesFee: null,
            conferenceExpensesFee: null,
            outsourcingProcurementFee: null,
            otherExplain: null,
            otherFee: null,
            year: "2021"
          }
        ],
        ldeSoftwareHardwareList: [],
        HardwareList: [
          {
            instrumentName: null,
            number: null,
            originalValue: null,
            purchaseTime: null,
            type: "1"
          }
        ],
        SoftwareList: [
          {
            instrumentName: null,
            number: null,
            originalValue: null,
            purchaseTime: null,
            type: "2"
          }
        ]
      },
      ldeUnitOthersSituation: {
        // standardSituation: "标准情况",
        // awardsSituation: "奖项情况",
        // technologySituation: "科技奖情况",
        // designCenterSituation: "企业被认定国家级、市级、区级工业设计中心情况",
        // otherSituation: "其他有关情况",
        // ldeFilesList: []
      },
      programFilesDtoList: []
    };
  },
  watch: {},
  computed: {},
  components: {
    Crumbs
  },
  mounted() {},
  created() {
    // 查询表
    this.queyData();
    this.getRradeList();
    // 获取区域列表
    this.getAreaList();
  },
  methods: {
    /*
     *method getRradeList
     获取行业列表
     */
    async getRradeList() {
      let { result } = await this.$http.get(
        "shsj_declare/sysDict/queryDictDataList",
        {
          dictId: 4000
        }
      );
      this.tradeList = result.dictDataList;
    },
    /*获取区域列表
     *method getAreaList
     */
    async getAreaList() {
      let { result } = await this.$http.get(
        "shsj_declare/sysDict/queryDictDataList",
        {
          dictId: 8000
        }
      );
      this.areaList = result.dictDataList;
      // this.tradeList = result.dictDataList;
    },
    /*method
     *数据查询*/

    async queyData() {
      let { messageCode, result } = await this.$http.post(
        "/shsj_declare/ldeDeclare/queryLdeDeclareInfo",
        {
          programId: this.$route.query.programId,
          specialId: this.$route.query.specialId,
          type: "10"
        }
      );
      if (messageCode == 10000) {
        this.ldeUnitBaseInfo.ldeUnitBase =
          result.declareInfo.ldeUnitBaseInfo.ldeUnitBase;
        if (this.ldeUnitBaseInfo.ldeUnitBase.unitName == null) {
          this.ldeUnitBaseInfo.ldeUnitBase.unitName = localStorage.companyName;
        }
        this.ldeUnitBaseInfo.sysDicts =
          result.declareInfo.ldeUnitBaseInfo.sysDicts;
        if (
          result.declareInfo.ldeUnitBaseInfo.ldeUnitContactsList.length == 2
        ) {
          this.ldeUnitBaseInfo.ldeUnitContactsList =
            result.declareInfo.ldeUnitBaseInfo.ldeUnitContactsList;
        }
        if (
          result.declareInfo.ldeUnitBaseInfo.ldeUnitFinancialDataList.length ==
          2
        ) {
          this.ldeUnitBaseInfo.ldeUnitFinancialDataList =
            result.declareInfo.ldeUnitBaseInfo.ldeUnitFinancialDataList;
        }

        let index = this.ldeUnitBaseInfo.sysDicts.findIndex(
          item =>
            item.dictId ==
            this.ldeUnitBaseInfo.ldeUnitBase.unitRegistrationTypeParentId
        );
        if (index == -1) {
          this.childrenList = [];
        } else {
          this.childrenList = this.ldeUnitBaseInfo.sysDicts[
            index
          ].sysDictDataList;
        }
        if (result.declareInfo.ldeDesignTeamBase != null) {
          this.ldeDesignTeamBase = result.declareInfo.ldeDesignTeamBase;
        }
        if (
          result.declareInfo.ldeInnovationAbilityInfo
            .ldeFesignInnovationAbilityList.length == 3
        ) {
          this.ldeInnovationAbilityInfo.ldeFesignInnovationAbilityList =
            result.declareInfo.ldeInnovationAbilityInfo.ldeFesignInnovationAbilityList;
        }
        if (
          result.declareInfo.ldeInnovationAbilityInfo
            .ldeProjectCompletionAndResultsList.length > 0
        ) {
          this.ldeInnovationAbilityInfo.ldeProjectCompletionAndResultsList =
            result.declareInfo.ldeInnovationAbilityInfo.ldeProjectCompletionAndResultsList;
        }

        // this.ldeSupportingSituationInfo =
        //   result.declareInfo.ldeSupportingSituationInfo;
        if (
          result.declareInfo.ldeSupportingSituationInfo
            .ldeInvestmentSituationList.length == 4
        ) {
          this.ldeSupportingSituationInfo.ldeInvestmentSituationList =
            result.declareInfo.ldeSupportingSituationInfo.ldeInvestmentSituationList;
        }
        if (
          result.declareInfo.ldeSupportingSituationInfo.ldeSoftwareHardwareList
            .length > 0
        ) {
          this.ldeSupportingSituationInfo.HardwareList = result.declareInfo.ldeSupportingSituationInfo.ldeSoftwareHardwareList.filter(
            item => item.type == 1
          );
          this.ldeSupportingSituationInfo.SoftwareList = result.declareInfo.ldeSupportingSituationInfo.ldeSoftwareHardwareList.filter(
            item => item.type == 2
          );
        }

        this.ldeUnitOthersSituation = result.declareInfo.ldeUnitOthersSituation;
        this.programFilesDtoList = result.declareInfo.programFilesDtoList;
      }
    },
    /*method
     **点击预览
     */
    preview(path) {
      window.open(path);
    },
    /** 返回
     * method goBack
     * */
    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.ivu-radio-wrapper {
  margin-right: 4px;
  margin-left: 4px;
}
.m_item {
  .s_col {
    border-right: 1px solid #e8ecf5;
    border-bottom: 1px solid #e8ecf5;
    .ivu-btn {
      margin: 5px;
      border-radius: 20px;
      padding: 10px 50px;
    }
    .picText {
      padding: 10px;
      display: inline-block;
    }
  }
}
.col_down {
  height: 45px;
  text-align: left;
  line-height: 45px;
  border-bottom: 1px solid #e8ecf5;
  border-right: 1px solid #e8ecf5;
  padding-left: 25px;
}
.col_up {
  text-align: right;
  height: 45px;
  line-height: 45px;
  border-right: 1px solid #e8ecf5;
  padding-right: 25px;
}

.attachment {
  .spantext {
    max-width: 100%;
    display: inline-block;
    overflow-wrap: break-word; /*  css3属性，当内容超过指定容器的边界时是否断行  */
  }
  .m_item {
    .s_col {
      border-right: 1px solid #e8ecf5;
      border-bottom: 1px solid #e8ecf5;
      .ivu-btn {
        margin: 5px;
        border-radius: 20px;
        padding: 10px 50px;
      }
      .picText {
        padding: 10px;
        display: inline-block;
      }
    }
  }
}
.openView:hover {
  cursor: pointer;
}
.radiolist {
  width: 100px;
  margin-right: 4px;
  margin-left: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.t_col {
  display: flex;
  justify-content: left;
  align-items: center;
  border-bottom: 1px solid #e8ecf5;
  border-right: 1px solid #e8ecf5;
  min-height: 45px;
  padding-left: 5px;
}
</style>
