<template>
  <div class="forgotPwd">
    <div class="form forgotPwd_box">
      <div class="formBox">
        <div class="title"><Icon type="md-unlock" />找回密码</div>
        <Form
          class="GL_form"
          ref="formValidate"
          :model="formValidate"
          :rules="ruleValidate"
          :label-width="150"
        >
          <FormItem label="统一社会信用代码" prop="accountCode">
            <Input v-model.trim="formValidate.accountCode"></Input>
          </FormItem>
          <FormItem label="邮箱" prop="email">
            <Input :maxlength="50" v-model.trim="formValidate.email"></Input>
          </FormItem>
          <FormItem class="formItem" label="验证码" prop="pictureCode">
            <Input :maxlength="10" v-model="formValidate.pictureCode"></Input>
            <!-- <img src="../../assets/pictureCode.png" alt /> -->
            <img
              @click="getCodeImg"
              alt="点击更换"
              title="点击更换"
              :src="this.codeUrl"
            />
          </FormItem>
          <FormItem class="formItem" label="邮箱验证码" prop="emailCode">
            <Input :maxlength="15" v-model="formValidate.emailCode"></Input>
            <Button
              type="text"
              :disabled="btnFlag"
              @click="sendCode"
              class="empty_btn"
              ghost
              >{{ btnText }}</Button
            >
          </FormItem>
          <!-- <FormItem> -->
          <Button
            class="btnNext"
            type="primary"
            @click="handleSubmit('formValidate')"
            >下一步</Button
          >
          <!-- </FormItem> -->
        </Form>
        <div class="foot">
          已有账号,
          <router-link to="/login">去登录</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ForgotPwd",
  props: {},
  data() {
    // const validateUniformSocialCreditCode = (rule, value, callback) => {
    //   let patrn = /^[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/g;
    //   if (value === "") {
    //     callback(new Error("请输入统一社会信用代码！"));
    //   } else if (patrn.test(value) == false) {
    //     callback(new Error("请输入正确的统一社会信用代码！"));
    //   } else {
    //     callback();
    //   }
    // };
    const validateEmail = (rule, value, callback) => {
      let patrn = /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/;
      if (value === "") {
        callback(new Error("请输入邮箱！"));
      } else if (patrn.test(value) == false) {
        callback(new Error("请输入正确的邮箱地址！"));
      } else {
        callback();
      }
    };
    return {
      codeUrl: "/shsj_declare/account/getCaptcha",
      // codeInputFlag: true,
      // 91310230MA1JXW0M00
      // 287571899@qq.com
      btnFlag: false,
      btnText: "发送验证码",
      formValidate: {
        accountCode: "",
        email: "",
        pictureCode: "",
        emailCode: ""
      },
      ruleValidate: {
        accountCode: [
          {
            required: true,
            validator: this.$util.validateUniformSocialCreditCode,
            trigger: "blur"
          }
        ],

        email: [{ required: true, validator: validateEmail, trigger: "blur" }],
        pictureCode: [
          { required: true, message: "请输入验证码", trigger: "blur" }
        ],
        emailCode: [
          { required: true, message: "请输入邮箱验证码", trigger: "blur" }
        ]
      }
    };
  },
  created() {
    this.getCodeImg();
  },
  mounted() {},
  methods: {
    /**
     * method
     * 点击下一步
     */
    handleSubmit(name) {
      this.$refs[name].validate(async valid => {
        if (valid) {
          var res = await this.$http.post(
            "shsj_declare/account/checkPictureEmailCode",
            this.formValidate
          );
          if (res.messageCode === 10000) {
            this.$Message.success("验证成功!");
            setTimeout(() => {
              this.$router.push("/findpwd");
            }, 1000);
          }
        } else {
          this.$Message.error("请填写正确格式的信息!");
        }
      });
    },
    /*method
     *点击发送邮箱验证码
     */
    async sendCode() {
      // this.codeInputFlag = false;

      // 验证码
      var res = await this.$http.post(
        "shsj_declare/account/checkUnitCodeAndEmail",
        {
          email: this.formValidate.email,
          accountCode: this.formValidate.accountCode
        }
      );
      if (res.messageCode === 10000) {
        this.$Message.success("邮件已发送!");
        this.btnFlag = true;
      }
      var num = 60;
      var timer = setInterval(() => {
        if (num === 0) {
          this.btnText = "发送验证码";
          clearInterval(timer);
          this.btnFlag = false;
        } else {
          num--;
          this.btnText = `${num}s`;
        }
      }, 1000);
    },
    /**
     * 窗口代码
     * @param {Boolean} bRefresh 是否刷新
     * 刷新验证码功能
     */
    async getCodeImg() {
      let num = new Date().getTime();
      // let num = Math.ceil(Math.random() * 10); //生成一个随机数（防止缓存）
      this.codeUrl = "/shsj_declare/account/getCaptcha?" + num;
    }
  }
};
</script>
<style lang="scss" scoped>
.forgotPwd {
  margin-top: 30px;
  padding: 20px;
  .forgotPwd_box {
    overflow: hidden;
    border-top: 5px solid #3385ff;
    border-radius: 10px;
    box-shadow: 0px 2px 10px -2px rgba(51, 133, 255, 0.7);
    .formBox {
      letter-spacing: 1px;
      text-align: center;
      margin: 0 auto;
      width: 50%;
      padding: 70px 10px;
      .title {
        font-size: 20px;
        margin-bottom: 30px;
        color: #666;
        .ivu-icon {
          vertical-align: baseline;
          color: #999;
          padding-right: 10px;
        }
      }
      .foot {
        margin: 40px auto;
      }
      .btnNext {
        margin-top: 50px;
      }
    }
    .formItem {
      width: 80%;
      position: relative;
      .empty_btn {
        position: absolute;
        top: -1px;
        right: -110px;
        width: 100px;
        height: 35px;
        border: 1px solid #3385ff;
        color: #3385ff;
        font-size: 14px;
      }
      img {
        width: 80px;
        position: absolute;
        top: -2px;
        right: -90px;
      }
    }
  }
}
</style>
