<template>
  <div class="DesignTeam GL_declareForm Gl_input">
    <Form
      :rules="ruleValidate"
      class="form"
      ref="formValidate"
      :model="formValidate"
      :label-width="0"
    >
      <!-- 企业名称 -->
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="6">
            <span class="redStar">*</span>设计团队人员数
          </Col>
          <Col class="m_col" span="18">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="peopleNumber"
            >
              <Input
                :maxlength="50"
                v-model.trim="formValidate.peopleNumber"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="6">
            <span class="redStar">*</span>其中：从事3年以上人数
          </Col>
          <Col class="m_col" span="18">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="peopleNumberThree"
            >
              <Input
                :maxlength="50"
                v-model.trim="formValidate.peopleNumberThree"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="6">
            <span class="redStar">*</span>大学本科以上学历人员或高级职称人员数
          </Col>
          <Col class="m_col" span="18">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="undergraduateAboveAndSeniorTitle"
            >
              <Input
                :maxlength="50"
                v-model.trim="formValidate.undergraduateAboveAndSeniorTitle"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="6">
            <span class="redStar">*</span>首席设计师（设计总监）岗位
          </Col>
          <Col class="m_col" span="18">
            <RadioGroup size="large" v-model="formValidate.postOfChiefDesigner">
              <Radio label="1">有</Radio>
              <Radio label="0">无</Radio>
            </RadioGroup>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="6">
            <span class="redStar">*</span>首席设计师（设计总监）简介
          </Col>
          <Col class="m_col" span="18">
            <Input
              @on-change="
                formValidate.chiefDesignerSynopsis = $util.ToDBC(
                  formValidate.chiefDesignerSynopsis,
                  $event
                )
              "
              :maxlength="1000"
              v-model="formValidate.chiefDesignerSynopsis"
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 8 }"
            ></Input>
          </Col>
        </Row>
      </div>
    </Form>
    <div class="foot">
      <button v-waves @click="cancel" class="GL_cancel">取消</button>
      <button v-waves @click="save('formValidate')" class="GL_save">
        保存
      </button>
      <button v-waves @click="next('formValidate')" class="GL_next">
        保存并跳转下一步
      </button>
    </div>
  </div>
</template>

<script>
import waves from "../../directive/waves";
export default {
  name: "designTeam",
  props: {},
  directives: { waves },
  data() {
    return {
      formValidate: {},
      ruleValidate: {
        validatePositiveInteger: [
          {
            validator: this.$util.validatePositiveInteger,
            trigger: "blur"
          }
        ]
      }
    };
  },
  created() {
    // 查询表
    this.queyData();
  },
  watch: {},
  computed: {},
  components: {},
  mounted() {},
  methods: {
    /*method querydata
     *查询表
     */
    async queyData() {
      let { messageCode, result } = await this.$http.post(
        "/shsj_declare/ldeDeclare/queryLdeDeclareInfo",
        {
          programId: this.$route.query.programId,
          specialId: this.$route.query.specialId,
          type: "2"
        }
      );
      if (messageCode == 10000) {
        this.programId = result.declareInfo.programId;
        if (result.declareInfo.ldeDesignTeamBase != null) {
          this.formValidate = result.declareInfo.ldeDesignTeamBase;
        }
      }
    },
    /*method
     *点击取消
     */
    cancel() {
      this.$router.go(-1);
    },
    /*保存表单
     *method saveProgram
     **/
    saveProgram(name, type) {
      this.$refs[name].validate(async valid => {
        if (valid) {
          let { messageCode } = await this.$http.post(
            "/shsj_declare/ldeDeclare/saveLdeDeclareInfo",
            {
              programId: this.programId,
              type: "2",
              specialId: this.$route.query.specialId,
              ldeDesignTeamBase: this.formValidate
            }
          );
          if (messageCode == 10000) {
            this.$Message.success("保存页面成功!");
            if (type == "next") {
              this.$emit("nextChange", 3);
            }
          }
        } else {
          this.$Message.error("内容格式不正确!");
        }
      });
    },
    /*method
     *点击保存
     */
    save(name) {
      this.saveProgram(name);
    },
    /*method
     *点击下一步
     */
    next(name) {
      this.saveProgram(name, "next");
    }
  }
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped></style>
