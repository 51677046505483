<template>
  <div class="industrialDesign GL_declareForm Gl_input">
    <crumbs :title="'设计创新企业 '"></crumbs>
    <Form
      :rules="ruleValidate"
      class="form"
      ref="formValidate"
      :model="formValidate"
      :label-width="0"
    >
      <Row>
        <Col class="col_unit" span="24">单位：万元、平方米、个、%</Col>
      </Row>
      <!-- 企业名称 -->
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>企业名称
          </Col>
          <Col class="m_col" span="20">
            <Input
              :maxlength="50"
              v-model.trim="formValidate.industrialDesignEnterprise.companyName"
              class="unit_input"
            />
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>企业注册地址
          </Col>
          <Col class="m_col" span="20">
            <Input
              :maxlength="50"
              v-model.trim="
                formValidate.industrialDesignEnterprise.companyAddress
              "
              class="unit_input"
            />
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>经营地址
          </Col>
          <Col class="m_col" span="20">
            <Input
              :maxlength="50"
              v-model.trim="
                formValidate.industrialDesignEnterprise.manageAddress
              "
              class="unit_input"
            />
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>社会信用代码
          </Col>
          <Col class="m_col" span="8">
            <Input
              :maxlength="50"
              v-model.trim="
                formValidate.industrialDesignEnterprise.socialCreditCode
              "
              class="unit_input"
            />
          </Col>
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>税务登记区
          </Col>
          <Col class="m_col" span="8">
            <Select
              v-model="formValidate.industrialDesignEnterprise.registeredArea"
            >
              <Option
                v-for="item in areaList"
                :key="item.dataId"
                :value="item.dataId"
                >{{ item.dataValue }}</Option
              >
            </Select>
          </Col>
        </Row>
      </div>
      <!--  基本情况-->
      <Row>
        <Col span="24">基本情况</Col>
      </Row>
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4"
            ><span class="redStar">*</span>所有制性质</Col
          >
          <Col class="m_col" span="20">
            <div style="width:100%">
              <RadioGroup
                @on-change="radioChange"
                class="RadioGroup"
                size="large"
                v-model="
                  formValidate.industrialDesignEnterprise
                    .unitRegistrationTypeParentId
                "
              >
                <Radio
                  :key="index"
                  v-for="(item, index) in formValidate.companyType"
                  :label="item.dictId"
                  >{{ item.dictName }}</Radio
                >
              </RadioGroup>
              <br />
              <div
                v-show="childrenList.length > 0"
                style="border-top:1px dashed #ccc;margin:5px 0"
              ></div>
              <RadioGroup
                v-show="childrenList.length > 0"
                class="RadioGroup"
                size="large"
                v-model="
                  formValidate.industrialDesignEnterprise.propertyOfOwnership
                "
              >
                <Radio
                  :key="index2"
                  v-for="(item2, index2) in childrenList"
                  :label="item2.dataId"
                  >{{ item2.dataValue }}</Radio
                >
              </RadioGroup>
            </div>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>
            <span>职工人数</span>
          </Col>
          <Col class="m_col" span="20">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="industrialDesignEnterprise.employeeCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.industrialDesignEnterprise.employeeCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>资产总额
          </Col>
          <Col class="m_col" span="8">
            <FormItem
              :rules="ruleValidate.validateFloatingNumber"
              prop="industrialDesignEnterprise.propertyTotal"
            >
              <Input
                :maxlength="13"
                v-model="formValidate.industrialDesignEnterprise.propertyTotal"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>固定资产净值
          </Col>
          <Col class="m_col" span="8">
            <FormItem
              :rules="ruleValidate.validateFloatingNumber"
              prop="industrialDesignEnterprise.fixedAssetsValue"
            >
              <Input
                :maxlength="12"
                v-model="
                  formValidate.industrialDesignEnterprise.fixedAssetsValue
                "
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>资产负债率
          </Col>
          <Col class="m_col" span="8">
            <FormItem
              :rules="ruleValidate.validatePercentageThanZero"
              prop="industrialDesignEnterprise.assetLiabilityRatioMain"
            >
              <Input
                :maxlength="50"
                v-model.trim="
                  formValidate.industrialDesignEnterprise
                    .assetLiabilityRatioMain
                "
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>信用评级
          </Col>
          <Col class="m_col" span="8">
            <Input
              :maxlength="10"
              v-model.trim="
                formValidate.industrialDesignEnterprise.creditRating
              "
              class="unit_input"
            />
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>主要服务领域
          </Col>
          <Col class="m_col" span="20">
            <Input
              :maxlength="50"
              v-model.trim="
                formValidate.industrialDesignEnterprise.rangeOfServices
              "
              class="unit_input"
            />
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">企业是否属于</Col>
          <Col class="m_col m_padding" span="20">
            <CheckboxGroup size="large" v-model="CheckboxArray">
              <Checkbox :label="1">上市企业</Checkbox>
              <Checkbox :label="2">高新技术企业</Checkbox>
              <Checkbox :label="4">专精特新企业</Checkbox>
            </CheckboxGroup>
          </Col>
        </Row>
      </div>
      <!-- 管理人员 -->
      <Row>
        <Col span="24">管理人员</Col>
      </Row>
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>人员
          </Col>
          <Col class="m_col m_title" span="5">
            <span class="redStar">*</span>姓名
          </Col>
          <Col class="m_col m_title" span="5">
            <span class="redStar">*</span>职务
          </Col>
          <Col class="m_col m_title" span="5">
            <span class="redStar">*</span>联系手机
          </Col>
          <Col class="m_col m_title" span="5">
            <span class="redStar">*</span>电子邮箱
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>负责人
          </Col>
          <Col class="m_col" span="5">
            <Input
              :maxlength="20"
              v-model.trim="
                formValidate.industrialDesignEnterprise.principalName
              "
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="5">
            <Input
              :maxlength="20"
              v-model.trim="
                formValidate.industrialDesignEnterprise.principalPost
              "
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="5">
            <FormItem
              :rules="ruleValidate.validateCellPhoneNumber"
              prop="industrialDesignEnterprise.principalPhone"
            >
              <Input
                :maxlength="20"
                v-model="formValidate.industrialDesignEnterprise.principalPhone"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="5">
            <FormItem
              :rules="ruleValidate.validateMailbox"
              prop="industrialDesignEnterprise.principalEmail"
            >
              <Input
                :maxlength="50"
                v-model="formValidate.industrialDesignEnterprise.principalEmail"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>联系人
          </Col>
          <Col class="m_col" span="5">
            <Input
              :maxlength="20"
              v-model.trim="
                formValidate.industrialDesignEnterprise.contactsName
              "
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="5">
            <Input
              :maxlength="20"
              v-model.trim="
                formValidate.industrialDesignEnterprise.contactsPost
              "
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="5">
            <FormItem
              :rules="ruleValidate.validateCellPhoneNumber"
              prop="industrialDesignEnterprise.contactsPhone"
            >
              <Input
                :maxlength="20"
                v-model="formValidate.industrialDesignEnterprise.contactsPhone"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="5">
            <FormItem
              :rules="ruleValidate.validateMailbox"
              prop="industrialDesignEnterprise.contactsEmail"
            >
              <Input
                :maxlength="50"
                v-model="formValidate.industrialDesignEnterprise.contactsEmail"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
      </div>
      <!-- 专业人员 -->
      <Row>
        <Col span="24">专业人员</Col>
      </Row>
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="9">
            <span class="redStar">*</span>设计创新团队人数
          </Col>
          <Col class="m_col" span="15">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="industrialDesignEnterprise.employeesCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.industrialDesignEnterprise.employeesCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="9">
            <span class="redStar">*</span>
            <span>其中：设计相关学科本科及以上学历人数</span>
          </Col>
          <Col class="m_col" span="5">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="industrialDesignEnterprise.degreeOfCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.industrialDesignEnterprise.degreeOfCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col m_title" span="5">
            <span class="redStar">*</span>
            <span>占设计团队比例</span>
          </Col>
          <Col class="m_col" span="5">
            <FormItem
              :rules="ruleValidate.validatePercentageThanZero"
              prop="industrialDesignEnterprise.degreeOf"
            >
              <Input
                :maxlength="6"
                v-model="formValidate.industrialDesignEnterprise.degreeOf"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="9">
            <span class="redStar">*</span>
            <span>具有设计相关学科专业技术职称（职业资格）的人数</span>
          </Col>
          <Col class="m_col" span="5">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="industrialDesignEnterprise.specialtyCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.industrialDesignEnterprise.specialtyCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col m_title" span="5">
            <span class="redStar">*</span>
            <span>占设计团队比例</span>
          </Col>
          <Col class="m_col" span="5">
            <FormItem
              :rules="ruleValidate.validatePercentageThanZero"
              prop="industrialDesignEnterprise.specialtyProportion"
            >
              <Input
                :maxlength="6"
                v-model="
                  formValidate.industrialDesignEnterprise.specialtyProportion
                "
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
      </div>

      <!-- 硬件条件 -->
      <Row>
        <Col span="24">硬件设备</Col>
      </Row>
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title icon_col" span="8">
            <Icon
              @click="add('hardwareFacilityList')"
              class="icon"
              type="ios-add-circle-outline"
            />
            <span class="text_col">
              <span class="redStar">*</span>仪器设备名称
            </span>
          </Col>
          <Col class="m_col m_title" span="8">
            <span class="redStar">*</span>台（套）数
          </Col>
          <Col class="m_col m_title" span="8">
            <span class="redStar">*</span>价值
          </Col>
        </Row>
        <Row
          type="flex"
          v-for="(item, index) in formValidate.resourceAndPerfInfo
            .hardwareFacilityList"
          :key="index"
          class="m_row"
        >
          <Col class="m_col icon_col" span="8">
            <Icon
              @click="reduce('hardwareFacilityList', index)"
              class="icon"
              type="ios-remove-circle-outline"
            />
            <Input
              :maxlength="50"
              v-model.trim="item.apparatusName"
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="8">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              :prop="
                `resourceAndPerfInfo.hardwareFacilityList[${index}].numberOfUnits`
              "
            >
              <Input
                :maxlength="11"
                v-model="item.numberOfUnits"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="8">
            <FormItem
              :rules="ruleValidate.validateThanZero"
              :prop="`resourceAndPerfInfo.hardwareFacilityList[${index}].cost`"
            >
              <Input :maxlength="13" v-model="item.cost" class="unit_input" />
            </FormItem>
          </Col>
        </Row>
      </div>
      <!-- 软件条件 -->
      <Row>
        <Col span="24">软件条件</Col>
      </Row>
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title icon_col" span="8">
            <Icon
              @click="add('softwareList')"
              class="icon"
              type="ios-add-circle-outline"
            />
            <span class="text_col">
              <span class="redStar">*</span>软件名称
            </span>
          </Col>
          <Col class="m_col m_title" span="8">
            <span class="redStar">*</span>数量（套）
          </Col>
          <Col class="m_col m_title" span="8">
            <span class="redStar">*</span>价值
          </Col>
        </Row>
        <Row
          type="flex"
          v-for="(item, index) in formValidate.resourceAndPerfInfo.softwareList"
          :key="index"
          class="m_row"
        >
          <Col class="m_col icon_col" span="8">
            <Icon
              @click="reduce('softwareList', index)"
              class="icon"
              type="ios-remove-circle-outline"
            />
            <Input
              :maxlength="50"
              v-model.trim="item.softwareName"
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="8">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              :prop="`resourceAndPerfInfo.softwareList[${index}].quantity`"
            >
              <Input
                :maxlength="11"
                v-model="item.quantity"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="8">
            <FormItem
              :rules="ruleValidate.validateThanZero"
              :prop="`resourceAndPerfInfo.softwareList[${index}].cost`"
            >
              <Input :maxlength="13" v-model="item.cost" class="unit_input" />
            </FormItem>
          </Col>
        </Row>
      </div>

      <!-- 经济指标 -->
      <Row>
        <Col span="24">经济指标</Col>
      </Row>
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="6">
            <span class="redStar">*</span>近三年主要指标
          </Col>
          <Col class="m_col m_title" span="6">
            <span class="redStar">*</span>2021年
          </Col>
          <Col class="m_col m_title" span="6">
            <span class="redStar">*</span>2022年
          </Col>
          <Col class="m_col m_title" span="6">
            <span class="redStar">*</span>2023年前三季度
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col
            style="justify-content: flex-start;"
            class="m_col m_title"
            span="6"
          >
            <span class="redStar">*</span>设计投入总额
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validateThanZero"
              prop="industrialEiList[0].designTotalInvestment"
            >
              <Input
                :maxlength="13"
                v-model="formValidate.industrialEiList[0].designTotalInvestment"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validateThanZero"
              prop="industrialEiList[1].designTotalInvestment"
            >
              <Input
                :maxlength="13"
                v-model="formValidate.industrialEiList[1].designTotalInvestment"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validateThanZero"
              prop="industrialEiList[2].designTotalInvestment"
            >
              <Input
                :maxlength="13"
                v-model="formValidate.industrialEiList[2].designTotalInvestment"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col
            style="justify-content: flex-start;"
            class="m_col m_title"
            span="6"
          >
            <span class="redStar">*</span>占企业研发设计支出比重
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePercentageThanZero"
              prop="industrialEiList[0].proportionOfRdDesign"
            >
              <Input
                :maxlength="6"
                v-model="formValidate.industrialEiList[0].proportionOfRdDesign"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePercentageThanZero"
              prop="industrialEiList[1].proportionOfRdDesign"
            >
              <Input
                :maxlength="6"
                v-model="formValidate.industrialEiList[1].proportionOfRdDesign"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePercentageThanZero"
              prop="industrialEiList[2].proportionOfRdDesign"
            >
              <Input
                :maxlength="6"
                v-model="formValidate.industrialEiList[2].proportionOfRdDesign"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col
            style="justify-content: flex-start;"
            class="m_col m_title"
            span="6"
          >
            <span class="redStar">*</span>其中：设计人员经费支出
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validateThanZero"
              prop="industrialEiList[0].designPersonnel"
            >
              <Input
                :maxlength="13"
                v-model="formValidate.industrialEiList[0].designPersonnel"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validateThanZero"
              prop="industrialEiList[1].designPersonnel"
            >
              <Input
                :maxlength="13"
                v-model="formValidate.industrialEiList[1].designPersonnel"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validateThanZero"
              prop="industrialEiList[2].designPersonnel"
            >
              <Input
                :maxlength="13"
                v-model="formValidate.industrialEiList[2].designPersonnel"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
      </div>
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col
            style="justify-content: flex-start;"
            class="m_col m_title"
            span="6"
          >
            <span class="redStar">*</span>承担设计项目数
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="industrialEiList[0].designProjectCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.industrialEiList[0].designProjectCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="industrialEiList[1].designProjectCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.industrialEiList[1].designProjectCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="industrialEiList[2].designProjectCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.industrialEiList[2].designProjectCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col
            class="m_col m_title"
            style="justify-content: flex-start;"
            span="6"
          >
            <span class="redStar">*</span>其中：完成项目数
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="industrialEiList[0].completedProjectCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.industrialEiList[0].completedProjectCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="industrialEiList[1].completedProjectCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.industrialEiList[1].completedProjectCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="industrialEiList[2].completedProjectCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.industrialEiList[2].completedProjectCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col
            class="m_col m_title"
            style="justify-content: flex-start;"
            span="6"
          >
            <span class="redStar" style="margin-left:3em">*</span>产业化项目数
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="industrialEiList[0].industrializationProjectCount"
            >
              <Input
                :maxlength="8"
                v-model="
                  formValidate.industrialEiList[0].industrializationProjectCount
                "
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="industrialEiList[1].industrializationProjectCount"
            >
              <Input
                :maxlength="8"
                v-model="
                  formValidate.industrialEiList[1].industrializationProjectCount
                "
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="industrialEiList[2].industrializationProjectCount"
            >
              <Input
                :maxlength="8"
                v-model="
                  formValidate.industrialEiList[2].industrializationProjectCount
                "
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
      </div>
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col
            style="justify-content: flex-start;"
            class="m_col m_title"
            span="6"
          >
            <span class="redStar">*</span>专利数
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="industrialEiList[0].patentLicensingCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.industrialEiList[0].patentLicensingCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="industrialEiList[1].patentLicensingCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.industrialEiList[1].patentLicensingCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="industrialEiList[2].patentLicensingCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.industrialEiList[2].patentLicensingCount"
                class="unit_input"
              />
            </FormItem>
          </Col> </Row
        ><Row type="flex" class="m_row">
          <Col
            class="m_col m_title"
            style="justify-content: flex-start;"
            span="6"
          >
            <span class="redStar">*</span>其中：发明专利（授权数）
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="industrialEiList[0].inventPatentCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.industrialEiList[0].inventPatentCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="industrialEiList[1].inventPatentCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.industrialEiList[1].inventPatentCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="industrialEiList[2].inventPatentCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.industrialEiList[2].inventPatentCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col
            class="m_col m_title"
            style="justify-content: flex-start;"
            span="6"
          >
            <span class="redStar" style="margin-left:3em">*</span
            >实用新型（授权数）
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="industrialEiList[0].practicalPatent"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.industrialEiList[0].practicalPatent"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="industrialEiList[1].practicalPatent"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.industrialEiList[1].practicalPatent"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="industrialEiList[2].practicalPatent"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.industrialEiList[2].practicalPatent"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col
            class="m_col m_title"
            style="justify-content: flex-start;"
            span="6"
          >
            <span class="redStar" style="margin-left:3em">*</span
            >外观设计（授权数）
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="industrialEiList[0].appearancePatent"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.industrialEiList[0].appearancePatent"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="industrialEiList[1].appearancePatent"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.industrialEiList[1].appearancePatent"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="industrialEiList[2].appearancePatent"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.industrialEiList[2].appearancePatent"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>

        <Row type="flex" class="m_row">
          <Col
            style="justify-content: flex-start;"
            class="m_col m_title"
            span="6"
          >
            <span class="redStar">*</span>版权数（经登记数）
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="industrialEiList[0].copyrightAuthorization"
            >
              <Input
                :maxlength="8"
                v-model="
                  formValidate.industrialEiList[0].copyrightAuthorization
                "
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="industrialEiList[1].copyrightAuthorization"
            >
              <Input
                :maxlength="8"
                v-model="
                  formValidate.industrialEiList[1].copyrightAuthorization
                "
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="industrialEiList[2].copyrightAuthorization"
            >
              <Input
                :maxlength="8"
                v-model="
                  formValidate.industrialEiList[2].copyrightAuthorization
                "
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
      </div>
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col
            style="justify-content: flex-start;"
            class="m_col m_title"
            span="6"
          >
            <span class="redStar">*</span>设计标准制定数
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="industrialEiList[0].standardCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.industrialEiList[0].standardCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="industrialEiList[1].standardCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.industrialEiList[1].standardCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="industrialEiList[2].standardCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.industrialEiList[2].standardCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col
            style="justify-content: flex-start;"
            class="m_col m_title"
            span="6"
          >
            <span class="redStar">*</span>其中：国际或国家标准
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="industrialEiList[0].nationalStandardCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.industrialEiList[0].nationalStandardCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="industrialEiList[1].nationalStandardCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.industrialEiList[1].nationalStandardCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="industrialEiList[2].nationalStandardCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.industrialEiList[2].nationalStandardCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col
            class="m_col m_title"
            style="justify-content: flex-start;"
            span="6"
          >
            <span class="redStar" style="margin-left:3em">*</span>行业标准
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="industrialEiList[0].industryStandardCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.industrialEiList[0].industryStandardCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="industrialEiList[1].industryStandardCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.industrialEiList[1].industryStandardCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="industrialEiList[2].industryStandardCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.industrialEiList[2].industryStandardCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col
            class="m_col m_title"
            style="justify-content: flex-start;"
            span="6"
          >
            <span class="redStar" style="margin-left:3em">*</span>地方或团体标准
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="industrialEiList[0].localStandardCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.industrialEiList[0].localStandardCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="industrialEiList[1].localStandardCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.industrialEiList[1].localStandardCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="industrialEiList[2].localStandardCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.industrialEiList[2].localStandardCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
      </div>
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col
            style="justify-content: flex-start;"
            class="m_col m_title"
            span="6"
          >
            <span class="redStar">*</span>企业营业收入
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validateFloatingNumber"
              prop="industrialEiList[0].companyIncome"
            >
              <Input
                :maxlength="13"
                v-model="formValidate.industrialEiList[0].companyIncome"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validateFloatingNumber"
              prop="industrialEiList[1].companyIncome"
            >
              <Input
                :maxlength="13"
                v-model="formValidate.industrialEiList[1].companyIncome"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validateFloatingNumber"
              prop="industrialEiList[2].companyIncome"
            >
              <Input
                :maxlength="13"
                v-model="formValidate.industrialEiList[2].companyIncome"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col
            style="justify-content: flex-start;"
            class="m_col m_title"
            span="6"
          >
            <span class="redStar">*</span>其中：设计服务收入
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validateFloatingNumber"
              prop="industrialEiList[0].industrialDesignTotal"
            >
              <Input
                :maxlength="13"
                v-model="formValidate.industrialEiList[0].industrialDesignTotal"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validateFloatingNumber"
              prop="industrialEiList[1].industrialDesignTotal"
            >
              <Input
                :maxlength="13"
                v-model="formValidate.industrialEiList[1].industrialDesignTotal"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validateFloatingNumber"
              prop="industrialEiList[2].industrialDesignTotal"
            >
              <Input
                :maxlength="13"
                v-model="formValidate.industrialEiList[2].industrialDesignTotal"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col
            class="m_col m_title"
            style="justify-content: flex-start;"
            span="6"
          >
            <span class="redStar" style="margin-left:3em">*</span>设计收入占比
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePercentageThanZero"
              prop="industrialEiList[0].industrialDesignDuty"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.industrialEiList[0].industrialDesignDuty"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePercentageThanZero"
              prop="industrialEiList[1].industrialDesignDuty"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.industrialEiList[1].industrialDesignDuty"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePercentageThanZero"
              prop="industrialEiList[2].industrialDesignDuty"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.industrialEiList[2].industrialDesignDuty"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col
            style="justify-content: flex-start;"
            class="m_col m_title"
            span="6"
          >
            <span class="redStar">*</span>利润总额
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validateFloatingNumber"
              prop="industrialEiList[0].profitTotal"
            >
              <Input
                :maxlength="13"
                v-model="formValidate.industrialEiList[0].profitTotal"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validateFloatingNumber"
              prop="industrialEiList[1].profitTotal"
            >
              <Input
                :maxlength="13"
                v-model="formValidate.industrialEiList[1].profitTotal"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validateFloatingNumber"
              prop="industrialEiList[2].profitTotal"
            >
              <Input
                :maxlength="13"
                v-model="formValidate.industrialEiList[2].profitTotal"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col
            style="justify-content: flex-start;"
            class="m_col m_title"
            span="6"
          >
            <span class="redStar">*</span>纳税总额
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validateThanZero"
              prop="industrialEiList[0].profitAndTaxTotal"
            >
              <Input
                :maxlength="13"
                v-model="formValidate.industrialEiList[0].profitAndTaxTotal"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validateThanZero"
              prop="industrialEiList[1].profitAndTaxTotal"
            >
              <Input
                :maxlength="13"
                v-model="formValidate.industrialEiList[1].profitAndTaxTotal"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validateThanZero"
              prop="industrialEiList[2].profitAndTaxTotal"
            >
              <Input
                :maxlength="13"
                v-model="formValidate.industrialEiList[2].profitAndTaxTotal"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col
            style="justify-content: flex-start;"
            class="m_col m_title"
            span="6"
          >
            <span class="redStar">*</span>资产负债率
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePercentageThanZero"
              prop="industrialEiList[0].assetLiabilityRatio"
            >
              <Input
                :maxlength="13"
                v-model="formValidate.industrialEiList[0].assetLiabilityRatio"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePercentageThanZero"
              prop="industrialEiList[1].assetLiabilityRatio"
            >
              <Input
                :maxlength="13"
                v-model="formValidate.industrialEiList[1].assetLiabilityRatio"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePercentageThanZero"
              prop="industrialEiList[2].assetLiabilityRatio"
            >
              <Input
                :maxlength="13"
                v-model="formValidate.industrialEiList[2].assetLiabilityRatio"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col
            style="justify-content: flex-start;"
            class="m_col m_title"
            span="6"
          >
            <span class="redStar">*</span>现金流情况
            (经营活动现金流量净额，可附说明)
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validateFloatingNumber"
              prop="industrialEiList[0].cashSituation"
            >
              <Input
                :maxlength="13"
                v-model="formValidate.industrialEiList[0].cashSituation"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validateFloatingNumber"
              prop="industrialEiList[1].cashSituation"
            >
              <Input
                :maxlength="13"
                v-model="formValidate.industrialEiList[1].cashSituation"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validateFloatingNumber"
              prop="industrialEiList[2].cashSituation"
            >
              <Input
                :maxlength="13"
                v-model="formValidate.industrialEiList[2].cashSituation"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
      </div>
      <!-- 近三年设计成果获奖情况 -->
      <Row>
        <Col span="24">近三年设计成果获奖情况</Col>
      </Row>
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title icon_col" span="6">
            <Icon
              @click="add('awardsInfoList')"
              class="icon"
              type="ios-add-circle-outline"
            />
            <span class="text_col">
              <span class="redStar">*</span>获奖作品
            </span>
          </Col>
          <Col class="m_col m_title" span="6">
            <span class="redStar">*</span>奖项名称
          </Col>
          <Col class="m_col m_title" span="6">
            <span class="redStar">*</span>获得时间
          </Col>
          <Col class="m_col m_title" span="6">
            <span class="redStar">*</span>授奖部门(或机构)
          </Col>
        </Row>
        <Row
          type="flex"
          v-for="(item, index) in formValidate.resourceAndPerfInfo
            .awardsInfoList"
          :key="index"
          class="m_row"
        >
          <Col class="m_col icon_col" span="6">
            <Icon
              @click="reduce('awardsInfoList', index)"
              class="icon"
              type="ios-remove-circle-outline"
            />
            <Input
              :maxlength="50"
              v-model.trim="item.awardsWorks"
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="6">
            <Input
              :maxlength="50"
              v-model.trim="item.awardsName"
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="6">
            <DatePicker
              :options="pickerOptions"
              :value="item.obtainTime"
              @on-change="item.obtainTime = $event"
              type="date"
              placeholder="选择时间"
              style="width: 200px"
            ></DatePicker>
          </Col>
          <Col class="m_col" span="6">
            <Input
              :maxlength="50"
              v-model.trim="item.authorizationDepartment"
              class="unit_input"
            />
          </Col>
        </Row>
      </div>
      <!-- 近三年开展公共服务情况 -->
      <Row>
        <Col span="24">近三年开展公共服务情况</Col>
      </Row>
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title icon_col" span="6">
            <Icon
              @click="add('serviceList')"
              class="icon"
              type="ios-add-circle-outline"
            />
            <span class="text_col">
              <span class="redStar">*</span>服务类型
            </span>
          </Col>
          <Col class="m_col m_title" span="6">
            <span class="redStar">*</span>项目名称
          </Col>
          <Col class="m_col m_title" span="6">
            <span class="redStar">*</span>开展时间
          </Col>
          <Col class="m_col m_title" span="6">
            <span class="redStar">*</span>组织部门
          </Col>
        </Row>
        <Row
          v-for="(item, index) in formValidate.resourceAndPerfInfo.serviceList"
          :key="index"
          class="m_row"
        >
          <Col class="m_col icon_col" span="6">
            <Icon
              @click="reduce('serviceList', index)"
              class="icon"
              type="ios-remove-circle-outline"
            />
            <Input
              :maxlength="50"
              v-model.trim="item.serviceType"
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="6">
            <Input
              :maxlength="50"
              v-model="item.serviceName"
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="6">
            <DatePicker
              :options="pickerOptions"
              :value="item.startTime"
              @on-change="item.startTime = $event"
              type="date"
              placeholder="选择时间"
              style="width: 200px"
            ></DatePicker>
          </Col>
          <Col class="m_col" span="6">
            <Input
              :maxlength="50"
              v-model="item.department"
              class="unit_input"
            />
          </Col>
        </Row>
      </div>
      <!-- 近三年主要设计成果产业化情况 -->
      <Row>
        <Col span="24">近三年主要设计成果产业化情况</Col>
      </Row>
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title icon_col" span="6">
            <Icon
              @click="add('designResultList')"
              class="icon"
              type="ios-add-circle-outline"
            />
            <span class="text_col">
              <span class="redStar">*</span>项目名称
            </span>
          </Col>
          <Col class="m_col m_title" span="5">
            <span class="redStar">*</span>客户企业
          </Col>
          <Col class="m_col m_title" span="5">
            <span class="redStar">*</span>完成时间
          </Col>
          <Col class="m_col m_title" span="8">
            <span class="redStar">*</span>产业化效果
          </Col>
        </Row>
        <Row
          type="flex"
          class="m_row"
          v-for="(item, index) in formValidate.resourceAndPerfInfo
            .designResultList"
          :key="index"
        >
          <Col class="m_col icon_col" span="6">
            <Icon
              @click="reduce('designResultList', index)"
              class="icon"
              type="ios-remove-circle-outline"
            />
            <Input
              :maxlength="50"
              v-model.trim="item.projectName"
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="5">
            <Input
              :maxlength="30"
              v-model.trim="item.clientCompany"
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="5">
            <!-- <Input v-model="item.time" class="unit_input" /> -->
            <DatePicker
              :options="pickerOptions"
              :value="item.finishDeliveryTime"
              @on-change="item.finishDeliveryTime = $event"
              type="date"
              placeholder="选择时间"
              style="width: 200px"
            ></DatePicker>
          </Col>
          <Col class="m_col" span="8">
            <Input
              type="textarea"
              :maxlength="300"
              v-model="item.industrializationResult"
              @on-change="
                item.industrializationResult = $util.ToDBC(
                  item.industrializationResult,
                  $event
                )
              "
              class="unit_input"
              placeholder="简述：项目内容、设计理念、设计创新点和技术水平情况，形成的新产品，为企业带来经济效益和社会效益情况等内容。"
              :autosize="{ minRows: 3, maxRows: 8 }"
            />
          </Col>
        </Row>
      </div>
      <!-- 牵头或参与完成国家、本市重大设计创新项目情况 -->
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="24">
            <span class="redStar">*</span
            >牵头或参与完成国家、本市重大设计创新项目情况
          </Col>
        </Row>
        <Row class="m_row textarea_row">
          <Col span="24">
            <Input
              :maxlength="1000"
              v-model="formValidate.statusQuoPlan.nationalMajorProgramInfo"
              @on-change="
                formValidate.statusQuoPlan.nationalMajorProgramInfo = $util.ToDBC(
                  formValidate.statusQuoPlan.nationalMajorProgramInfo,
                  $event
                )
              "
              type="textarea"
              :autosize="{ minRows: 5, maxRows: 8 }"
              placeholder="近三年牵头或参与完成国家、本市重大研发、高质量发展、文化创意、重大工程项目等设计创新工作情况。"
            ></Input>
          </Col>
        </Row>
      </div>
      <!-- 组织或参与国家、本市重要设计类活动情况 -->
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="24">
            <span class="redStar">*</span>组织或参与国家、本市重要设计类活动情况
          </Col>
        </Row>
        <Row class="m_row textarea_row">
          <Col span="24">
            <Input
              :maxlength="1000"
              v-model="formValidate.statusQuoPlan.activitySituation"
              @on-change="
                formValidate.statusQuoPlan.activitySituation = $util.ToDBC(
                  formValidate.statusQuoPlan.activitySituation,
                  $event
                )
              "
              type="textarea"
              :autosize="{ minRows: 5, maxRows: 8 }"
              placeholder="近三年牵头或参与设计创新重要活动，包括国家、本市重要设计类活动情况，承担省部级设计创新课题研究，与中小企业开展设计创新项目合作、为中小企业提供设计创新咨询服务，获得省部级示范认定等情况，以及必要的佐证材料。（可另附页）"
            ></Input>
          </Col>
        </Row>
      </div>
      <!-- 发展情况说明 -->
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="24">
            <span class="redStar">*</span>发展情况说明
          </Col>
        </Row>
        <Row class="m_row textarea_row">
          <Col span="24">
            <Input
              :maxlength="1000"
              v-model="formValidate.statusQuoPlan.operationInfo"
              @on-change="
                formValidate.statusQuoPlan.operationInfo = $util.ToDBC(
                  formValidate.statusQuoPlan.operationInfo,
                  $event
                )
              "
              type="textarea"
              :autosize="{ minRows: 5, maxRows: 8 }"
              placeholder="重点介绍设计创新企业发展经历、组织体系、团队建设、运营模式、主要业绩、管理制度、产学研合作情况、履行设计创新公共服务平台职责等，以及必要的佐证材料。（可另附页）"
            ></Input>
          </Col>
        </Row>
      </div>
      <!-- 中心今后三年目标与规划情况 -->
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="24">
            <span class="redStar">*</span>中心今后三年目标与规划情况
          </Col>
        </Row>
        <Row class="m_row textarea_row">
          <Col span="24">
            <Input
              :maxlength="1000"
              v-model="formValidate.statusQuoPlan.targetAndPlan"
              @on-change="
                formValidate.statusQuoPlan.targetAndPlan = $util.ToDBC(
                  formValidate.statusQuoPlan.targetAndPlan,
                  $event
                )
              "
              type="textarea"
              :autosize="{ minRows: 5, maxRows: 8 }"
              placeholder="重点是中心今后三年创新建设、有效投入、设计成果等主要指标，以及组织体系建设、运营模式创新、团队建设、履行设计创新公共服务平台职责等规划和措施等情况。"
            ></Input>
          </Col>
        </Row>
      </div>
    </Form>
    <div class="foot">
      <button v-waves @click="cancel" class="GL_cancel">取消</button>
      <button v-waves @click="save('formValidate')" class="GL_save">
        保存
      </button>
      <button v-waves @click="next('formValidate')" class="GL_next">
        保存并跳转下一步
      </button>
    </div>
  </div>
</template>

<script>
import crumbs from "../../components/crumbs";
import waves from "../../directive/waves";
export default {
  components: {
    crumbs
  },
  directives: { waves },
  name: "",
  props: {},
  data() {
    const disabledDate = v => {
      return (
        v.getTime() < new Date("1970-1-1").getTime() ||
        v.getTime() > new Date("2038-1-11").getTime()
      );
    };
    return {
      pickerOptions: {
        disabledDate
      },
      CheckboxArray: [],
      childrenList: [],
      // 区域列表
      areaList: [],
      // countNum: 0,
      programId: null,
      formValidate: {
        programId: null,
        pageStatus: 1,
        declareType: 3002,
        resourceAndPerfInfo: {
          awardsInfoList: [
            {
              awardsWorks: null,
              awardsName: null,
              obtainTime: null,
              authorizationDepartment: null
            }
          ],
          designResultList: [
            {
              projectName: null,
              clientCompany: null,
              finishDeliveryTime: null,
              industrializationResult: null
            }
          ],
          hardwareFacilityList: [
            {
              apparatusName: null,
              numberOfUnits: null,
              cost: null,
              useInfo: null
            }
          ],
          softwareList: [
            {
              softwareName: null,
              quantity: null,
              cost: null,
              useInfo: null
            }
          ],
          serviceList: [
            {
              serviceType: null,
              serviceName: null,
              startTime: null,
              department: null
            }
          ]
        },
        statusQuoPlan: {
          //牵头或参与完成国家、本市重大设计创新项目情况
          nationalMajorProgramInfo: null,
          //组织或参与国家、本市重要设计类活动情况
          activitySituation: null,
          //发展情况说明
          operationInfo: null,
          //中心今后三年目标与规划情况
          targetAndPlan: null,
          programType: 1
        },
        industrialDesignEnterprise: {
          programId: null
          // companyName: null,
          // companyAddress: null,
          // manageAddress: null, //企业经营地址
          // registeredArea: null,
          // thatTime: null, //获得区级工业设计中心认定时间
          // establishedTime: null, //成立时间
          // registeredCapital: null, //注册资本
          // propertyTotal: null, //资产总额
          // employeeCount: null, //职工人数
          // propertyOfOwnership: null, // "所有制性质",
          // creditRating: null, //信用等级
          // companyIsBelong: null, //企业是否属于(1:上市企业 2：高新技术企业)
          // rangeOfServices: null,
          // principalName: null,
          // principalPost: null,
          // principalPhone: null,
          // contactsName: null,
          // contactsPost: null,
          // contactsPhone: null,
          // employeesCount: null, //工业设计从业人数
          // degreeOf: null, //其中：本科及以上学历人员数（含具有技师及以上职业资格人员、高级专业技术职务人员）和占比
          // degreeOfCount: null,
          // specialtyCount: null, //具有技师（⾼级技师）职业资格的⼈员、⾼级专业技术职务⼈员数
          // specialtyProportion: null //具有技师（⾼级技师）职业资格的⼈员、⾼级专业技术职务⼈员数及占⽐
        },
        industrialEiList: [
          //经济指标、工业设计成果
          {
            companyIncome: null, //公司营业收入
            industrialDesignDuty: null, //设计收入占比
            profitAndTaxTotal: null, //利税总额
            profitTotal: null, //利润总额
            projectFinishCount: null, //工业设计项目完成数
            epibolyProjectCount: null, //承担服务外包项目数
            foreignProjectCount: null, //其中:承担国外项目数
            patentLicensingCount: null, //专利授权数
            inventPatentCount: null, //其中:发明专利数
            copyrightAuthorization: null, //版权授权数
            standardCount: null, //标准制定数
            nationalStandardCount: null, //其中：国际或国家标准
            industryStandardCount: null, //行业标准
            localStandardCount: null, //地方标准
            designProjectCount: null, //承担设计项目数
            assetLiabilityRatio: null, //资产负债率
            industrialDesignTotal: null, //其中：设计服务收入
            proportionOfRdDesign: null, //占企业研发设计支出比重
            cashSituation: null, //现金流情况
            year: 2021 //年份
          },
          {
            companyIncome: null,
            industrialDesignDuty: null,
            profitAndTaxTotal: null,
            profitTotal: null,
            projectFinishCount: null,
            epibolyProjectCount: null,
            foreignProjectCount: null,
            patentLicensingCount: null,
            inventPatentCount: null,
            copyrightAuthorization: null,
            standardCount: null, //标准制定数
            nationalStandardCount: null, //其中：国际或国家标准
            industryStandardCount: null, //行业标准
            localStandardCount: null, //地方标准
            designProjectCount: null, //承担设计项目数
            assetLiabilityRatio: null, //资产负债率
            industrialDesignTotal: null, //其中：设计服务收入
            proportionOfRdDesign: null, //占企业研发设计支出比重
            cashSituation: null, //现金流情况
            year: 2022
          },
          {
            companyIncome: null,
            industrialDesignDuty: null,
            profitAndTaxTotal: null,
            profitTotal: null,
            projectFinishCount: null,
            epibolyProjectCount: null,
            foreignProjectCount: null,
            patentLicensingCount: null,
            inventPatentCount: null,
            copyrightAuthorization: null,
            standardCount: null, //标准制定数
            nationalStandardCount: null, //其中：国际或国家标准
            industryStandardCount: null, //行业标准
            localStandardCount: null, //地方标准
            designProjectCount: null, //承担设计项目数
            assetLiabilityRatio: null, //资产负债率
            industrialDesignTotal: null, //其中：设计服务收入
            proportionOfRdDesign: null, //占企业研发设计支出比重
            cashSituation: null, //现金流情况
            year: 2023
          }
        ]
      },

      ruleValidate: {
        validateThanZero: [
          // 验证大于等于0的所有数，小数点后保留两位小数
          { validator: this.$util.validateThanZero, trigger: "blur" }
        ],
        validatePositiveInteger: [
          // 验证大于等于0的所有整数
          { validator: this.$util.validatePositiveInteger, trigger: "blur" }
        ],
        validateCellPhoneNumber: [
          { validator: this.$util.validateCellPhoneNumber, trigger: "blur" }
        ],
        validateMailbox: [
          { validator: this.$util.validateMailbox, trigger: "blur" }
        ],
        validatePercentageThanZero: [
          { validator: this.$util.validatePercentageThanZero, trigger: "blur" }
        ],
        validateFloatingNumber: [
          { validator: this.$util.validateFloatingNumber, trigger: "blur" }
        ]
      }
    };
  },
  beforeRouteEnter(to, from, next) {
    if (from.path == "/manage") {
      next(vm => {
        vm.$store.commit("getWhereFrom", "manage");
      });
    }
    next();
  },
  created() {
    this.queryPage();
    // 获取区域列表
    this.getAreaList();
    this.programId = this.$store.state.industryProgramId;
    this.formValidate.programId = this.$store.state.industryProgramId;
    this.formValidate.industrialDesignEnterprise.programId = this.$store.state.industryProgramId;
  },
  computed: {},

  mounted() {},
  methods: {
    /**减少产品
     * method reduce product
     * */
    reduce(type, index) {
      switch (type) {
        case "awardsInfoList":
          if (this.formValidate.resourceAndPerfInfo.awardsInfoList.length > 1) {
            this.formValidate.resourceAndPerfInfo.awardsInfoList.splice(
              index,
              1
            );
          } else {
            this.$Message.error("最少一条");
          }

          break;
        case "designResultList":
          if (
            this.formValidate.resourceAndPerfInfo.designResultList.length > 1
          ) {
            this.formValidate.resourceAndPerfInfo.designResultList.splice(
              index,
              1
            );
          } else {
            this.$Message.error("最少一条");
          }

          break;
        case "hardwareFacilityList":
          if (
            this.formValidate.resourceAndPerfInfo.hardwareFacilityList.length >
            1
          ) {
            this.formValidate.resourceAndPerfInfo.hardwareFacilityList.splice(
              index,
              1
            );
          } else {
            this.$Message.error("最少一条");
          }

          break;
        case "softwareList":
          if (this.formValidate.resourceAndPerfInfo.softwareList.length > 1) {
            this.formValidate.resourceAndPerfInfo.softwareList.splice(index, 1);
          } else {
            this.$Message.error("最少一条");
          }

          break;
        case "serviceList":
          if (this.formValidate.resourceAndPerfInfo.serviceList.length > 1) {
            this.formValidate.resourceAndPerfInfo.serviceList.splice(index, 1);
          } else {
            this.$Message.error("最少一条");
          }

          break;
      }
    },
    /**增加产品
     * method add product
     * */
    add(type) {
      switch (type) {
        case "awardsInfoList":
          this.formValidate.resourceAndPerfInfo.awardsInfoList.push({
            awardsWorks: null,
            awardsName: null,
            obtainTime: null,
            authorizationDepartment: null
          });
          break;
        case "designResultList":
          this.formValidate.resourceAndPerfInfo.designResultList.push({
            projectName: null,
            clientCompany: null,
            finishDeliveryTime: null,
            industrializationResult: null
          });
          break;
        case "hardwareFacilityList":
          this.formValidate.resourceAndPerfInfo.hardwareFacilityList.push({
            apparatusName: null,
            numberOfUnits: null,
            cost: null,
            useInfo: null
          });
          break;
        case "softwareList":
          this.formValidate.resourceAndPerfInfo.softwareList.push({
            softwareName: null,
            quantity: null,
            cost: null,
            useInfo: null
          });
          break;
        case "serviceList":
          this.formValidate.resourceAndPerfInfo.serviceList.push({
            serviceType: null,
            serviceName: null,
            startTime: null,
            department: null
          });
          break;
      }
    },
    /*初始化数据
     *method queryPage
     */
    async queryPage() {
      // console.log(this.$route.query);
      if (this.$route.query.programId) {
        this.$store.commit("getIndustryProgramId", this.$route.query.programId);
      }
      if (this.$route.query.specialId) {
        this.$store.commit("getIndustrySpecialId", this.$route.query.specialId);
      }
      let res = await this.$http.post(
        "shsj_declare/industralDesign/getIndustralDesignInfo",
        {
          programId:
            this.$route.query.programId || this.$store.state.industryProgramId,
          pageStatus: 1,
          declareType: 3002
        }
      );
      if (res.messageCode === 10000) {
        if (res.result.result != null) {
          this.formValidate.industrialDesignEnterprise =
            res.result.result.IndustrialDesignEnterpriseInfo;
          if (
            this.formValidate.industrialDesignEnterprise.companyName == null
          ) {
            this.formValidate.industrialDesignEnterprise.companyName =
              localStorage.companyName;
          }
          if (
            res.result.result.IndustrialDesignEnterpriseInfo.industrialEiList
              .length == 3
          ) {
            this.formValidate.industrialEiList =
              res.result.result.IndustrialDesignEnterpriseInfo.industrialEiList;
          }
          if (res.result.result.resourceAndPerfInfo) {
            for (let k in res.result.result.resourceAndPerfInfo) {
              if (
                res.result.result.resourceAndPerfInfo[k] &&
                res.result.result.resourceAndPerfInfo[k].length > 0
              ) {
                this.formValidate.resourceAndPerfInfo[k] =
                  res.result.result.resourceAndPerfInfo[k];
              }
            }
          }
          if (res.result.result.plan) {
            this.formValidate.statusQuoPlan = res.result.result.plan;
          }

          this.formValidate.industrialDesignEnterprise.unitRegistrationTypeParentId =
            this.formValidate.industrialDesignEnterprise
              .unitRegistrationTypeParentId - 0;
          this.formValidate.industrialDesignEnterprise.propertyOfOwnership =
            this.formValidate.industrialDesignEnterprise.propertyOfOwnership -
            0;
          this.formValidate.companyType = res.result.result.companyType;
          let index = this.formValidate.companyType.findIndex(
            item =>
              item.dictId ==
              this.formValidate.industrialDesignEnterprise
                .unitRegistrationTypeParentId
          );
          if (index == -1) {
            this.childrenList = [];
          } else {
            this.childrenList = this.formValidate.companyType[
              index
            ].sysDictDataList;
          }
          // 多选框
          if (
            this.formValidate.industrialDesignEnterprise.companyIsBelong == 1
          ) {
            this.CheckboxArray = [1];
          } else if (
            this.formValidate.industrialDesignEnterprise.companyIsBelong == 2
          ) {
            this.CheckboxArray = [2];
          } else if (
            this.formValidate.industrialDesignEnterprise.companyIsBelong == 3
          ) {
            this.CheckboxArray = [1, 2];
          } else if (
            this.formValidate.industrialDesignEnterprise.companyIsBelong == 4
          ) {
            this.CheckboxArray = [4];
          } else if (
            this.formValidate.industrialDesignEnterprise.companyIsBelong == 5
          ) {
            this.CheckboxArray = [1, 4];
          } else if (
            this.formValidate.industrialDesignEnterprise.companyIsBelong == 6
          ) {
            this.CheckboxArray = [2, 4];
          } else if (
            this.formValidate.industrialDesignEnterprise.companyIsBelong == 7
          ) {
            this.CheckboxArray = [1, 2, 4];
          }
        }
      }
    },
    /****/
    radioChange(e) {
      let list = this.formValidate.companyType.filter(item => item.dictId == e);
      if (list.length > 0) {
        this.childrenList = list[0].sysDictDataList;
        this.formValidate.industrialDesignEnterprise.propertyOfOwnership = null;
      }
    },
    /*获取区域列表
     *method getAreaList
     */
    async getAreaList() {
      let { result } = await this.$http.get(
        "shsj_declare/sysDict/queryDictDataList",
        {
          dictId: 8000
        }
      );
      this.areaList = result.dictDataList;
      let shanghaiIndex = this.areaList.findIndex(
        item => item.dataValue == "上海市"
      );
      this.areaList.splice(shanghaiIndex, 1);
    },
    /*保存表单
     *method saveProgram
     **/
    saveProgram(name, type) {
      this.$refs[name].validate(async valid => {
        if (valid) {
          if (this.CheckboxArray.length > 0) {
            this.formValidate.industrialDesignEnterprise.companyIsBelong = this.CheckboxArray.reduce(
              (result, item) => result + item
            );
          } else {
            this.formValidate.industrialDesignEnterprise.companyIsBelong = null;
          }
          if (this.formValidate.industrialDesignEnterprise.thatTime == "") {
            this.formValidate.industrialDesignEnterprise.thatTime = null;
          }
          if (
            this.formValidate.industrialDesignEnterprise.establishedTime == ""
          ) {
            this.formValidate.industrialDesignEnterprise.establishedTime = null;
          }

          let { messageCode } = await this.$http.post(
            "/shsj_declare/industralDesign/saveIndustralDesignInfo",
            this.formValidate
          );
          if (messageCode === 10000) {
            this.$Message.success("保存页面成功!");
            if (type == "next") {
              this.$router.push("/declare/member");
            }
          }
        } else {
          return this.$Message.error("内容格式不正确!");
        }
      });
    },
    /**取消保存
     * method cancel
     * */
    cancel() {
      this.$Message.info("取消操作");
      setTimeout(() => {
        if (this.$store.state.industryFromPage == "manage") {
          return this.$router.push("/manage");
        }
        return this.$router.push("/declare");
      }, 1000);
    },
    /**保存
     * method save
     * */
    async save(name) {
      await this.saveProgram(name, "save");
    },

    next(name) {
      this.saveProgram(name, "next");
    }
  }
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped></style>
