<template>
  <div class="personnel GL_declareForm Gl_input">
    <Form
      :rules="ruleValidate"
      class="form"
      ref="formValidate"
      :model="formValidate"
      :label-width="0"
    >
      <!-- 工业设计团队带头人及主要成员情况 -->
      <!-- 基本情况 -->
      <div
        class="forItem"
        :key="index"
        v-for="(item, index) in formValidate.foregoerMemberList"
      >
        <Crumbs
          style="padding:45px 0"
          :title="'设计创新团队带头人及主要成员情况 '"
        ></Crumbs>
        <Row>
          <Col span="24">基本情况</Col>
        </Row>
        <div class="iconBox">
          <Icon @click="add" class="icon" type="ios-add-circle-outline" />
          <Icon
            @click="reduce(index)"
            class="icon"
            type="ios-remove-circle-outline"
          />
        </div>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">
              <span class="redStar">*</span>姓名
            </Col>
            <Col class="m_col" span="8">
              <Input
                :maxlength="20"
                v-model.trim="item.peopleName"
                class="unit_input"
              />
            </Col>
            <Col class="m_col m_title" span="4">
              <span class="redStar">*</span>
              <span>性别</span>
            </Col>
            <Col class="m_col" span="8">
              <!-- <Input v-model="item.gender" class="unit_input" /> -->
              <RadioGroup size="large" v-model="item.gender">
                <Radio label="2">男</Radio>
                <Radio label="1">女</Radio>
              </RadioGroup>
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">
              <span class="redStar">*</span>出生年月
            </Col>
            <Col class="m_col" span="8">
              <!-- <Input v-model="item.birthDate" class="unit_input" /> -->
              <DatePicker
                placement="bottom-end"
                :value="item.dateOfBirth"
                @on-change="item.dateOfBirth = $event"
                type="month"
                placeholder="选择时间"
              ></DatePicker>
            </Col>
            <Col class="m_col m_title" span="4">
              <span class="redStar">*</span>
              <span>国籍</span>
            </Col>
            <Col class="m_col" span="8">
              <Input
                :maxlength="18"
                v-model.trim="item.nationality"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">
              <span class="redStar">*</span>公司职务
            </Col>
            <Col class="m_col" span="8">
              <Input
                :maxlength="20"
                v-model.trim="item.companyPost"
                class="unit_input"
              />
            </Col>
            <Col class="m_col m_title" span="4">
              <span class="redStar">*</span>
              <span>联系手机</span>
            </Col>
            <Col class="m_col" span="8">
              <FormItem
                :rules="ruleValidate.validateCellPhoneNumber"
                :prop="`foregoerMemberList[${index}].phone`"
              >
                <Input v-model="item.phone" class="unit_input" />
              </FormItem>
            </Col>
          </Row>
        </div>
        <!-- 职称学历 -->
        <Row>
          <Col span="24">职称学历</Col>
        </Row>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">
              <span class="redStar">*</span>专业职称
            </Col>
            <Col class="m_col" span="20">
              <Input
                :maxlength="20"
                v-model.trim="item.specialty"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">
              <span class="redStar">*</span>学历、学位 <br />（毕业院校及专业）
            </Col>
            <Col class="m_col" span="20">
              <Input
                :maxlength="20"
                v-model.trim="item.education"
                class="unit_input"
              />
            </Col>
          </Row>
        </div>
        <!-- 工作经历及成绩 -->
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col style="height: 120px" class="m_col m_title" span="4">
              <span class="redStar">*</span>工作经历及成绩
            </Col>
            <Col style="height: 120px" class="m_col" span="20">
              <Input
                :maxlength="1000"
                v-model="item.workExperience"
                @on-change="
                  item.workExperience = $util.ToDBC(item.workExperience, $event)
                "
                type="textarea"
                :autosize="{ minRows: 5, maxRows: 5 }"
                placeholder="从事设计创新领域的主要工作经历和取得的成绩"
              ></Input>
            </Col>
          </Row>
        </div>
      </div>
      <!-- 工业设计团队带头人及主要成员情况 -->
    </Form>
    <div class="foot">
      <button v-waves @click="cancel" class="GL_cancel">取消</button>
      <button v-waves @click="save('formValidate')" class="GL_save">
        保存
      </button>
      <button v-waves @click="next('formValidate')" class="GL_next">
        保存并跳转下一步
      </button>
    </div>
  </div>
</template>

<script>
import Crumbs from "../../components/crumbs";
import waves from "../../directive/waves";
export default {
  components: {
    Crumbs
  },
  directives: { waves },
  name: "personnel",
  props: {},
  data() {
    return {
      programId: null,
      countNum: 0,
      formValidate: {
        programId: null,
        pageStatus: 5,
        declareType: 3001,
        foregoerMemberList: [
          {
            peopleName: null,
            gender: null,
            dateOfBirth: null,
            nationality: null,
            companyPost: null,
            phone: null,
            specialty: null,
            education: null,
            workExperience: null,
            programType: 1
          },
          {
            peopleName: null,
            gender: null,
            dateOfBirth: null,
            nationality: null,
            companyPost: null,
            phone: null,
            specialty: null,
            education: null,
            workExperience: null,
            programType: 1
          }
        ]
      },
      ruleValidate: {
        validateCellPhoneNumber: [
          { validator: this.$util.validateCellPhoneNumber, trigger: "blur" }
        ]
      }
    };
  },
  created() {
    this.programId = this.$store.state.industryProgramId;
    this.formValidate.programId = this.$store.state.industryProgramId;
    this.queryPage();
  },

  computed: {},
  filters: {},
  mounted() {},
  methods: {
    /*初始化数据
     *method queryPage
     */
    async queryPage() {
      let res = await this.$http.post(
        "shsj_declare/industralDesign/getIndustralDesignInfo",
        {
          programId: this.programId,
          pageStatus: 5,
          declareType: 3001
        }
      );
      if (res.messageCode === 10000) {
        if (res.result.result != null && res.result.result.length > 0) {
          res.result.result.forEach(item => {});
          this.formValidate.foregoerMemberList = res.result.result;
        }
      }
    },

    /**减少人员
     * method reduce product
     * */
    reduce(index) {
      if (this.formValidate.foregoerMemberList.length > 1) {
        this.formValidate.foregoerMemberList.splice(index, 1);
        return this.$Message.success("删除成功");
      }
      this.$Message.error("删除失败，成员数量不得少于1人");
    },
    /**增加人员
     * method add product
     * */
    add() {
      if (this.formValidate.foregoerMemberList.length < 5) {
        this.formValidate.foregoerMemberList.push({
          peopleName: null,
          gender: null,
          dateOfBirth: null,
          nationality: null,
          companyPost: null,
          phone: null,
          specialty: null,
          education: null,
          workExperience: null,
          programType: 1
        });
        return this.$Message.success("添加成功");
      }
      this.$Message.error("添加失败，成员数量不得超过五人");
    },
    /*保存表单
     *method saveProgram
     **/
    saveProgram(name, type) {
      this.$refs[name].validate(async valid => {
        if (valid) {
          let { messageCode } = await this.$http.post(
            "shsj_declare/industralDesign/saveIndustralDesignInfo",
            this.formValidate
          );
          if (messageCode === 10000) {
            this.$Message.success("保存页面成功!");
            if (type == "next") {
              this.$router.push("/declare/AttachmentUpload");
            }
          }
        } else {
          this.$Message.error("内容格式不正确!");
        }
      });
    },
    /**取消保存
     * method cancel
     * */
    cancel() {
      this.$Message.info("取消操作");
      setTimeout(() => {
        if (this.$store.state.industryFromPage == "manage") {
          return this.$router.push("/manage");
        }
        return this.$router.push("/declare");
      }, 1000);
    },
    /**保存
     * method save
     * */
    save(name) {
      this.saveProgram(name, "save");
    },
    /**保存并下一步
     * method save and next
     * */
    next(name) {
      this.saveProgram(name, "next");
      // setTimeout(() => {
      //   this.$router.push("/declare/AttachmentUpload");
      // }, 1000);
    }
  }
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.personnel {
  .forItem {
    position: relative;
    .iconBox {
      // width: 50px;
      position: absolute;
      left: 310px;
      top: 45px;
      // right: 0;
      z-index: 888;
      .icon {
        padding: 0px 5px;
        color: #3385ff;
        font-size: 20px;
        font-weight: 700;
      }
    }
  }
}
</style>
