<template>
  <div class="GL_declareForm Gl_input">
    <Crumbs :title="'所在企业情况'"></Crumbs>
    <Form
      :rules="ruleValidate"
      class="form"
      ref="formValidate"
      :model="formValidate"
      :label-width="0"
    >
      <Row>
        <Col class="col_unit" span="24">单位：万元、平方米、个、%</Col>
      </Row>
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>申报单位名称
          </Col>
          <Col class="m_col" span="20">
            <Input
              :maxlength="50"
              v-model.trim="formValidate.companyInformation.companyName"
              class="unit_input"
            />
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>所属行业
          </Col>
          <Col class="m_col" span="20">
            <Select v-model="formValidate.companyInformation.industry">
              <Option
                :key="index"
                v-for="(item, index) in tradeList"
                :value="item.dataId"
                >{{ item.dataValue }}</Option
              >
            </Select>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>注册地址
          </Col>
          <Col class="m_col" span="20">
            <Input
              :maxlength="50"
              v-model.trim="formValidate.companyInformation.registeredAddress"
              class="unit_input"
            />
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>经营地址
          </Col>
          <Col class="m_col" span="20">
            <Input
              :maxlength="50"
              v-model.trim="formValidate.companyInformation.businessAddress"
              class="unit_input"
            />
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>社会信用代码
          </Col>
          <Col class="m_col" span="8">
            <Input
              :maxlength="50"
              v-model.trim="formValidate.companyInformation.socialCreditCode"
              class="unit_input"
            />
          </Col>
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>税务登记区
          </Col>
          <Col class="m_col" span="8">
            <Select v-model="formValidate.companyInformation.registeredArea">
              <Option
                v-for="item in areaList"
                :key="item.dataId"
                :value="item.dataId"
                >{{ item.dataValue }}</Option
              >
            </Select>
          </Col>
        </Row>
      </div>
      <!-- 基本情况 -->
      <Row>
        <Col span="24">基本情况</Col>
      </Row>
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4"
            ><span class="redStar">*</span>所有制性质</Col
          >
          <Col class="m_col" span="20">
            <div style="width:100%">
              <RadioGroup
                @on-change="radioChange"
                class="RadioGroup"
                size="large"
                v-model="
                  formValidate.companyInformation.unitRegistrationTypeParentId
                "
              >
                <Radio
                  :key="index"
                  v-for="(item, index) in formValidate.companyType"
                  :label="item.dictId"
                  >{{ item.dictName }}</Radio
                >
              </RadioGroup>
              <br />
              <div
                v-show="childrenList.length > 0"
                style="border-top:1px dashed #ccc;margin:5px 0"
              ></div>
              <RadioGroup
                v-show="childrenList.length > 0"
                class="RadioGroup"
                size="large"
                v-model="formValidate.companyInformation.allNature"
              >
                <Radio
                  :key="index2"
                  v-for="(item2, index2) in childrenList"
                  :label="item2.dataId"
                  >{{ item2.dataValue }}</Radio
                >
              </RadioGroup>
            </div>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>
            <span>职工人数</span>
          </Col>
          <Col class="m_col" span="20">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="companyInformation.numberOfEmployees"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.companyInformation.numberOfEmployees"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>资产总额
          </Col>
          <Col class="m_col" span="8">
            <FormItem
              :rules="ruleValidate.validateFloatingNumber"
              prop="companyInformation.totalAssets"
            >
              <Input
                :maxlength="13"
                v-model="formValidate.companyInformation.totalAssets"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>固定资产净值
          </Col>
          <Col class="m_col" span="8">
            <FormItem
              :rules="ruleValidate.validateFloatingNumber"
              prop="companyInformation.netWorth"
            >
              <Input
                :maxlength="13"
                v-model="formValidate.companyInformation.netWorth"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>资产负债率
          </Col>
          <Col class="m_col" span="8">
            <FormItem
              :rules="ruleValidate.validatePercentageThanZero"
              prop="companyInformation.assetLiabilityRatio"
            >
              <Input
                :maxlength="13"
                v-model="formValidate.companyInformation.assetLiabilityRatio"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>信用评级
          </Col>
          <Col class="m_col" span="8">
            <Input
              :maxlength="10"
              v-model="formValidate.companyInformation.bankCreditRating"
              class="unit_input"
            />
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">企业是否属于</Col>
          <Col class="m_col m_padding" span="20">
            <CheckboxGroup size="large" v-model="CheckboxArray">
              <Checkbox :label="1">上市企业</Checkbox>
              <Checkbox :label="2">高新技术企业</Checkbox>
              <Checkbox :label="4">专精特新企业</Checkbox>
            </CheckboxGroup>
          </Col>
        </Row>
      </div>
      <!-- 联系方式 -->
      <Row>
        <Col span="24">联系方式</Col>
      </Row>
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>人员
          </Col>
          <Col class="m_col m_title" span="5">
            <span class="redStar">*</span>姓名
          </Col>
          <Col class="m_col m_title" span="5">
            <span class="redStar">*</span>职务
          </Col>
          <Col class="m_col m_title" span="5">
            <span class="redStar">*</span>手机
          </Col>
          <Col class="m_col m_title" span="5">
            <span class="redStar">*</span>电子邮箱
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>企业负责人
          </Col>
          <Col class="m_col" span="5">
            <Input
              :maxlength="20"
              v-model.trim="formValidate.companyInformation.principalName"
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="5">
            <Input
              :maxlength="20"
              v-model.trim="formValidate.companyInformation.principalPost"
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="5">
            <FormItem
              :rules="ruleValidate.validateCellPhoneNumber"
              prop="companyInformation.principalContactWay"
            >
              <Input
                :maxlength="20"
                v-model="formValidate.companyInformation.principalContactWay"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="5">
            <FormItem
              :rules="ruleValidate.validateMailbox"
              prop="companyInformation.principalEmail"
            >
              <Input
                :maxlength="50"
                v-model="formValidate.companyInformation.principalEmail"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>申请联系人
          </Col>
          <Col class="m_col" span="5">
            <Input
              :maxlength="20"
              v-model.trim="formValidate.companyInformation.applyForName"
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="5">
            <Input
              :maxlength="20"
              v-model.trim="formValidate.companyInformation.applyForPost"
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="5">
            <FormItem
              :rules="ruleValidate.validateCellPhoneNumber"
              prop="companyInformation.applyForContactWy"
            >
              <Input
                :maxlength="20"
                v-model="formValidate.companyInformation.applyForContactWy"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="5">
            <FormItem
              :rules="ruleValidate.validateMailbox"
              prop="companyInformation.applyForContactEmail"
            >
              <Input
                :maxlength="50"
                v-model="formValidate.companyInformation.applyForContactEmail"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
      </div>
      <!-- 经营情况 -->
      <Row>
        <Col span="24">经营情况</Col>
      </Row>
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>近三年主要指标
          </Col>
          <Col class="m_col m_title" span="6">
            <span class="redStar">*</span>2021年
          </Col>
          <Col class="m_col m_title" span="6">
            <span class="redStar">*</span>2022年
          </Col>
          <Col class="m_col m_title" span="8">
            <span class="redStar">*</span>2023年前三季度
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>营业收入
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validateFloatingNumber"
              prop="companyInformation.operatingRevenueOne"
            >
              <Input
                :maxlength="13"
                v-model="formValidate.companyInformation.operatingRevenueOne"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validateFloatingNumber"
              prop="companyInformation.operatingRevenueTwo"
            >
              <Input
                :maxlength="13"
                v-model="formValidate.companyInformation.operatingRevenueTwo"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="8">
            <FormItem
              :rules="ruleValidate.validateFloatingNumber"
              prop="companyInformation.operatingRevenueThree"
            >
              <Input
                :maxlength="13"
                v-model="formValidate.companyInformation.operatingRevenueThree"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>利润总额
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validateFloatingNumber"
              prop="companyInformation.totalProfitOne"
            >
              <Input
                :maxlength="13"
                v-model="formValidate.companyInformation.totalProfitOne"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validateFloatingNumber"
              prop="companyInformation.totalProfitTwo"
            >
              <Input
                :maxlength="13"
                v-model="formValidate.companyInformation.totalProfitTwo"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="8">
            <FormItem
              :rules="ruleValidate.validateFloatingNumber"
              prop="companyInformation.totalProfitThree"
            >
              <Input
                :maxlength="13"
                v-model="formValidate.companyInformation.totalProfitThree"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>纳税总额
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validateFloatingNumber"
              prop="companyInformation.totalTaxAmountOne"
            >
              <Input
                :maxlength="13"
                v-model="formValidate.companyInformation.totalTaxAmountOne"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validateFloatingNumber"
              prop="companyInformation.totalTaxAmountTwo"
            >
              <Input
                :maxlength="13"
                v-model="formValidate.companyInformation.totalTaxAmountTwo"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="8">
            <FormItem
              :rules="ruleValidate.validateFloatingNumber"
              prop="companyInformation.totalTaxAmountThree"
            >
              <Input
                :maxlength="13"
                v-model="formValidate.companyInformation.totalTaxAmountThree"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>研发设计支出
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validateThanZero"
              prop="companyInformation.inTheForeheadOne"
            >
              <Input
                :maxlength="13"
                v-model="formValidate.companyInformation.inTheForeheadOne"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validateThanZero"
              prop="companyInformation.inTheForeheadTwo"
            >
              <Input
                :maxlength="13"
                v-model="formValidate.companyInformation.inTheForeheadTwo"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="8">
            <FormItem
              :rules="ruleValidate.validateThanZero"
              prop="companyInformation.inTheForeheadThree"
            >
              <Input
                :maxlength="13"
                v-model="formValidate.companyInformation.inTheForeheadThree"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>出口交货值
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validateFloatingNumber"
              prop="companyInformation.exportValueOne"
            >
              <Input
                :maxlength="13"
                v-model="formValidate.companyInformation.exportValueOne"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validateFloatingNumber"
              prop="companyInformation.exportValueTwo"
            >
              <Input
                :maxlength="13"
                v-model="formValidate.companyInformation.exportValueTwo"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="8">
            <FormItem
              :rules="ruleValidate.validateFloatingNumber"
              prop="companyInformation.exportValueThree"
            >
              <Input
                :maxlength="13"
                v-model="formValidate.companyInformation.exportValueThree"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
      </div>

      <!-- 主要产品 -->
      <Row>
        <Col span="24">主要产品情况</Col>
      </Row>
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title icon_col" span="6">
            <Icon @click="add" class="icon" type="ios-add-circle-outline" />
            <span class="text_col">
              <span class="redStar">*</span>产品名称
            </span>
          </Col>
          <Col class="m_col m_title" span="6">
            <span class="redStar">*</span>上年度产能
          </Col>
          <Col class="m_col m_title" span="6">
            <span class="redStar">*</span>上年度产量
          </Col>
          <Col class="m_col m_title" span="6">
            <span class="redStar">*</span>上年度销售额
          </Col>
        </Row>
        <Row
          v-for="(item, index) in formValidate.companyContacts"
          :key="index"
          class="m_row"
        >
          <Col class="m_col icon_col" span="6">
            <Icon
              @click="reduce(index)"
              class="icon"
              type="ios-remove-circle-outline"
            />
            <Input
              :maxlength="20"
              v-model.trim="item.productName"
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validateFloatingNumber"
              :prop="`companyContacts[${index}].capacity`"
            >
              <Input
                :maxlength="15"
                v-model="item.capacity"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validateFloatingNumber"
              :prop="`companyContacts[${index}].previousYearOutput`"
            >
              <Input
                :maxlength="15"
                v-model="item.previousYearOutput"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validateThanZero"
              :prop="`companyContacts[${index}].salesVolume`"
            >
              <Input
                :maxlength="13"
                v-model="item.salesVolume"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
      </div>
      <!-- 情况 -->
      <div class="m_item">
        <Row class="m_row">
          <Col class="m_col m_title" style="text-align: center" span="24">
            <span class="redStar">*</span>企业简介
          </Col>
        </Row>
        <Row class="m_row textarea_row">
          <Col span="24">
            <Input
              :maxlength="1000"
              v-model="formValidate.companyInformation.introduction"
              @on-change="
                formValidate.companyInformation.introduction = $util.ToDBC(
                  formValidate.companyInformation.introduction,
                  $event
                )
              "
              type="textarea"
              :autosize="{ minRows: 5, maxRows: 8 }"
              placeholder="企业基本情况、主要产品、设计理念、技术特点、品牌建设、知识产权应用及保护、企业文化、履行社会责任等方面的情况。"
            ></Input>
          </Col>
        </Row>
      </div>
    </Form>
    <div class="foot">
      <button v-waves @click="cancel" class="GL_cancel">取消</button>
      <button v-waves @click="save('formValidate')" class="GL_save">
        保存
      </button>
      <button v-waves @click="next('formValidate')" class="GL_next">
        保存并跳转下一步
      </button>
    </div>
  </div>
</template>

<script>
import Crumbs from "../../components/crumbs";
import waves from "../../directive/waves";
export default {
  components: {
    Crumbs
  },
  directives: { waves },
  name: "",
  props: {},
  data() {
    return {
      // 复选框数组
      CheckboxArray: [],
      childrenList: [],
      programId: null,
      // 区域列表
      areaList: [],
      // 所属行业列表
      tradeList: [],
      // countNum: 0,
      formValidate: {
        programId: null,
        pageStatus: 1,
        declareType: 3001,
        companyInformation: {
          // socialCreditCode: null, //社会信用代码
          // programId: null,
          // businessAddress: null, //企业经营地址
          // companyName: null, //企业名称
          // registeredArea: 8001, //税务登记区
          // industry: null, //所属行业
          // registeredAddress: null, //企业注册地址
          // unitRegistrationTypeParentId: null //企业注册类型
          // numberOfEmployees: null, //职工人数
          // totalAssets: null, //资产总额
          // netWorth: null, //固定资产净值
          // assetLiabilityRatio: null, //资产负债率
          // bankCreditRating: null, //银行信用等级
          // companyIsBelong: null, //企业是否属于（1：上市企业 2：高新技术企业）
          // principalName: null, //负责人姓名
          // principalPost: null, //负责人职务
          // principalContactWay: null, //负责人电话
          // applyForName: null, //联系人姓名
          // applyForPost: null, //联系人职务
          // applyForContactWy: null, //联系人电话
          // operatingRevenue: null, //营业收入
          // totalTaxOfInterest: null, //利税总额
          // totalProfit: null, //利润总额
          // exportValue: null, //出口交货值
          // inTheForeheadOne: null, //近三年投入额第一年
          // inTheForeheadTwo: null, //近三年投入额第二年
          // inTheForeheadThree: null, //近三年投入额第三年
          // growthRateOne: null, //增长率第一年
          // growthRateTwo: null, //增长率第二年
          // growthRateThree: null, //增长率第三年
          // patentLicensingCount: null, //专利授权数量
          // copyrightAuthorizationCount: null, //版权授权数量
          // remark: null, //备注
          // inventPatentMandate: null, //发明专利授权数
          // inventCopyrightMandate: null, //发明版权授权数
          // inventPatentRemark: null,
          // copyrightApplication: null, //发明专利授权数
          // copyrightAuthorization: null, //发明版权授权数
          // copyrightRemark: null,
          // companyTheConstruction: null,
          // scienceCondition: null,
          // standardConditin: null,
          // qualityTheConstruction: null,
          // projectCase: null,
          // dutyCondition: null,
          // planCondition: null
        },
        companyContacts: [
          {
            productName: null, //产品名称
            capacity: null, //产能
            previousYearOutput: null, //上年度产量
            salesVolume: null //上年度销售额
          }
        ]
      },
      ruleValidate: {
        validatePositiveInteger: [
          // 验证大于或等于零的整数
          {
            validator: this.$util.validatePositiveInteger,
            trigger: "blur"
          }
        ],
        validateFloatingNumber: [
          // 验证可以为负数的浮点数
          { validator: this.$util.validateFloatingNumber, trigger: "blur" }
        ],
        validatePercentageThanZero: [
          // 验证大于等于0的所有数，小数点后保留两位小数5位以内
          { validator: this.$util.validatePercentageThanZero, trigger: "blur" }
        ],
        validateCellPhoneNumber: [
          // 验证固话和手机号
          { validator: this.$util.validateCellPhoneNumber, trigger: "blur" }
        ],
        validateMailbox: [
          { validator: this.$util.validateMailbox, trigger: "blur" }
        ],
        validateThanZero: [
          { validator: this.$util.validateThanZero, trigger: "blur" }
        ],
        validateThanZeroFifteen: [
          { validator: this.$util.validateThanZeroFifteen, trigger: "blur" }
        ],
        validatePercentage: [
          { validator: this.$util.validatePercentage, trigger: "blur" }
        ]
      }
    };
  },
  beforeRouteEnter(to, from, next) {
    if (from.path == "/declare") {
      next(vm => {
        vm.$store.commit("getWhereFrom", "declare");
        if (vm.$route.query.specialId) {
          vm.$store.commit("getIndustrySpecialId", vm.$route.query.specialId);
        }
        if (vm.$route.query.programId == null) {
          vm.getProgramId();
        } else {
          vm.$store.commit("getIndustryProgramId", vm.$route.query.programId);
          vm.queryPage();
        }
      });
    } else if (from.path == "/manage") {
      next(vm => {
        vm.$store.commit("getWhereFrom", "manage");
        if (vm.$route.query.specialId) {
          vm.$store.commit("getIndustrySpecialId", vm.$route.query.specialId);
        }
        if (vm.$route.query.programId) {
          vm.$store.commit("getIndustryProgramId", vm.$route.query.programId);
        }
        vm.queryPage();
      });
    } else {
      next(vm => {
        vm.queryPage();
      });
    }
  },
  created() {
    // 获取区域列表
    this.getAreaList();
    this.getRradeList();
    this.formValidate.programId = this.$store.state.industryProgramId;
    this.formValidate.companyInformation.programId = this.$store.state.industryProgramId;
  },
  mounted() {},
  methods: {
    /*在没有id的情况下获取项目id
     *method queryPage
     */
    async getProgramId() {
      let res = await this.$http.post(
        "shsj_declare/industralDesign/saveProjectMain",
        {
          programName: null,
          specialId: this.$store.state.industrySpecialId,
          declareType: 3001
        }
      );
      this.$store.commit("getIndustryProgramId", res.result.programId);
      this.queryPage();
    },

    /*初始化数据
     *method queryPage
     */
    async queryPage() {
      let res = await this.$http.post(
        "shsj_declare/industralDesign/getIndustralDesignInfo",
        {
          programId: this.$store.state.industryProgramId,
          pageStatus: 1,
          declareType: 3001
        }
      );
      if (res.messageCode === 10000) {
        if (res.result.result != null) {
          this.formValidate.companyInformation =
            res.result.result.companyInformation;
          if (this.formValidate.companyInformation.companyName == null) {
            this.formValidate.companyInformation.companyName =
              localStorage.companyName;
          }

          this.formValidate.companyContacts =
            res.result.result.companyInformation.companyContacts;
          this.formValidate.companyInformation.unitRegistrationTypeParentId =
            this.formValidate.companyInformation.unitRegistrationTypeParentId -
            0;
          this.formValidate.companyInformation.allNature =
            this.formValidate.companyInformation.allNature - 0;
          this.formValidate.companyType = res.result.result.companyType;
          let index = this.formValidate.companyType.findIndex(
            item =>
              item.dictId ==
              this.formValidate.companyInformation.unitRegistrationTypeParentId
          );
          if (index == -1) {
            this.childrenList = [];
          } else {
            this.childrenList = this.formValidate.companyType[
              index
            ].sysDictDataList;
          }
          // 多选框
          if (this.formValidate.companyInformation.companyIsBelong == 1) {
            this.CheckboxArray = [1];
          } else if (
            this.formValidate.companyInformation.companyIsBelong == 2
          ) {
            this.CheckboxArray = [2];
          } else if (
            this.formValidate.companyInformation.companyIsBelong == 3
          ) {
            this.CheckboxArray = [1, 2];
          } else if (
            this.formValidate.companyInformation.companyIsBelong == 4
          ) {
            this.CheckboxArray = [4];
          } else if (
            this.formValidate.companyInformation.companyIsBelong == 5
          ) {
            this.CheckboxArray = [1, 4];
          } else if (
            this.formValidate.companyInformation.companyIsBelong == 6
          ) {
            this.CheckboxArray = [2, 4];
          } else if (
            this.formValidate.companyInformation.companyIsBelong == 7
          ) {
            this.CheckboxArray = [1, 2, 4];
          }
        }
      }
    },
    /****/
    radioChange(e) {
      let list = this.formValidate.companyType.filter(item => item.dictId == e);
      if (list.length > 0) {
        this.childrenList = list[0].sysDictDataList;
        this.formValidate.companyInformation.allNature = null;
      }
    },
    /*
     *method getRradeList
     获取行业列表
     */
    async getRradeList() {
      let { result } = await this.$http.get(
        "shsj_declare/sysDict/queryDictDataList",
        {
          dictId: 4000
        }
      );
      this.tradeList = result.dictDataList;
    },
    /*获取区域列表
     *method getAreaList
     */
    async getAreaList() {
      let { result } = await this.$http.get(
        "shsj_declare/sysDict/queryDictDataList",
        {
          dictId: 8000
        }
      );
      this.areaList = result.dictDataList;
      let shanghaiIndex = this.areaList.findIndex(
        item => item.dataValue == "上海市"
      );
      this.areaList.splice(shanghaiIndex, 1);
    },
    /**减少产品
     * method reduce product
     * */
    reduce(index) {
      if (this.formValidate.companyContacts.length > 1) {
        return this.formValidate.companyContacts.splice(index, 1);
      }
      this.$Message.error("最少一条");
    },
    /**增加产品
     * method add product
     * */
    add() {
      this.formValidate.companyContacts.push({
        productName: "",
        capacity: "",
        previousYearOutput: "",
        salesVolume: null //上年度销售额
      });
    },
    /*保存表单
     *method saveProgram
     **/
    saveProgram(name, type) {
      this.$refs[name].validate(async valid => {
        if (valid) {
          if (this.CheckboxArray.length > 0) {
            this.formValidate.companyInformation.companyIsBelong = this.CheckboxArray.reduce(
              (result, item) => result + item
            );
          } else {
            this.formValidate.companyInformation.companyIsBelong = null;
          }
          this.formValidate.programId = this.$store.state.industryProgramId;
          let { messageCode } = await this.$http.post(
            "/shsj_declare/industralDesign/saveIndustralDesignInfo",
            this.formValidate
          );
          if (messageCode === 10000) {
            this.$Message.success("保存页面成功!");
            if (type == "next") {
              this.$router.push("/declare/centersitutation");
            }
          }
        } else {
          this.$Message.error("内容格式不正确!");
        }
      });
    },
    /**取消保存
     * method cancel
     * */
    cancel() {
      this.$Message.info("取消操作");
      setTimeout(() => {
        if (this.$store.state.industryFromPage == "manage") {
          return this.$router.push("/manage");
        }
        return this.$router.push("/declare");
      }, 1000);
    },
    /**保存
     * method save
     * */
    save(name) {
      this.saveProgram(name, "save");
    },
    /**保存并下一步
     * method save and next
     * */
    next(name) {
      this.saveProgram(name, "next");
    }
  }
};
</script>

<style lang="scss" scoped>
.radiolist {
  width: 100px;
  margin-right: 4px;
  margin-left: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

::v-deep .ivu-select-dropdown {
  max-height: 150px;
}
</style>
