import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "babel-polyfill";

// 引用一些常用的JavaScript function
import Functions from "./util/functions";
Vue.prototype.$util = Functions;

// http请求二次封装，处理token
import Http from "./util/http";
Vue.prototype.$http = Http;

// iview
import iView from "iview";
import "iview/dist/styles/iview.css";
Vue.use(iView);

import VueQuillEditor from "vue-quill-editor";
// require styles
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
Vue.use(VueQuillEditor);

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount("#app");
