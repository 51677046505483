<template>
  <div class="declare">
    <Crumbs :title="$route.meta.title"></Crumbs>
    <div class="content">
      <ul class="content_ul">
        <li v-for="(item, index) in list" :key="index">
          <div class="li_box">
            <div>
              <a style="cursor:default;" href.prevent>
                <img :src="item.src" alt />
              </a>
            </div>
            <div class="content_ws">
              <p style="height:21px" v-if="item.specialType == 1002">
                市级设计创新中心
              </p>
              <p style="height:21px" v-else>{{ item.specialTypeValue }}</p>
              <div class="downLink">
                <a
                  :style="
                    item2.guideFileName == '123' && item2.guideFilePath == '123'
                      ? 'visibility:hidden'
                      : ''
                  "
                  :key="index2"
                  v-for="(item2, index2) in item.manageSpecialGuideFileList"
                  :title="'点击下载：' + item2.guideFileName"
                  @click="downLoad(item2.guideFilePath)"
                  class="aLink"
                  href="#"
                  >{{ item2.guideFileName }}</a
                >
              </div>
              <div>
                {{
                  item.specialStartTime.substring(
                    0,
                    item.specialStartTime.length - 3
                  )
                }}
                -
                {{
                  item.specialEndTime.substring(
                    0,
                    item.specialEndTime.length - 3
                  )
                }}
              </div>
              <div class="btnBox" style="text-align: center;">
                <Button
                  class="btnLink"
                  @click="goProgram(item)"
                  :disabled="item.wasShow == 0"
                  :title="
                    item.wasShow == 0 && item.comment == '去申报'
                      ? '已申报，一年内不得重复提交'
                      : ''
                  "
                  >{{ item.comment }}</Button
                >
                <span
                  style="padding-left: 10px;"
                  v-if="
                    item.specialType == 1002 &&
                      item.comment !== '该项目已截止申报,请期待明年'
                  "
                >
                  <Button
                    class="btnLink"
                    @click="goProgram(item)"
                    :disabled="item.wasShow == 0"
                    >去复核</Button
                  >
                </span>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <!-- <div class="note">
        【重要通知】：上海设计100+网上申报3月21日截止，纸质材料4月5日之前快递。
      </div>-->
    </div>
  </div>
</template>

<script>
import Crumbs from "../../components/crumbs";
export default {
  components: {
    Crumbs
  },
  name: "",
  props: {},
  data() {
    return {
      list: [],
      linknum: 1
    };
  },
  created() {
    // this.getList();
  },
  mounted() {
    this.getList();
  },
  methods: {
    /*获取专项列表
     **method getList
     */
    async getList() {
      let res = await this.$http.get(
        "shsj_declare/program/querytManageSpecial"
      );
      this.list = res.result.programApplyListDtos.map(item => {
        switch (item.specialType) {
          case 1001:
            item["src"] = require("../../assets/special0.png");
            // item["path"] = `/shanghaidesign`;
            // item["specialStartTime"] = "2021/12/29 09:00:00";
            // item["specialEndTime"] = "2022/03/25 24:00:00";
            item["path"] = `/shanghaidesign2024`; // 2024年申报
            return item;
          // break;
          case 1002:
            item["src"] = require("../../assets/special1.png");
            if (item["declareType"] == 3002) {
              item["path"] = `/declare/industrialdesign`;
            } else {
              item["path"] = `/declare/companysituation`;
            }

            return item;
          // break;
          case 1003:
            item["src"] = require("../../assets/special2.png");
            item["path"] = `/modecompany`;
            return item;
          // break;
        }
      });
      let arr = [];
      this.list.forEach(item => {
        arr.push(item.manageSpecialGuideFileList.length);
      });

      let result = [];
      for (var i = 0; i < arr.length; i += 3) {
        result.push(arr.slice(i, i + 3));
      }
      for (let k of result) {
        for (let i = 0; i < k.length; i++) {
          k[i] = Math.max(...k);
        }
      }
      let newArray = [];
      for (let k of result) {
        newArray.push(...k);
      }
      this.list.forEach((item, index) => {
        item["linkNum"] = newArray[index];
        if (item.manageSpecialGuideFileList.length != item.linkNum) {
          let num = item.linkNum - item.manageSpecialGuideFileList.length;
          for (let i = 0; i < num; i++) {
            item.manageSpecialGuideFileList.push({
              guideFileName: "123",
              guideFilePath: "123"
            });
          }
        }
      });
    },
    /*点击下载
     **method download
     */
    downLoad(path) {
      window.open(path);
    },
    goProgram(item) {
      this.$router.push({
        path: item.path,
        query: {
          specialId: item.specialId,
          programId: item.programId
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.declare {
  .downLink {
    min-height: 22px;
  }
  .ivu-btn {
    padding: 0px;
    border-radius: 14px;
  }
  .btnLink {
    padding: 7px 17px 8px;
  }
  .content {
    .btnBox {
      text-align: center;
      margin: 20px auto;
    }
    padding: 0 20px;
    .content_ul {
      overflow: hidden;
      width: 100%;
      li {
        float: left;
        width: 33%;
        padding: 10px;
        .li_box {
          transition: all 0.4s ease-in;
          overflow: hidden;
          border: 1px solid #eee;
          border-radius: 10px;
          img {
            width: 100%;
            height: 240px;
          }
          .content_ws {
            padding: 10px 15px;
            .aLink {
              display: block;
              margin: 5px 0;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          &:hover {
            box-shadow: 0px 2px 15px 5px rgba(0, 0, 0, 0.1);
            // transform: translateY(-7px);
            transition: all 0.4s ease-in-out;
          }
        }
      }
    }
  }
}
.note {
  text-align: center;
  color: red;
}
</style>
