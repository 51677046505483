var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Status GL_declareForm Gl_input"},[_c('Crumbs',{attrs:{"title":'现状及规划 '}}),_c('Form',{ref:"formValidate",staticClass:"form",attrs:{"model":_vm.formValidate,"label-width":80}},[_c('Row',[_c('Col',{attrs:{"span":"24"}},[_vm._v("设计成果获奖情况")])],1),_c('div',{staticClass:"m_item"},[_c('Row',{staticClass:"m_row",attrs:{"type":"flex"}},[_c('Col',{staticClass:"m_col m_title",staticStyle:{"text-align":"center"},attrs:{"span":"24"}},[_c('span',{staticClass:"redStar"},[_vm._v("*")]),_vm._v("企业运营等有关情况 ")])],1),_c('Row',{staticClass:"m_row textarea_row"},[_c('Col',{attrs:{"span":"24"}},[_c('Input',{attrs:{"maxlength":800,"type":"textarea","autosize":{ minRows: 5, maxRows: 8 },"placeholder":"重点是企业现在的组织体系、运营模式、产学研合作及专业人员培训及设计创新社会化服务等有关情况(800字以内)"},on:{"on-change":function($event){_vm.formValidate.statusQuoPlan.operationInfo = _vm.$util.ToDBC(
                _vm.formValidate.statusQuoPlan.operationInfo,
                $event
              )}},model:{value:(_vm.formValidate.statusQuoPlan.operationInfo),callback:function ($$v) {_vm.$set(_vm.formValidate.statusQuoPlan, "operationInfo", $$v)},expression:"formValidate.statusQuoPlan.operationInfo"}})],1)],1)],1),_c('div',{staticClass:"m_item"},[_c('Row',{staticClass:"m_row",attrs:{"type":"flex"}},[_c('Col',{staticClass:"m_col m_title",staticStyle:{"text-align":"center"},attrs:{"span":"24"}},[_c('span',{staticClass:"redStar"},[_vm._v("*")]),_vm._v("企业参与国家、本市重要设计类活动情况 ")])],1),_c('Row',{staticClass:"m_row textarea_row"},[_c('Col',{attrs:{"span":"24"}},[_c('Input',{attrs:{"maxlength":800,"type":"textarea","autosize":{ minRows: 5, maxRows: 8 },"placeholder":"(800字以内)"},on:{"on-change":function($event){_vm.formValidate.statusQuoPlan.activitySituation = _vm.$util.ToDBC(
                _vm.formValidate.statusQuoPlan.activitySituation,
                $event
              )}},model:{value:(_vm.formValidate.statusQuoPlan.activitySituation),callback:function ($$v) {_vm.$set(_vm.formValidate.statusQuoPlan, "activitySituation", $$v)},expression:"formValidate.statusQuoPlan.activitySituation"}})],1)],1)],1),_c('div',{staticClass:"m_item"},[_c('Row',{staticClass:"m_row",attrs:{"type":"flex"}},[_c('Col',{staticClass:"m_col m_title",staticStyle:{"text-align":"center"},attrs:{"span":"24"}},[_c('span',{staticClass:"redStar"},[_vm._v("*")]),_vm._v("今后两年目标与规划情况 ")])],1),_c('Row',{staticClass:"m_row textarea_row"},[_c('Col',{attrs:{"span":"24"}},[_c('Input',{attrs:{"maxlength":800,"type":"textarea","autosize":{ minRows: 5, maxRows: 8 },"placeholder":"重点是企业今后两年创新建设、有效投入、设计成果等主要指标，以及组织体系建设、运营模式创新、人才队伍建设、履行设计创新公共服务平台职责等规划和措施等情况(800字以内)"},on:{"on-change":function($event){_vm.formValidate.statusQuoPlan.targetAndPlan = _vm.$util.ToDBC(
                _vm.formValidate.statusQuoPlan.targetAndPlan,
                $event
              )}},model:{value:(_vm.formValidate.statusQuoPlan.targetAndPlan),callback:function ($$v) {_vm.$set(_vm.formValidate.statusQuoPlan, "targetAndPlan", $$v)},expression:"formValidate.statusQuoPlan.targetAndPlan"}})],1)],1)],1)],1),_c('div',{staticClass:"foot"},[_c('button',{directives:[{name:"waves",rawName:"v-waves"}],staticClass:"GL_cancel",on:{"click":_vm.cancel}},[_vm._v("取消")]),_c('button',{directives:[{name:"waves",rawName:"v-waves"}],staticClass:"GL_save",on:{"click":function($event){return _vm.save('save')}}},[_vm._v("保存")]),_c('button',{directives:[{name:"waves",rawName:"v-waves"}],staticClass:"GL_next",on:{"click":_vm.next}},[_vm._v("保存并跳转下一步")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }