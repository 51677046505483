<template>
    <div class="receiveAdvice">
        <div class="receiveAdvice-top">
            <div class="blue-div"></div>
            <span style="margin-left: 10px;">收到建议</span>
        </div>
        <div class="sky-white"></div>
        <div class="receiveAdvice-content">
            <Table :columns="columns" :data="data">
                <template slot-scope="{ row, index }" slot="action">
                    <Button @click="show(row)">查看</Button>
                    <Button style="margin-left: 5px" @click="remove(row)">删除</Button>
                </template>
            </Table>
            <div class="m-page">
                <myPage :page="page" @onchange="onPage"></myPage>
            </div>
        </div>
        <Modal
                width="360"
                title="删除"
                v-model="isModel"
                :mask-closable="false"
                @on-ok="okDel"
                @on-cancel="cancelDel">
            <p style="padding-top:10px;height:50px;font-size:16px;text-align: center">是否删除该张图片？</p>
        </Modal>
    </div>
</template>
<script>
    import myPage from "../../components/page"
    export default {
        name: "",
        props:{

        },
        data(){
            return {
                isModel:false,
                adviseId:null,
                page:{
                    "total":0,
                    "pageSize":10,
                },
                postList:{
                    "programId":null,
                    "page":1,
                    "pageSize":10,
                },
                data:[],
                columns:[
                    {
                        title: '序号',
                        key: 'numId',
                        align: 'center'
                    },
                    {
                        title: '姓名',
                        key: 'name',
                        align: 'center'
                    },
                    {
                        title: '标题',
                        key: 'title',
                        align: 'center'
                    },
                    {
                        title: '填写日期',
                        key: 'createTime',
                        align: 'center'
                    },
                    {
                        title: '操作',
                        slot: 'action',
                        align: 'center'
                    }
                ]
            }
        },
        components: {
            myPage
        },
        computed:{
            getProgramId(){
                return this.$route.query.programId
            },
        },
        created(){
            this.init()
        },
        methods:{
            /**
             * 查询初始化数据
             * */
            init(){
                this.postList.programId = this.getProgramId;
                this.$http.post("/shsj_declare/hpDeclare/queryProgramAdviseList",this.postList).then((res)=>{
                    if(res.messageCode == 10000){
                        this.data = res.result.hpProgramAdviseInfoList.list;
                        this.page.total=res.result.hpProgramAdviseInfoList.total;
                        for(let i=0;i<this.data.length;i++){
                            this.data[i].numId = i
                        }
                    }
                })
            },
            show(row){
                this.$router.push({
                    path: "/receiveAdviceShow",
                    query: {
                        type: "show",
                        adviseId: row.adviseId,
                    }
                });
            },
            remove(row){
                this.isModel = true;
                this.adviseId = row.adviseId

            },
            okDel(){
                this.isModel = false;
                this.$http.get("/shsj_declare/hpDeclare/removeProgramAdvise?adviseId="+this.adviseId).then((res)=>{
                    if(res.messageCode == 10000){
                        this.$Message.success(res.messageContent)
                    }
                })
            },
            cancelDel(){
                this.isModel = false;
            },
            onPage(page){
                this.postList.page=page;
                this.init()
            }
        }
    }
</script>
<style scoped>
    .receiveAdvice{
        width: 100vm;
        height: 100vh;
        /*background-color: #f5f5f5;*/
    }
    .receiveAdvice-top{
        height: 60px;
        background-color: white;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 16px;
        padding-left: 40px;
    }
    .sky-white{
        height: 10px;
        background-color: #f5f5f5;
    }
    .blue-div{
        height: 25px;
        width: 5px;
        background-color: #3385ff;
    }
    .receiveAdvice-content{
        padding-top: 20px;
        background-color: white;
    }
    .m-page{
        float: right;
        margin-top: 10px;
    }
</style>   