<template>
  <div class="shangHaiDesignAuit">
    <div class="designOne">
      <div class="design-title-one">一、单位基本情况</div>
      <Row class="content-row">
        <Col class="key" span="4">单位名称</Col>
        <Col class="value" span="8">{{ postList.hpUnitBase.unitName }}</Col>
        <Col class="key" span="4">税务登记区</Col>
        <Col class="value" span="8">
          <Select
            style="text-align: center"
            disabled
            v-model="postList.hpUnitBase.taxRegistrationArea"
          >
            <Option
              :key="index"
              v-for="(item, index) in specialAreaLists"
              :value="item.dataId"
              >{{ item.dataValue }}</Option
            >
          </Select>
        </Col>
        <Col class="key" span="4">社会信用代码</Col>
        <Col class="value" span="8">{{ postList.hpUnitBase.unitCode }}</Col>
        <Col class="key" span="4">电子邮箱</Col>
        <Col class="value" span="8">{{ postList.hpUnitBase.email }}</Col>
        <Col class="key" span="4">单位类型</Col>
        <Col class="value" span="8">{{
          postList.hpUnitBase.declareUnitType
        }}</Col>
        <Col class="key" span="4">通讯地址</Col>
        <Col class="value" span="8">{{
          postList.hpUnitBase.mailingAddress
        }}</Col>
        <Col class="key" span="4">邮编</Col>
        <Col class="value" span="8">{{ postList.hpUnitBase.postcode }}</Col>
        <Col class="key" span="4">单位负责人</Col>
        <Col class="value" span="8">{{ postList.hpUnitBase.legalPerson }}</Col>
        <Col class="key" span="4">负责人移动电话</Col>
        <Col class="value" span="8">{{ postList.hpUnitBase.mobilePhone }}</Col>
        <Col class="key" span="4">申报联系人</Col>
        <Col class="value" span="8">{{ postList.hpUnitBase.contentName }}</Col>
        <Col class="key" span="4">部门职务</Col>
        <Col class="value" span="8">{{ postList.hpUnitBase.department }}</Col>
        <Col class="key" span="4">联系人移动电话</Col>
        <Col class="value" span="8">{{ postList.hpUnitBase.mobile }}</Col>
        <!-- <Col class="key" span="4"></Col>
        <Col class="value" span="8"></Col> -->
      </Row>
      <div class="design-title-two">申报单位简介（限500字内）</div>
      <div class="design-content">
        <textarea
          readonly="readonly"
          v-model="postList.hpUnitBase.unitIntroduce"
          style="border: 0;width: 100%"
          name
        ></textarea>
      </div>
    </div>
    <div class="video-sky"></div>
    <div class="designTwo">
      <div class="design-title-one">
        二、申报作品资料
        <span>（没有可填“无”）</span>
      </div>
      <Row class="content-row">
        <Col class="key" span="4">作品名称</Col>
        <Col class="value" span="20">{{ postList.hpDeclareData.dataName }}</Col>
        <!-- <Col class="key" span="4">申报类别</Col> -->
        <Col class="key" span="4">申报领域</Col>
        <Col class="value" span="8">
          {{ postList.hpDeclareData.declareSecondTypeValue }}
        </Col>
        <Col class="key" span="4">具体类目</Col>
        <Col class="value" span="8">
          {{ postList.hpDeclareData.industryInvolvedValue }}
        </Col>
        <Col class="key" span="4">设计完成时间</Col>
        <Col class="value" span="8">{{
          postList.hpDeclareData.designFinishTime
        }}</Col>
        <Col class="key" span="4">是否已申请专利</Col>
        <Col class="value" span="8">{{ postList.hpDeclareData.hasPatent }}</Col>
        <Col class="key1" span="4">产品上市/案例落地/事件发生时间</Col>
        <Col class="value" span="8">{{
          postList.hpDeclareData.eventsTime
        }}</Col>
        <Col class="key" span="4">是否已登记版权</Col>
        <Col class="value" span="8">{{
          postList.hpDeclareData.hasRegisterCopyright
        }}</Col>
        <Col class="key" span="4">已实现销售额</Col>
        <Col class="value" span="8">{{ postList.hpDeclareData.hasSales }}</Col>
        <Col class="key" span="4">生命周期内预期总销售额</Col>
        <Col class="value" span="8">{{ postList.hpDeclareData.wantSales }}</Col>
        <Col class="key" span="4">设计者/团队成员</Col>
        <Col class="value" span="8">{{
          postList.hpDeclareData.memberName
        }}</Col>
        <Col class="key" span="4">移动电话</Col>
        <Col class="value" span="8">{{
          postList.hpDeclareData.contentPhone
        }}</Col>
        <Col class="key" span="4">职务/职称</Col>
        <Col class="value" span="8">{{ postList.hpDeclareData.position }}</Col>
        <Col class="key" span="4">申报类型</Col>
        <Col class="value" span="8">
          {{ postList.hpDeclareData.declareTypeValue }}
        </Col>
      </Row>
      <div class="design-title-two">设计说明（限500字内）</div>
      <div class="design-content">
        <textarea
          readonly="readonly"
          v-model="postList.hpDeclareData.designExplain"
          style="border: 0;width: 100%"
          name
        ></textarea>
      </div>
      <div class="design-title-two">设计价值（限500字内）</div>
      <div class="design-title-two">(一) 市场价值</div>
      <div class="design-content">
        <textarea
          readonly="readonly"
          v-model="postList.hpDeclareData.marketValue"
          style="border: 0;width: 100%"
          name
        ></textarea>
      </div>
      <div class="design-title-two">(二) 社会价值</div>
      <div class="design-content">
        <textarea
          readonly="readonly"
          v-model="postList.hpDeclareData.socialValue"
          style="border: 0;width: 100%"
          name
        ></textarea>
      </div>
      <div
        v-show="postList.hpDeclareData.recommendUnitAdvise"
        class="design-title-two"
      >
        推荐单位意见（非必须）
      </div>
      <div
        v-show="postList.hpDeclareData.recommendUnitAdvise"
        class="design-content"
      >
        <Row class="content-row" style="margin-top: 0">
          <Col class="key" span="4">
            推荐单位
          </Col>
          <Col class="value" span="20">
            {{ postList.hpDeclareData.unitAdvise }}
          </Col>
        </Row>
        <textarea
          readonly="readonly"
          v-model="postList.hpDeclareData.recommendUnitAdvise"
          style="border: 0;width: 100%"
          name
        ></textarea>
      </div>
    </div>
    <div class="video-sky"></div>
    <div class="designTwo">
      <div class="design-title-one">
        三、申报作品原图
        <span>（3-6张）</span>
      </div>
      <div class="originalImage">
        <div v-for="(item, index) in postList.hpOriginalImageList">
          <a
            :href="item.originalImagePath"
            download=""
            :alt="item.originalImageName"
            >{{ item.originalImageName }}</a
          >
        </div>
      </div>
      <div class="design-title-one">
        申报作品封面展示图
      </div>
      <div class="imgStyle">
        <div>
          <img
            style="width: 280px;height: 200px;object-fit: scale-down;"
            :src="postList.hpCoverImage.imagePath"
            :alt="postList.hpCoverImage.imageName"
          />
        </div>
      </div>
      <div class="design-title-one">
        申报作品代表性展示图
        <span>（3张）</span>
      </div>
      <div class="imgStyle">
        <div v-for="(item, index) in postList.hpResultsImagesList">
          <img
            style="width: 280px;height: 200px;object-fit: scale-down;"
            :src="item.imagePath"
            :alt="item.imageName"
          />
        </div>
      </div>
      <div v-show="postList.hpVideoList.length" class="design-title-one">
        本地上传视频
      </div>
      <div v-show="postList.hpVideoList.length" class="originalImage">
        <div>
          <a
            v-if="postList.hpVideoList.length"
            :href="postList.hpVideoList[0].videoPath"
            :alt="postList.hpVideoList[0].videoName"
            target="_blank"
          >
            {{ postList.hpVideoList[0].videoName }}
          </a>
          <!--          <video-->
          <!--                  v-if="postList.hpVideoList.length"-->
          <!--                  controls="controls"-->
          <!--                  width="100%"-->
          <!--                  height="100%"-->
          <!--                  :src="postList.hpVideoList[0].videoPath"></video>-->
        </div>
      </div>
      <div
        v-show="postList.hpProgramVideosList.length"
        class="design-title-one"
      >
        视频库上传视频
      </div>
      <div v-show="postList.hpProgramVideosList.length" class="originalImage">
        <div>
          <a
            v-if="postList.hpProgramVideosList.length"
            :href="postList.hpProgramVideosList[0].filePath"
            :alt="postList.hpProgramVideosList[0].fileName"
            target="_blank"
          >
            {{ postList.hpProgramVideosList[0].fileName }}
          </a>
          <!--          <video-->
          <!--                  v-if="postList.hpProgramVideosList.length"-->
          <!--                  controls="controls"-->
          <!--                  width="100%"-->
          <!--                  height="100%"-->
          <!--                  :src="postList.hpProgramVideosList[0].filePath"></video>-->
        </div>
      </div>
      <div class="video-sky"></div>
      <!--      <div class="designTwo">-->
      <!--        <div class="design-title-one">-->
      <!--          四、加报年度设计企业/年度设计人物（非必须）-->
      <!--        </div>-->
      <!--        <Row class="content-row">-->
      <!--          <Col class="key2" span="4"-->
      <!--            ><div style="margin-top: 10px">申报类别</div></Col-->
      <!--          >-->
      <!--          <Col class="value1" span="20">-->
      <!--            <div style="margin-left: 30px;">-->
      <!--              <input disabled :checked="isShow1" type="checkbox" />年度设计企业-->
      <!--            </div>-->
      <!--            &lt;!&ndash;<span>：姓名 <input style="width: 30%" type="text"> 职务 <input style="width: 30%" type="text"></span>&ndash;&gt;-->
      <!--          </Col>-->
      <!--        </Row>-->
      <!--        <div class="design-title-two">事 迹 说 明</div>-->
      <!--        <div class="design-content">-->
      <!--          <textarea-->
      <!--            v-model="postList.hpAddDeclare.unitDeedsShow"-->
      <!--            readonly="readonly"-->
      <!--            style="border: 0;width: 100%"-->
      <!--            name-->
      <!--          ></textarea>-->
      <!--        </div>-->
      <!--        <Row class="content-row">-->
      <!--          <Col class="key2" span="4"-->
      <!--            ><div style="margin-top: 10px">申报类别</div></Col-->
      <!--          >-->
      <!--          <Col class="value1" span="20">-->
      <!--            <div>-->
      <!--              <span style="margin-left:30px;"-->
      <!--                ><input-->
      <!--                  disabled-->
      <!--                  :checked="isShow2"-->
      <!--                  type="checkbox"-->
      <!--                />年度设计人物：</span-->
      <!--              >-->
      <!--              <span style="margin-left: 30px;">姓名</span>-->
      <!--              <input-->
      <!--                readonly="readonly"-->
      <!--                v-model="postList.hpAddDeclare.personName"-->
      <!--                style="width: 170px;border: 0;border-bottom: 1px solid #cccccc;padding-left:10px;"-->
      <!--                type="text"-->
      <!--              />-->
      <!--              <span>职务</span>-->
      <!--              <input-->
      <!--                readonly="readonly"-->
      <!--                v-model="postList.hpAddDeclare.personPosition"-->
      <!--                style="width: 170px;border: 0;border-bottom: 1px solid #cccccc;padding-left:10px;"-->
      <!--                type="text"-->
      <!--              />-->
      <!--            </div>-->
      <!--            &lt;!&ndash;<span>：姓名 <input style="width: 30%" type="text"> 职务 <input style="width: 30%" type="text"></span>&ndash;&gt;-->
      <!--          </Col>-->
      <!--        </Row>-->
      <!--        <div class="design-title-two">事 迹 说 明</div>-->
      <!--        <div class="design-content">-->
      <!--          <textarea-->
      <!--            v-model="postList.hpAddDeclare.deedsShow"-->
      <!--            readonly="readonly"-->
      <!--            style="border: 0;width: 100%"-->
      <!--            name-->
      <!--          ></textarea>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="video-sky"></div>-->
      <div class="designTwo">
        <div class="design-title-one">四、附件上传</div>
        <Row class="content-row">
          <div v-for="(item, index) in postList.programFilesDtoList">
            <div class="flex-row">
              <div class="key-upload value-upload" span="6">
                {{ item.declareMaterialName }}
              </div>
              <div class="value-upload value-upload1" span="18">
                <div
                  class="flex-row uploadFile"
                  v-for="(item1, index1) in item.programFilesList"
                >
                  <a
                    style="margin-left: 15px"
                    :href="item1.filePath"
                    target="_blank"
                    >{{ item1.fileName }}</a
                  >
                </div>
              </div>
            </div>
          </div>
        </Row>
      </div>
      <div class="view-bottom">
        <button class="button-esc" @click="buttonEsc">返回</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShanghaiDesignAuit",
  data() {
    return {
      postList: {
        hpUnitBase: {
          programId: null,
          unitName: "",
          unitCode: "",
          declareType: null,
          email: null,
          declareUnitType: null,
          declareUnitTypeOther: null,
          industryInvolved: null,
          mailingAddress: null,
          postcode: null,
          legalPerson: null, //法人代表
          mobilePhone: null, //移动电话
          contentName: null,
          department: null,
          position: null,
          telephone: null,
          mobile: null,
          fix: null,
          unitIntroduce: null
        },
        hpDeclareData: {
          programId: null,
          dataName: "",
          designFinishTime: null,
          hasPatent: null,
          eventsTime: null,
          hasRegisterCopyright: "",
          hasSales: null,
          wantSales: null,
          memberName: null,
          stayUnitName: null,
          position: null,
          contentPhone: null,
          mobile: null,
          fix: null,
          designExplain: null,
          designValueExplain: null,
          marketValue: null,
          socialValue: null
        },
        hpAddDeclare: {
          programId: null,
          declareType: null,
          unitOrPerson: "",
          personName: "",
          personPosition: null,
          deedsShow: null
        },
        hpResultsImagesList: [
          {
            irId: null,
            programId: null,
            imageName: "",
            imagePath: ""
          }
        ],
        hpOriginalImage: {
          programId: null,
          originalImageName: "",
          originalIamgePath: ""
        },
        hpVideosList: [],
        hpVideoList: [],
        programFilesDtoList: []
      },
      imageList: [
        // {imagePath:require("../../assets/special0.png"),imageName:""},
      ],
      isShow1: false,
      isShow2: false,
      specialUnitTypeLists: [],
      indrstryTypeLists: [], //所属行业
      specialAreaLists: [], //税务所属区
      specialTypeLists: [], //申报类型
      dictionaryLists: [4100, 5000, 6000, 8000]
    };
  },
  computed: {
    getType() {
      return this.$route.query.type;
    },
    getProgramId() {
      return this.$route.query.programId;
    },
    getSpecialId() {
      return this.$route.query.specialId;
    }
  },
  created() {
    this.getDictionaryList();
  },
  methods: {
    /**
     * 获取字典列表
     * */
    getDictionaryList() {
      for (let i in this.dictionaryLists) {
        this.$http
          .get(
            "/shsj_declare/sysDict/queryDictDataList?dictId=" +
              this.dictionaryLists[i]
          )
          .then(res => {
            if (res.messageCode == 10000) {
              if (this.dictionaryLists[i] == 5000) {
                this.specialUnitTypeLists = res.result.dictDataList;
              } else if (this.dictionaryLists[i] == 6000) {
                this.specialTypeLists = res.result.dictDataList;
              } else if (this.dictionaryLists[i] == 4100) {
                this.indrstryTypeLists = res.result.dictDataList;
              }
              this.init();
            }
          });
      }
      this.$http
        .get("/shsj_declare/sysDict/queryDictDataListByHp?dictId=8000")
        .then(res => {
          this.specialAreaLists = res.result.dictDataList;
          this.specialAreaLists.splice(0, 1);
        });
    },
    /**
     * 查询初始化数据
     * */
    init() {
      this.$http
        .post("/shsj_declare/hpDeclare/queryHpDeclareByType", {
          programId: this.getProgramId,
          type: "0",
          specialId: this.getSpecialId
        })
        .then(res => {
          if (res.messageCode == 10000) {
            this.postList = res.result.newHpDelareInfoDto;
            if (
              this.postList.hpAddDeclare.unitOrPerson == 1 &&
              this.postList.hpAddDeclare.wasUnit == 0
            ) {
              this.isShow1 = true;
            } else if (
              this.postList.hpAddDeclare.unitOrPerson == 0 &&
              this.postList.hpAddDeclare.wasUnit == 1
            ) {
              this.isShow2 = true;
            } else if (
              this.postList.hpAddDeclare.unitOrPerson == 1 &&
              this.postList.hpAddDeclare.wasUnit == 1
            ) {
              this.isShow1 = true;
              this.isShow2 = true;
            }
            if (this.postList.hpDeclareData.hasPatent == 1) {
              this.postList.hpDeclareData.hasPatent = "是";
            } else {
              this.postList.hpDeclareData.hasPatent = "否";
            }
            if (this.postList.hpDeclareData.hasRegisterCopyright == 1) {
              this.postList.hpDeclareData.hasRegisterCopyright = "是";
            } else {
              this.postList.hpDeclareData.hasRegisterCopyright = "否";
            }
            if (this.postList.hpDeclareData.designFinishTime) {
              this.postList.hpDeclareData.designFinishTime = this.postList.hpDeclareData.designFinishTime.substring(
                0,
                10
              );
            }
            if (this.postList.hpDeclareData.eventsTime) {
              this.postList.hpDeclareData.eventsTime = this.postList.hpDeclareData.eventsTime.substring(
                0,
                10
              );
            }
            for (let i in this.specialUnitTypeLists) {
              if (
                this.specialUnitTypeLists[i].dataId ==
                this.postList.hpUnitBase.declareUnitType
              ) {
                if (this.postList.hpUnitBase.declareUnitType == 5008) {
                  this.postList.hpUnitBase.declareUnitType =
                    "其他-" + this.postList.hpUnitBase.declareUnitTypeOther;
                } else {
                  this.postList.hpUnitBase.declareUnitType = this.specialUnitTypeLists[
                    i
                  ].dataValue;
                }
              }
            }
            for (let j in this.specialTypeLists) {
              if (
                this.specialTypeLists[j].dataId ==
                this.postList.hpUnitBase.declareType
              ) {
                this.postList.hpUnitBase.declareType = this.specialTypeLists[
                  j
                ].dataValue;
              }
            }
          }
        });
    },
    /**
     * 返回
     * */
    buttonEsc() {
      this.$router.push({
        path: "/manage",
        query: { programId: this.getProgramId, specialId: this.getSpecialId }
      });
    }
  }
};
</script>

<style scoped>
.shangHaiDesignAuit {
  padding-left: 190px;
  padding-top: 50px;
}
.design-title-one {
  font-size: 20px;
  font-weight: 500;
}
.design-title-one span {
  font-size: 14px;
  color: #999999;
  margin-left: 10px;
}
.content-row {
  border-top: 1px solid #ededed;
  border-left: 1px solid #ededed;
  margin-top: 20px;
}
.key {
  background-color: #f6f9ff;
  height: 50px;
  display: flex;
  flex-direction: row;
  /*align-items: center;*/
  justify-content: center;
  text-align: center;
  border-right: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  font-size: 14px;
  padding-top: 10px;
}
.key1 {
  background-color: #f6f9ff;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-right: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  font-size: 14px;
}
.key2 {
  background-color: #f6f9ff;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-right: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  font-size: 14px;
}
.value {
  height: 50px;
  display: flex;
  flex-direction: row;
  /*align-items: center;*/
  justify-content: center;
  border-right: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  overflow-y: auto;
  font-size: 14px;
  padding-top: 10px;
}
.value1 {
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  /*justify-content: center;*/
  border-right: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  font-size: 14px;
  padding-top: 10px;
}
.design-title-two {
  background-color: #f6f9ff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid #ededed;
  border-top: 0;
  height: 50px;
}
.design-content {
  border: 1px solid #ededed;
  border-top: 0;
}
.design-content textarea {
  padding: 20px 30px;
  height: 350px;
}
.video-sky {
  height: 10px;
  background-color: rgba(248, 248, 248, 1);
  margin: 40px 0;
}
.imgStyle {
  width: 100%;
}
.imgStyle div {
  display: inline-block;
  width: 450px;
  height: 300px;
  margin-top: 30px;
}
.imgStyle div:nth-child(2) {
  margin-left: 40px;
}
.view-bottom {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.view-bottom button {
  width: 120px;
  height: 40px;
  border-radius: 5px;
  outline: none;
  font-size: 14px;
}
.button-esc {
  border: 1px solid #d1d1d1;
  background-color: #ffffff;
  color: #333333;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.key-upload {
  width: 33.3%;
  background-color: #f6f9ff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 50px;
}
.value-upload {
  min-height: 50px;
  border-right: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  font-size: 14px;
  text-align: center;
}
.value-upload1 {
  width: 66.6%;
  padding-left: 40px;
  padding-top: 5px;
}
.originalImage {
  margin: 20px 0;
}
.originalImage div {
  margin: 10px 0;
}
/*.imgStyle div:nth-child(3){*/
/*margin-left: 30px;*/
/*}*/
</style>
