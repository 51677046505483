<template>
  <div>
    <div class="top-tab">
      <div
        style="width: 4px;height: 25px;background-color: #3385ff;margin-right: 10px"
      ></div>
      <div style="font-size: 16px">上海设计100+</div>
    </div>
    <div style="height: 10px;background-color: #f8f8f8"></div>
    <div class="modeCompany">
      <div class="content-left">
        <div :class="{ active: isActive == 6 }" @click="formDescription">
          填表须知（申报前请仔细阅读）
        </div>
        <div :class="{ active: isActive == 1 }" @click="unitBasicInformation">
          单位基本情况
        </div>
        <div :class="{ active: isActive == 2 }" @click="applicationMaterials">
          申报材料
        </div>
        <div :class="{ active: isActive == 3 }" @click="pictureVideo">
          图片视频
        </div>
        <div :class="{ active: isActive == 5 }" @click="uploader">附件上传</div>
      </div>
      <div class="content-right">
        <div class="flex-row uploadFile">
          <div v-show="isActive == 3" class="mustWrite">*</div>
          <div
            style="width: 4px;height: 25px;background-color: #3385ff;margin-right: 10px"
          ></div>
          <div class="content-right-title">
            {{ title }}
            <span class="content-right-title1">{{ title2 }}</span>
          </div>
        </div>
        <div class="box1" v-show="isActive == 1">
          <Row class="content-row">
            <Col class="key" span="4">
              <div class="mustWrite">*</div>
              单位名称
            </Col>
            <Col class="value input" span="20">
              <input
                maxlength="40"
                v-model="postList.hpUnitBase.unitName"
                type="text"
              />
            </Col>
            <Col class="key" span="4">
              <div class="mustWrite">*</div>
              社会信用代码
            </Col>
            <Col class="value input" span="8">
              <input
                disabled
                maxlength="20"
                v-model="postList.hpUnitBase.unitCode"
                type="text"
              />
            </Col>
            <Col class="key" span="4">
              <div class="mustWrite">*</div>
              税务登记地
            </Col>
            <Col class="value input" span="8">
              <Select v-model="postList.hpUnitBase.taxRegistrationArea">
                <Option
                  :key="index"
                  v-for="(item, index) in specialAreaLists"
                  :value="item.dataId"
                  >{{ item.dataValue }}</Option
                >
              </Select>
            </Col>
            <Col class="key" span="4">
              <div class="mustWrite">*</div>
              单位类型
            </Col>
            <Col class="value-radio" span="20">
              <RadioGroup v-model="postList.hpUnitBase.declareUnitType">
                <Radio
                  v-for="item in specialUnitTypeLists"
                  :label="item.dataId"
                  :key="item.dataId"
                  >{{ item.dataValue }}</Radio
                >
                <!--<input v-show="postList.hpUnitBase.declareUnitType == 5005" v-model="postList.hpUnitBase.declareUnitTypeOther" style="border: 0;border-bottom: 1px solid #cccccc;padding-left: 10px" type="text">-->
                <!--<input v-show="postList.hpUnitBase.declareUnitType != 5005" style="border: 0;border-bottom: 1px solid #cccccc;padding-left: 10px" type="text">-->
                <input
                  v-model="postList.hpUnitBase.declareUnitTypeOther"
                  style="width:120px;border: 0;border-bottom: 1px solid #cccccc;padding-left: 10px;outline: none"
                  :maxlength="20"
                  type="text"
                />
              </RadioGroup>
            </Col>
            <Col class="key" span="4">
              <div class="mustWrite">*</div>
              通讯地址
            </Col>
            <Col class="value input" span="8">
              <input
                maxlength="100"
                v-model="postList.hpUnitBase.mailingAddress"
                type="text"
              />
            </Col>
            <Col class="key" span="4">
              <div class="mustWrite">*</div>
              邮编
            </Col>
            <Col class="value input" span="8">
              <input
                type="text"
                oninput="value = value.replace(/[^0-9]/g,'')"
                maxlength="6"
                v-model="postList.hpUnitBase.postcode"
              />
            </Col>
            <Col class="key" span="4">
              <div class="mustWrite">*</div>
              单位负责人
            </Col>
            <Col class="value input" span="8">
              <input
                maxlength="20"
                v-model="postList.hpUnitBase.legalPerson"
                type="text"
              />
            </Col>
            <Col class="key" span="4">
              <div class="mustWrite">*</div>
              负责人移动电话
            </Col>
            <Col class="value input" span="8">
              <input
                maxlength="20"
                v-model="postList.hpUnitBase.mobilePhone"
                type="text"
              />
            </Col>
            <Col class="key" span="4">
              <div class="mustWrite">*</div>
              申报联系人
            </Col>
            <Col class="value input" span="8">
              <input
                maxlength="20"
                v-model="postList.hpUnitBase.contentName"
                type="text"
              />
            </Col>
            <Col class="key" span="4">
              <div class="mustWrite">*</div>
              联系人移动电话
            </Col>
            <Col class="value input" span="8">
              <input
                maxlength="20"
                v-model="postList.hpUnitBase.mobile"
                type="text"
              />
            </Col>
            <!--            <Col class="key" span="4">-->
            <!--              <div class="mustWrite">*</div>-->
            <!--              所属部门-->
            <!--            </Col>-->
            <!--            <Col class="value input" span="8">-->
            <!--              <input-->
            <!--                maxlength="50"-->
            <!--                v-model="postList.hpUnitBase.department"-->
            <!--                type="text"-->
            <!--              />-->
            <!--            </Col>-->
            <Col class="key" span="4">
              <div class="mustWrite">*</div>
              部门职务
            </Col>
            <Col class="value input" span="8">
              <input
                maxlength="50"
                v-model="postList.hpUnitBase.department"
                type="text"
              />
            </Col>
            <Col class="key" span="4">
              <div class="mustWrite">*</div>
              电子邮箱
            </Col>
            <Col class="value input" span="8">
              <input
                maxlength="100"
                v-model="postList.hpUnitBase.email"
                type="text"
              />
            </Col>
            <!--            <Col class="key" span="4">-->
            <!--              <div class="mustWrite">*</div>-->
            <!--              固定电话-->
            <!--            </Col>-->
            <!--            <Col class="value input" span="8">-->
            <!--              <input-->
            <!--                maxlength="20"-->
            <!--                v-model="postList.hpUnitBase.telephone"-->
            <!--                type="text"-->
            <!--              />-->
            <!--            </Col>-->
            <!--            <Col class="key" span="4">传真电话</Col>-->
            <!--            <Col class="value input" span="8">-->
            <!--              <input-->
            <!--                maxlength="20"-->
            <!--                v-model="postList.hpUnitBase.fix"-->
            <!--                type="text"-->
            <!--              />-->
            <!--            </Col>-->
          </Row>
          <div class="content-right-title2">
            <div class="mustWrite">*</div>
            申报单位简介（限500字内）
          </div>
          <div class="content-right-content">
            <!-- <textarea
              maxlength="500"
              v-model="postList.hpUnitBase.unitIntroduce"
              style="height:350px;border: 0;width: 100%"
              name
            ></textarea> -->
            <Input
              :maxlength="500"
              v-model="postList.hpUnitBase.unitIntroduce"
              @on-change="
                postList.hpUnitBase.unitIntroduce = $util.ToDBC(
                  postList.hpUnitBase.unitIntroduce,
                  $event
                )
              "
              type="textarea"
              :autosize="{ minRows: 10, maxRows: 12 }"
            ></Input>
          </div>
        </div>
        <div class="box2" v-show="isActive == 2">
          <Row class="content-row">
            <Col class="key1" span="4">
              <div class="mustWrite">*</div>
              <div style="margin-top: 10px;">作品名称</div>
            </Col>
            <Col class="value input" span="20">
              <input
                maxlength="50"
                v-model="postList.hpDeclareData.dataName"
                type="text"
              />
            </Col>
            <Col class="key" span="4">
              <div class="mustWrite">*</div>
              <!-- 申报类别 -->
              申报领域
            </Col>
            <Col class="value-radio1" span="8">
              <Select v-model="postList.hpDeclareData.declareSecondType">
                <Option
                  :key="index"
                  v-for="(item, index) in declareCategory"
                  :value="item.dataId"
                  >{{ item.dataValue }}</Option
                >
              </Select>
            </Col>
            <Col class="key" span="4">
              <div class="mustWrite">*</div>
              具体类目
            </Col>
            <Col class="value-radio1" span="8">
              <Select v-model="postList.hpDeclareData.industryInvolved">
                <Option
                  :key="index"
                  v-for="(item, index) in indrstryTypeLists"
                  :value="item.dataId"
                  >{{ item.dataValue }}</Option
                >
              </Select>
            </Col>
            <Col class="key1" span="4">
              <div class="mustWrite">*</div>
              <div style="margin-top: 10px;">设计完成时间</div>
            </Col>
            <Col class="value input" span="8">
              <DatePicker
                type="date"
                @on-change="finishTime"
                v-model="designFinishTime"
                placeholder="请选择日期"
                style="width: 240px"
              ></DatePicker>
            </Col>
            <Col class="key1" span="4">
              <div class="mustWrite">*</div>
              <div style="margin-top: 10px;">是否已申请专利</div>
            </Col>
            <Col class="value input" span="8">
              <RadioGroup v-model="postList.hpDeclareData.hasPatent">
                <Radio label="1">是</Radio>
                <Radio style="margin-left: 30px" label="0">否</Radio>
              </RadioGroup>
            </Col>
            <Col class="key1" span="4">
              <div class="mustWrite">*</div>
              <span style="text-align: center"
                >产品上市/案例落地/事件发生时间</span
              >
            </Col>
            <Col class="value input" span="8">
              <DatePicker
                type="date"
                @on-change="eventTime"
                v-model="eventsTime"
                placeholder="请选择日期"
                style="width: 240px"
              ></DatePicker>
            </Col>
            <Col class="key1" span="4">
              <div class="mustWrite">*</div>
              <div style="margin-top: 10px;">是否已登记版权</div>
            </Col>
            <Col class="value input" span="8">
              <RadioGroup v-model="postList.hpDeclareData.hasRegisterCopyright">
                <Radio label="1">是</Radio>
                <Radio style="margin-left: 30px" label="0">否</Radio>
              </RadioGroup>
            </Col>
            <Col class="key1" span="4">
              <div class="mustWrite">*</div>
              <div style="margin-top: 10px;">已实现销售额(万元)</div>
            </Col>
            <Col class="value input" span="8">
              <input
                :maxlength="maxLength"
                v-model="postList.hpDeclareData.hasSales"
                @input="checkInput"
                type="text"
              />
            </Col>
            <Col class="key1" span="4">
              <div class="mustWrite">*</div>
              <div>生命周期内预期总销售额(万元)</div>
            </Col>
            <Col class="value input" span="8">
              <input
                :maxlength="maxLength"
                v-model="postList.hpDeclareData.wantSales"
                @input="checkInput2"
                type="text"
              />
            </Col>
            <Col class="key" span="4" style="height: 70px">
              <div class="mustWrite">*</div>
              <div>是否有产业化需求</div>
            </Col>
            <Col class="value input" span="8" style="height: 70px">
              <RadioGroup
                v-model="postList.hpDeclareData.industrializationDemand"
                style="width: 100%"
              >
                <Row>
                  <Radio :label="1">是</Radio>
                  <input
                    maxlength="30"
                    v-model="
                      postList.hpDeclareData.industrializationDemandValue
                    "
                    type="text"
                  />
                </Row>
                <Radio style="margin-top: 5px" :label="0">否</Radio>
              </RadioGroup>
            </Col>
            <Col class="key" span="4" style="height: 70px">
              <div class="mustWrite">*</div>
              <div>是否有商业合作需求</div>
            </Col>
            <Col class="value input" span="8" style="height: 70px">
              <RadioGroup
                v-model="postList.hpDeclareData.businessCooperationNeeds"
                style="width: 100%"
              >
                <Row>
                  <Radio :label="1">是</Radio>
                  <input
                    maxlength="30"
                    v-model="
                      postList.hpDeclareData.businessCooperationNeedsValue
                    "
                    type="text"
                  />
                </Row>
                <Radio style="margin-top: 5px" :label="0">否</Radio>
              </RadioGroup>
            </Col>
            <Col class="key1" span="4">
              <div class="mustWrite">*</div>
              <div style="margin-top: 10px;">设计者/团队成员</div>
            </Col>
            <Col class="value input" span="8">
              <input
                maxlength="100"
                v-model="postList.hpDeclareData.memberName"
                type="text"
              />
            </Col>
            <Col class="key1" span="4">
              <div class="mustWrite">*</div>
              <div style="margin-top: 10px;">移动电话</div>
            </Col>
            <Col class="value input" span="8">
              <input
                maxlength="20"
                v-model="postList.hpDeclareData.contentPhone"
                type="text"
              />
            </Col>
            <!--            <Col class="key1" span="4">-->
            <!--              <div class="mustWrite">*</div>-->
            <!--              <div style="margin-top: 10px;">所在单位</div>-->
            <!--            </Col>-->
            <!--            <Col class="value input" span="8">-->
            <!--              <input-->
            <!--                maxlength="50"-->
            <!--                v-model="postList.hpDeclareData.stayUnitName"-->
            <!--                type="text"-->
            <!--              />-->
            <!--            </Col>-->
            <Col class="key1" span="4">
              <div class="mustWrite">*</div>
              <div style="margin-top: 10px;">职务/职称</div>
            </Col>
            <Col class="value input" span="8">
              <input
                maxlength="50"
                v-model="postList.hpDeclareData.position"
                type="text"
              />
            </Col>
            <Col class="key" span="4">
              <div class="mustWrite">*</div>
              申报类型
            </Col>
            <Col class="value-radio1" span="8">
              <Select v-model="postList.hpDeclareData.declareType">
                <Option
                  :key="index"
                  v-for="(item, index) in specialTypeLists"
                  :value="item.dataId"
                  >{{ item.dataValue }}</Option
                >
              </Select>
            </Col>
          </Row>

          <div class="content-right-title2">
            <div class="mustWrite">*</div>
            设计说明（限 500 字内）
          </div>
          <div class="content-right-content">
            <Input
              :maxlength="500"
              v-model="postList.hpDeclareData.designExplain"
              @on-change="
                postList.hpDeclareData.designExplain = $util.ToDBC(
                  postList.hpDeclareData.designExplain,
                  $event
                )
              "
              type="textarea"
              placeholder="对申报作品的设计理念、用途功能、设计特色等进行说明。可挑选从创新性、引领性、美学效果、人机工学、功能性、品质、环保性、经济性等方面展开。"
              :autosize="{ minRows: 10, maxRows: 12 }"
            ></Input>
          </div>
          <div
            class="content-right-title2"
            style="border-bottom:1px solid #ececec"
          >
            <div class="mustWrite">*</div>
            设计价值 (限500字内)
          </div>
          <div class="content-right-title2">
            <div class="mustWrite">*</div>
            （一）市场价值 (不少于200字)
          </div>
          <div class="content-right-content">
            <Input
              :maxlength="500"
              v-model="postList.hpDeclareData.marketValue"
              @on-change="
                postList.hpDeclareData.marketValue = $util.ToDBC(
                  postList.hpDeclareData.marketValue,
                  $event
                )
              "
              type="textarea"
              placeholder="市场价值"
              :autosize="{ minRows: 10, maxRows: 12 }"
            ></Input>
          </div>
          <div class="content-right-title2">
            <div class="mustWrite">*</div>
            （二） 社会价值
          </div>
          <div class="content-right-content">
            <!-- <textarea
              maxlength="3000"
              v-model="postList.hpDeclareData.socialValue"
              style="height:350px;border: 0;width: 100%"
              name
            ></textarea> -->
            <Input
              :maxlength="500"
              v-model="postList.hpDeclareData.socialValue"
              @on-change="
                postList.hpDeclareData.socialValue = $util.ToDBC(
                  postList.hpDeclareData.socialValue,
                  $event
                )
              "
              type="textarea"
              placeholder="社会价值"
              :autosize="{ minRows: 10, maxRows: 12 }"
            ></Input>
          </div>
          <div class="content-right-title2">推荐单位意见（非必须）</div>
          <div class="content-right-content">
            <!-- <textarea
              maxlength="500"
              v-model="postList.hpDeclareData.recommendUnitAdvise"
              style="height:350px;border: 0;width: 100%"
              name
            ></textarea> -->
            <Row class="content-row" style="margin-top: 0">
              <Col class="key" span="4">
                <!--                <div class="mustWrite">*</div>-->
                推荐单位
              </Col>
              <Col class="value input" span="20">
                <input
                  maxlength="40"
                  v-model="postList.hpDeclareData.unitAdvise"
                  type="text"
                />
              </Col>
            </Row>
            <Input
              :maxlength="25"
              v-model="postList.hpDeclareData.recommendUnitAdvise"
              @on-change="
                postList.hpDeclareData.recommendUnitAdvise = $util.ToDBC(
                  postList.hpDeclareData.recommendUnitAdvise,
                  $event
                )
              "
              type="textarea"
              :autosize="{ minRows: 10, maxRows: 12 }"
            ></Input>
          </div>
        </div>
        <div class="box3" v-show="isActive == 3">
          <div class="masterMap">
            <!--            <div class="flex-row uploadFile">-->
            <!--              <div class="mustWrite">*</div>-->
            <!--              <span>原图</span>-->
            <!--            </div>-->
            <div class="masterMapUpload">
              <div>
                <myUpload
                  @uploadSuccess="uploadSuccessImg2($event)"
                  type="drag"
                  :disabled="isDisabled1"
                  :formatMassage="formatImgMessage"
                  :format="formatImg"
                  :size="sizeImg"
                  :action="action"
                >
                  <button class="button-save">上传</button>
                </myUpload>
              </div>
              <div>
                <span>注：只支持JPG/JPEG/PNG，大小不超过10M</span>
              </div>
            </div>
          </div>
          <div style="margin-left:10px;margin-bottom: 10px" v-show="imageList1">
            <div
              class="flex-row uploadFile"
              v-for="(item, index) in imageList1"
            >
              <a
                :href="item.originalImagePath"
                target="_blank"
                style="width:400px;overflow: hidden"
              >
                {{ item.originalImageName }}
              </a>
              <div
                @click="imageDel1(index)"
                style="color: #6eb1ff;font-size: 25px;margin-left: 15px"
              >
                <Icon type="ios-remove-circle-outline" />
              </div>
            </div>
          </div>
          <div style="margin-top: 30px" class="flex-row uploadFile">
            <div class="mustWrite">*</div>
            <div
              style="width: 4px;height: 25px;background-color: #3385ff;margin-right: 10px"
            ></div>
            <div style="font-size: 22px;" class="uploadVideo-left-title">
              申报作品封面展示图
              <span class="content-right-title1"
                >（从申报作品原图里选择一张）</span
              >
            </div>
          </div>
          <div class="imgStyle1">
            <div class="imgUpload1">
              <myUpload
                @uploadSuccess="uploadSuccessImg3($event)"
                type="drag"
                :format="formatImg"
                :formatMassage="formatImgMessage"
                :show-upload-list="false"
                :size="sizeImg2"
                :action="action"
              >
                <div style="background-color:#ececec;color: white;">
                  <Icon size="100" type="ios-add" />
                </div>
                <div style="background-color:#ececec;color: #3385ff">
                  尺寸为600×400px
                </div>
                <div style="background-color:#ececec;color: #3385ff">
                  只支持JPG/JPEG/PNG,
                </div>
                <div style="background-color:#ececec;color: #3385ff;">
                  大小不超过500KB
                </div>
              </myUpload>
            </div>
            <div class="images1" v-show="postList.hpCoverImage.imagePath">
              <img
                style="width: 185px; height: 165px;object-fit: scale-down;"
                :src="postList.hpCoverImage.imagePath"
                :alt="postList.hpCoverImage.imageName"
              />
            </div>
          </div>
          <div style="margin-top: 30px" class="flex-row uploadFile">
            <div class="mustWrite">*</div>
            <div
              style="width: 4px;height: 25px;background-color: #3385ff;margin-right: 10px"
            ></div>
            <div style="font-size: 22px;" class="uploadVideo-left-title">
              申报作品代表性展示图
              <span class="content-right-title1"
                >（从申报作品原图里选择三张）</span
              >
            </div>
          </div>
          <div class="imgStyle">
            <div class="imgUpload">
              <myUpload
                multiple
                :disabled="isDisabled"
                @uploadSuccess="uploadSuccessImg($event)"
                type="drag"
                :format="formatImg"
                :formatMassage="formatImgMessage"
                :show-upload-list="false"
                :size="sizeImg1"
                :action="action"
              >
                <div style="background-color:#ececec;color: white;">
                  <Icon size="130" type="ios-add" />
                </div>
                <div style="background-color:#ececec;color: #3385ff">
                  尺寸建议为1920×1280或1280×1920px
                </div>
                <div style="background-color:#ececec;color: #3385ff">
                  只支持JPG/JPEG/PNG,
                </div>
                <div style="background-color:#ececec;color: #3385ff;">
                  大小不超过1M
                </div>
              </myUpload>
            </div>
            <div
              v-show="imageList"
              class="images"
              v-for="(item, index) in imageList"
            >
              <img
                style="width: 280px;height: 200px;object-fit: scale-down;"
                :src="item.imagePath"
                :alt="item.imageName"
              />
              <div class="image-del" @click="imageDel(index)">
                <Icon
                  style="font-size: 32px;color: #0000ff;font-weight: 900"
                  type="ios-remove-circle-outline"
                />
              </div>
            </div>
          </div>
          <div class="video-sky"></div>
          <div class="uploadVideo">
            <div class="uploadVideo-left">
              <div class="flex-row uploadFile">
                <div
                  style="width: 4px;height: 25px;background-color: #3385ff;margin-right: 10px"
                ></div>
                <div style="font-size: 22px" class="uploadVideo-left-title">
                  上传视频
                </div>
              </div>
              <div class="content-right-title1">
                （视频格式MP4，视频上传限制2分钟以内，1080p，200M以内）
              </div>
              <div class="uploadVideoLocal">
                <div class="flex-row uploadFile">本地上传：</div>
                <div v-show="isProcessShow" style="width: 80%">
                  <Progress
                    :percent="processNum"
                    status="active"
                    :stroke-width="5"
                  />
                </div>
                <myUpload
                  @uploadSuccess="uploadSuccessVideo($event)"
                  @uploadProgress="uploadProgressVideo($event)"
                  @processShow="processShow($event)"
                  type="drag"
                  :disabled="isVideoShow"
                  :formatMassage="formatVideoMessage"
                  :format="formatVideo"
                  :size="sizeVideo"
                  :action="action"
                  style="width: 130px"
                >
                  <div style="text-align: left;">
                    <button
                      style="margin-left: 0;margin-top: 10px;cursor: pointer"
                      :class="isVideoShow ? 'button-save1' : 'button-save'"
                    >
                      上传
                    </button>
                  </div>
                </myUpload>
                <div
                  style="margin-left:10px;margin-bottom: 10px"
                  v-show="isVideo"
                >
                  <div
                    class="flex-row uploadFile"
                    v-for="(item, index) in videoList"
                  >
                    <a
                      style="max-width: 300px;overflow: hidden;
    text-overflow: ellipsis;"
                      :href="item.videoPath"
                      target="_blank"
                    >
                      {{ item.videoName }}
                    </a>
                    <div
                      @click="videoDel1(index)"
                      style="color: #6eb1ff;font-size: 25px;margin-left: 15px;z-index:999"
                    >
                      <Icon type="ios-remove-circle-outline" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="uploadVideoList">
                <div>视频库上传</div>
                <!--<myUpload-->
                <!--multiple-->
                <!--@uploadSuccess="uploadSuccessVideos($event)"-->
                <!--type="drag"-->
                <!--:format= "formatVideo"-->
                <!--:formatMassage="formatVideoMessage"-->
                <!--:max-size="sizeVideo*1024"-->
                <!--:action="action">-->
                <div style="text-align: left">
                  <button
                    style="margin-left: 0;margin-top: 10px;cursor: pointer"
                    @click="uploadSuccessVideos"
                    :disabled="isVideoShow"
                    :class="isVideoShow ? 'button-save1' : 'button-save'"
                  >
                    上传
                  </button>
                </div>
                <!--</myUpload>-->
                <div
                  style="margin-left:10px;margin-bottom: 10px"
                  v-show="isVideos"
                >
                  <div
                    class="flex-row uploadFile"
                    v-for="(item, index) in videosArray"
                  >
                    <a
                      style="max-width: 300px;overflow: hidden;
    text-overflow: ellipsis;"
                      :href="item.filePath"
                      target="_blank"
                    >
                      {{ item.fileName }}
                    </a>
                    <div
                      @click="videoDel2(index)"
                      style="color: #6eb1ff;font-size: 25px;margin-left: 15px;z-index:999"
                    >
                      <Icon type="ios-remove-circle-outline" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="uploadVideo-right">
              <div style="font-size: 22px" class="uploadVideo-left-title">
                视频库
              </div>
              <Table
                :data="uploadVideoInfo"
                :columns="uploadVideoTitle"
                class="video-table"
                border
              >
                <template slot-scope="{ row }" slot="videoName">
                  <a
                    :href="row.videoPath"
                    style="cursor: pointer"
                    target="_blank"
                    >{{ row.videoName }}</a
                  >
                </template>
                <template slot-scope="{ row, index }" slot="action">
                  <span
                    style="color: deepskyblue;cursor: pointer"
                    @click="videoDel(row, index)"
                    >删除</span
                  >
                </template>
              </Table>
            </div>
          </div>
        </div>
        <div class="box4" v-show="isActive == 4">
          <CheckboxGroup @on-change="checked" v-model="typeFourList">
            <Row class="content-row">
              <Col v-show="isFour" class="key" span="4">
                <div v-show="isFour" class="mustWrite">*</div>
                <!-- <div>申报类别</div> -->
                <div>申报领域</div>
              </Col>
              <Col v-show="!isFour" class="key1" span="4">
                <!-- <div style="margin-top: 10px">申报类别</div> -->
                <div style="margin-top: 10px">申报领域</div>
              </Col>
              <Col class="value input" span="20">
                <div>
                  <Checkbox label="1">年度设计企业</Checkbox>
                </div>
                <!--<span>：姓名 <input style="width: 30%" type="text"> 职务 <input style="width: 30%" type="text"></span>-->
              </Col>
            </Row>
            <div v-show="isFour" class="content-right-title2">
              <div class="mustWrite">*</div>
              事 迹 说 明
            </div>
            <div class="content-right-content">
              <!-- <textarea
                maxlength="1500"
                v-show="isFour"
                :readonly="isReadonly"
                v-model="postList.hpAddDeclare.unitDeedsShow"
                style="height:350px;border: 0;width: 100%"
                placeholder="总结年度设计工作事迹，提出申报年度设计企业的理由，包括但不限于产业贡献、产业引领、社会影响、国际影响等方面，限1500字以内。"
                name
              ></textarea> -->
              <Input
                :maxlength="1500"
                v-show="isFour"
                :readonly="isReadonly"
                v-model="postList.hpAddDeclare.unitDeedsShow"
                placeholder="总结年度设计工作事迹，提出申报年度设计企业的理由，包括但不限于产业贡献、产业引领、社会影响、国际影响等方面，限1500字以内。"
                @on-change="
                  postList.hpAddDeclare.unitDeedsShow = $util.ToDBC(
                    postList.hpAddDeclare.unitDeedsShow,
                    $event
                  )
                "
                type="textarea"
                :autosize="{ minRows: 10, maxRows: 12 }"
              ></Input>
            </div>
            <Row class="content-row">
              <Col v-show="isFour1" class="key" span="4">
                <div v-show="isFour1" class="mustWrite">*</div>
                <!-- <div>申报类别</div> -->
                <div>申报领域</div>
              </Col>
              <Col v-show="!isFour1" class="key1" span="4">
                <!-- <div style="margin-top: 10px">申报类别</div> -->
                <div style="margin-top: 10px">申报领域</div>
              </Col>
              <Col class="value input" span="20">
                <div>
                  <Checkbox label="2">
                    年度设计人物：
                    <span style="margin-left: 30px;">姓名</span>
                    <input
                      maxlength="50"
                      :readonly="isReadonly1"
                      v-model="postList.hpAddDeclare.personName"
                      style="width: 30%;border: 0;border-bottom: 1px solid #cccccc;padding-left:10px"
                      type="text"
                    />
                    <span>职务</span>
                    <input
                      maxlength="50"
                      :readonly="isReadonly1"
                      v-model="postList.hpAddDeclare.personPosition"
                      style="width: 30%;border: 0;border-bottom: 1px solid #cccccc;padding-left:10px"
                      type="text"
                    />
                  </Checkbox>
                </div>
                <!--<span>：姓名 <input style="width: 30%" type="text"> 职务 <input style="width: 30%" type="text"></span>-->
              </Col>
            </Row>
            <div v-show="isFour1" class="content-right-title2">
              <div class="mustWrite">*</div>
              事 迹 说 明
            </div>
            <div class="content-right-content">
              <!-- <textarea
                maxlength="1500"
                v-show="isFour1"
                :readonly="isReadonly1"
                v-model="postList.hpAddDeclare.deedsShow"
                style="height:350px;border: 0;width: 100%"
                placeholder="总结年度设计工作事迹，提出申报年度设计人物的理由，包括但不限于产业贡献、产业引领、社会影响、国际影响等方面，限1500字以内。"
                name
              ></textarea> -->
              <Input
                :maxlength="1500"
                v-show="isFour1"
                :readonly="isReadonly1"
                v-model="postList.hpAddDeclare.deedsShow"
                placeholder="总结年度设计工作事迹，提出申报年度设计人物的理由，包括但不限于产业贡献、产业引领、社会影响、国际影响等方面，限1500字以内。"
                @on-change="
                  postList.hpAddDeclare.deedsShow = $util.ToDBC(
                    postList.hpAddDeclare.deedsShow,
                    $event
                  )
                "
                type="textarea"
                :autosize="{ minRows: 10, maxRows: 12 }"
              ></Input>
            </div>
          </CheckboxGroup>
        </div>
        <div class="box5" v-show="isActive == 5">
          <div class="attention">
            <span>提醒：</span>
            请使用原件扫描或复印件加盖公章后扫描，格式为*.pdf，大小不超过10M(若超过请拆分上传)，标注
            <span>*</span> 的附件为必须上传。
          </div>
          <Row class="content-row">
            <div v-for="(item, index) in programFilesDto">
              <div class="flex-row">
                <div class="key-upload value-upload" span="6">
                  <div v-show="item.wasNeed == 1" class="mustWrite">*</div>
                  <div v-show="item.wasNeed != 1" style="width: 22px"></div>
                  <div>{{ item.declareMaterialName }}</div>
                </div>
                <div class="value-upload value-upload1" span="18">
                  <myUpload
                    class="flex-row fileStyle"
                    multiple
                    @uploadSuccess="uploadSuccessFile(index, arguments)"
                    @uploadRemove="removeFile(index, arguments)"
                    :defaultList="item.programFilesList"
                    type="drag"
                    :format="formatFile"
                    :formatMassage="formatFileMessage"
                    :size="sizeFile"
                    :isShowUploadlist="isProcess"
                    :action="action"
                    :fileLength="fileLength"
                  >
                    <button class="uploadClick">点击上传</button>
                  </myUpload>
                  <!--                  <div>-->
                  <!--                    <div-->
                  <!--                      class="flex-row uploadFile"-->
                  <!--                      v-for="(item1, index1) in postList.programFilesDtoList[-->
                  <!--                        index-->
                  <!--                      ].programFilesList"-->
                  <!--                    >-->
                  <!--                      <a-->
                  <!--                        style="margin-left: 15px"-->
                  <!--                        :href="item1.filePath"-->
                  <!--                        target="_blank"-->
                  <!--                        >{{ item1.fileName }}</a-->
                  <!--                      >-->
                  <!--                      <div-->
                  <!--                        @click="delFile(item1, index1, index)"-->
                  <!--                        style="color: #6eb1ff;font-size: 25px;margin-left: 15px"-->
                  <!--                      >-->
                  <!--                        <Icon type="ios-remove-circle-outline" />-->
                  <!--                      </div>-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                </div>
              </div>
            </div>
          </Row>
          <!-- <Row style="margin-top:0px;border-top:none" class="content-row">
            <Col class="key1" span="8">
              <div class="mustWrite">*</div>
              <div style="margin-top: 10px;">是否一键公示</div>
            </Col>
            <Col class="value input" span="16">
              <RadioGroup style="margin-top:6px" v-model="postList.isPublic">
                <Radio label="0">是</Radio>
                <Radio style="margin-left: 30px" label="1">否</Radio>
              </RadioGroup>
            </Col>
          </Row> -->
        </div>
        <div class="box6" v-show="isActive == 6">
          <div class="needingAttention">
            <p class="title">填 表 须 知</p>
            <p class="item">
              1．本申报表须认真如实填写。表内项目无相关内容的，应填写“无”。个别项目填写不下时，可进行扩展。
            </p>
            <p class="item">
              2．封面除右上角序号一栏，其余内容由申报单位填写，并在指定位置盖章确认。
            </p>
            <p class="item">
              3．鼓励请相关主管部门，相关高校、行业协会、园区、集团企业填写推荐意见并盖章（此项工作非必须）。
            </p>
            <p class="item">
              4．线上提交。申报网站：<a
                target="view_window"
                href="http://www.creativecity.sh.cn"
                >www.creativecity.sh.cn</a
              >
              ，截止时间：<strong>2024年5月20日</strong>。
            </p>
            <p class="item">
              5．纸质提交。从申报系统中导出申报表，A4双面打印，连同附件材料左侧装订，一式一份，签字盖章，<strong>2024年5月20日</strong>前提交。接受快递，纸质材料不退还。
            </p>
            <p class="item">
              联系人：时昊15900681021，杨馨黎13611936003<br />
              上海市徐汇区桂箐路15号1号楼2楼
            </p>
          </div>
        </div>
        <div class="content-button">
          <button v-show="isActive != 6" class="button-esc" @click="escButton">
            取消
          </button>
          <button v-show="isActive != 6" class="button-save" @click="save">
            保存
          </button>
          <button
            class="button-next"
            @click="saveNext"
            v-show="isActive != 5 && isActive != 6"
          >
            保存并跳转下一步
          </button>
          <!-- <button class="button-next" @click="saveView" v-show="isActive == 5">
            保存并预览
          </button> -->
          <button class="button-next" @click="sumbit" v-show="isActive == 5">
            提交
          </button>
        </div>
      </div>
      <!--<Modal-->
      <!--width="360"-->
      <!--title=""-->
      <!--v-model="isModel"-->
      <!--:mask-closable="false"-->
      <!--@on-ok="ok"-->
      <!--@on-cancel="cancel">-->
      <!--<p style="padding-top:40px;height:80px;font-size:16px;text-align: center">您未保存，是否保存？</p>-->
      <!--</Modal>-->
      <Modal
        width="360"
        title="删除"
        v-model="isModelVideo"
        :mask-closable="false"
        @on-ok="okDel"
        @on-cancel="cancelDel"
      >
        <p
          style="padding-top:10px;height:50px;font-size:16px;text-align: center"
        >
          是否删除该条视频？
        </p>
      </Modal>
      <Modal
        width="360"
        title="删除"
        v-model="isModelVideo1"
        :mask-closable="false"
        @on-ok="okDelVideo"
        @on-cancel="cancelDelVideo"
      >
        <p
          style="padding-top:10px;height:50px;font-size:16px;text-align: center"
        >
          是否删除该条视频？
        </p>
      </Modal>
      <Modal
        width="360"
        title="删除"
        v-model="isModelImg"
        :mask-closable="false"
        @on-ok="okImgDel"
        @on-cancel="cancelImgDel"
      >
        <p
          style="padding-top:10px;height:50px;font-size:16px;text-align: center"
        >
          是否删除该张图片？
        </p>
      </Modal>
      <Modal
        width="360"
        title="删除"
        v-model="isModelImg1"
        :mask-closable="false"
        @on-ok="okImgDel1"
        @on-cancel="cancelImgDel1"
      >
        <p
          style="padding-top:10px;height:50px;font-size:16px;text-align: center"
        >
          是否删除该张图片？
        </p>
      </Modal>
      <!--视频库选择弹窗-->
      <myVideo
        :videosList="postList.hpVideosList"
        :isShowPopup="isModelVideo2"
        @okVideos="okVideos($event)"
        @cancelVideos="cancelVideos($event)"
      ></myVideo>
    </div>
  </div>
</template>

<script>
import myUpload from "../../components/upload.vue";
import myVideo from "../ShanghaiDesign/videosPopup";
export default {
  name: "",
  props: {},
  data() {
    return {
      programId: this.$route.query.programId,
      specialId: this.$route.query.specialId,
      maxLength: 10,
      title: "填表须知",
      title2: "",
      isActive: 6,
      isActivePlus: null,
      isSave: true, //验证成功之后保存
      // isSaveSuccess:false,  //是否保存成功
      // isModel:false,   //判断弹窗
      isModelVideo: false, //判断视频删除弹窗
      isModelVideo1: false, //判断本地视频删除弹窗
      isModelVideo2: false, //判断视频库上传视频弹窗
      isModelImg: false, //判断图片删除弹窗
      isModelImg1: false, //判断原图删除弹窗
      videoId: null, //视频库id
      imageId: null, //图片删除序号
      isEscButton: false, //判断是否是取消按钮
      isJudge: false, //判断本地视频（删除用）
      isJudge1: false, //判断视频库视频（删除用）
      formatImg: ["jpg", "jpeg", "png"],
      formatImgMessage: "jpg,jpeg,png",
      sizeImg: 10,
      sizeImg1: 1,
      sizeImg2: 0.5,
      isDisabled: false,
      isDisabled1: false,
      formatVideo: ["MP4", "mp4"],
      formatVideoMessage: "MP4，mp4",
      sizeVideo: 200,
      formatFile: ["pdf"],
      formatFileMessage: "pdf",
      sizeFile: 10,
      action: "/shsj_declare/file/fileUpload",
      imageList: [
        // {receiver:require("../../assets/special0.png")},
      ],
      imageList1: [],
      isVideo: false,
      isVideos: false,
      videoList: [],
      tempTextarea: null, //文本框临时存储字符
      postList: {
        programId: null, //项目ID
        specialId: null, //专项ID
        type: null, //查询那部分（1 单位基本情况 2申报资料 3 图片视频 4 加报年度设计企业/年度设计人物 5 附件上传）
        hpUnitBase: {
          //单位基本情况
          unitName: "", //申报单位名称
          unitCode: "", //社会信用代码
          taxRegistrationArea: "", //税务登记区
          declareType: null, //申报类别(字典)
          email: null, //电子邮箱
          declareUnitType: null, //申报单位类别
          declareUnitTypeOther: null, // 申报单位类别 其他
          industryInvolved: null, //所属行业
          mailingAddress: null, //通信地址
          postcode: null, //邮编
          legalPerson: null, //法人代表
          mobilePhone: null, //移动电话
          contentName: null, //联系人
          department: null, //所属部门
          position: null, //行政职务
          telephone: null, //固定电话
          mobile: null, //移动电话
          fix: null, //传真电话
          unitIntroduce: null //申报单位简介（限500字内）
        },
        hpDeclareData: {
          //申报资料
          dataName: "", //乔德云朵六分区深睡枕
          designFinishTime: null, //设计完成时间
          hasPatent: null, //是否已申请专利
          eventsTime: null, //产品上市/案例落地/事件发生时间
          hasRegisterCopyright: null, //是否已进行版权登记
          hasSales: null, //已实现销售额
          wantSales: null, //预期总销售额
          memberName: null, //设计者/团队成员
          stayUnitName: null, //所在单位
          position: null, //职务/职称
          contentPhone: null, //联系电话
          mobile: null, //移动电话
          fix: null, //传真电话
          designExplain: null, //设 计 说 明
          designValueExplain: null, //设 计 价 值 说 明
          marketValue: null, //市场价值
          socialValue: null, //社会价值
          /**
           * 产业化需求
           */
          industrializationDemand: null,
          /**
           * 产业化需求内容
           */
          industrializationDemandValue: null,
          /**
           * 商业合作需求
           */
          businessCooperationNeeds: null,
          /**
           * 商业合作需求内容
           */
          businessCooperationNeedsValue: null
        },
        hpAddDeclare: {
          //加报年度设计企业/年度设计人物
          declareType: null, //申报类别
          unitOrPerson: "", //是否是人物 0 否 1是
          personName: "", //姓名
          personPosition: null, //职务
          deedsShow: null, //人物事 迹 说 明
          wasUnit: "", //是否是企业 0 否 1是
          unitDeedsShow: null // 企业事迹说明
        },
        hpResultsImagesList: [
          //申报成果代表性图片
        ],
        hpOriginalImageList: [],
        hpCoverImage: {
          //封面图
          imageName: "",
          imagePath: ""
        },
        // "hpOriginalImage": {//原图
        //     "programId": null,
        //     "originalImageName": "",
        //     "originalIamgePath": ""
        // },
        hpVideoList: [
          //上传视频-本地上传
          {
            videoName: "",
            videoPath: ""
          }
        ],
        hpVideosList: [
          //上传视频-视频库上传
          {
            videoName: "",
            videoPath: ""
          }
        ],
        hpProgramVideosList: [], //项目对应视频库视频
        programFilesDtoList: [
          //附件上传
          {
            sfId: null,
            programFilesList: []
          }
        ]
      },
      uploadVideoTitle: [
        {
          title: "序号",
          key: "numId",
          align: "center",
          width: 80
        },
        {
          title: "视频名",
          slot: "videoName",
          align: "center"
        },
        {
          title: "上传日期",
          key: "createTime",
          align: "center"
        },
        {
          title: "操作",
          slot: "action",
          align: "center"
        }
      ],
      designFinishTime: "",
      eventsTime: "",
      programFilesDto: [], //附件
      typeFourList: [],
      uploadVideoInfo: [], //视频库信息
      specialUnitTypeLists: [], //申报单位类别
      specialTypeLists: [], //申报类别
      declareCategory: [],
      specialAreaLists: [], //税务所属区
      indrstryTypeLists: [], //所属行业
      dictionaryLists: [10720, 5000, 6000, 8000],
      videosList: [], //视频库上传文件
      videosArray: [], //视频库上传展示文件
      videosTem: [], //视频临时文件
      isFour: false,
      isFour1: false,
      isReadonly: false,
      isReadonly1: false,
      isProcess: true, //上传进度条
      processNum: 0, //
      isVideoShow: false,
      isProcessShow: false,
      fileLength: null
    };
  },
  mounted() {
    this.init();
    // this.unitBasicInformation();
    this.getDictionaryList();
    this.getIndrstryTypeLists();
  },
  watch: {
    "imageList.length": {
      handler(newValue, oldValue) {
        if (newValue == 3) {
          this.isDisabled = true;
        } else if (newValue < 3) {
          this.isDisabled = false;
        }
      }
    },
    "imageList1.length": {
      handler(newValue, oldValue) {
        console.log(6666, newValue);
        if (newValue > 5) {
          this.isDisabled1 = true;
        } else if (newValue < 6) {
          this.isDisabled1 = false;
        }
      }
    },
    typeFourList: {
      handler(newValue, oldValue) {
        if (newValue.length == 0) {
          this.isReadonly = true;
          this.isFour = false;
          this.isFour1 = false;
          this.isReadonly1 = true;
          this.postList.hpAddDeclare.personName = "";
          this.postList.hpAddDeclare.personPosition = "";
          this.postList.hpAddDeclare.deedsShow = "";
          this.postList.hpAddDeclare.unitDeedsShow = "";
        } else if (newValue[0] == 1 && newValue.length == 1) {
          this.isReadonly = false;
          this.isReadonly1 = true;
          this.isFour = true;
          this.isFour1 = false;
          this.postList.hpAddDeclare.deedsShow = "";
          this.postList.hpAddDeclare.personName = "";
          this.postList.hpAddDeclare.personPosition = "";
        } else if (newValue[0] == 2 && newValue.length == 1) {
          this.isReadonly = true;
          this.isReadonly1 = false;
          this.isFour1 = true;
          this.isFour = false;
          this.postList.hpAddDeclare.unitDeedsShow = "";
        } else {
          this.isReadonly = false;
          this.isReadonly1 = false;
          this.isFour = true;
          this.isFour1 = true;
        }
      }
    }
  },
  computed: {
    getType() {
      return this.$route.query.type;
    }
  },
  components: {
    myUpload,
    myVideo
  },
  methods: {
    /**
     * 查询初始化数据
     * */
    init() {
      this.backtop(); //回到顶部
      this.videosTem = []; //清空视频临时存储
      if (this.isActive == 6) {
        return false;
      }
      this.$http
        .post("/shsj_declare/hpDeclare/queryHpDeclareByType", {
          programId: this.programId,
          specialId: this.specialId,
          // "programId":6,
          type: this.isActive //查询页 0全部（预览） 1 单位基本情况 2申报资料 3 图片视频 4 加报年度设计企业/年度设计人物 5 附件上传
        })
        .then(res => {
          this.postList.programId = this.programId;
          this.postList.specialId = this.specialId;
          if (this.isActive == 1) {
            if (res.result.hpUnitBase) {
              this.postList.hpUnitBase = res.result.hpUnitBase;
              this.postList.hpUnitBase.programId = this.programId;
              if (this.getType != "show") {
                this.postList.hpUnitBase.unitCode = localStorage
                  .getItem("accountCode")
                  .split("-")[0];
                // this.postList.hpUnitBase.unitName = localStorage.getItem(
                //   "companyName"
                // );
                if (this.postList.hpUnitBase.unitName) {
                  this.postList.hpUnitBase.unitName =
                    res.result.hpUnitBase.unitName;
                } else {
                  this.postList.hpUnitBase.unitName = localStorage.getItem(
                    "companyName"
                  );
                }
              } else {
                if (this.postList.hpUnitBase.unitCode) {
                  this.postList.hpUnitBase.unitCode = res.result.hpUnitBase.unitCode.split(
                    "-"
                  )[0];
                } else {
                  this.postList.hpUnitBase.unitCode = localStorage
                    .getItem("accountCode")
                    .split("-")[0];
                }
                if (this.postList.hpUnitBase.unitName) {
                  this.postList.hpUnitBase.unitName =
                    res.result.hpUnitBase.unitName;
                } else {
                  this.postList.hpUnitBase.unitName = localStorage.getItem(
                    "companyName"
                  );
                }
              }
              if (this.postList.hpUnitBase.declareUnitType == 5008) {
                this.postList.hpUnitBase.declareUnitTypeOther =
                  res.result.hpUnitBase.declareUnitTypeOther;
              } else {
                this.postList.hpUnitBase.declareUnitTypeOther = "";
              }
            } else {
              this.postList.hpUnitBase.unitCode = localStorage
                .getItem("accountCode")
                .split("-")[0];
              this.postList.hpUnitBase.unitName = localStorage.getItem(
                "companyName"
              );
            }
          } else if (this.isActive == 2) {
            if (res.result.hpDeclareData) {
              this.postList.hpDeclareData = res.result.hpDeclareData;
              if (res.result.hpDeclareData.designFinishTime) {
                let dTime = res.result.hpDeclareData.designFinishTime.split(
                  "."
                );
                this.designFinishTime =
                  dTime[0] + "-" + dTime[1] + "-" + dTime[2];
              }
              if (res.result.hpDeclareData.eventsTime) {
                let eTime = res.result.hpDeclareData.eventsTime.split(".");
                this.eventsTime = eTime[0] + "-" + eTime[1] + "-" + eTime[2];
              }
            }
          } else if (this.isActive == 3) {
            if (res.result) {
              this.imageList = res.result.map.hpResultsImagesList;
              this.postList.hpResultsImagesList =
                res.result.map.hpResultsImagesList;
              this.postList.hpVideoList = res.result.map.hpVideoList;
              this.postList.hpVideosList = res.result.map.hpVideosList;
              if (res.result.map.hpCoverImage) {
                this.postList.hpCoverImage = res.result.map.hpCoverImage;
              }
              this.uploadVideoInfo = res.result.map.hpVideosList;
              if (res.result.map.hpVideoList) {
                this.isVideo = true;
                this.videoList = res.result.map.hpVideoList;
              }
              if (res.result.map.hpProgramVideosList) {
                this.isVideos = true;
                this.videosArray = res.result.map.hpProgramVideosList;
              }
              for (let i = 0; i < this.uploadVideoInfo.length; i++) {
                this.uploadVideoInfo[i].numId = i + 1;
              }
              if (
                res.result.map.hpVideoList.length > 0 ||
                res.result.map.hpProgramVideosList.length > 0
              ) {
                this.isVideoShow = true;
              } else {
                this.isVideoShow = false;
              }
            }
            if (res.result.map.hpOriginalImage) {
              this.postList.hpOriginalImageList =
                res.result.map.hpOriginalImage;
              this.imageList1 = res.result.map.hpOriginalImage;
            }
          } else if (this.isActive == 4) {
            if (res.result.hpAddDeclare) {
              this.postList.hpAddDeclare = res.result.hpAddDeclare;
              if (this.postList.hpAddDeclare.unitOrPerson) {
                if (
                  this.postList.hpAddDeclare.unitOrPerson == "1" &&
                  this.postList.hpAddDeclare.wasUnit == "1"
                ) {
                  this.typeFourList = ["1", "2"];
                } else if (
                  this.postList.hpAddDeclare.unitOrPerson == 1 &&
                  this.postList.hpAddDeclare.wasUnit == 0
                ) {
                  this.typeFourList = ["2"];
                } else if (
                  this.postList.hpAddDeclare.unitOrPerson == 0 &&
                  this.postList.hpAddDeclare.wasUnit == 1
                ) {
                  this.typeFourList = ["1"];
                }
              } else {
                this.typeFourList = [];
              }
            }
          } else if (this.isActive == 5) {
            this.programFilesDto = res.result.programFilesDto;
            this.postList.programFilesDtoList = res.result.programFilesDto;
          } else if (this.isActive == 6) {
            // this.programFilesDto = res.result.programFilesDto;
            // this.postList.programFilesDtoList = res.result.programFilesDto;
          }
        });
    },
    /**
     * 获取字典列表
     * */
    getDictionaryList() {
      for (let i in this.dictionaryLists) {
        this.$http
          .get(
            "/shsj_declare/sysDict/queryDictDataList?dictId=" +
              this.dictionaryLists[i]
          )
          .then(res => {
            if (res.messageCode == 10000) {
              if (this.dictionaryLists[i] == 10720) {
                // this.indrstryTypeLists = res.result.dictDataList;
                this.declareCategory = res.result.dictDataList;
              } else if (this.dictionaryLists[i] == 5000) {
                this.specialUnitTypeLists = res.result.dictDataList;
              } else if (this.dictionaryLists[i] == 6000) {
                this.specialTypeLists = res.result.dictDataList;
              }
            }
          });
      }
      this.$http
        .get(
          "/shsj_declare/sysDict/queryDictDataListByHp?dictId=8000&year=2024"
        )
        .then(res => {
          this.specialAreaLists = res.result.dictDataList;
          this.specialAreaLists.splice(0, 1);
        });
    },
    // 获取字典具体类目
    async getIndrstryTypeLists() {
      let { result } = await this.$http.get(
        `/shsj_declare/sysDict/queryDictDataListBySpecialId?specialId=${this.$route.query.specialId}&dictId=4100`
      );
      this.indrstryTypeLists = result.dictDataList;
    },
    /**
     * 侧边栏切换
     * */
    titleChange() {
      if (this.isActive == 1) {
        this.title = "单位基本情况";
        this.title2 = "";
      } else if (this.isActive == 2) {
        this.title = "申报作品资料";
        this.title2 = "(没有可填“无”)";
      } else if (this.isActive == 3) {
        this.title = "申报作品原图";
        this.title2 = "（3-6张）";
      } else if (this.isActive == 4) {
        this.title = "加报年度设计企业/年度设计人物（非必须）";
        this.title2 = "";
      } else if (this.isActive == 5) {
        this.title = "附件上传";
        this.title2 = "";
      } else if (this.isActive == 6) {
        this.title = "填表须知";
        this.title2 = "";
      }
      this.init();
    },
    escButton() {
      // if(this.isSaveSuccess == false){
      //     this.isModel = true;
      //     this.isEscButton = true
      // }else{
      //     this.$router.go(-1)
      // }
      if (this.getType) {
        this.$router.push("/manage");
      } else {
        this.$router.push("/declare");
      }
    },
    /**
     *  单位基本情况
     * */
    formDescription: function() {
      // if(this.isSaveSuccess == false){
      //     this.isModel = true
      // }else{
      this.isActive = 6;
      this.titleChange();
      // this.isSaveSuccess = false;
      // }
      // this.isActivePlus = 1;
      // this.titleChange();
      // this.isSaveSuccess = false;
    },
    /**
     *  单位基本情况
     * */
    unitBasicInformation: function() {
      // if(this.isSaveSuccess == false){
      //     this.isModel = true
      // }else{
      this.isActive = 1;
      this.titleChange();
      // this.isSaveSuccess = false;
      // }
      // this.isActivePlus = 1;
      // this.titleChange();
      // this.isSaveSuccess = false;
    },
    /**
     *  申报材料
     * */
    applicationMaterials: function() {
      // if(this.aaa){
      //     this.isModel = true
      // }
      // if(this.isSaveSuccess == false){
      //     this.isModel = true
      // }else{
      this.isActive = 2;
      this.titleChange();
      // this.isSaveSuccess = false;
      // }
      // this.isActivePlus = 2;
    },
    /**
     *  图片视频
     * */
    pictureVideo: function() {
      // if(this.isSaveSuccess == false){
      //     this.isModel = true
      // }else{
      this.isActive = 3;
      this.titleChange();
      //     this.isSaveSuccess = false;
      // }
      // this.isActivePlus = 3;
    },
    /**
     *  加报年度设计企业/年度设计人物
     * */
    annualDesign: function() {
      // if(this.isSaveSuccess == false){
      //     this.isModel = true
      // }else{
      this.isActive = 4;
      this.titleChange();
      //     this.isSaveSuccess = false;
      // }
      // this.isActivePlus = 4;
    },
    /**
     *  附件上传
     * */
    uploader: function() {
      // if(this.isSaveSuccess == false){
      //     this.isModel = true
      // }else{
      this.isActive = 5;
      this.titleChange();
      //     this.isSaveSuccess = false;
      // }
      // this.isActivePlus = 5;
    },
    /**
     * 复选框监听
     * */
    checked(data) {
      if (data.length == 0) {
        this.isFour = false;
        this.isFour1 = false;
        this.isReadonly = true;
        this.isReadonly1 = true;
        this.postList.hpAddDeclare.personName = "";
        this.postList.hpAddDeclare.personPosition = "";
        this.postList.hpAddDeclare.deedsShow = "";
        this.postList.hpAddDeclare.unitDeedsShow = "";
      } else if (data[0] == 1 && data.length == 1) {
        this.isFour = true;
        this.isFour1 = false;
        this.isReadonly = false;
        this.isReadonly1 = true;
        this.postList.hpAddDeclare.deedsShow = "";
        this.postList.hpAddDeclare.personName = "";
        this.postList.hpAddDeclare.personPosition = "";
      } else if (data[0] == 2 && data.length == 1) {
        this.isFour1 = true;
        this.isFour = false;
        this.isReadonly = true;
        this.isReadonly1 = false;
        this.postList.hpAddDeclare.unitDeedsShow = "";
      } else {
        this.isFour = true;
        this.isFour1 = true;
        this.isReadonly = false;
        this.isReadonly1 = false;
      }
    },
    // /**
    // * 文本框监听回车
    // * */
    // getEntry(){
    //     console.log(event)
    //     console.log(event.keyCode)
    //     console.log(111,this.postList.hpUnitBase.unitIntroduce)
    //     let code=event.keyCode;
    //     if(code==13 && this.tempTextarea==13){
    //         console.log(222)
    //         this.postList.hpUnitBase.unitIntroduce.substr(0, this.postList.hpUnitBase.unitIntroduce.length - 1);
    //         this.tempTextarea = code
    //     }
    // },
    /**
     * 保存校验
     * */
    regValidate(params) {
      let _this = this;
      let regu = /(^(\d{3,4}-)?\d{7,8})$/; //固话和传真
      let regu1 = /^((1[0-9][0-9]\d{8}$))/; //手机
      let regu2 = /^[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/; //社会信用代码
      let regu3 = /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/; //邮箱
      let regu4 = /^[0-9]{6}$/; //邮编
      let re = new RegExp(regu);
      let re1 = new RegExp(regu1);
      let re2 = new RegExp(regu2);
      let re3 = new RegExp(regu3);
      let re4 = new RegExp(regu4);
      if (params.telephone) {
        if (!re.test(params.telephone)) {
          this.$Spin.hide();
          this.$Message.error("固定电话格式错误");
          _this.isSave = false;
          return;
        } else {
          _this.isSave = true;
        }
      } else {
        _this.isSave = true;
      }
      if (params.mobilePhone) {
        if (!re1.test(params.mobilePhone)) {
          this.$Spin.hide();
          this.$Message.error("负责人移动电话格式错误");
          _this.isSave = false;
          return;
        } else {
          _this.isSave = true;
        }
      } else {
        _this.isSave = true;
      }
      if (params.mobile) {
        if (!re1.test(params.mobile)) {
          this.$Spin.hide();
          this.$Message.error("联系人移动电话格式错误");
          _this.isSave = false;
          return;
        } else {
          _this.isSave = true;
        }
      } else {
        _this.isSave = true;
      }
      if (params.fix) {
        if (!re.test(params.fix)) {
          this.$Spin.hide();
          this.$Message.error("传真电话格式错误");
          _this.isSave = false;
          return;
        } else {
          _this.isSave = true;
        }
      } else {
        _this.isSave = true;
      }
      if (params.unitCode) {
        // if(!re2.test(params.unitCode)){
        //     this.$Spin.hide();
        //     this.$Message.error("社会信用代码格式错误");
        //     _this.isSave = false;
        //     return
        // }else{
        //     _this.isSave = true;
        // }
        if (_this.$util.CheckSocialCreditCode(params.unitCode)) {
          _this.isSave = true;
        } else {
          this.$Spin.hide();
          this.$Message.error("社会信用代码格式错误");
          _this.isSave = false;
          return;
        }
      } else {
        _this.isSave = true;
      }
      if (params.contentPhone) {
        if (re.test(params.contentPhone) || re1.test(params.contentPhone)) {
          _this.isSave = true;
        } else {
          this.$Spin.hide();
          this.$Message.error("联系电话格式错误");
          _this.isSave = false;
          return;
        }
      } else {
        _this.isSave = true;
      }
      if (params.email) {
        // let regu1 = _this.$util.validate("email");
        // if(!regu1[0].test(_this.postList.hpUnitBase.regu1)){
        if (!re3.test(params.email)) {
          this.$Spin.hide();
          this.$Message.error("请输入包含@和.的正确邮箱！");
          _this.isSave = false;
          return;
        } else {
          _this.isSave = true;
        }
      } else {
        _this.isSave = true;
      }
      if (params.postcode) {
        if (!re4.test(params.postcode)) {
          this.$Spin.hide();
          this.$Message.error("邮编格式错误");
          _this.isSave = false;
          return;
        } else {
          _this.isSave = true;
        }
      } else {
        _this.isSave = true;
      }
    },
    /**
     * 申报材料模块特殊字段校验
     * */
    hpDeclareDataVail() {
      if (
        this.postList.hpDeclareData.designExplain &&
        this.postList.hpDeclareData.marketValue &&
        this.postList.hpDeclareData.socialValue
      ) {
        // if (this.postList.hpDeclareData.designExplain.length < 200) {
        //   this.$Spin.hide();
        //   this.$Message.error("设计说明字数小于200字，请继续填写");
        //   this.isSave = false;
        //   return;
        // } else
        if (this.postList.hpDeclareData.marketValue.length < 200) {
          this.$Spin.hide();
          this.$Message.error("市场价值字数小于200字，请继续填写");
          this.isSave = false;
          return;
        } else if (
          this.postList.hpDeclareData.marketValue.length +
            this.postList.hpDeclareData.socialValue.length >
          500
        ) {
          this.$Spin.hide();
          this.$Message.error("市场价值加社会价值字数不能大于500字");
          this.isSave = false;
          return;
        }
      } else {
        this.$Spin.hide();
        this.$Message.error("设计说明、市场价值、社会价值有必填项未填");
        this.isSave = false;
        return;
      }

      if (
        this.postList.hpDeclareData.unitAdvise &&
        !this.postList.hpDeclareData.recommendUnitAdvise
      ) {
        this.$Spin.hide();
        this.$Message.error("推荐单位意见未填写");
        this.isSave = false;
        return;
      } else if (
        !this.postList.hpDeclareData.unitAdvise &&
        this.postList.hpDeclareData.recommendUnitAdvise
      ) {
        this.$Spin.hide();
        this.$Message.error("推荐单位未填写");
        this.isSave = false;
        return;
      }
      // if (!this.postList.hpDeclareData.industrializationDemand) {
      //   this.$Spin.hide();
      //   this.$Message.error("请填写产业化需求");
      //   this.isSave = false;
      //   return;
      // }
      // if (!this.postList.hpDeclareData.businessCooperationNeeds) {
      //   this.$Spin.hide();
      //   this.$Message.error("请填写商业合作需求");
      //   this.isSave = false;
      //   return;
      // }
      // if (
      //   this.postList.hpDeclareData.industrializationDemand == "1" &&
      //   this.postList.hpDeclareData.industrializationDemandValue
      // ) {
      //   this.$Spin.hide();
      //   this.$Message.error("请填写产业化需求内容");
      //   this.isSave = false;
      //   return;
      // }
      // if (
      //   this.postList.hpDeclareData.businessCooperationNeeds == "1" &&
      //   this.postList.hpDeclareData.businessCooperationNeedsValue
      // ) {
      //   this.$Spin.hide();
      //   this.$Message.error("请填写商业合作需求内容");
      //   this.isSave = false;
      //   return;
      // }
    },
    /**
     * 保存
     * */
    save() {
      this.$Spin.show();
      let _this = this;
      this.postList.type = this.isActive;
      if (this.isActive == 1) {
        this.regValidate(this.postList.hpUnitBase);
        if (!this.postList.hpUnitBase.unitIntroduce) {
          this.$Spin.hide();
          this.$Message.error("申报单位简介不能为空");
          _this.isSave = false;
          return;
        }
        // if (this.postList.hpUnitBase.unitIntroduce) {
        //   if (this.postList.hpUnitBase.unitIntroduce.length < 200) {
        //     this.$Spin.hide();
        //     this.$Message.error("申报单位简介字数小于200字，请继续填写");
        //     _this.isSave = false;
        //     return;
        //   }
        // } else {
        //   this.$Spin.hide();
        //   this.$Message.error("申报单位简介不能为空");
        //   _this.isSave = false;
        //   return;
        // }
        //         if (this.postList.hpUnitBase.unitIntroduce) {
        //   if (this.postList.hpUnitBase.unitIntroduce.length < 200) {
        //     this.$Spin.hide();
        //     this.$Message.error("申报单位简介字数小于200字，请继续填写");
        //     _this.isSave = false;
        //     return;
        //   }
        // } else {
        //   this.$Spin.hide();
        //   this.$Message.error("申报单位简介不能为空");
        //   _this.isSave = false;
        //   return;
        // }

        setTimeout(function() {
          if (_this.isSave == true) {
            _this.saveBase(_this.postList);
          }
        }, 1000);
      } else if (this.isActive == 2) {
        if (!this.postList.hpDeclareData.hasSales) {
          this.postList.hpDeclareData.hasSales = null;
        }
        if (!this.postList.hpDeclareData.wantSales) {
          this.postList.hpDeclareData.wantSales = null;
        }
        if (!this.postList.hpDeclareData.designFinishTime) {
          this.postList.hpDeclareData.designFinishTime = null;
        }
        if (!this.postList.hpDeclareData.eventsTime) {
          this.postList.hpDeclareData.eventsTime = null;
        }
        this.regValidate(this.postList.hpDeclareData);
        this.hpDeclareDataVail();
        setTimeout(function() {
          if (_this.isSave == true) {
            _this.saveBase(_this.postList);
          }
        }, 1000);
      } else if (this.isActive == 3) {
        if (this.imageList1.length < 3) {
          this.$Spin.hide();
          this.$Message.error("原图图片小于三张，请继续上传");
          _this.isSave = false;
          return;
        } else {
          _this.isSave = true;
        }
        if (this.imageList.length < 3) {
          this.$Spin.hide();
          this.$Message.error("申报代表性展示图小于三张，请继续上传");
          _this.isSave = false;
          return;
        } else {
          _this.isSave = true;
        }
        for (let i = 0; i < this.postList.hpVideosList.length; i++) {
          delete this.postList.hpVideosList[i].numId;
        }
        this.postList.hpOriginalImageList = this.imageList1;
        this.postList.hpResultsImagesList = this.imageList;
        this.postList.hpVideoList = this.videoList;
        if (this.videosTem) {
          this.postList.hpVideosList = this.videosTem;
        } else {
          this.postList.hpVideosList = [];
        }
        this.postList.hpProgramVideosList = this.videosArray;
        this.saveBase(this.postList);
      } else if (this.isActive == 4) {
        if (this.typeFourList[0] != null || this.typeFourList[1] != null) {
          if (this.typeFourList.length == 2) {
            this.postList.hpAddDeclare.unitOrPerson = "1";
            this.postList.hpAddDeclare.wasUnit = "1";
          } else if (this.typeFourList[0] == 1) {
            this.postList.hpAddDeclare.wasUnit = "1";
            this.postList.hpAddDeclare.unitOrPerson = "0";
          } else if (this.typeFourList[0] == 2) {
            this.postList.hpAddDeclare.unitOrPerson = "1";
            this.postList.hpAddDeclare.wasUnit = "0";
          }
        } else {
          this.postList.hpAddDeclare.unitOrPerson = null;
          this.postList.hpAddDeclare.wasUnit = null;
        }
        this.saveBase(this.postList);
      } else if (this.isActive == 5) {
        this.saveBase(this.postList);
      }
    },
    saveBase(params) {
      this.$http
        .post("/shsj_declare/hpDeclare/fillInHpDeclareInfo", params)
        .then(res => {
          if (res.messageCode == 10000) {
            this.$Spin.hide();
            this.$Message.success(res.messageContent);
            // this.isSaveSuccess = true;
            this.programId = res.result.programId;
            this.init();
          }
        });
    },
    /**
     *  保存并跳转下一步
     * */
    saveNext() {
      let _this = this;
      _this.save();
      setTimeout(function() {
        //     if(_this.isSaveSuccess){
        if (_this.isSave == true) {
          if (_this.isActive != 5) {
            if (_this.isActive == 3) {
              _this.isActive = _this.isActive + 2;
            } else {
              _this.isActive = _this.isActive + 1;
            }
            _this.titleChange();
            // _this.isSaveSuccess = false;
          }
        }
        //     }
      }, 1000);
    },
    /**
     *  保存并预览
     * */
    saveView() {
      this.$Spin.show();
      if (this.videosTem) {
        this.postList.hpVideosList = this.videosTem;
      } else {
        this.postList.hpVideosList = [];
      }
      this.postList.type = this.isActive;
      this.$http
        .post("/shsj_declare/hpDeclare/fillInHpDeclareInfo", this.postList)
        .then(res => {
          if (res.messageCode == 10000) {
            this.$Spin.hide();
            this.$Message.success(res.messageContent);
            // this.isSaveSuccess = true;
            this.$router.push({
              path: "/shanghaiDesignAuit",
              query: {
                type: "view",
                programId: this.programId,
                specialId: this.specialId
              }
            });
          }
        });
    },
    /**
     *  提交
     * */
    sumbit() {
      this.$Spin.show();
      let _this = this;
      console.log(this.programId);
      this.postList.type = this.isActive;
      this.$http
        .post("/shsj_declare/hpDeclare/fillInHpDeclareInfo", this.postList)
        .then(res => {
          if (res.messageCode == 10000) {
            _this.$Spin.hide();
            this.programId = res.result.programId;
            this.$http
              .get(
                "/shsj_declare/hpDeclare/submitProgram?programId=" +
                  _this.programId
              )
              .then(res => {
                _this.$Spin.hide();
                if (res.messageCode == 10000) {
                  _this.$Message.success(res.messageContent);
                  _this.$router.push({
                    path: "/manage",
                    query: {
                      type: "view",
                      programId: _this.programId,
                      specialId: _this.specialId
                    }
                  });
                }
              });
          }
        });
    },
    /**
     *  视频库删除
     * */
    videoDel(row, index) {
      this.isModelVideo = true;
      this.videoId = row.videoId;
      this.videoIds = index;
    },
    videoDel1(index) {
      this.isModelVideo1 = true;
      this.videoIds = index;
      this.isJudge = true;
    },
    videoDel2(index) {
      this.isModelVideo1 = true;
      this.videoIds = index;
      this.isJudge1 = true;
    },
    /**
     *  图片删除
     * */
    imageDel(index) {
      this.isModelImg = true;
      this.imageId = index;
    },
    imageDel1(index) {
      this.isModelImg1 = true;
      this.imageId = index;
    },
    /**
     *  图片上传成功
     * */
    uploadSuccessImg($event) {
      console.log($event);
      if (
        ($event.wdith <= 1920 && $event.heigh <= 1280) ||
        ($event.wdith <= 1280 && $event.heigh <= 1920)
      ) {
        this.$Message.success("上传成功!");
        let image = $event;
        let object = {};
        object.imagePath = image.filePath;
        object.imageName = image.fileName;
        this.imageList.push(object);
        // this.postList.hpResultsImagesList.push(object)
      } else {
        this.$Message.error("图片尺寸不合适!");
      }
    },
    /**
     *  原图上传成功
     * */
    uploadSuccessImg2($event) {
      this.$Message.success("上传成功!");
      let image = $event;
      let object = {};
      object.oiId = null;
      object.programId = this.programId;
      object.originalImagePath = image.filePath;
      object.originalImageName = image.fileName;
      this.imageList1.push(object);
      // this.postList.hpOriginalImage.programId = this.programId;
      // this.postList.hpOriginalImage.originalImagePath = image.filePath;
      // this.postList.hpOriginalImage.originalImageName = image.fileName;
    },
    /**
     *  封面图上传成功
     * */
    uploadSuccessImg3($event) {
      if ($event.heigh == 400 && $event.wdith == 600) {
        this.$Message.success("上传成功!");
        let image = $event;
        this.postList.hpCoverImage.imagePath = image.filePath;
        this.postList.hpCoverImage.imageName = image.fileName;
      } else {
        this.$Message.error("图片尺寸不合适!");
      }
    },
    /**
     *  视频上传成功
     * */
    uploadSuccessVideo($event) {
      this.isVideo = true;
      let video = $event;
      let object = {};
      let objList = [];
      object.programId = this.programId;
      object.videoPath = video.filePath;
      object.videoName = video.fileName;
      objList.push(object);
      // this.postList.hpVideosList.push(object);
      this.videoList = objList;
      this.videosTem = objList;
      if (this.videoList.length > 0) {
        this.isVideoShow = true;
      } else {
        this.isVideoShow = false;
      }
    },
    uploadProgressVideo($event) {
      this.processNum = parseInt($event);
      let that = this;
      setTimeout(function() {
        if ($event == 100) {
          that.isProcessShow = false;
          that.processNum = 0;
        }
      }, 1000);
    },
    processShow($event) {
      this.isProcessShow = $event;
    },
    /**
     *  视频库上传
     * */
    uploadSuccessVideos() {
      this.isModelVideo2 = true;
    },
    okVideos($event) {
      this.isModelVideo2 = $event[0];
      let object = {};
      object.fileName = this.postList.hpVideosList[$event[1]].videoName;
      object.filePath = this.postList.hpVideosList[$event[1]].videoPath;
      let objectList = [];
      objectList.push(object);
      this.videosArray = objectList;
      this.isVideos = true;
      if (this.videosArray.length > 0) {
        this.isVideoShow = true;
      } else {
        this.isVideoShow = false;
      }
    },
    cancelVideos($event) {
      this.isModelVideo2 = $event;
    },
    /**
     *  文件上传成功
     * */
    uploadSuccessFile(index, arg) {
      let file = arg[0];
      this.postList.programFilesDtoList[index].programFilesList.push(file);
    },
    /**
     *  文件删除
     * */
    removeFile(index, arg) {
      if (arg[0].url) {
        let removeURL = this.postList.programFilesDtoList[
          index
        ].programFilesList.findIndex(item => item.filePath == arg[0].url);
        this.postList.programFilesDtoList[index].programFilesList.splice(
          removeURL,
          1
        );
      } else {
        if (arg[0].response.messageCode == 10000) {
          let delindex = this.postList.programFilesDtoList[
            index
          ].programFilesList.findIndex(
            item => item.filePath == arg[0].response.result.returnFile.filePath
          );
          this.postList.programFilesDtoList[index].programFilesList.splice(
            delindex,
            1
          );
        } else {
          this.$Message.error(arg[0].response.messageContent);
        }
      }
      // for (let i in this.postList.programFilesDtoList[index].programFilesList) {
      //   if (
      //     arg[0].response.result.returnFile.filePath ==
      //     this.postList.programFilesDtoList[index].programFilesList[i].filePath
      //   ) {
      //     this.postList.programFilesDtoList[index].programFilesList.splice(
      //       i,
      //       1
      //     );
      //   }
      // }
    },
    // delFile(item, index1, index) {
    //   this.postList.programFilesDtoList[index].programFilesList.splice(
    //     index1,
    //     1
    //   );
    // },
    /**
     *  弹窗函数
     * */
    // ok () {
    //     let _this = this;
    //     this.save();
    //     if(this.isEscButton == false){
    //         setTimeout(function(){
    //             if(_this.isSaveSuccess){
    //                 _this.isActive = _this.isActivePlus;
    //                 _this.titleChange();
    //                 _this.isSaveSuccess = false;
    //                 _this.isModel = false;
    //             }
    //         },2000)
    //     }else if(this.isEscButton == true){
    //         this.$router.go(-1)
    //     }
    // },
    // cancel () {
    //     if(this.isEscButton == false){
    //         this.isActive = this.isActivePlus;
    //         this.titleChange();
    //         this.isModel = false;
    //     }else if(this.isEscButton == true){
    //         this.$router.go(-1)
    //     }
    // },
    /**
     *  视频删除弹窗
     * */
    okDel() {
      this.$http
        .get("/shsj_declare/hpDeclare/removeHpVideos?videoId=" + this.videoId)
        .then(res => {
          if (res.messageCode == 10000) {
            this.$Message.success(res.messageContent);
            this.init();
            this.isModelVideo = false;
          }
        });
    },
    cancelDel() {
      if (this.isModelImg == true) {
        this.isModelImg = false;
      } else if (this.isModelVideo == true) {
        this.isModelVideo = false;
      }
    },
    okDelVideo() {
      if (this.isJudge) {
        this.videoList.splice(this.videoIds, 1);
        // this.postList.hpVideoList.splice(this.videoIds, 1);
        this.isJudge = false;
        if (this.videoList.length > 0) {
          this.isVideoShow = true;
        } else {
          this.isVideoShow = false;
        }
      } else if (this.isJudge1) {
        this.videosArray.splice(this.videoIds, 1);
        this.isJudge1 = false;
        if (this.videosArray.length > 0) {
          this.isVideoShow = true;
        } else {
          this.isVideoShow = false;
        }
      }
      this.isModelVideo1 = false;
    },
    cancelDelVideo() {
      this.isModelVideo1 = false;
      this.isJudge = false;
      this.isJudge1 = false;
    },
    /**
     *  图片删除弹窗
     * */
    okImgDel() {
      this.imageList.splice(this.imageId, 1);
      this.isModelImg = false;
    },
    cancelImgDel() {
      this.isModelImg = false;
    },
    okImgDel1() {
      this.imageList1.splice(this.imageId, 1);
      this.isModelImg1 = false;
    },
    cancelImgDel1() {
      this.isModelImg1 = false;
    },
    /**日期格式化
     * method appStartTime
     * */
    finishTime(e) {
      this.postList.hpDeclareData.designFinishTime = e;
    },
    eventTime(e) {
      this.postList.hpDeclareData.eventsTime = e;
    },
    /**输入框限制10位数字和两位小数
     * method checkInput dealInputVal
     * */
    checkInput() {
      this.postList.hpDeclareData.hasSales = this.dealInputVal(
        this.postList.hpDeclareData.hasSales
      );
    },
    checkInput2() {
      this.postList.hpDeclareData.wantSales = this.dealInputVal(
        this.postList.hpDeclareData.wantSales
      );
    },
    dealInputVal(value) {
      let reg = /.*\..*/;
      if (reg.test(value)) {
        this.maxLength = 11;
      } else {
        this.maxLength = 10;
      }
      value = value.replace(/^0*(0\.|[1-9])/, "$1");
      value = value.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
      value = value.replace(/^\./g, ""); //验证第一个字符是数字而不是字符
      value = value.replace(/\.{1,}/g, "."); //只保留第一个.清除多余的
      value = value
        .replace(".", "$#$")
        .replace(/\./g, "")
        .replace("$#$", ".");
      value = value.replace(/^(\-)*(\d*)\.(\d\d).*$/, "$1$2.$3"); //只能输入两个小数
      value =
        value.indexOf(".") > 0
          ? value.split(".")[0].substring(0, 10) + "." + value.split(".")[1]
          : value.substring(0, 10);
      return value;
    },
    backtop() {
      let timer = setInterval(function() {
        let osTop =
          document.documentElement.getElementsByClassName("content-right")[0]
            .scrollTop ||
          document.body.getElementsByClassName("content-right")[0].scrollTop;
        let ispeed = Math.floor(-osTop - 100);
        document.documentElement.getElementsByClassName(
          "content-right"
        )[0].scrollTop = document.body.getElementsByClassName(
          "content-right"
        )[0].scrollTop = osTop + ispeed;
        if (osTop === 0) {
          clearInterval(timer);
        }
      }, 30);
    },
    aa() {
      alert("111");
    }
  }
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.top-tab {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 65px;
  padding-left: 40px;
}
.modeCompany {
  display: flex;
  flex-direction: row;
  background-color: rgba(248, 248, 248, 1);
  height: calc(100vh - 160px);
  overflow: hidden;
}
.content-left {
  width: 250px;
  padding-top: 20px;
  background-color: #ffffff;
  cursor: pointer;
}
.content-left div {
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 20px;
  font-size: 14px;
}
.active {
  background-color: #3385ff;
  color: white;
  border-radius: 5px;
}
.content-right {
  margin-left: 10px;
  padding: 50px 30px;
  background-color: white;
  width: 100%;
  overflow-y: auto;
}
.content-right-title {
  font-size: 20px;
}
.content-right-title1 {
  font-size: 14px;
  color: #999999;
  margin-left: 10px;
}
.content-row {
  border-top: 1px solid #ededed;
  border-left: 1px solid #ededed;
  margin-top: 20px;
}
.key {
  background-color: #f6f9ff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  font-size: 14px;
  min-height: 50px;
}
.key1 {
  background-color: #f6f9ff;
  display: flex;
  flex-direction: row;
  /*align-items: center;*/
  justify-content: center;
  border-right: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  font-size: 14px;
  min-height: 50px;
  padding-top: 5px;
}
.key-upload {
  width: 33.3%;
  background-color: #f6f9ff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 50px;
}
.value-upload {
  min-height: 50px;
  border-right: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  font-size: 14px;
  text-align: center;
}
.value-upload1 {
  width: 66.6%;
  padding-left: 40px;
  padding-top: 5px;
}
.mustWrite {
  color: red;
  font-size: 24px;
  margin-right: 10px;
  margin-top: 7px;
}
.value {
  min-height: 50px;
  display: flex;
  flex-direction: row;
  /*align-items: center;*/
  border-right: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  font-size: 14px;
  padding-top: 10px;
}
.value1 {
  min-height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-right: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  font-size: 14px;
  /*padding-top: 10px;*/
}
.value-radio {
  min-height: 50px;
  display: flex;
  flex-direction: row;
  /*align-items: center;*/
  border-right: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  font-size: 14px;
  padding-left: 10px;
  padding-top: 15px;
}
.value-radio1 {
  min-height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-right: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  font-size: 14px;
  padding-left: 30px;
  /*padding-top: 15px;*/
}
.input {
  /*justify-content: fl;*/
  padding-left: 15px;
}
.value input {
  width: 80%;
  height: 30px;
  border: 1px solid #eeeeee;
  outline: none;
}
.content-right-title2 {
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #f6f9ff;
  border-right: 1px solid #ededed;
  border-left: 1px solid #ededed;
}
.content-right-content {
  border: 1px solid #ededed;
  // min-height: 200px;
  /*border-top: 0;*/
}
.content-right-content textarea {
  padding: 20px 30px;
}
.content-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /*align-items: center;*/
  margin-top: 50px;
  margin-bottom: 40px;
}
.content-button button {
  width: 120px;
  height: 40px;
  border-radius: 5px;
  outline: none;
  font-size: 14px;
  margin-left: 30px;
  cursor: pointer;
}
.button-esc {
  border: 1px solid #d1d1d1;
  background-color: #ffffff;
  color: #333333;
}
.button-save {
  width: 120px;
  height: 40px;
  border-radius: 5px;
  outline: none;
  font-size: 14px;
  margin-left: 30px;
  border: 1px solid #6eb1ff;
  color: #6eb1ff;
  background-color: #ffffff;
}
.button-save1 {
  width: 120px;
  height: 40px;
  border-radius: 5px;
  outline: none;
  font-size: 14px;
  margin-left: 30px;
  border: 1px solid #cccccc;
  color: #cccccc;
  background-color: #ffffff;
}
.button-next {
  background-color: #6eb1ff;
  color: #ffffff;
  border: 0;
}
.imgStyle {
  width: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.imgStyle .imgUpload {
  /*display: inline-block;*/
  width: 300px;
  height: 200px;
  margin-top: 30px;
}
.imgStyle1 {
  width: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.imgStyle1 .imgUpload1 {
  /*display: inline-block;*/
  width: 185px;
  height: 165px;
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ececec;
}
.imgStyle .images {
  /*display: inline-block;*/
  width: 280px;
  height: 200px;
  margin-top: 30px;
  position: relative;
}
.imgStyle1 .images1 {
  /*display: inline-block;*/
  width: 185px;
  height: 165px;
  /*margin-top: 30px;*/
  margin-left: 20px;
}
.imgStyle .imgUpload:nth-child(1) {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ececec;
}
.imgStyle .images:nth-child(2) {
  margin-left: 10px;
}
.imgStyle .images:nth-child(3) {
  margin-left: 10px;
}
.masterMap {
  margin-top: 20px;
  margin-bottom: 20px;
}
.masterMapUpload {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}
.masterMapUpload div:nth-child(1) button {
  margin-left: 0;
}
.masterMapUpload div:nth-child(2) {
  color: #999999;
  margin-left: 20px;
  font-size: 14px;
}
.uploadVideo {
  display: flex;
  flex-direction: row;
  /*padding-left: 30px;*/
  padding-top: 40px;
}
.video-sky {
  height: 10px;
  background-color: rgba(248, 248, 248, 1);
}
.uploadVideo-left {
  width: 50%;
}
.uploadVideo-right {
  width: 50%;
}
.uploadVideoLocal {
  margin-top: 50px;
}
.uploadVideoList {
  margin-top: 50px;
}
.video-table {
  margin-top: 30px;
}
.uploadClick {
  width: 200px;
  height: 35px;
  border-radius: 20px;
  outline: none;
  border: 0;
  background-color: #efefef;
  color: #666666;
}
.fileStyle {
  justify-content: left;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-center {
  justify-content: center;
}
.uploadFile {
  align-items: center;
}
.image-del {
  position: relative;
  top: -120px;
  left: 124px;
  display: none;
}
.images:hover .image-del {
  display: block;
}
.images:hover img {
  opacity: 0.3;
}
.attention {
  margin: 15px 0;
  span {
    color: red;
  }
}
.needingAttention {
  padding: 10px 80px;
  .title {
    text-align: center;
    font-size: 25px;
    margin-bottom: 20px;
  }
  .item {
    // text-indent: 2rem;
    margin: 20px 0;
    font-size: 16px;
  }
}
::v-deep .ivu-input:focus {
  outline: none;
  box-shadow: 0 0 0 2px transparent;
  // border: 2px solid #000 !important;
}
::v-deep .ivu-input {
  // outline: none;
  border: none;
  // box-shadow: 0 0 0 2px transparent;
}
</style>
