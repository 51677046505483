<template>
  <div class="industryDesign">
    <Crumbs :transformText="transformText" title="市级设计创新中心"></Crumbs>
    <Row>
      <Col span="5">
        <BroadsideTab :tabList="tabList"></BroadsideTab>
      </Col>
      <Col span="19">
        <Row style="font-size:18px">
          <Col span="2">类别:</Col>
          <Col span="22">
            <RadioGroup size="large" v-model="type">
              <!-- <Radio style="font-size:18px" label="1">企业工业设计中心</Radio>
              <Radio style="font-size:18px" label="2"
                >工业设计企业<span style="font-size:14px;color:#FF0000"
                  >（请根据企业实际情况选择）</span
                ></Radio -->
              <Radio style="font-size:18px" label="1">企业设计创新中心</Radio>
              <Radio style="font-size:18px" label="2"
                >设计创新企业<span style="font-size:14px;color:#FF0000"
                  >（请根据企业实际情况选择）</span
                ></Radio
              >
            </RadioGroup>
          </Col>
        </Row>
        <!--  -->
        <router-view style="overflow-y:auto; " class="component"></router-view>
      </Col>
    </Row>
    <div class="transbox">
      <div style="overflow: hidden;flex:1;">
        <div class="text">
          <span style="color:red">填表须知:</span
          >{{ type === "1" ? transformText : transformText2 }}
        </div>
      </div>
      <Button type="text" @click="modal = true" style="color:red">
        展开<Icon type="ios-arrow-down" />
      </Button>
    </div>
    <Modal v-model="modal" title="填表须知" footer-hide>
      <div class="modal" v-if="type === '1'">
        <p>1.本申请表适用于申报设计创新中心的企事业单位填写。</p>
        <p>
          2.填写本申请表应确保所填资料真实准确。
        </p>
        <p>3.本申请表所有填报项目页面不足时，可另附页面。</p>
        <p>4.申请声明末尾务必请申请单位法定代表人签名，并加盖单位公章。</p>
        <p>
          5.申请表中营收、盈利、人员、资产、完成项目、成果产业化、获奖、知识产权、设计标准等事项需附相关佐证材料。申报单位认为其它有必要的事项，可根据需要提供佐证材料。
        </p>
      </div>
      <div class="modal" v-else>
        <p>1.本申请表适用于申报设计创新企业的单位填写。</p>
        <p>
          2.填写本申请表应确保所填资料真实准确。
        </p>
        <p>3.本申请表所有填报项目页面不足时，可另附页面。</p>
        <p>4.申请声明末尾务必请申请单位法定代表人签名，并加盖单位公章。</p>
        <p>
          5.申请表中营收、盈利、人员、资产、完成项目、成果产业化、获奖、知识产权、设计标准等事项需附相关佐证材料。申报单位认为其它有必要的事项，可根据需要提供佐证材料。
        </p>
      </div>
    </Modal>
  </div>
</template>

<script>
import BroadsideTab from "../../components/broadsideTab";
import Crumbs from "../../components/crumbs";
export default {
  components: {
    BroadsideTab,
    Crumbs
  },
  name: "",
  props: {},
  data() {
    return {
      modal: false,
      transformText:
        "1.本申请表适用于申报设计创新中心的企事业单位填写。2.填写本申请表应确保所填资料真实准确。3.本申请表所有填报项目页面不足时，可另附页面。4.申请声明末尾务必请申请单位法定代表人签名，并加盖单位公章。5.申请表中营收、盈利、人员、资产、完成项目、成果产业化、获奖、知识产权、设计标准等事项需附相关佐证材料。申报单位认为其它有必要的事项，可根据需要提供佐证材料。",
      transformText2:
        "1.本申请表适用于申报设计创新企业的单位填写。2.填写本申请表应确保所填资料真实准确。3.本申请表所有填报项目页面不足时，可另附页面。4.申请声明末尾务必请申请单位法定代表人签名，并加盖单位公章。5.申请表中营收、盈利、人员、资产、完成项目、成果产业化、获奖、知识产权、设计标准等事项需附相关佐证材料。申报单位认为其它有必要的事项，可根据需要提供佐证材料。",
      // heightRange: null,
      // sideIndex: 0,
      industryCenter: [],
      type: "1",
      list: {
        arry1: [
          // { label: "填表须知", path: "/declare/NeedingAttention" },
          { label: "所在企业情况", path: "/declare/companysituation" },
          {
            // label: "工业设计中心情况",
            label: "设计创新中心情况",
            path: "/declare/centersitutation"
          },
          // { label: "资源及绩效", path: "/declare/resourceperformance" },

          // { label: "现状及规划", path: "/declare/statusplanning" },
          {
            // label: "工业设计团队带头人及主要成员情况",
            label: "设计创新团队带头人及主要成员情况",
            path: "/declare/Personnel"
          },
          { label: "附件上传", path: "/declare/AttachmentUpload" }
        ],
        arry2: [
          // { label: "填表须知", path: "/declare/NeedingAttentionTwo" },
          { label: "设计创新企业", path: "/declare/industrialdesign" },
          // { label: "资源及绩效", path: "/declare/Resources" },

          // { label: "现状及规划", path: "/declare/status" },
          {
            label: "设计创新团队带头人及主要成员情况",
            path: "/declare/member"
          },
          { label: "附件上传", path: "/declare/industryupload" }
        ]
      },
      tabList: [
        // { label: "填表须知", path: "/declare/NeedingAttention" },
        { label: "所在企业情况", path: "/declare/companysituation" },
        { label: "设计创新中心情况", path: "/declare/centersitutation" },
        { label: "资源及绩效", path: "/declare/resourceperformance" },
        { label: "现状及规划", path: "/declare/statusplanning" },
        {
          label: "设计创新团队带头人及主要成员情况",
          path: "/declare/Personnel"
        },
        { label: "附件上传", path: "/declare/AttachmentUpload" }
      ]
    };
  },
  created() {
    // this.heightRange = "calc(100vh - 200 px)";
    var index = this.tabList.findIndex(item => {
      return item.path == this.$route.fullPath.split("?")[0];
    });
    if (index != -1) {
      this.tabList = this.list.arry1;
      this.type = "1";
    } else {
      this.tabList = this.list.arry2;
      this.type = "2";
      this.$router.push(this.$route.fullPath);
    }
  },
  mounted() {},
  methods: {},
  watch: {
    type: function(newType, oldType) {
      if (newType === "1") {
        this.tabList = this.list.arry1;
        this.$router.push("/declare/companysituation");
      } else {
        this.tabList = this.list.arry2;
        this.$router.push("/declare/industrialdesign");
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.modal {
  font-size: 14px;
  p {
    margin-bottom: 10px;
  }
}
.industryDesign {
  position: relative;
}
.component {
  padding: 50px 20px 50px 0;
  height: calc(100vh - 220px);
}
.transbox {
  display: flex;
  align-items: center;
  position: absolute;
  top: 10px;
  left: 250px;
  width: 900px;
  .text {
    cursor: pointer;
    width: 2600px;
    animation: move 50s linear infinite normal backwards;
    &:hover,
    &:focus {
      animation-play-state: paused;
    }
  }
}
.red {
  color: red;
}
@keyframes move {
  0% {
    transform: translateX(1000px);
  }
  20% {
    transform: translateX(300px);
  }
  40% {
    transform: translateX(-400px);
  }
  60% {
    transform: translateX(-1100px);
  }
  80% {
    transform: translateX(-1800px);
  }
  100% {
    transform: translateX(-2500px);
  }
}
</style>
