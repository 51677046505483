<template>
  <div class="needingAttention">
    <p class="title">填 表 须 知</p>
    <p class="item">
      1.填写本申请表应确保所填资料真实准确。
    </p>
    <p class="item">
      2.相关财务指标项填报说明：
    </p>
    <p class="item">
      （1）资产总额：按照企业申请当年的前一个年度经审计的年度财务报表中的总资产填列。
    </p>
    <p class="item">
      （2）主营业务收入：按照企业申请当年的前一个年度经审计的年度财务报表中的主营业务收入填列。
    </p>
    <p class="item">
      （3）利润总额：按照企业申请当年的前一个年度经审计的年度财务报表中的利润总额填列。
    </p>
    <p class="item">
      （4）“设计团队基本情况”表项，按照在本企业缴纳社保的人员情况填列。
    </p>
    <p class="item">
      （5）投入总额：按收付实现制计算的金额，包括人工费、仪器设备购置和安装费、商业软件购置费、租赁费、试制费、材料费、鉴定验收费、培训费、图书资料费、知识产权申请费、差旅费、会议费、设计外包采购费等。
    </p>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  components: {},
  mounted() {},
  methods: {}
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.needingAttention {
  //   font-weight: 700;
  padding: 10px 80px;
  .title {
    text-align: center;
    font-size: 25px;
    margin-bottom: 20px;
  }
  .item {
    // text-indent: 2rem;
    margin: 20px 0;
    font-size: 16px;
  }
}
</style>
