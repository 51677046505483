<template>
    <div class="receiveAdvice">
        <div class="receiveAdvice-top">
            <div class="blue-div"></div>
            <span style="margin-left: 10px;">收到建议查看</span>
        </div>
        <div class="sky-white"></div>
        <div class="receiveAdvice-content">
            <div class="content-one">
                <div><span style="color: red">*</span><span>标题</span></div>
                <div class="content-input">
                    <input readonly="readonly" v-model="programAdvise.title" type="text">
                </div>
            </div>
            <div class="content-two">
                <div><span style="color: red">*</span><span>详细描述 <span class="span-style">（欢迎提出您在使用过程中遇到的问题或宝贵意见，感谢您对我们的支持。500字符以内）</span></span></div>
                <div class="content-input">
                    <textarea readonly="readonly" v-model="programAdvise.content"  maxlength="500" name="" ></textarea>
                </div>
            </div>
            <div class="content-one">
                <div><span>姓名</span></div>
                <div class="content-input">
                    <input readonly="readonly" v-model="programAdvise.name"  type="text">
                </div>
            </div>
            <div class="content-one">
                <div><span>联系邮箱 <span class="span-style">（留下您的联系邮箱，便于我们及时回复您）</span></span></div>
                <div class="content-input">
                    <input readonly="readonly" v-model="programAdvise.email"  type="text">
                </div>
            </div>
            <div class="content-one">
                <div><span>联系电话</span></div>
                <div class="content-input">
                    <input readonly="readonly" v-model="programAdvise.phone"  type="text">
                </div>
            </div>
        </div>
        <div class="receiveAdvice-button">
            <button @click="escButton">返回</button>
        </div>
    </div>
</template>
<script>
    export default {
        name: "",
        props:{

        },
        data(){
            return {
                programAdvise:{},
            }
        },
        components: {

        },
        computed:{
            getAdviseId(){
                return this.$route.query.adviseId
            },
        },
        created(){
            this.init()
        },
        methods:{
            /**
             * 查询初始化数据
             * */
            init(){
                this.$http.get("/shsj_declare/hpDeclare/queryProgramAdviseDetail?adviseId="+this.getAdviseId).then((res)=>{
                    if(res.messageCode == 10000){
                        this.programAdvise = res.result.programAdvise;
                    }
                })
            },
            escButton(){
                this.$router.go(-1)
            }
        }
    }
</script>
<style scoped>
    .receiveAdvice{
        width: 100vm;
        height: 100vh;
        /*background-color: #f5f5f5;*/
    }
    .receiveAdvice-top{
        height: 60px;
        background-color: white;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 16px;
        padding-left: 40px;
    }
    .sky-white{
        height: 10px;
        background-color: #f5f5f5;
    }
    .blue-div{
        height: 25px;
        width: 5px;
        background-color: #3385ff;
    }
    .receiveAdvice-content{
        padding: 0 50px;
        background-color: white;
    }
    .content-one{
        height: 70px;
        margin-top: 30px;
    }
    .content-two{
        height: 275px;
        margin-top: 30px;
    }
    .content-input{
        margin-top: 10px;
        width: 100%;
    }
    .content-input input{
        height: 40px;
        width: 100%;
        border: 1px solid #dddddd;
    }
    .content-input textarea{
        height: 245px;
        width: 100%;
        border: 1px solid #dddddd;
    }
    .span-style{
        color: #999999;
        font-size: 14px;
    }
    .receiveAdvice-button button{
        margin: 70px 580px;
        width: 120px;
        height: 40px;
        border-radius: 5px;
        outline: none;
        border: 1px solid #dddddd;
        background-color: white;
        color: #666666;
    }
</style>