<template>
  <div class="login GL_login_register">
    <div class="loginBox">
      <div class="leftBox">
        <div class="rightTitle">申 报 文 件</div>
        <div>
          <!-- :title="
              `点击查看：${
                item.dataValue.split('/')[item.dataValue.split('/').length - 1]
              }`
            " -->
          <p
            class="itemp"
            @click="openPDF(item.dataValue)"
            :key="index"
            v-for="(item, index) in pdfList"
          >
            {{
              item.dataValue
                .split("/")
                [item.dataValue.split("/").length - 1].split(".")[0]
            }}
          </p>
        </div>
        <div class="english_link_box" @click="routerGoEnglish">
          Shanghai Design 100+ <br />
          Application Entrance for English
        </div>
      </div>
      <div class="from">
        <div class="title">上海设计申报项目管理平台</div>
        <Form
          ref="formValidate"
          :model="formValidate"
          :rules="ruleValidate"
          :label-width="0"
        >
          <FormItem label prop="accountCode">
            <Input
              v-model.trim="formValidate.accountCode"
              placeholder="请输入统一信用代码"
            ></Input>
          </FormItem>
          <FormItem label prop="accountPassword">
            <Input
              :maxlength="20"
              type="password"
              v-model.trim="formValidate.accountPassword"
              placeholder="请输入密码"
            ></Input>
          </FormItem>
          <FormItem class="formItem" label prop="pictureCode">
            <Input
              :maxlength="10"
              @keydown.enter.native="searchEnterFun"
              v-model.trim="formValidate.pictureCode"
              placeholder="请输入验证码"
            ></Input>
            <img
              @click="getCodeImg"
              alt="点击更换"
              title="点击更换"
              :src="this.codeUrl"
            />
          </FormItem>
          <FormItem>
            <Radio @click.native="flag" v-model="userCode">记住信用代码</Radio>
            <router-link class="ForgotPwd" to="/forgotpwd"
              >忘记密码</router-link
            >
          </FormItem>
          <FormItem>
            <Button type="primary" @click="handleSubmit('formValidate')"
              >马上登录</Button
            >
          </FormItem>
        </Form>
        <div class="foot">
          没有账号,
          <router-link to="/register">去注册</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import md5 from "js-md5";
export default {
  name: "Login",
  props: {},
  data() {
    const validateAccountCode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("该项为必填！"));
      } else {
        // 主账号
        if (value.indexOf("-") == -1) {
          if (!this.$util.CheckSocialCreditCode(value)) {
            callback(new Error("不是有效的统一社会信用编码！"));
          } else {
            callback();
          }
        } else {
          // 子账号
          if (!this.$util.CheckSocialCreditCode(value.split("-")[0])) {
            callback(new Error('"-" 前请输入有效的统一社会信用编码！'));
          } else {
            callback();
          }
        }
      }
    };
    return {
      pdfList: [],
      // 91310230MA1JXW0M07
      codeUrl: "/shsj_declare/account/getCaptcha",
      userCode: true,
      formValidate: {
        accountCode: "",
        accountPassword: "",
        pictureCode: ""
      },
      ruleValidate: {
        accountCode: [
          {
            validator: validateAccountCode,
            // validator: this.$util.validateUniformSocialCreditCode,
            trigger: "blur"
          }
        ],
        accountPassword: [
          { validator: this.$util.validatePass, trigger: "blur" }
        ],
        pictureCode: [
          { required: true, message: "验证码不能为空", trigger: "blur" }
        ]
      }
    };
  },
  created() {
    this.formValidate.accountCode = localStorage.getItem("accountCode");
    this.getCodeImg();
    this.downLoadPdf();
  },
  mounted() {},
  methods: {
    /**
     * method 登陆提交
     */
    handleSubmit(name) {
      this.$refs[name].validate(async valid => {
        if (valid) {
          var res = await this.$http.post("shsj_declare/account/accountLogin", {
            accountCode: this.formValidate.accountCode,
            accountPassword: md5(this.formValidate.accountPassword),
            pictureCode: this.formValidate.pictureCode
          });
          if (res.messageCode === 10000) {
            this.$Message.success("登录成功!");
            localStorage.setItem("companyName", res.result.account.companyName);
            localStorage.setItem("accountId", res.result.account.accountId);
            if (this.userCode) {
              localStorage.setItem(
                "accountCode",
                this.formValidate.accountCode
              );
            } else {
              localStorage.removeItem("accountCode");
            }
            this.$router.push("/declare");
          }
        }
      });
    },
    /**
     * 窗口代码
     * @param {Boolean} bRefresh 是否刷新
     * 刷新验证码功能
     */
    //     getIdentifyingCode() {

    //     this.validCodeSrc= "/ycs_back/login/getCaptcha?" + Timestamp;
    // },
    async getCodeImg() {
      let num = new Date().getTime();
      // let num = Math.ceil(Math.random() * 10);
      this.codeUrl = "/shsj_declare/account/getCaptcha?" + num;
    },
    /**
     * method 回车
     */
    searchEnterFun() {
      this.handleSubmit("formValidate");
    },
    /**
     * method 是否记住用户名
     */
    flag() {
      this.userCode = !this.userCode;
    },
    /*pdf
     **/

    async downLoadPdf() {
      let { result } = await this.$http.get(
        "/shsj_declare/sysDict/queryDictDataList?dictId=10500"
      );
      console.log(result);
      this.pdfList = result.dictDataList;
    },
    openPDF(e) {
      console.log(e);
      window.open(`${e}?time=${new Date().getTime()}`);
    },
    routerGoEnglish() {
      window.open("https://xmsb.creativecity.sh.cn/shsj-declare-en/#/login");
    }
  }
};
</script>
<style lang="scss" scoped>
.login {
  overflow: hidden;
  min-height: 100vh;
  position: relative;
  .loginBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
    .formItem {
      width: 80%;
      position: relative;
      img {
        width: 80px;
        position: absolute;
        top: -2px;
        right: -90px;
      }
    }
    .ivu-form-item-content {
      position: relative;
      .ForgotPwd {
        position: absolute;
        right: 0;
        color: #9c9c9c;
        cursor: pointer;
      }
    }
    .leftBox {
      font-family: "Microsoft YaHei", 微软雅黑, "MicrosoftJhengHei", 华文细黑,
        STHeiti, MingLiu;
      position: absolute;
      background: url("../../assets/baseMap.png") no-repeat;
      background-position: 30px 30px;
      width: 50%;
      height: 100%;
      padding: 60px;
    }
  }
  .rightTitle {
    color: #fff;
    font-size: 18px;
    margin-bottom: 50px;
    font-weight: 700;
  }
  .itemp {
    color: #fff;
    margin-bottom: 10px;
    cursor: pointer;
  }
}

.english_link_box {
  color: #fff;
  padding: 8px 25px;
  box-shadow: 0 0 12px 5px #226af4;
  width: 100%;
  border-radius: 4px;
  position: absolute;
  bottom: 60px;
  width: 70%;
  margin-left: 0;
  text-align: center;
  font-weight: bold;

  &:hover {
    cursor: pointer;
  }
}
</style>
