import Vue from "vue";
import VueRouter from "vue-router";
import Error404 from "../views/Error404.vue";
import Home from "../views/Home.vue";
// 登录,注册,忘记密码,找回密码
import Login from "../views/login/Login.vue";
import Register from "../views/login/Register.vue";
import ForgotPwd from "../views/login/ForgotPwd.vue";
import FindPwd from "../views/login/FindPwd.vue";
// 项目申报,项目管理
import Manage from "../views/project/Manage.vue";
import Declare from "../views/project/Declare.vue";
// 新增申报
import IndustryDesign from "../views/addDeclare/IndustryDesign.vue";
import ModeCompany from "../views/addDeclare/ModeCompany.vue";
import ShanghaiDesign from "../views/addDeclare/ShanghaiDesign.vue";
import ShanghaiDesign2023 from "../views/addDeclare/ShanghaiDesign2023.vue";
import ShanghaiDesign2024 from "../views/addDeclare/ShanghaiDesign2024.vue";
//上海设计预览
import ShanghaiDesignAuit from "../views/ShanghaiDesign/ShanghaiDesignAuit";
import ShanghaiDesignView from "../views/ShanghaiDesign/ShanghaiDesignView";
import ShanghaiDesignView2023 from "../views/ShanghaiDesign/ShanghaiDesignView2023";
import ShanghaiDesignView2024 from "../views/ShanghaiDesign/ShanghaiDesignView2024";
import receiveAdvice from "../views/ShanghaiDesign/receiveAdvice";
import receiveAdviceShow from "../views/ShanghaiDesign/receiveAdviceShow";
// 企业工业设计中心
import AttachmentUpload from "../views/industryCenter/AttachmentUpload.vue";
import CenterSitutation from "../views/industryCenter/CenterSitutation.vue";
import CompanySituation from "../views/industryCenter/CompanySituation.vue";
import Personnel from "../views/industryCenter/Personnel.vue";
import ResourcePerformance from "../views/industryCenter/ResourcePerformance.vue";
import StatusPlanning from "../views/industryCenter/StatusPlanning.vue";
// import NeedingAttention from '../views/industryCenter/NeedingAttention.vue';
// 工业设计企业
import IndustrialDesign from "../views/industryCompany/IndustrialDesign.vue";
import Resources from "../views/industryCompany/Resources.vue";
import Status from "../views/industryCompany/Status.vue";
import Member from "../views/industryCompany/Member.vue";
import IndustryUpload from "../views/industryCompany/IndustryUpload.vue";
// 企业工业设计中心,工业设计企业 查看修改
// import IndustryView from '../views/industryCompany/IndustryView.vue';
import CenterView from "../views/industryCenter/CenterView.vue";
// 市级设计引领示范企业查看
import ModeCompanyView from "../views/modeCompanyCompon/ModeCompanyView";

import "../style/common.scss";

Vue.use(VueRouter);

const routes = [
  // { path: '/', redirect: '/login' },
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/register",
    name: "register",
    component: Register
  },
  //Home
  {
    path: "/home",
    component: Home,
    children: [
      {
        path: "/forgotpwd",
        component: ForgotPwd
      },
      {
        path: "/findpwd",
        component: FindPwd
      },
      {
        path: "/manage",
        component: Manage,
        meta: { title: "项目管理", active: "/manage" }
      },
      {
        path: "/declare",
        meta: { title: "项目申报", active: "/declare" },
        component: Declare
      },
      {
        path: "/industrydesign",
        component: IndustryDesign,
        redirect: "/declare/companysituation",
        children: [
          {
            path: "/declare/attachmentupload",
            component: AttachmentUpload,
            meta: { active: "/manage" }
          },
          {
            path: "/declare/centersitutation",
            component: CenterSitutation,
            meta: { active: "/manage" }
          },
          {
            path: "/declare/companysituation",
            component: CompanySituation,
            meta: { active: "/manage" }
          },
          {
            path: "/declare/personnel",
            component: Personnel,
            meta: { active: "/manage" }
          },
          {
            path: "/declare/resourceperformance",
            component: ResourcePerformance,
            meta: { active: "/manage" }
          },
          {
            path: "/declare/statusplanning",
            component: StatusPlanning,
            meta: { active: "/manage" }
          },
          // {
          // 	path: '/declare/NeedingAttention',
          // 	component: NeedingAttention,
          // 	meta: { active: '/manage' }
          // },
          // {
          // 	path: '/declare/NeedingAttentionTwo',
          // 	component: NeedingAttention,
          // 	meta: { active: '/manage' }
          // },
          {
            path: "/declare/industrialdesign",
            component: IndustrialDesign,
            meta: { active: "/manage" }
          },
          {
            path: "/declare/resources",
            component: Resources,
            meta: { active: "/manage" }
          },
          {
            path: "/declare/status",
            component: Status,
            meta: { active: "/manage" }
          },
          {
            path: "/declare/member",
            component: Member,
            meta: { active: "/manage" }
          },
          {
            path: "/declare/industryupload",
            component: IndustryUpload,
            meta: { active: "/manage" }
          }
        ]
      },
      {
        path: "/modecompany",
        component: ModeCompany,
        meta: { active: "/manage" }
      },
      {
        path: "/shanghaidesign",
        component: ShanghaiDesign,
        meta: { active: "/manage" }
      },
      {
        path: "/ShanghaiDesign2023",
        component: ShanghaiDesign2023,
        meta: { active: "/manage" }
      },
      {
        path: "/ShanghaiDesign2024",
        component: ShanghaiDesign2024,
        meta: { active: "/manage" }
      },

      {
        path: "/shanghaiDesignAuit",
        component: ShanghaiDesignAuit,
        meta: { active: "/manage" }
      },
      {
        path: "/receiveAdvice",
        component: receiveAdvice,
        meta: { active: "/manage" }
      },
      {
        path: "/receiveAdviceShow",
        component: receiveAdviceShow,
        meta: { active: "/manage" }
      },
      {
        path: "/shanghaiDesignView",
        component: ShanghaiDesignView,
        meta: { active: "/manage" }
      },
      {
        path: "/shanghaiDesignView2023",
        component: ShanghaiDesignView2023,
        meta: { active: "/manage" }
      },
      {
        path: "/shanghaiDesignView2024",
        component: ShanghaiDesignView2024,
        meta: { active: "/manage" }
      },
      {
        name: "Industryview",
        path: "/industryview2020",
        component: () => import(`../views/industryCompany/IndustryView.vue`),
        meta: { active: "/manage" }
      },
      {
        name: "Industryview2021",
        path: "/industryview2021",
        component: () =>
          import(`../views/industryCompany/IndustryView2021.vue`),
        meta: { active: "/manage" }
      },
      {
        name: "Industryview2022",
        path: "/industryview2022",
        component: () =>
          import(`../views/industryCompany/IndustryView2022.vue`),
        meta: { active: "/manage" }
      },
      {
        name: "Industryview2023",
        path: "/industryview2023",
        component: () =>
          import(`../views/industryCompany/IndustryView2023.vue`),
        meta: { active: "/manage" }
      },
      {
        name: "Centerview",
        path: "/centerview2020",
        component: () => import(`../views/industryCenter/CenterView.vue`),
        meta: { active: "/manage" }
      },
      {
        name: "Centerview2021",
        path: "/centerview2021",
        component: () => import(`../views/industryCenter/CenterView2021.vue`),
        meta: { active: "/manage" }
      },
      {
        name: "Centerview2022",
        path: "/centerview2022",
        component: () => import(`../views/industryCenter/CenterView2022.vue`),
        meta: { active: "/manage" }
      },
      {
        name: "Centerview2023",
        path: "/centerview2023",
        component: () => import(`../views/industryCenter/CenterView2023.vue`),
        meta: { active: "/manage" }
      },
      {
        path: "/modeCompanyView",
        component: ModeCompanyView,
        meta: { active: "/manage" }
      },
      {
        path: "/modeCompanyView2020",
        component: () => import(`../views/modeCompanyCompon/ModeCompanyView`),
        meta: { active: "/manage" }
      },
      {
        path: "/modeCompanyView2021",
        component: () =>
          import(`../views/modeCompanyCompon/ModeCompanyView2021`),
        meta: { active: "/manage" }
      },
      {
        path: "/modeCompanyView2022",
        component: () =>
          import(`../views/modeCompanyCompon/ModeCompanyView2022`),
        meta: { active: "/manage" }
      }
    ]
  },

  // 404
  { path: "*", name: "notFind", component: Error404 }
];
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

const router = new VueRouter({
  // mode: "history",
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  // base: process.env.BASE_URL,
  //路由基路径 可设置为当前项目名
  base: "/shsj-declare-web/",
  routes
});

export default router;
