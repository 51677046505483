<template>
  <div class="broadsid">
    <ul class="broadsid_ul">
      <li
        :class="$route.fullPath.split('?')[0] === item.path ? 'active' : ''"
        @click="tabEvent(item.path)"
        v-for="(item, index) in tabList"
        :key="index"
      >
        {{ item.label }}
      </li>
      ​
    </ul>
  </div>
</template>

<script>
export default {
  name: "page",
  props: {
    tabList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    tabEvent(path) {
      this.$router.push(path);
    }
  },
  watch: {}
};
</script>

<style lang="scss" scoped>
.broadsid {
  width: 200px;
  border: 2px solid #f6f9ff;
  padding: 20px 0;
  .broadsid_ul {
    li {
      cursor: pointer;
      padding: 15px 12px;
      // font-size: 16px;
      // background-color: pink;
      border-radius: 5px;
      a {
        color: #000;
      }
    }
    .active {
      background-color: #3385ff;
      color: #fff;
    }
  }
}
</style>
