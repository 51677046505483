<template>
  <div class="supportingFacilities GL_declareForm Gl_input">
    <Form
      :rules="ruleValidate"
      class="form"
      ref="formValidate"
      :model="formValidate"
      :label-width="0"
    >
      <!-- （一）设计创新投入情况（单位：万元） -->
      <Row>
        <Col style="font-size:16px ; margin-bottom:10px" span="24"
          >（一）设计创新投入情况（单位：万元）</Col
        >
      </Row>
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col
            span="6"
            class="m_title"
            style="background:linear-gradient(21deg,#F6F9FF 49.5%, #E8ECF5 49.5%, #E8ECF5 50.5%, #F6F9FF 50.5%)"
          >
            <Row class="col_up">
              <Col span="24">年度</Col>
            </Row>
            <Row class="col_down">
              <Col span="24">投入分析</Col>
            </Row>
          </Col>
          <Col span="18">
            <Row type="flex">
              <Col class="m_col m_title" span="12">
                <span class="redStar">*</span>申报前的两年
              </Col>
              <Col class="m_col m_title" span="12">
                <span class="redStar">*</span>申报当年和后一年计划
              </Col>
            </Row>
            <Row type="flex">
              <Col class="m_col m_title" span="6">
                <span class="redStar">*</span>2020年
              </Col>
              <Col class="m_col m_title" span="6">
                <span class="redStar">*</span>2021年
              </Col>
              <Col class="m_col m_title" span="6">
                <span class="redStar">*</span>2022年
              </Col>
              <Col class="m_col m_title" span="6">
                <span class="redStar">*</span>2023年
              </Col>
            </Row>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="6">
            <span class="redStar">*</span>投入总额
          </Col>
          <Col span="18">
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[0].totalInvestment"
              >
                <Input
                  :maxlength="13"
                  v-model="
                    formValidate.ldeInvestmentSituationList[0].totalInvestment
                  "
                  class="unit_input"
                />
              </FormItem>
            </Col>
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[1].totalInvestment"
              >
                <Input
                  :maxlength="13"
                  v-model="
                    formValidate.ldeInvestmentSituationList[1].totalInvestment
                  "
                  class="unit_input"
                />
              </FormItem>
            </Col>
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[2].totalInvestment"
              >
                <Input
                  :maxlength="13"
                  v-model="
                    formValidate.ldeInvestmentSituationList[2].totalInvestment
                  "
                  class="unit_input"
                />
              </FormItem>
            </Col>
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[3].totalInvestment"
              >
                <Input
                  :maxlength="13"
                  v-model="
                    formValidate.ldeInvestmentSituationList[3].totalInvestment
                  "
                  class="unit_input"
                />
              </FormItem>
            </Col>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="6">
            其中：人工费
          </Col>
          <Col span="18">
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[0].laborFee"
              >
                <Input
                  :maxlength="13"
                  v-model="formValidate.ldeInvestmentSituationList[0].laborFee"
                  class="unit_input"
                />
              </FormItem>
            </Col>
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[1].laborFee"
              >
                <Input
                  :maxlength="13"
                  v-model="formValidate.ldeInvestmentSituationList[1].laborFee"
                  class="unit_input"
                />
              </FormItem>
            </Col>
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[2].laborFee"
              >
                <Input
                  :maxlength="13"
                  v-model="formValidate.ldeInvestmentSituationList[2].laborFee"
                  class="unit_input"
                />
              </FormItem>
            </Col>
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[3].laborFee"
              >
                <Input
                  :maxlength="13"
                  v-model="formValidate.ldeInvestmentSituationList[3].laborFee"
                  class="unit_input"
                />
              </FormItem>
            </Col>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="6">
            仪器设备购置和安装费
          </Col>
          <Col span="18">
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[0].equipmentPurchaseAndInstallationFee"
              >
                <Input
                  :maxlength="13"
                  v-model="
                    formValidate.ldeInvestmentSituationList[0]
                      .equipmentPurchaseAndInstallationFee
                  "
                  class="unit_input"
                />
              </FormItem>
            </Col>
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[1].equipmentPurchaseAndInstallationFee"
              >
                <Input
                  :maxlength="13"
                  v-model="
                    formValidate.ldeInvestmentSituationList[1]
                      .equipmentPurchaseAndInstallationFee
                  "
                  class="unit_input"
                />
              </FormItem>
            </Col>
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[2].equipmentPurchaseAndInstallationFee"
              >
                <Input
                  :maxlength="13"
                  v-model="
                    formValidate.ldeInvestmentSituationList[2]
                      .equipmentPurchaseAndInstallationFee
                  "
                  class="unit_input"
                />
              </FormItem>
            </Col>
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[3].equipmentPurchaseAndInstallationFee"
              >
                <Input
                  :maxlength="13"
                  v-model="
                    formValidate.ldeInvestmentSituationList[3]
                      .equipmentPurchaseAndInstallationFee
                  "
                  class="unit_input"
                />
              </FormItem>
            </Col>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="6">
            商业软件购置费
          </Col>
          <Col span="18">
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[0].softwarePurchaseFee"
              >
                <Input
                  :maxlength="13"
                  v-model="
                    formValidate.ldeInvestmentSituationList[0]
                      .softwarePurchaseFee
                  "
                  class="unit_input"
                />
              </FormItem>
            </Col>
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[1].softwarePurchaseFee"
              >
                <Input
                  :maxlength="13"
                  v-model="
                    formValidate.ldeInvestmentSituationList[1]
                      .softwarePurchaseFee
                  "
                  class="unit_input"
                />
              </FormItem>
            </Col>
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[2].softwarePurchaseFee"
              >
                <Input
                  :maxlength="13"
                  v-model="
                    formValidate.ldeInvestmentSituationList[2]
                      .softwarePurchaseFee
                  "
                  class="unit_input"
                />
              </FormItem>
            </Col>
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[3].softwarePurchaseFee"
              >
                <Input
                  :maxlength="13"
                  v-model="
                    formValidate.ldeInvestmentSituationList[3]
                      .softwarePurchaseFee
                  "
                  class="unit_input"
                />
              </FormItem>
            </Col>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="6">
            租赁费
          </Col>
          <Col span="18">
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[0].rentalFee"
              >
                <Input
                  :maxlength="13"
                  v-model="formValidate.ldeInvestmentSituationList[0].rentalFee"
                  class="unit_input"
                />
              </FormItem>
            </Col>
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[1].rentalFee"
              >
                <Input
                  :maxlength="13"
                  v-model="formValidate.ldeInvestmentSituationList[1].rentalFee"
                  class="unit_input"
                />
              </FormItem>
            </Col>
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[2].rentalFee"
              >
                <Input
                  :maxlength="13"
                  v-model="formValidate.ldeInvestmentSituationList[2].rentalFee"
                  class="unit_input"
                />
              </FormItem>
            </Col>
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[3].rentalFee"
              >
                <Input
                  :maxlength="13"
                  v-model="formValidate.ldeInvestmentSituationList[3].rentalFee"
                  class="unit_input"
                />
              </FormItem>
            </Col>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="6">
            试制费
          </Col>
          <Col span="18">
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[0].trialProductionFee"
              >
                <Input
                  :maxlength="13"
                  v-model="
                    formValidate.ldeInvestmentSituationList[0]
                      .trialProductionFee
                  "
                  class="unit_input"
                />
              </FormItem>
            </Col>
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[1].trialProductionFee"
              >
                <Input
                  :maxlength="13"
                  v-model="
                    formValidate.ldeInvestmentSituationList[1]
                      .trialProductionFee
                  "
                  class="unit_input"
                />
              </FormItem>
            </Col>
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[2].trialProductionFee"
              >
                <Input
                  :maxlength="13"
                  v-model="
                    formValidate.ldeInvestmentSituationList[2]
                      .trialProductionFee
                  "
                  class="unit_input"
                />
              </FormItem>
            </Col>
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[3].trialProductionFee"
              >
                <Input
                  :maxlength="13"
                  v-model="
                    formValidate.ldeInvestmentSituationList[3]
                      .trialProductionFee
                  "
                  class="unit_input"
                />
              </FormItem>
            </Col>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="6">
            材料费
          </Col>
          <Col span="18">
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[0].materialFee"
              >
                <Input
                  :maxlength="13"
                  v-model="
                    formValidate.ldeInvestmentSituationList[0].materialFee
                  "
                  class="unit_input"
                />
              </FormItem>
            </Col>
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[1].materialFee"
              >
                <Input
                  :maxlength="13"
                  v-model="
                    formValidate.ldeInvestmentSituationList[1].materialFee
                  "
                  class="unit_input"
                />
              </FormItem>
            </Col>
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[2].materialFee"
              >
                <Input
                  :maxlength="13"
                  v-model="
                    formValidate.ldeInvestmentSituationList[2].materialFee
                  "
                  class="unit_input"
                />
              </FormItem>
            </Col>
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[3].materialFee"
              >
                <Input
                  :maxlength="13"
                  v-model="
                    formValidate.ldeInvestmentSituationList[3].materialFee
                  "
                  class="unit_input"
                />
              </FormItem>
            </Col>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="6">
            鉴定验收费
          </Col>
          <Col span="18">
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[0].appraisalFee"
              >
                <Input
                  :maxlength="13"
                  v-model="
                    formValidate.ldeInvestmentSituationList[0].appraisalFee
                  "
                  class="unit_input"
                />
              </FormItem>
            </Col>
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[1].appraisalFee"
              >
                <Input
                  :maxlength="13"
                  v-model="
                    formValidate.ldeInvestmentSituationList[1].appraisalFee
                  "
                  class="unit_input"
                />
              </FormItem>
            </Col>
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[2].appraisalFee"
              >
                <Input
                  :maxlength="13"
                  v-model="
                    formValidate.ldeInvestmentSituationList[2].appraisalFee
                  "
                  class="unit_input"
                />
              </FormItem>
            </Col>
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[3].appraisalFee"
              >
                <Input
                  :maxlength="13"
                  v-model="
                    formValidate.ldeInvestmentSituationList[3].appraisalFee
                  "
                  class="unit_input"
                />
              </FormItem>
            </Col>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="6">
            培训费
          </Col>
          <Col span="18">
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[0].trainingFee"
              >
                <Input
                  :maxlength="13"
                  v-model="
                    formValidate.ldeInvestmentSituationList[0].trainingFee
                  "
                  class="unit_input"
                />
              </FormItem>
            </Col>
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[1].trainingFee"
              >
                <Input
                  :maxlength="13"
                  v-model="
                    formValidate.ldeInvestmentSituationList[1].trainingFee
                  "
                  class="unit_input"
                />
              </FormItem>
            </Col>
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[2].trainingFee"
              >
                <Input
                  :maxlength="13"
                  v-model="
                    formValidate.ldeInvestmentSituationList[2].trainingFee
                  "
                  class="unit_input"
                />
              </FormItem>
            </Col>
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[3].trainingFee"
              >
                <Input
                  :maxlength="13"
                  v-model="
                    formValidate.ldeInvestmentSituationList[3].trainingFee
                  "
                  class="unit_input"
                />
              </FormItem>
            </Col>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="6">
            图书资料费
          </Col>
          <Col span="18">
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[0].booksAndReferenceFee"
              >
                <Input
                  :maxlength="13"
                  v-model="
                    formValidate.ldeInvestmentSituationList[0]
                      .booksAndReferenceFee
                  "
                  class="unit_input"
                />
              </FormItem>
            </Col>
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[1].booksAndReferenceFee"
              >
                <Input
                  :maxlength="13"
                  v-model="
                    formValidate.ldeInvestmentSituationList[1]
                      .booksAndReferenceFee
                  "
                  class="unit_input"
                />
              </FormItem>
            </Col>
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[2].booksAndReferenceFee"
              >
                <Input
                  :maxlength="13"
                  v-model="
                    formValidate.ldeInvestmentSituationList[2]
                      .booksAndReferenceFee
                  "
                  class="unit_input"
                />
              </FormItem>
            </Col>
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[3].booksAndReferenceFee"
              >
                <Input
                  :maxlength="13"
                  v-model="
                    formValidate.ldeInvestmentSituationList[3]
                      .booksAndReferenceFee
                  "
                  class="unit_input"
                />
              </FormItem>
            </Col>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="6">
            知识产权申请费
          </Col>
          <Col span="18">
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[0].intellectualPropertyApplicationFee"
              >
                <Input
                  :maxlength="13"
                  v-model="
                    formValidate.ldeInvestmentSituationList[0]
                      .intellectualPropertyApplicationFee
                  "
                  class="unit_input"
                />
              </FormItem>
            </Col>
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[1].intellectualPropertyApplicationFee"
              >
                <Input
                  :maxlength="13"
                  v-model="
                    formValidate.ldeInvestmentSituationList[1]
                      .intellectualPropertyApplicationFee
                  "
                  class="unit_input"
                />
              </FormItem>
            </Col>
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[2].intellectualPropertyApplicationFee"
              >
                <Input
                  :maxlength="13"
                  v-model="
                    formValidate.ldeInvestmentSituationList[2]
                      .intellectualPropertyApplicationFee
                  "
                  class="unit_input"
                />
              </FormItem>
            </Col>
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[3].intellectualPropertyApplicationFee"
              >
                <Input
                  :maxlength="13"
                  v-model="
                    formValidate.ldeInvestmentSituationList[3]
                      .intellectualPropertyApplicationFee
                  "
                  class="unit_input"
                />
              </FormItem>
            </Col>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="6">
            差旅费
          </Col>
          <Col span="18">
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[0].travelExpensesFee"
              >
                <Input
                  :maxlength="13"
                  v-model="
                    formValidate.ldeInvestmentSituationList[0].travelExpensesFee
                  "
                  class="unit_input"
                />
              </FormItem>
            </Col>
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[1].travelExpensesFee"
              >
                <Input
                  :maxlength="13"
                  v-model="
                    formValidate.ldeInvestmentSituationList[1].travelExpensesFee
                  "
                  class="unit_input"
                />
              </FormItem>
            </Col>
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[2].travelExpensesFee"
              >
                <Input
                  :maxlength="13"
                  v-model="
                    formValidate.ldeInvestmentSituationList[2].travelExpensesFee
                  "
                  class="unit_input"
                />
              </FormItem>
            </Col>
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[3].travelExpensesFee"
              >
                <Input
                  :maxlength="13"
                  v-model="
                    formValidate.ldeInvestmentSituationList[3].travelExpensesFee
                  "
                  class="unit_input"
                />
              </FormItem>
            </Col>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="6">
            会议费
          </Col>
          <Col span="18">
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[0].conferenceExpensesFee"
              >
                <Input
                  :maxlength="13"
                  v-model="
                    formValidate.ldeInvestmentSituationList[0]
                      .conferenceExpensesFee
                  "
                  class="unit_input"
                />
              </FormItem>
            </Col>
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[1].conferenceExpensesFee"
              >
                <Input
                  :maxlength="13"
                  v-model="
                    formValidate.ldeInvestmentSituationList[1]
                      .conferenceExpensesFee
                  "
                  class="unit_input"
                />
              </FormItem>
            </Col>
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[2].conferenceExpensesFee"
              >
                <Input
                  :maxlength="13"
                  v-model="
                    formValidate.ldeInvestmentSituationList[2]
                      .conferenceExpensesFee
                  "
                  class="unit_input"
                />
              </FormItem>
            </Col>
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[3].conferenceExpensesFee"
              >
                <Input
                  :maxlength="13"
                  v-model="
                    formValidate.ldeInvestmentSituationList[3]
                      .conferenceExpensesFee
                  "
                  class="unit_input"
                />
              </FormItem>
            </Col>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="6">
            设计外包采购费
          </Col>
          <Col span="18">
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[0].outsourcingProcurementFee"
              >
                <Input
                  :maxlength="13"
                  v-model="
                    formValidate.ldeInvestmentSituationList[0]
                      .outsourcingProcurementFee
                  "
                  class="unit_input"
                />
              </FormItem>
            </Col>
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[1].outsourcingProcurementFee"
              >
                <Input
                  :maxlength="13"
                  v-model="
                    formValidate.ldeInvestmentSituationList[1]
                      .outsourcingProcurementFee
                  "
                  class="unit_input"
                />
              </FormItem>
            </Col>
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[2].outsourcingProcurementFee"
              >
                <Input
                  :maxlength="13"
                  v-model="
                    formValidate.ldeInvestmentSituationList[2]
                      .outsourcingProcurementFee
                  "
                  class="unit_input"
                />
              </FormItem>
            </Col>
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[3].outsourcingProcurementFee"
              >
                <Input
                  :maxlength="13"
                  v-model="
                    formValidate.ldeInvestmentSituationList[3]
                      .outsourcingProcurementFee
                  "
                  class="unit_input"
                />
              </FormItem>
            </Col>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="3">
            其他
          </Col>
          <Col class="m_col m_title" span="3">
            <Input
              placeholder="(描述说明)"
              :maxlength="20"
              v-model="formValidate.ldeInvestmentSituationList[0].otherExplain"
              class="unit_input"
            />
          </Col>
          <Col span="18">
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[0].otherFee"
              >
                <Input
                  :maxlength="13"
                  v-model="formValidate.ldeInvestmentSituationList[0].otherFee"
                  class="unit_input"
                />
              </FormItem>
            </Col>
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[1].otherFee"
              >
                <Input
                  :maxlength="13"
                  v-model="formValidate.ldeInvestmentSituationList[1].otherFee"
                  class="unit_input"
                />
              </FormItem>
            </Col>
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[2].otherFee"
              >
                <Input
                  :maxlength="13"
                  v-model="formValidate.ldeInvestmentSituationList[2].otherFee"
                  class="unit_input"
                />
              </FormItem>
            </Col>
            <Col class="m_col" span="6">
              <FormItem
                :rules="ruleValidate.validateThanZero"
                prop="ldeInvestmentSituationList[3].otherFee"
              >
                <Input
                  :maxlength="13"
                  v-model="formValidate.ldeInvestmentSituationList[3].otherFee"
                  class="unit_input"
                />
              </FormItem>
            </Col>
          </Col>
        </Row>
      </div>
      <!-- 主要用于设计的硬件情况 -->
      <Row>
        <Col style="font-size:16px ; margin-bottom:10px" span="24"
          >（二）软硬件配套设施情况</Col
        >
      </Row>
      <Row>
        <Col span="24">主要用于设计的硬件情况</Col>
      </Row>
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title icon_col" span="6">
            <Icon
              @click="add('hardware')"
              class="icon"
              type="ios-add-circle-outline"
            />
            <span class="text_col">
              <span class="redStar">*</span>仪器（设备）名称
            </span>
          </Col>
          <Col class="m_col m_title" span="6">
            <span class="redStar">*</span>台（套）数
          </Col>
          <Col class="m_col m_title" span="6">
            <span class="redStar">*</span>原值(总价)(万元)
          </Col>
          <Col class="m_col m_title" span="6">
            <span class="redStar">*</span>购置时间
          </Col>
        </Row>
        <Row
          v-for="(item, index) in formValidate.HardwareList"
          :key="index"
          class="m_row"
        >
          <Col class="m_col icon_col" span="6">
            <Icon
              @click="reduce('HardwareList', index)"
              class="icon"
              type="ios-remove-circle-outline"
            />
            <Input
              :maxlength="50"
              v-model.trim="item.instrumentName"
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              :prop="`HardwareList[${index}].number`"
            >
              <Input :maxlength="8" v-model="item.number" class="unit_input" />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validateThanZero"
              :prop="`HardwareList[${index}].originalValue`"
            >
              <Input
                :maxlength="13"
                v-model="item.originalValue"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <DatePicker
              :value="item.purchaseTime"
              @on-change="item.purchaseTime = $event"
              type="date"
              placeholder="选择时间"
              style="width: 200px"
            ></DatePicker>
          </Col>
        </Row>
      </div>
      <!-- 主要用于设计的软件情况 -->
      <Row>
        <Col span="24">主要用于设计的软件情况</Col>
      </Row>
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title icon_col" span="6">
            <Icon
              @click="add('software')"
              class="icon"
              type="ios-add-circle-outline"
            />
            <span class="text_col"> <span class="redStar">*</span>名称 </span>
          </Col>
          <Col class="m_col m_title" span="6">
            <span class="redStar">*</span>数量（套）
          </Col>
          <Col class="m_col m_title" span="6">
            <span class="redStar">*</span>价值（万元）
          </Col>
          <Col class="m_col m_title" span="6">
            <span class="redStar">*</span>购置时间
          </Col>
        </Row>
        <Row
          v-for="(item, index) in formValidate.SoftwareList"
          :key="index"
          class="m_row"
        >
          <Col class="m_col icon_col" span="6">
            <Icon
              @click="reduce('SoftwareList', index)"
              class="icon"
              type="ios-remove-circle-outline"
            />
            <Input
              :maxlength="50"
              v-model.trim="item.instrumentName"
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              :prop="`SoftwareList[${index}].number`"
            >
              <Input :maxlength="8" v-model="item.number" class="unit_input" />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validateThanZero"
              :prop="`SoftwareList[${index}].originalValue`"
            >
              <Input
                :maxlength="13"
                v-model="item.originalValue"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <DatePicker
              :value="item.purchaseTime"
              @on-change="item.purchaseTime = $event"
              type="date"
              placeholder="选择时间"
              style="width: 200px"
            ></DatePicker>
          </Col>
        </Row>
      </div>
    </Form>
    <div class="foot">
      <button v-waves @click="cancel" class="GL_cancel">取消</button>
      <button v-waves @click="save('formValidate')" class="GL_save">
        保存
      </button>
      <button v-waves @click="next('formValidate')" class="GL_next">
        保存并跳转下一步
      </button>
    </div>
  </div>
</template>

<script>
import waves from "../../directive/waves";
export default {
  name: "supportingFacilities",
  props: {},
  directives: { waves },
  data() {
    return {
      programId: null,
      formValidate: {
        ldeInvestmentSituationList: [
          {
            totalInvestment: null,
            laborFee: null,
            equipmentPurchaseAndInstallationFee: null,
            softwarePurchaseFee: null,
            rentalFee: null,
            trialProductionFee: null,
            materialFee: null,
            appraisalFee: null,
            trainingFee: null,
            booksAndReferenceFee: null,
            intellectualPropertyApplicationFee: null,
            travelExpensesFee: null,
            conferenceExpensesFee: null,
            outsourcingProcurementFee: null,
            otherExplain: null,
            otherFee: null,
            year: 2020
          },
          {
            totalInvestment: null,
            laborFee: null,
            equipmentPurchaseAndInstallationFee: null,
            softwarePurchaseFee: null,
            rentalFee: null,
            trialProductionFee: null,
            materialFee: null,
            appraisalFee: null,
            trainingFee: null,
            booksAndReferenceFee: null,
            intellectualPropertyApplicationFee: null,
            travelExpensesFee: null,
            conferenceExpensesFee: null,
            outsourcingProcurementFee: null,
            otherExplain: null,
            otherFee: null,
            year: 2021
          },
          {
            totalInvestment: null,
            laborFee: null,
            equipmentPurchaseAndInstallationFee: null,
            softwarePurchaseFee: null,
            rentalFee: null,
            trialProductionFee: null,
            materialFee: null,
            appraisalFee: null,
            trainingFee: null,
            booksAndReferenceFee: null,
            intellectualPropertyApplicationFee: null,
            travelExpensesFee: null,
            conferenceExpensesFee: null,
            outsourcingProcurementFee: null,
            otherExplain: null,
            otherFee: null,
            year: 2022
          },
          {
            totalInvestment: null,
            laborFee: null,
            equipmentPurchaseAndInstallationFee: null,
            softwarePurchaseFee: null,
            rentalFee: null,
            trialProductionFee: null,
            materialFee: null,
            appraisalFee: null,
            trainingFee: null,
            booksAndReferenceFee: null,
            intellectualPropertyApplicationFee: null,
            travelExpensesFee: null,
            conferenceExpensesFee: null,
            outsourcingProcurementFee: null,
            otherExplain: null,
            otherFee: null,
            year: 2023
          }
        ],
        ldeSoftwareHardwareList: [],
        HardwareList: [
          {
            instrumentName: null,
            number: null,
            originalValue: null,
            purchaseTime: null,
            type: "1"
          }
        ],
        SoftwareList: [
          {
            instrumentName: null,
            number: null,
            originalValue: null,
            purchaseTime: null,
            type: "2"
          }
        ]
      },
      ruleValidate: {
        validateThanZero: [
          {
            validator: this.$util.validateThanZero,
            trigger: "blur"
          }
        ],
        validatePositiveInteger: [
          { validator: this.$util.validatePositiveInteger, trigger: "blur" }
        ]
      }
    };
  },
  created() {
    // 查询表
    this.queyData();
  },
  watch: {},
  computed: {},
  components: {},
  mounted() {},
  methods: {
    /*method querydata
     *查询表
     */
    async queyData() {
      let { messageCode, result } = await this.$http.post(
        "/shsj_declare/ldeDeclare/queryLdeDeclareInfo",
        {
          programId: this.$route.query.programId,
          specialId: this.$route.query.specialId,
          type: "4"
        }
      );
      if (messageCode == 10000) {
        this.programId = result.declareInfo.programId;
        if (
          result.declareInfo.ldeSupportingSituationInfo
            .ldeInvestmentSituationList.length == 4
        ) {
          this.formValidate.ldeInvestmentSituationList =
            result.declareInfo.ldeSupportingSituationInfo.ldeInvestmentSituationList;
        }
        if (
          result.declareInfo.ldeSupportingSituationInfo.ldeSoftwareHardwareList
            .length > 1
        ) {
          this.formValidate.HardwareList = result.declareInfo.ldeSupportingSituationInfo.ldeSoftwareHardwareList.filter(
            item => item.type == 1
          );
          this.formValidate.SoftwareList = result.declareInfo.ldeSupportingSituationInfo.ldeSoftwareHardwareList.filter(
            item => item.type == 2
          );
        }
      }
    },
    /**增加产品
     * method add product
     * */
    add(e) {
      switch (e) {
        case "hardware":
          this.formValidate.HardwareList.push({
            instrumentName: null,
            number: null,
            originalValue: null,
            purchaseTime: null,
            type: 1
          });
          break;
        case "software":
          this.formValidate.SoftwareList.push({
            instrumentName: null,
            number: null,
            originalValue: null,
            purchaseTime: null,
            type: 2
          });
          break;
      }
    },
    /**减少产品
     * method reduce product
     * */
    reduce(type, index) {
      switch (type) {
        case "HardwareList":
          if (this.formValidate.HardwareList.length > 1) {
            this.formValidate.HardwareList.splice(index, 1);
          } else {
            this.$Message.error("最少一条");
          }

          break;
        case "SoftwareList":
          if (this.formValidate.SoftwareList.length > 1) {
            this.formValidate.SoftwareList.splice(index, 1);
          } else {
            this.$Message.error("最少一条");
          }

          break;
      }
    },
    /*method
     *点击取消
     */
    cancel() {
      this.$router.go(-1);
    },
    /*method
     *点击保存
     */
    save(name) {
      this.saveProgram(name);
    },
    /*method
     *点击下一步
     */
    next(name) {
      this.saveProgram(name, "next");
    },
    /*保存表单
     *method saveProgram
     **/
    saveProgram(name, type) {
      this.$refs[name].validate(async valid => {
        if (valid) {
          for (let k in this.formValidate.ldeInvestmentSituationList) {
            let formValidateItem = this.formValidate.ldeInvestmentSituationList[
              k
            ];
            let itemArray = [
              Number(formValidateItem.appraisalFee),
              Number(formValidateItem.laborFee),
              Number(formValidateItem.equipmentPurchaseAndInstallationFee),
              Number(formValidateItem.softwarePurchaseFee),
              Number(formValidateItem.rentalFee),
              Number(formValidateItem.trialProductionFee),
              Number(formValidateItem.materialFee),
              Number(formValidateItem.trainingFee),
              Number(formValidateItem.booksAndReferenceFee),
              Number(formValidateItem.intellectualPropertyApplicationFee),
              Number(formValidateItem.travelExpensesFee),
              Number(formValidateItem.conferenceExpensesFee),
              Number(formValidateItem.outsourcingProcurementFee),
              Number(formValidateItem.otherFee)
            ];

            let total = itemArray.reduce(function(a, b) {
              var r1, r2, m;
              try {
                r1 = a.toString().split(".")[1].length;
              } catch (e) {
                r1 = 0;
              }
              try {
                r2 = b.toString().split(".")[1].length;
              } catch (e) {
                r2 = 0;
              }
              // this.$nextTick(() => {
              m = Math.pow(10, Math.max(r1, r2));
              // });

              return (a * m + b * m) / m;
            });

            if (total.toFixed(2) != Number(formValidateItem.totalInvestment)) {
              return this.$Message.error(
                `${k * 1 + 2020}年投入总额与总金额不等`
              );
            }
          }

          let { messageCode } = await this.$http.post(
            "/shsj_declare/ldeDeclare/saveLdeDeclareInfo",
            {
              programId: this.programId,
              type: "4",
              specialId: this.$route.query.specialId,
              ldeSupportingSituationInfo: {
                ldeInvestmentSituationList: this.formValidate
                  .ldeInvestmentSituationList,
                ldeSoftwareHardwareList: [
                  ...this.formValidate.HardwareList,
                  ...this.formValidate.SoftwareList
                ]
              }
            }
          );
          if (messageCode == 10000) {
            this.$Message.success("保存页面成功!");
            if (type == "next") {
              this.$emit("nextChange", 5);
            }
          }
        } else {
          this.$Message.error("内容格式不正确!");
        }
      });
    }
  }
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.supportingFacilities {
  .col_down {
    height: 45px;
    text-align: left;
    line-height: 45px;
    border-bottom: 1px solid #e8ecf5;
    border-right: 1px solid #e8ecf5;
    padding-left: 25px;
  }
  .col_up {
    text-align: right;
    height: 45px;
    line-height: 45px;
    border-right: 1px solid #e8ecf5;
    padding-right: 25px;
  }
}
</style>
