<template>
  <div class="crumbs">
    <div class="title">{{ title }}</div>
  </div>
</template>

<script>
export default {
  name: "crumbs",
  props: {
    title: {
      type: String,
      default() {
        return "";
      }
    }
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {}
};
</script>
<style lang="scss" scoped>
.crumbs {
  display: flex;
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  .title {
    font-size: 18px;
    border-left: 4px solid #3385ff;
    padding-left: 10px;
  }
}
</style>
